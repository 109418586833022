import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { getStatsObjectFromData, getTopScoreList, getHighscoreListWithOwnScore, getUserHighscoreName, numberWithSpaces } from './StatHelper';
import { Translate } from 'react-i18nify';

import DistributionChart from './DistributionChart';

/**
 * Mission stats component with score distribution chart, high score list, game status and personal top 3 score
 */
class MissionStats extends Component {
    constructor(props, context) {
        super(props, context);
    }

    /**
     * Get Chart
     * @param skipResults returns empty div if skipResults set to true
     * @returns {XML}
     */
    getChart(skipResults) {
        if (skipResults) {
            return <div className="mission-stats--chart">
                <br/><Translate value="noResultsTutorial"/>
            </div>;
        }
        else {
            return <div className="mission-stats--chart">
                <DistributionChart stats={this.props.highScoreStats} adminView={this.props.adminView}
                                   lineColor={this.props.lineColor} profile={this.props.profile}/>
            </div>;
        }
    }

    /**
     * Get Highscore list
     * @param skipResults returns empty div if skipResults set to true
     * @returns {XML}
     */
    getHigscores(skipResults) {
        if (skipResults) {
            return <div className="mission-stats--highscore"></div>;
        }
        else {
            return <Highscores stats={this.props.highScoreStats} usersById={this.props.usersById}
                               profile={this.props.profile} adminView={this.props.adminView} />;
        }
    }

    /**
     * Get personal top score
     * returns empty div if adminView or skipResults is set to true
     * @param adminView
     * @param skipResults
     * @returns {*}
     */
    getPersonalTopScore(adminView, skipResults) {
        if (adminView || skipResults) {
            return <div className="mission-stats--highscore"></div>;
        }
        else {
            return <React.Fragment>
                        <Divider/>
                        <PersonalTopScore scores={getTopScoreList(this.props.stats, 3, 0, null, !this.props.adminView)}/>
                    </React.Fragment>;
        }
    }


    render() {
        return (
            <div className="mission-stats">
                {this.getChart(this.props.skipResults)}
                <div className="mission-stats--data">
                    <div className="mission-stats--data-container">
                        {this.getHigscores(this.props.skipResults)}
                        <GameStatus stats={this.props.stats}/>
                    </div>
                    {this.getPersonalTopScore(this.props.adminView, this.props.skipResults)}
                </div>
            </div>
        );
    }
}

MissionStats.propTypes = {
    usersById: PropTypes.object,
    missionData: PropTypes.object,
    adminView: PropTypes.bool.isRequired,
    lineColor: PropTypes.string.isRequired
};

/**
 * High score list component
 */
class Highscores extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            selectedTime: 0
        }
    }

    /**
     * Handler for time range selected event.
     * Sets selectedTime state to selected value to change selected item in drop down menu
     * @param event
     * @param index
     * @param value Value of selected drop down item
     */
    onTimeChange(event) {
        this.setState({
            selectedTime: event.target.value
        });
    }

    getHighScoreList(stats) {
        const result = [];
        const startTime = this.state.selectedTime !== 0 ? moment().subtract(this.state.selectedTime, 'months') : null;

        const scores = this.props.adminView ? getTopScoreList(stats, 10, 0, startTime, !this.props.adminView, true) :
            getHighscoreListWithOwnScore(stats, null, null, this.props.profile._id, startTime);

        for (let i = 0; i < scores.length; i++) {
            let stat = scores[i];
            const user = stat.userId === this.props.profile._id ? this.props.profile : this.props.usersById[stat.userId];
            result.push({rank: stat.rank? stat.rank : (i+1) , name: getUserHighscoreName(user, undefined, undefined, this.props.adminView), score: stat.score});
        }

        return result;
    }

    getHighscoreItems() {
        const scores = this.getHighScoreList(this.props.stats);
        return scores.map((highscore, index) =>
            <tr key={index} className="mission-stats--highscore-item">
                <td>{highscore.rank}.</td>
                <td className="mission-stats--highscore-name">{highscore.name}</td>
                <td className="mission-stats--highscore-score">{numberWithSpaces(highscore.score)}</td>
            </tr>
        );
    }

    render() {
        const texts = {
            highScore: <Translate value="highScore"/>,
            allTime: <Translate value="allTime"/>,
            lastMonths6: <Translate value="lastMonths6"/>,
            lastMonths3: <Translate value="lastMonths3"/>,
            lastMonth: <Translate value="lastMonth"/>
        };
        return (
            <div className="mission-stats--highscore">
                <div className="mission-stats--highscore-container">
                    <h2 className="header2 mission-stats--highscore-header">{texts.highScore}</h2>
                    <div className="mission-stats--highscore-time">
                        <Select value={this.state.selectedTime} onChange={this.onTimeChange.bind(this)}>
                            <MenuItem key={0} value={0}>{texts.allTime}</MenuItem>
                            <MenuItem key={6} value={6}>{texts.lastMonths6}</MenuItem>
                            <MenuItem key={3} value={3}>{texts.lastMonths3}</MenuItem>
                            <MenuItem key={1} value={1}>{texts.lastMonth}</MenuItem>
                        </Select>
                    </div>
                    <table className="mission-stats--highscore-list">
                        <tbody>
                        {this.getHighscoreItems()}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

Highscores.propTypes = {
    usersById: PropTypes.object
};

/**
 * Game status component with game stats
 */
class GameStatus extends Component {
    constructor(props, context) {
        super(props, context);
    }

    getStatusData(stats) {
        return getStatsObjectFromData(stats);
    }


    render() {
        const statusData = this.getStatusData(this.props.stats);

        const texts = {
            gameStatus: <Translate value="gameStatus"/>,
            totalPlayTimes: <Translate value="totalPlayTimes"/>,
            aborted: <Translate value="aborted"/>,
            playTime: <Translate value="playTime"/>,
            playTimeAvg: <Translate value="playTimeAvg"/>
        };
        return (
            <div className="mission-stats--game-status">
                <div className="mission-stats--game-status-header">
                    <h2 className="header2">{texts.gameStatus}</h2>
                </div>
                <table className="mission-stats--status-list">
                    <tbody>
                    <tr className="mission-stats--status-row">
                        <td>{texts.totalPlayTimes}</td>
                        <td className="mission-stats--status-value">{statusData.totalTimesPlayed}</td>
                    </tr>
                    <tr className="mission-stats--status-row">
                        <td>{texts.aborted}</td>
                        <td className="mission-stats--status-value">{statusData.abortedCount}</td>
                    </tr>
                    <tr className="mission-stats--status-row">
                        <td>{texts.playTime}</td>
                        <td className="mission-stats--status-value">{statusData.totalPlayTime}</td>
                    </tr>
                    <tr className="mission-stats--status-row">
                        <td>{texts.playTimeAvg}</td>
                        <td className="mission-stats--status-value">{statusData.avgPlayTime}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

GameStatus.propTypes = {
    stats: PropTypes.array
};

/**
 * Personal top 3 score component
 */
class PersonalTopScore extends Component {
    constructor(props, context) {
        super(props, context);
    }

    getScoreTableHeaders() {
        return this.props.scores.map((score, index) =>
            <th key={index}>{score.score}</th>
        );
    }

    getScoreTableCells() {
        return this.props.scores.map((score, index)=>
            <td key={index}>{score.beginTime ? moment(score.beginTime).format('D.M.YYYY') : ''}</td>
        );
    }

    render() {
        if (this.props.scores === undefined) {
            return null;
        }

        const texts = {
            personalTop: <Translate value="personalTop3"/>
        };

        return (
            <div className="mission-stats--personal">
                <h2 className="header2">{texts.personalTop}</h2>
                <table className="mission-stats--personal-table">
                    <tbody>
                    <tr className="mission-stats--personal-row">
                        {this.getScoreTableHeaders()}
                    </tr>
                    <tr className="mission-stats--personal-date">
                        {this.getScoreTableCells()}
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

PersonalTopScore.propTypes = {
    scores: PropTypes.array
};

export default MissionStats;
