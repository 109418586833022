import { SEARCH_POOL, ERROR_RESPONSE, CREATE_POOL, UPDATE_POOL, DELETE_POOL } from '../actions/types';

const INITIAL_STATE = { poolSearchResults: [], message: '', error: '' };

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case CREATE_POOL:
            window.dispatchEvent(new CustomEvent('notification', { detail: { message: 'pool_created' } }));
            return { ...state, poolSearchResults: [] };
        case UPDATE_POOL:
            window.dispatchEvent(new CustomEvent('notification', { detail: { message: 'pool_updated' } }));
            return { ...state, poolSearchResults: [] };
        case ERROR_RESPONSE:
            return { ...state, error: action.payload };
        case SEARCH_POOL:
            return { ...state, poolSearchResults: action.payload };
        case DELETE_POOL:
            window.dispatchEvent(new CustomEvent('notification', { detail: { message: 'pool_deleted' } }));
            return { ...state, poolSearchResults: state.poolSearchResults.filter((item, index) => item.pool.id != action.payload.results)};
        default:
            return state;
    }
}
