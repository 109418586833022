import React, { Component } from 'react';
import { connect } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import { Translate, I18n } from 'react-i18nify';
import { bindActionCreators } from 'redux';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { InputLabel, FormControl } from '@material-ui/core';
import { registerUser, getRegisterInfo } from './actions/auth';
import { ERROR_RESPONSE } from './actions/types';
import { validatePassword, UNITS, LANGUAGES } from './Helpers';
import PrivacyPolicyAgreement from './PrivacyPolicyAgreement';
import Localization from './Localization';

const texts = {
    welcome: <Translate value="welcome"/>,
    description1: <Translate value="registerDescription1"/>,
    description2: <Translate value="registerDescription2"/>,
    description4: <Translate value="registerDescription4" />,
    firstName: <Translate value="firstNameRegForm" />,
    lastName: <Translate value="lastNameRegForm"/>,
    nickName: <Translate value="nickName"/>,
    title: <Translate value="jobTitleRegForm"/>,
    password: <Translate value="passwordRegForm"/>,
    verifyPassword: <Translate value="verifyPassword"/>,
    save: <Translate value="save"/>,
    errorPasswords: <Translate value="passwordsDontMatch"/>,
    errorPasswordComplexity: <Translate value="passwordComplexity"/>,
    units: <Translate value="unitPlural"/>,
    language: <Translate value="language"/>,
    choosePreferredLanguage: <Translate value="choosePreferredLanguage"/>,
    fillPersonalInfo: <Translate value="fillPersonalInfo"/>
};

class RegisterUserPage extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            agreementConfirmed: false,
            firstName: '',
            lastName: '',
            nick: '',
            title: '',
            password: '',
            verifyPassword: '',
            unit: UNITS[0].value,
            language: LANGUAGES[0].value,
        };
    }

    /**
     * Component mout, get email using url token
     */
    componentWillMount() {
        if (this.props.getRegisterInfo) {
            this.props.getRegisterInfo(this.getToken());
        }
    }

    /**
     * Get token from url
     * @returns {string}
     */
    getToken() {
        const i = window.location.href.indexOf("?");
        return i !== -1 ? window.location.href.substr(i + 1) : "";
    }

    /**
     * Handler for save button click event.
     * Sends register user request to back-end
     */
    onSaveClick() {
        if (this.verifyInputs()) {
            this.props.registerUser(this.getToken(), {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.props.email,
                password: this.state.password,
                title: this.state.title,
                agreementConfirmed: this.state.agreementConfirmed,
                unit: this.state.unit,
                nick: this.state.nick,
                language: this.state.language
            });
        }
    }

    /**
     * Returns translation object for password error or null if password is OK
     * @returns {object}
     */
    verifyPasswords() {
        if (this.state.verifyPassword && this.state.password) {
            if (this.state.verifyPassword !== this.state.password) {
                return texts.errorPasswords;
            }
            if (!validatePassword(this.state.password)) {
                return texts.errorPasswordComplexity;
            }
        }
        return '';
    }

    /**
     * Returns true if all text fields are valid and passwords match.
     * @returns {boolean}
     */
    verifyInputs() {
        return this.state.firstName.length > 0 && this.state.lastName.length > 0 && this.state.title.length > 0 &&
            this.state.password.length > 0 && this.state.verifyPassword.length > 0 && this.verifyPasswords() === '';
    }

    /**
     * Returns error message for server response error
     * @returns {string} Error message
     */
    getErrorMessage() {
        //TODO: Use proper error messages
        if (this.props.errorMessage && this.props.errorMessage.statusText) {
            return <Translate value="errorWithMessage" message={this.props.errorMessage.statusText}/>;
        }
        return '';
    }

    getPossibleNickError(message) {
        if (message && message.data && message.data.error === "duplicate_nick") {
            return  I18n.t('duplicate_nick');
        }
        return '';
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.authenticated) {
            this.props.history.push('/account');
        }
        if (nextProps.errorMessage && this.props.errorMessage !== nextProps.errorMessage) {
            if (!nextProps.errorMessage.data ||  nextProps.errorMessage.data.error !== "duplicate_nick") {
                this.props.history.push('/');

                const errorMessage = nextProps.errorMessage;
                this.props.dispatch({type: ERROR_RESPONSE, payload: null});
                window.setTimeout(function() {
                    window.dispatchEvent(new CustomEvent('custom_error', {detail: errorMessage}));
                }, 1000);
            }
        }
    }

    onUnitChange = (event) => this.setState({unit: event.target.value});

    onLanguageChange = (event) => {
        this.setState({language: event.target.value});
        Localization.setLocale(event.target.value);
    };

    render() {
        const passwordErrorMessage = this.verifyPasswords();
        const errorMessage = this.getErrorMessage();
        const errorMessageNick = this.getPossibleNickError(this.props.errorMessage);
        const buttonDisabled = this.verifyInputs() === false || this.state.agreementConfirmed === false;
        // I18n.t() used to make concatenation to mailto:"" easier
        // Using I18n.t() in class const's does not work for some reason
        const supportEmail = I18n.t("supportEmail");
        let buttonStyle = 'register--button' + (buttonDisabled ? ' register--button-disabled' : '');
        const style = {
            title: { marginBottom: '30px' },
            description4: { margin: '8% auto 5% auto'},
            select: { marginTop: '0.5em' }
        };

        const units = [];
        for (let i = 0; i < UNITS.length; i++) {
            units.push(<MenuItem key={i} value={UNITS[i].value}>{I18n.t(UNITS[i].locKey)}</MenuItem>);
        }

        const languages = [];
        for (let i = 0; i < LANGUAGES.length; i++) {
            languages.push(<MenuItem key={i} value={LANGUAGES[i].value}>{I18n.t(LANGUAGES[i].locKey)}</MenuItem>);
        }
        return (
            <div className="main-page register--page">
                <Paper className="main-page--content">
                    <div className="register--content">
						<div className="register--logo-container">
							<img className="register--logo" src={napconBuildVersion + "images/napcon_logo.png"} />
                        </div>
                        <h1 className="register--header" style={style.title}>{texts.welcome}</h1>
                        <Divider/>
                        <div className="register--description">{texts.description1}</div>
                        <div className="register--description">
                            {texts.description2} <a href={"mailto:" + supportEmail}>{supportEmail}</a>.
                        </div>
                        <form>
                            <div className="register--description">{texts.choosePreferredLanguage}:</div>
                            <FormControl style={style.select}>
                                <InputLabel shrink>{texts.language}</InputLabel>
                                <Select onChange={this.onLanguageChange}
                                            value={this.state.language}>{languages}</Select>
                            </FormControl>

                            <div className="register--description">{texts.fillPersonalInfo}:</div>
                            <TextField className="register--textfield required-field" label={texts.firstName}
                                       required onChange={e => this.setState({firstName: e.target.value})}
                                       autoComplete="given-name"/>
                            <TextField className="register--textfield" label={texts.lastName}
                                       required onChange={e => this.setState({lastName: e.target.value})}
                                       autoComplete="family-name"
                                       />
                            <TextField className="register--textfield" label={texts.nickName}
                                    onChange={e => this.setState({ nick: e.target.value })}
                                    autoComplete="nick-name" error={errorMessageNick !== ""}
                                    helperText={errorMessageNick} maxLength={20}
                                    />
                            <TextField className="register--textfield" label={texts.title}
                                       required onChange={e => this.setState({title: e.target.value})}
                                       autoComplete="honorific-prefix"
                                        />
                            <FormControl style={style.select}>
                                <InputLabel shrink>{texts.units}</InputLabel>
                                <Select onChange={this.onUnitChange}
                                    value={this.state.unit}>
                                        {units}
                                </Select>
                            </FormControl>
                            <div style={style.description4}>
                                        {texts.description4}
                                        </div>
                            <TextField className="register--textfield" label={this.props.email} disabled
                                        />
                            <TextField className="register--textfield" label={texts.password} type="password"
                                       required onChange={e => this.setState({password: e.target.value})}
                                       autoComplete="new-password"
                                       />
                            <TextField className="register--textfield" label={texts.verifyPassword} required
                                       type="password" error={passwordErrorMessage !== ""} helperText={passwordErrorMessage}
                                       onChange={e => this.setState({verifyPassword: e.target.value})}
                                       autoComplete="new-password"
                                       />
                            <PrivacyPolicyAgreement simple={true} onChange={(e) => this.setState({agreementConfirmed: !this.state.agreementConfirmed})}/>
                        </form>
                        <Button className={buttonStyle}
                                    onClick={this.onSaveClick.bind(this)} disabled={buttonDisabled}>{texts.save}</Button>
                        <div>{errorMessage}</div>
                    </div>
                </Paper>
            </div>
        );
    }
}

RegisterUserPage.defaultProps = {
    email: ''
};

function mapStateToProps(state) {
    return {
        errorMessage: state.auth.error,
        message: state.auth.message,
        authenticated: state.auth.authenticated,
        email: state.auth.registerEmail
    };
}

const mapDispatchToProps = (dispatch) => {
    const boundActionCreators = bindActionCreators({registerUser, getRegisterInfo}, dispatch);
    return {...boundActionCreators, dispatch};
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterUserPage);
