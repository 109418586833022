import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { I18n, Translate } from 'react-i18nify';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { DialogTitle, DialogContent, DialogActions, Paper } from '@material-ui/core';
import UserPoolStats from './UserPoolStats';
import { getStatsObjectFromData, getStatsWithGroupFilter } from './StatHelper';
import { getUrlParameterByName } from "./Helpers";
import { getStats, getStatsForUsersArgs } from './actions/stats';
import { getHighScoresArgs, getHighScoreSum, getHighscoresForGame, getScoresForUserByGame, getBestScoresForUser} from './actions/highscore';
import { getActiveUsersArgs} from './actions/activeusers';

class PoolStatPopup extends Component {
    constructor(props, context) {
        super(props, context);

        this.resetFetches();

        this.state = {
            blockBackgroundClick: true,
            scoreListPool: [],
            //All groups enabled as default
            selectedGroup: getUrlParameterByName("group") || "-1",
            selectedUser: getUrlParameterByName("user") || "-1",
            selectedTime: parseInt(getUrlParameterByName("time")) || 1,
            selectedGame: getUrlParameterByName("game") || "none",
            selectedMissionId: parseInt(getUrlParameterByName("mission")) || -1,
            gameList:  ["furnace", "distiller", "condensing", "boiling", "heat transfer"],
        };
    }

    componentDidMount() {
        this.checkGetStats(this.props, this.state.selectedTime);
        this.props.getScoresForUserByGame(this.props.userPool.pool._id,I18n._localeKey);
    }

    componentWillUnmount() {
        this.resetFetches();
    }

    componentDidUpdate(prevProps) {
        this.checkGetStats(this.props, this.state.selectedTime);

        if (prevProps.userPool.pool._id !== this.props.userPool.pool._id) {
            this.resetFetches();
            this.props.getScoresForUserByGame(this.props.userPool.pool._id,I18n._localeKey);
        }
    }

    resetFetches() {
        this.fetchedStats = false;
        this.fetchedHighscores = false;
        this.fetchedActiveUsers = false;
        this.fetchedTotalPoints = false;
        this.fetchedTime = {};
        this.prevSelectedUser = "-1";
        this.prevSelectedGroup = "-1";
    }

    checkGetStats(checkedProps, time) {
        if (!checkedProps.rights || checkedProps.rights.init === false) {
            this.resetFetches();
            return;
        }

        if (this.userIsAdmin(checkedProps)) {
            const activeMonths = time >= 0 && time < 6 ? 6 : time;
            this.checkGetMonthlyStatsFromServer(checkedProps, time, "fetchedStats", checkedProps.getStatsForUsersArgs.bind(this));
        }
        else {
            if (!this.fetchedStats) {
                this.fetchedStats = true;
                const endTime = moment().endOf('day');
                const startTime = moment(endTime).subtract(1, 'month');
                this.props.getStats(checkedProps.profile._id, startTime, endTime, null, checkedProps.currentGame);
            }
        }
    }

   checkGetMonthlyStatsFromServer(checkedProps, months, fetchVariableName, apiCall) {
        if (checkedProps.currentGame && checkedProps.users && checkedProps.users.length > 0 &&
            this.fetchedTime[fetchVariableName] !== months) {
            this.fetchedTime[fetchVariableName] = months;

            const endTime = moment().endOf('day');
            const params = {
                poolId: checkedProps.userPool.pool._id,
                groupIds: null, // Group filtering done on client
                groupId: null,
                beginDate: months > 0 ? moment(endTime).subtract(months, 'month') : null,
                endDate: moment().endOf('day'),
                missionId: null,
                status: null,
                game: checkedProps.currentGame
            };
            apiCall(params);
        }
    }

    userIsAdmin(checkedProps) {
        return checkedProps.rights.siteAdmin || checkedProps.rights.admin || checkedProps.rights.poolAdmin;
    }

    handleCancel = (event) => {
        this.props.handleCancel(event.target.value);
    }

    getAdminTotalStats(stats) {
        const result = getStatsObjectFromData(stats);
        result.totalPoints = this.state.selectedUser === "-1" && this.state.selectedGroup === "-1" && this.props.totalPoints ? this.props.totalPoints : "-";
        return result;
    }

	render() {
        let scoresList = [];
            if (this.props.gameUserScore) {
                scoresList = this.props.gameUserScore.map((user) =>
                    <div className="align-center general-pool-info">
                        <div className="general-pool-title pool-info">{user.firstName}{" "}{user.lastName}{" - "}{user.nick}{" - "}{user.email}</div>
                        {user.results.length > 0 ? <UserPoolStats games={user.results}/> : <h3>No Scores</h3>}
                    </div>
                );
            }
        const texts = {
            playTime: <Translate value="totalPlayTime"/>,
            avgPlayTime: <Translate value="avgPlayTime"/>,
            activeUsers: <Translate value="activeUsersMonth"/>,
            singleUserStatistics: <Translate value="singleUserStatistics" tag="h3"/>,
            poolPortalScores: <Translate value="poolPortalScores" tag="h3"/>,
            poolStatistic: <Translate value="poolStatistic"/>,
        };

		const actions = [
            <Button
            color="primary"
            style={{margin: "10px 0px"}}
            key={"Cancel"}
            onClick={this.handleCancel}
            >Cancel</Button>
        ];

        const usedStats = getStatsWithGroupFilter(this.props.stats);
        const game = this.getAdminTotalStats(usedStats, null);
        const firstComponent = <div className="general-pool-info">
                <div className="pool-info">{texts.playTime}{": "}{game.totalPlayTime}</div>
                <div className="pool-info">{texts.avgPlayTime}{": "}{game.avgPlayTime}</div>
                <div className="pool-info">{texts.activeUsers}{": "}{game.activeUsers}{"/"}{this.props.seats}</div>
        </div>;


		return (
            <div>
				<Dialog
                    className="dialog--legal-dialog"
                    fullWidth={true}
					maxWidth = {"md"}
					open={this.props.open}
					onClose={this.handleClose}
					PaperComponent={Paper}
                    PaperProps={{className: "legal-body"}}
                    disableBackdropClick={this.state.blockBackgroundClick}
					>
                <DialogTitle disableTypography={true} style={{fontSize: 20, fontWeight: 600}}>{texts.poolStatistic}</DialogTitle>

					<DialogContent className="legal-body legal-dialog">
                        <div className="general-pool-info-content">
                        <div>{texts.poolPortalScores}</div>
                        {firstComponent}
                            <div>
                                <div className="general-pool-title">{texts.singleUserStatistics}</div>
                                <hr className="sidebar-pool-statistic-line"/>
                                {scoresList}
                            </div>
                        </div>
                    </DialogContent>
					<DialogActions>
						{actions}
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}

PoolStatPopup.propTypes = {
    profile: PropTypes.object,

};

function mapStateToProps(state) {
    return {
        rights: state.user.rights,
        profile: state.user.profile,
        groups: state.group.groups,
        userPool: state.userPool.currentPool,
        users: state.userPool.users,
        usersById: state.userPool.usersById,
        stats: state.stats.stats,
        highScores: state.highScores.data,
        totalPoints: state.highScores.totalPoints,
        activeUsers: state.activeUsers.data,
        currentGame: state.userPool.currentGame,
        gameTop10: state.highScores.gameTop10,
        bestScores: state.highScores.bestScores,
        gameUserScore: state.highScores.gameUserScore,
    };
}

const mapDispatchToProps = (dispatch) => {
    const boundActionCreators = bindActionCreators({getStats, getStatsForUsersArgs, getHighScoresArgs, getActiveUsersArgs,
        getHighScoreSum, getHighscoresForGame, getBestScoresForUser, getScoresForUserByGame}, dispatch);
    return {...boundActionCreators, dispatch};
};

export default connect(mapStateToProps, mapDispatchToProps)(PoolStatPopup);
