import React, { Component } from 'react';

class NoMatch extends Component {
    render() {
        return (
            <div>PAGE NOT FOUND!</div>
        );
    }
}
export default NoMatch;
