import React, { Component } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import PropTypes from 'prop-types';

export default class LinearProgressBar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            completed: 0,
        };
    }

    componentDidMount() {
        this.props.mode? this.progress(1):'';
    }

    componentWillReceiveProps() {
        this.props.mode? this.progress(1):'';
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    progress(completed) {
        if (completed <= this.props.maxValue) {
            this.setState({ completed });
            this.timer = setTimeout(() => this.progress(completed + 1), 1000);
        }
    }

    getProgressStatus(completed) {
        var calculateProgress = this.props.maxValue > 0;
        return calculateProgress ? completed / this.props.maxValue * 100 : 0;
    }

    render() {
        /* Reusable Linear progressbar for both determinate and indeterminate variant, send props mode={'determinate'}
        from parent for determinate variant, default value: 'indeterminate' */

        return (
            <LinearProgress variant={this.props.mode} value={this.getProgressStatus(this.state.completed)} />
        );
    }
}


LinearProgressBar.propTypes = {
    mode: PropTypes.string,
    maxValue: PropTypes.number
};

LinearProgressBar.defaultProps = {
    mode: "indeterminate",
    maxValue: ""
};