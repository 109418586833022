import React from 'react';
import adminTitileBarImage from '../../public/images/userguide_images/administrator_instructions_1.png';
import poolsImage from '../../public/images/userguide_images/administrator_instructions_2.png';
import addAdminImage from '../../public/images/userguide_images/administrator_instructions_3.png';
import addUserImage from '../../public/images/userguide_images/administrator_instructions_4.png';
import NavigationButton from './NavigationButton';

const AdministratorInstructions = () => {
  
  return (
    <div className="content-userguide">
      <h1 className="section-header">Section 6 Administrator Instructions</h1>
      <h2>Adding persons to a pool as an admin</h2>
      <div>Log in to <a href="https://napcongames.com/">www.napcongames.com</a> with your account</div>
      <div>Click Admin in the menu at the top right of your screen</div>
      <img src={adminTitileBarImage} style={{ width: "90%", margin: "30px 0" }} alt="logo" />
      <div>If you have multiple pools, select the right pool from the top section of the page.</div>
      <img src={poolsImage} style={{ width: "70%", margin: "30px 0" }} alt="logo" />
      <div>Add new administrator: If you wish to add another administrator to the pool, select “Add Administrator”. Type in the e-mail address and press “Send”.</div>
      <div>Promote existing user to administrator: If you wish to promote an existing user, select “Promote user to administrator” and click the “Administrator email”, and select the preferred user from the dropdown menu.</div>
      <img src={addAdminImage} style={{ width: "90%", margin: "30px 0" }} alt="logo" />
      <div>Adding regular users: To add regular users (players), select “Add user(s)” from the bottom of the page and type in the email addresses you wish to invite. You can invite multiple users by separating their email addresses with a comma sign. Press Send to invite the users.</div>
      <img src={addUserImage} style={{ width: "90%", margin: "30px 0" }} alt="logo" />
      <NavigationButton prevName="Learning Objectives" prevLink="/userguide/learningObjectives" nextName="Troubleshooting" nextLink="/userguide/troubleshooting"/>  
    </div>
  );
};


export default AdministratorInstructions