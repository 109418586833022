import React, {Component} from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import { getFullStatsForMission, getFullPoolStatsForMission } from './actions/stats';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {getStatsWithGroupFilter, getUserIdListFromUsers} from './StatHelper';
import {MISSIONCOLORS} from './Helpers';

import CollapsePanel from './CollapsePanel';
import MissionStats from './MissionStats';

/**
 * Component with collapsible mission statistics
 */
class MissionStatsList extends Component {
    constructor(props, context) {
        super(props, context);
    }

    /**
     * Handler for panel opened event
     */
    onPanelOpen(e) {
        if (!this.props.rightsInit) {
            return;
        }

        if (this.props.missionStatsPool && this.props.missionStatsPool[e.missionId]) {
            // no-op, already has data
        }
        else if (this.props.userPool) {
            const groupId = this.props.group ? this.props.group._id : null;
            this.props.getFullPoolStatsForMission(this.props.userPool.pool._id, groupId, e.missionId, this.props.currentGame);
        }

        if (this.props.missionStats && this.props.missionStats[e.missionId]) {
            // no-op, already has data
        }
        else {
            this.props.getFullStatsForMission(this.props.profile._id, e.missionId, this.props.currentGame);
        }
    }

    getMissionStats(missionId, poolStats) {
        if (poolStats) {
            return this.props.missionStatsPool ? this.props.missionStatsPool[missionId] : [];
        }
        else {
            return this.props.missionStats ? this.props.missionStats[missionId] : [];
        }
    }

    /**
     * Maps mission data to collapsible MissionStats component
     * @returns {*}
     */
    getMissionComponents() {
        if (this.props.missionInfo === undefined || this.props.missionInfo.length === 0 || !this.props.usersById || !this.props.rightsInit) {
            return null;
        }

        const groupId = this.props.group ? this.props.group._id : null;
        const poolId = this.props.userPool ? this.props.userPool.pool._id : null;
        const changes = [this.props.adminView, groupId, poolId];
        const open = window.location.href.indexOf("openMissions") !== -1;

        return this.props.missionInfo.map((mission, index) => {
            const stats = getStatsWithGroupFilter(this.getMissionStats(mission.missionId, this.props.adminView), this.props.selectedGroupUserIds);
            const scoreStats = getStatsWithGroupFilter(this.getMissionStats(mission.missionId, true), this.props.selectedGroupUserIds);
            const colorClass = "collapse-panel--circle mission--" + (1+(index % 6));
            const lineColor = MISSIONCOLORS[index%6];
            const pageBreak = index%3 === 0;

            // TODO index === 0 in initiallyOpen
            return <CollapsePanel key={index} primaryText={this.props.gameName} secondaryText={mission.name}
                           onPanelOpen={this.onPanelOpen.bind(this, mission)} disabled={false} pageBreak={pageBreak}
                           initiallyOpen={open} closeWhenChangedData={changes} colorClass={colorClass}>
                <MissionStats adminView={this.props.adminView} stats={stats} highScoreStats={scoreStats} lineColor={lineColor}
                              usersById={this.props.usersById} profile={this.props.profile} missionData={mission}
                              skipResults={mission.skipResults}  />
            </CollapsePanel>
        });
    }

    render() {
        const missionComponents = this.getMissionComponents();
        return (
            <List className={"mission-stats-list"}>
                {missionComponents}
            </List>
        );
    }
}

MissionStatsList.propTypes = {
    usersById: PropTypes.object,
    adminView: PropTypes.bool.isRequired,
    gameName: PropTypes.node.isRequired,
    missionInfo: PropTypes.array.isRequired,
    profile: PropTypes.object.isRequired,
    selectedGroupUserIds: PropTypes.array,
    rightsInit: PropTypes.bool.isRequired
};



const mapStateToProps = (state) => ({
    missionStats: state.stats.missionStats,
    missionStatsPool: state.stats.missionStatsPool
});
const mapDispatchToProps = (dispatch) => {
    const boundActionCreators = bindActionCreators({getFullStatsForMission, getFullPoolStatsForMission}, dispatch);
    return {...boundActionCreators, dispatch};
};
export default connect(mapStateToProps, mapDispatchToProps)(MissionStatsList);


