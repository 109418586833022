import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getUsersInPool, removeUserFromPool } from './actions/userpool';
import { getGroups } from './actions/group';
import { deleteUser } from './actions/index';

import AccountPagePool from './AccountPagePool';
import AdminFindData from './AdminFindData';
import PageHeader from './PageHeader';
import AdminWriteAdminMessage from './AdminWriteAdminMessage';

/**
 * Admin page main component (pool administration)
 */
class PoolAdminPage extends Component {
    constructor(props, context) {
        super(props, context);
    }

    shouldRenderPoolPanel() {
        if (this.props.rights.poolAdmin || this.props.rights.admin) {
            return true;
        }
        else {
            return this.props.rights.siteAdmin && this.props.searchSelect && this.props.searchSelect.type === 'Pool';
        }
    }

    render() {
        const adminPanel = this.props.rights.siteAdmin ? <AdminFindData/> : null;
		const adminMessagePanel = this.props.rights.siteAdmin ? <AdminWriteAdminMessage /> : null;
        const poolPanel = this.shouldRenderPoolPanel() ? <AccountPagePool {...this.props} /> : null;

        return (
            <div>
                <PageHeader {...this.props} subHeaderLangKey="admin" />
                {adminPanel}
				{adminMessagePanel}
                {poolPanel}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        rights: state.user.rights,
        profile: state.user.profile,
        userPool: state.userPool.currentPool,
        admins: state.userPool.admins,
        users: state.userPool.users,
        groups: state.group.groups,
        searchSelect: state.user.searchSelect,
        searchedProfile: state.user.searchedProfile,
    };
}

export default connect(mapStateToProps, {getUsersInPool, getGroups, removeUserFromPool, deleteUser})(PoolAdminPage);
