import React, { Component } from 'react';
import { List, ListItem, Collapse } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';



const helpDocs = [
    {
        index: 0,
        subject: "What are NAPCON Games?",
        text: "NAPCON Games are educational games made for process operators and students working in or studying fields related to process industry such as, but not limited to, petrochemical industry. The games focus on different unit processes and combine theoretical content innovatively with a hands-on experience where you learn by doing. " +
            "The games are based on the same high fidelity dynamic process simulation as our [link] allowing the trainees to access operator training simulator level of accuracy in phenomena from any location at any time." +
            "/ For more information about our games portfolio, see our [link1].",
        figure: "images/blender_and_distiller.png",
        links: [{text: "NAPCON Simulator", url: "https://www.napconsuite.com/product/napcon-simulator/"}, {text: "product page", url: "https://www.napconsuite.com/product/napcon-games/"}]
    },
    {
        index: 1,
        subject: "How to order NAPCON Games products?",
      //text: "You can order NAPCON Games products directly from our [link]. Once you are on the ordering page, please fill in the form to complete the order. " +
      //      "If you are interested in another kind of a package not available through our order form, please contact us with the contact form available from the “CONTACT US” button. " +
      //      "/ After your order is placed successfully, you will be contacted by our staff within a few business days. If you have any questions, please contact us with the contact form or by e-mail at napcongames@neste.com.",
        text: "Please contact us with the contact form available from the Home page by clicking the “CONTACT US” button and we will get in touch with you.",
        figure: "images/home_page.png"
      //figure: "images/order_page.png",
      //links: [{text: "ordering page", url: "https://napcongames.com/order"}]
    },
    {
        index: 2,
        subject: "What is a NAPCON Games license?",
        text: "A NAPCON Games license is sold to a company or an institution allowing it to grant access to a certain game for a fixed number of individuals (e.g. employees or students) for a period of normally one year. / You will find more information about the license system in the “How does the license system work?” section.",
        figure: "images/admin_page.png",
        links: []
    },
    {
        index: 3,
        subject: "How does the license system work?",
        text: "NAPCON Games business is based on sales of licenses through a license system. One NAPCON Games license grants access to a certain game for a fixed number of individuals (e.g. employees or students) for a period of normally one year. Licenses can be bought to multiple games and upgraded in size to allow more individuals access to the games. " +
            "/ For example, “Company A” orders a 10 users license for NAPCON Games Distiller that is valid for one year. During the purchase process the company states the e-mail address of the main administrator of their choice (e.g. matt.richardson@acompany.com) and name of the license (e.g. “Company A” ). " +
            "/ After the order has been processed by NAPCON Games, the invitation e-mail with the individual signup link is sent to “Matt”. He follows the link, creates a user profile and signs in. “Matt” is now the first user in “Company A” license pool, and also its main administrator. " +
            "/ “Matt” is an administrator so he is able to invite other users to use “Company A” license pool. “Matt” can invite anyone with an e-mail account to join the pool. Invitees will all receive an e-mail invitation with a signup link. " +
            "/ “Matt” can add users up to ten users in total since that is the size of the license he bought. He can also remove them from the pool and invite other users to replace. The removed user accounts are not deleted and can they can be added to a license pool later if there are free seats available. “Matt” can also promote other users to administrators. " +
            "/ After one year the license will expire. The users in the pool can no longer access the games unless they have a valid license attached to their account through some other way eg. another company or institution. “Company A” needs to renew their license if they wish to continue playing. The created user accounts are not deleted even if their license expires.",
        figure: "images/license_1.png",
        links: []
    },
    {
      index: 4,
      subject: "How to start playing NAPCON Games products?",
      text: "First, you will have to activate your NAPCON Games account. After your order has been confirmed, you should receive an invitation e-mail from us (napcongames@neste.com) that provides a link to activate your account. Open the link and follow the instructions to activate your account. " +
            "/ If you cannot find this e-mail, please check your junk mail and other filters in your mailbox. If you are still unable to find the e-mail, contact us at napcongames@neste.com." +
            "/ If you are already registered, you can head to the [link] and login from the home page or by clicking the “LOGIN” button from the navigation menu. Enter your registration e-mail and the password you created for your account. " +
            "In case you have forgotten your password, you may reset your password by entering your registered e-mail address and clicking the “Forgot Password” button. An e-mail will be sent to you with the password resetting link." +
            "/ After logging in to the [link] you can see the games you have access to on the home page marked by the “PLAY” button or on the left side of the navigation menu in the top section of the page. " +
            "You can start the games by clicking the “PLAY” button or the name of the game in the navigation menu.",
      figure: "images/distiller_8.png",
      links: [{text: "NAPCON Games Portal", url: "https://napcongames.com/"}]
    },
    {
      index: 5,
      subject: "What is NAPCON Games Portal?",
      text: "NAPCON Games Portal is this website. The Portal offers access to our educational games, provides statistics for the users and can be used to order our games.",
      figure: "images/home_page.png",
      links: []
    },
    {
        index: 6,
        subject: "What game statistics does NAPCON Games Portal provide to the users?",
        text: "Users can see their NAPCON Games statistics by clicking “STATISTICS” button in the navigation menu at the top section of the page when logged in. / In the statistics view the user can find: / - Achievements and badges / - Mission specific statistics such as leaderboards, score distributions, play time counters and much more " +
            "/ / All the statistics are license pool specific so the users cannot see statistics of a license pool which they are not a member of./ The license pool administrators have even more comprehensive statistics view than normal users. This includes user activity and ability to filter statistics by time and by specific user or user group.",
        figure: "images/statistics_page.png",
        links: []
    }
  ];


/**
 * Component with collapsible help subjects
 */
class HelpSection extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            open: this.getHelpSectionStates(),
        }

    }

   
    /*
     this function returns initial states for FAQ listitems  
     */
    getHelpSectionStates() {
        var object = {};
        for (let i = 0; i < helpDocs.length; i++) {
            object[i] = false;
        }

        return object;
    }

    getChapterComponents(text, links) {
        const arr = text.split("/");
        return arr.map((str) => {
            return <div key={str} className="help-page--left">
                {this.insertLinks(str, links)}
            </div>
        });
    }


    // Return toggle icon to be used when F.A.Q list items open/closed 
    getToggleIcon(index) {
        if (this.state.open[index] === false) {
            return <Icon className="material-icons">{"add"}</Icon>;
        } else {
            return <Icon className="material-icons">{"remove"}</Icon>;
        }
    }


    /**
     * Handler for nested list toggled.
     * Updates this.state.open to detect if input is visible or not.
     * @param evt
     */
    onNestedListToggle = (index, event) => {
        this.setState({
            open: this.getHelpSectionUpdatedStates(index, !this.state.open[index]),
        });
        
    }


    /*
     * Function that returns new states of F.A.Q (helpsection) listitems.
     * it Takes a copy of current state object 'open', Update states for opened 
     * or closed helpsection listitem and return the new copy of states.
     */
    getHelpSectionUpdatedStates(index, isOpened) {
        var states = this.state.open;
        states[index] = isOpened;
        return states;
    }



    insertLinks(text, links) {
        //Find places to add link using the link identifier string without the link index and end
        const linkId = "[link";
        const arr = text.split(linkId);
        const arrNew = [];

        arr.forEach(function(currElement, index) {
            let textString = currElement;
            // Find link end to get the link index
            const linkEnd = textString.indexOf(']');
            let linkIndex = 0;

            if(linkEnd !== -1)
            {
                // Link index is between start of the splitted string and link end
                const linkIndexFromStr = Number(textString.substring(0, linkEnd));
                if(Number.isInteger(linkIndexFromStr)){
                    linkIndex = linkIndexFromStr;
                }

                // Remove the link index and link end from the text
                const textStartIndex = linkEnd + 1;
                if(textStartIndex < textString.length)
                    textString = textString.substring(textStartIndex);
                else
                    textString = "";

                // Get the link and insert it if found
                const link = links[linkIndex];
                if(link){
                    arrNew.push(<a key={link.text} href={link.url}>{link.text}</a>);
                }
            }

            arrNew.push(textString);

        }.bind(this) );

        return <div> {arrNew} </div>;
    }

    /**
     * Maps help data to collapsible Help component
     * @returns {*}
     */
    getHelpComponents() {
        return helpDocs.map((arr) => {
            const index = arr["index"];
            const subject = arr["subject"];
            const text = arr["text"];
            const figure = arr["figure"];
            const links = arr["links"];
            const textComponents = this.getChapterComponents(text, links);
            return <React.Fragment key={index}><ListItem
                className="help-panel--header"
                onClick={this.onNestedListToggle.bind(this, index)}
                >{subject}
                {this.getToggleIcon(index)}
                </ListItem>
                <Collapse in={this.state.open[index]}>
                {[
                    <ListItem key={1} style={{fontSize:16}}>
                        <div className="help-page--row">
                            <div className="help-page--row-container">
                                <div>
                                    {textComponents}
                                </div>
                            </div>
                            <div className="help-page--right">
                                <div className="help-page--row">
                                    <div className="help-page--image-right help-page--vertical-middle">
                                        <img src={napconBuildVersion + figure}
                                            className="help-page--image" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ListItem >
                    ,
                ]}
                </Collapse></React.Fragment>
        });
    }

    render() {
        const helpComponents = this.getHelpComponents();
        return (
            <div>
                {helpComponents}
            </div>
        );
    }
}

HelpSection.propTypes = {
};


export default HelpSection;


