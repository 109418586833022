import React, { Component } from 'react';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Cookies from 'universal-cookie';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Translate } from 'react-i18nify';
import { gameListData } from 'helpersgames';
import { logoutUser, sessionExpiresLogoutUser, newToken } from './actions/auth';
import { getAgreementVersion } from './actions/index';
import MainPageContent from './MainPageContent';
import AccountPage from './AccountPage';
import CompetitionPage from './CompetitionPage';
import NewPasswordPage from './NewPasswordPage';
import CompanyPage from './CompanyPage';
import PoolAdminPage from './PoolAdminPage';
import PoolsPage from './PoolsPage';
import StatisticsPage from './StatisticsPage';
import AdminServerStats from './AdminServerStats';
import TitleBar from './TitleBar';
import RegisterUserPage from './RegisterUserPage';
import ExternalGamePage from './ExternalGamePage';
import DistillationGamePage from './DistillationGamePage';
import Notification from './Notification';
import InfoPage from './InfoPage';
import HelpPage from './HelpPage';
import UserGuidePage from './UserGuidePage';
import ScrollToTop from './ScrollToTop';
import GamesTrialHomePage from './GamesTrialHomePage';
import EdupoliSimulatorPage from './EdupoliSimulatorPage';
import LegalDialog from './LegalDialog';
import NoMatch from './NoMatchElement';
import LogoutPage from './LogoutPage';
import Localization from './Localization';
import CookieBottomButtonBar from './CookieBottomButtonBar';

const cookies = new Cookies();
// Google analytics, not necessary with Google Tag Manager
// ReactGA.initialize(napconGACode, {debug: false, titleCase: true});
const customHistory = createBrowserHistory();
// customHistory.listen((location, action) => {
//     ReactGA.set({page: location.pathname});
//     ReactGA.pageview(location.pathname);
// });

/* Check that this corresponds with tokenExpirationTime in
portal\server\src\controllers\authentication.js */
export const TOKEN_EXPIRATION_TIME = 1200; // seconds

const TOKEN_RENEW_INTERVAL = 19 * 60 * 1000; // milliseconds

const font = "'Gotham A','Gotham B','Gotham',sans-serif";
const _lightTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#42aae0',
        },
        secondary: {
            main: 'rgba(0, 0, 0, 0.8)'
        },
        error: {
            main: '#f52f2f'
        }
    },
    paper: {
        padding: 50
    },
    overrides: {
        MuiTooltip: {
          tooltip: {
            fontSize: 12,
            border: '1px solid #42aae0',
            color: '#42aae0',
            backgroundColor: 'rgba(224, 240, 255, 0.85)',
            padding: '3px 8px',
            width: 130,
            height: 50,
            borderRadius: 5,
          }
        },
        MuiToolbar: {
            root: {
                minHeight: '56px !important',
                height: '5vh'
            }
        },
        MuiInput: {
            underline: {
                '&:after': {
                    borderBottomColor: '#42aae0'
                }
            }
        },
        MuiFormLabel: {
            root: {
                '&$focused': {
                    color: '#42aae0'
                }
            }
        }
    },
    typography: {
        fontFamily: font,
    }
});

const _darkTheme = createMuiTheme({
    palette: {
        type: 'dark',
        primary: {
            main: '#42aae0',
        },
        secondary: {
            main: 'rgba(255, 255, 255, 0.6)',
        }
    },
    typography: {
        fontFamily: font,
    }
});

class Main extends Component {

    constructor(props, context) {
        super(props, context);


        this.state = {
            inGame: false,
            cookieConsent: cookies.get('consent'),
            checkLanguage: true,
            token: cookies.get('token'),
        };

        window.addEventListener('custom_error', this.onError.bind(this));
    }

    onError(event) {
        // 401 is received while logged in -> logout
        if (this.props.authenticated && event.detail) {
            if (event.detail.status === 401 || (event.detail.response && event.detail.response.status === 401)) {
                // customHistory.push('/logout');
            }
        }
    }

    componentDidMount() {
        if (this.state.token) {
            this.props.newToken();
        }
        this.handleLoginChange(this.props.authenticated);
        this.props.getAgreementVersion();
    }

    componentDidUpdate(prevProps) {
        if (this.props.authenticated !== prevProps.authenticated) {
            this.handleLoginChange(this.props.authenticated);
        }
        if (!Object.prototype.hasOwnProperty.call(this.props.privacyPolicy, 'settingValue') && this.props.privacyPolicy.settingValue !== prevProps.privacyPolicy.settingValue) {
            this.props.getAgreementVersion();
        }
    }

    // check if cookie expires and force user to logout
    checkCookieValidity() {
        if (this.props.authenticated) {
            const token = cookies.get('token');
            if (!token) {
                const msg = 'session_expired';
                this.props.sessionExpiresLogoutUser(msg);
            }

        }
    }

    // auto renew cookie as long as user logged in before it get expired
    handleLoginChange(authenticated) {
        if (this._renewTimer) {
            window.clearInterval(this._renewTimer);
            this._renewTimer = null;
        }
        if (authenticated) {
            this._renewTimer = window.setInterval(function () {
                this.props.newToken();
            }.bind(this), TOKEN_RENEW_INTERVAL);
        }
    }

    getPrivateRoute(path, component, mainStyle) {
        return this.props.authenticated ?
            <Route exact path={path}>
                <MainPageContent mainStyle={mainStyle} component={component} />
            </Route> :
            <Redirect key={"private-route"} to={'/'} from={path} />;
    }

    getPrivateRouteWithRouteComponent(path, routeComponent) {
        return this.props.authenticated ? routeComponent : <Redirect key={"private-route-with-route-component"} to={'/'} from={path} />;
    }

    /**
     * Returns route to path only when user is not authenticated
     * @param path
     * @param component
     */
    getPublicOnlyRoute(path, component) {
        return this.props.authenticated ? <Redirect key={"public-route"} to="/" from={path} /> :
            <Route exact path={path} component={component} />
    }

    getMainPageStyle() {
        if (this.state.inGame) {
            return {
                width: '100%',
                height: '100%',
                padding: 0,
                margin: 0,
                overflow: 'hidden',
            };
        }
        return {};
    }

    getMainStyle() {
        return this.state.inGame ? { overflow: 'hidden' } : { overflow: 'auto' };
    }

    getCurrentTheme() {
        return this.state.inGame ? _darkTheme : _lightTheme;
    }

    onViewGamePage(value) {
        this.setState({
            inGame: value,
        });
    }

    handleCookieConsent() {
        cookies.set('consent', true, { path: '/', maxAge: 86400 * 365 });
        this.setState({ cookieConsent: true });

        // Resize the game frame if in-game and cookie banner is closed
        if (this.state.inGame) {
            window.postMessage({ type: 'resize' }, '*');
        }
    }

    renderCookieConsent() {
        if (this.state.cookieConsent) {
            return null;
        }
        return <CookieBottomButtonBar centerText={<Translate value="cookieInfo" />} privacyPolicy={<LegalDialog content="privacyPolicy"/>}
            buttonText={<Translate value="ok" />} onClick={this.handleCookieConsent.bind(this)} />;
    }

    getGamePageRoutes(mainStyle) {
        const result = [];
        Object.keys(gameListData.games).forEach((key) => {
            const game = gameListData.games[key];
            const exactPath = '/' + game.portalRouteLink;
            let gameRouteComponent;
            if (key !== 'edupoli-simulator') {
                gameRouteComponent = <Route key={key} exact path={exactPath} render={
                    (routeProps) => <MuiThemeProvider
                        theme={this.getCurrentTheme()}><DistillationGamePage {...routeProps} gameId={key}
                            gameShownCallback={this.onViewGamePage.bind(this)}/></MuiThemeProvider>}/>;
            } else {
                gameRouteComponent = <Route key={key} exact path={exactPath} component={EdupoliSimulatorPage} />;
            }

            if (exactPath !== '/owc') {
                result.push(game.hasPreviewMissions ? gameRouteComponent : this.getPrivateRouteWithRouteComponent(exactPath, gameRouteComponent, mainStyle));
            }
            //result.push(game.hasPreviewMissions ? gameRouteComponent : this.getPrivateRouteWithRouteComponent(exactPath, gameRouteComponent, mainStyle));
        });
        return result;
    }

    changeLanguage(lan) {
        Localization.setLocale(lan);
        this.setState({ checkLanguage: false });
    }

    getExternalGameRoute() {
        // TODO: L2O get game and mission id's from X
        // TODO: L2O token and login
        return <Route path="/external" render={ (routeProps) => <MuiThemeProvider
            theme={this.getCurrentTheme()}><ExternalGamePage {...routeProps} gameShownCallback={this.onViewGamePage.bind(this)}/></MuiThemeProvider>}
        />;
    }

    render() {
        if (this.state.checkLanguage && this.props.profile.language) {
            this.changeLanguage(this.props.profile.language);
        }

        const mainStyle = this.getMainPageStyle();
        const tourStyle = { width: '100%', paddingRight: 0, paddingLeft: 0, paddingTop: 0, minHeight: 0, paddingBottom: '2%' };
        const servers = this.props.rights.siteAdmin ? this.getPrivateRoute('/servers', <AdminServerStats />, mainStyle) : null;
        const hiddenStyle = { display: 'none' };

        const competition = this.props.rights.siteAdmin ?

            <Route exact path="/competition">
                <MainPageContent mainStyle={tourStyle} component={<CompetitionPage />} />
            </Route> :
            <Redirect key={"competion-page-route"} to={'/'} from="/competition" />;

        const help = <Route exact path="/help">
            <MainPageContent mainStyle={mainStyle} component={<HelpPage />} />
        </Route>;


        const userguide = this.props.authenticated ?
            <Route path="/userguide">
                <MainPageContent mainStyle={mainStyle} component={<UserGuidePage />} />
            </Route>:
            <Redirect key={"userguide-page-route"} to={'/'} from="/userguide" />;


        const owcHome = <Route exact path="/games_trial" component={GamesTrialHomePage} />;

        return (
            <MuiThemeProvider theme={_lightTheme}>
                <Router history={customHistory}>
                    <ScrollToTop>
                        <Switch>
                            {this.getPublicOnlyRoute('/register', RegisterUserPage)}
                            <Route>
                                <div className="main" style={this.getMainStyle()}>
                                    <MuiThemeProvider theme={this.getCurrentTheme()}>
                                        <div className="cookieWrapper">
                                            {this.renderCookieConsent()}
                                        </div>
                                    </MuiThemeProvider>
                                    <Route path="/" render={ (routeProps) => <TitleBar inGame={this.state.inGame} {...routeProps} />} />
                                    <Notification />
                                    <Switch>
                                        {this.getExternalGameRoute()}
                                        <Route exact path="/" component={InfoPage} />
                                        {/*competition*/}
                                        {owcHome}

                                        {/* <Route exact path="/order" component={OrderPage}/> */}
                                        <Route exact path="/termsofservice">
                                            <MainPageContent mainStyle={mainStyle} component={<LegalDialog open={true} content="termsOfService" />}/>
                                        </Route>
                                        {help}
                                        {userguide}
                                        {servers}
                                        {this.getPrivateRoute('/admin', <PoolAdminPage />, mainStyle)}
                                        {this.getPrivateRoute('/statistics', <StatisticsPage />, mainStyle)}
                                        {this.getPrivateRoute('/account', <AccountPage />, mainStyle)}
                                        <Route exact path="/logout">
                                            <MainPageContent mainStyle={mainStyle} component={<LogoutPage {... logoutUser} />} />
                                        </Route>
                                        {this.getPrivateRoute('/pools', <PoolsPage />, mainStyle)}
                                        {this.getPrivateRoute('/company', <CompanyPage />, mainStyle)}
                                        <Route exact path="/resetpassword">
                                            <MainPageContent mainStyle={mainStyle} component={<NewPasswordPage />} />
                                        </Route>
                                        {this.getGamePageRoutes(mainStyle)}
                                        {/* <Route>
                                            <MainPageContent mainStyle={mainStyle} component={<NoMatch />} />
                                        </Route> */}
                                        <Redirect key={"root-page-route"} to={'/'}/>
                                    </Switch>
                                </div>
                            </Route>
                        </Switch>
                    </ScrollToTop>
                </Router>
            </MuiThemeProvider>
        );
    }
}

function mapStateToProps(state) {
    return {
        rights: state.user.rights,
        profile: state.user.profile,
        authenticated: state.auth.authenticated,
        privacyPolicy: state.setting.currentAgreementVersion,
    };
}

export default connect(mapStateToProps, { newToken, sessionExpiresLogoutUser, getAgreementVersion })(Main);
