import { I18n } from 'react-i18nify';
import moment from 'moment';

const binarySearch = require('binarysearch');

const ACHIEVEMENTS = ["PlayCount", "UniqueMissionCount", "TisleInSpec", "RealModePoints", "QuickEnd"];

function getUserIdListFromUsers(users) {
    const idList = [];
    for (let user of users) {
        if (user.status === "Active") {
            idList.push(user._id);
        }
    }
    return idList;
}

function getStatsObjectFromData(stats) {
    const result = {
        totalTimesPlayed: 0,
        totalPlayTime: "-",
        avgPlayTime: "-",
        activeUsers: 0,
        totalPoints: 0,
        cumulativePlayTimeSeconds: 0,
        abortedCount: 0,
        uniquePlayers:[],
        cumulativePlayTime:0
    };

    if (stats && stats.length > 0) {
        for (let stat of stats) {
            const endTime = moment(stat.endTime);
            const beginTime = moment(stat.beginTime);

            result.cumulativePlayTime += endTime.diff(beginTime, 'seconds');
            result.totalTimesPlayed++;
            result.totalPoints += stat.score;

            if (result.uniquePlayers.indexOf(stat.userId) === -1) {
                result.uniquePlayers.push(stat.userId);
            }
            if (stat.status === "Abandoned") {
                result.abortedCount++;
            }
        }

        result.totalPlayTime = getDurationAsHHMM(moment.duration(result.cumulativePlayTime, 'seconds'));
        result.avgPlayTime = getDurationAsHHMM(moment.duration(result.totalTimesPlayed > 0 ? result.cumulativePlayTime/result.totalTimesPlayed : 0, 'seconds'));
        result.activeUsers = result.uniquePlayers.length;
    }
    return result;
}

function getDurationAsHHMM(duration) {
    if (duration.asDays() >= 1) {
        // asDays() used instead of days() to avoid overflowing if over a month played
        return Math.floor(duration.asDays()) + I18n.t('d') + " " +
            duration.hours() + I18n.t('h') + " " +
            duration.minutes() + I18n.t('min');
    } else if (duration.asHours() >= 1) {
        return duration.hours() + I18n.t('h') + " " +
            duration.minutes() + I18n.t('min');
    } else {
        return duration.minutes() + I18n.t('min');
    }
}

function getTopScoreList(stats, maxNumScores, minNumScores, startDateMoment, approvedOnly, onePerPlayer) {
    const result = [];
    if (stats !== undefined && stats.length > 0) {
        const clone = getProcessedStatsList(stats, "Success", approvedOnly, true, onePerPlayer, startDateMoment);

        for(let i = 0; i < clone.length; i++) {
            result.push(clone[i]);
            if (result.length >= maxNumScores) {
                break;
            }
        }
        if (minNumScores) {
            while (result.length < minNumScores) {
                result.push({});
            }
        }
    }
    return result;
}

/**
 * Get highscore list with top 3 scores, score before own score, own score and one after own score.
 * @param stats
 * @param score, score received for current game. If null ownUserId's best score is used.
 * @param status
 * @param ownUserId
 * @param startDateMoment
 * @returns {Array}
 */
function getHighscoreListWithOwnScore(stats, score, status, ownUserId, startDateMoment) {
    const result = [];

    if (stats !== undefined && stats.length > 0) {

        // Filter only completed mission attempt, sort score in descending order
        const clone = getProcessedStatsList(stats, "Success", true, true, true, startDateMoment);

        let ownTopScore = 0;
        let info = {ownScoreObject:{}};
        let personalBest = false;

        // filter mission stats for the user played the game
        for(let i = 0; i < clone.length; i++) {
            if (clone[i].userId === ownUserId) {
                ownTopScore = Number(clone[i].score);
                info = {
                    ownScoreObject: clone[i],
                    insertRank: i };
                break;
            }
        }

        // TODO, SHOULD ALWAYS FIND OWNSCOREOBJECT FROM DATA COMING FROM SERVER TO KNOW REAL STATUS?
        if (score !== null) {
            const receivedScore = Number(score);
            info = getOwnScoreInfo(clone, {userId: ownUserId, score: receivedScore, status: status});
            personalBest = receivedScore >= ownTopScore && status === "Success";
        }

        const addedObjects = [];
        for(let i = 0; i < 3; i++) {
            if (i < clone.length) {
                addedObjects.push(clone[i]);
                result.push(createGameHighScoreObject(i + 1, clone[i].userId, clone[i].score,
                    clone[i] === info.ownScoreObject, clone[i] === info.ownScoreObject && personalBest));
            }
        }

        if (info.ownScoreObject.status === "Success" && info.insertRank !== -1) {
            for (let i = info.insertRank - 1; i <= info.insertRank + 1; i++) {
                if (i >= 0 && i < clone.length && addedObjects.indexOf(clone[i]) === -1) {
                    addedObjects.push(clone[i]);
                    result.push(createGameHighScoreObject(i + 1, clone[i].userId, clone[i].score,
                        clone[i] === info.ownScoreObject, clone[i] === info.ownScoreObject && personalBest));
                }
            }
        }
    }
    return result;
}

function getOwnScoreInfo(clone, ownScoreObject) {
    let insertRank = binarySearch(clone, ownScoreObject, function(value,find){
        if(value.score > find.score) return -1;
        else if(value.score < find.score) return 1;
        return 0;
    });

    // Same score already found, check if it is our score
    let ownScoreFound = false;
    if (insertRank !== -1) {
        for (let i = insertRank; i < clone.length; i++) {
            if (clone[i].score !== ownScoreObject.score) {
                break;
            }
            else {
                if (clone[i].userId === ownScoreObject.userId) {
                    ownScoreFound = true;
                    ownScoreObject = clone[i];
                    break;
                }
            }
        }
    }

    // Do not add own score
    if (!ownScoreFound) {
        if (ownScoreObject.status === "Success") {
            insertRank = binarySearch.insert(clone, ownScoreObject, function (value, find) {
                if (value.score > find.score) return -1;
                else if (value.score < find.score) return 1;
                return 0;
            });
        }
        else {
            insertRank = -1;
        }
    }

    return {
        ownScoreObject: ownScoreObject,
        insertRank: insertRank
    };
}

function createGameHighScoreObject(rank, userId, score, own, personalBest) {
    const result = {
        rank: rank,
        userId: userId,
        score: score,
    };
    if (own) {
        result.own = true;
    }
    if (personalBest) {
        result.personalBest = true;
    }
    return result;
}


function getProcessedStatsList(stats, requiredStatus, requiresApproved, sortByScore, onePerPlayer, startDateMoment) {
    let resultList = null;

    if (requiredStatus || requiresApproved) {

        // filter only suuccesfully completed mission data
        resultList = stats.filter(function (result) {
            if (requiresApproved && !result.approved) {
                return false;
            }
            else if(requiredStatus && requiredStatus !== result.status) {
                return false;
            }
            return true;
        });
    }
    else {
        resultList = stats.slice(0);
    }

    // sort score list in descending order
    if (sortByScore) {
        resultList.sort(function(a, b) {
            return (b.score !== a.score) ? b.score - a.score : moment(a.beginTime).diff(b.beginTime);
        });
    }

    if (startDateMoment) {
        resultList = resultList.filter(function(result) {
            return startDateMoment.isBefore(moment(result.beginTime));
        });
    }

    if (onePerPlayer) {
        const added = {};
        resultList = resultList.filter(function(result) {
            if (!added.hasOwnProperty(result.userId)) {
                added[result.userId] = result;
                return true;
            }
            return false;
        });
    }

    return resultList;
}

/**
 * Return highscore distribution list. User does not have to approve score, status must be success.
 * @param stats
 * @param approvedOnly
 * @param ownId
 * @param ownDataColor
 * @returns {Array}
 */
function getScoreDistributionList(stats, approvedOnly, ownId, ownDataColor) {
    const result = [];
    const increment = 10000;
    const maxDefault = 150000;

    if (stats !== undefined && stats.length > 0) {
        const clone = getProcessedStatsList(stats, "Success", approvedOnly, true);

        const users = [];
        const highScores = [];
        const highestScore = clone.length > 0 ? Math.ceil(clone[0].score / increment) * increment : maxDefault;
        for(let s of clone) {
            if(users.indexOf(s.userId) === -1) {
                users.push(s.userId);
                highScores.push(s);
            }
        }

        let x = increment;
        let minLimit = -9999999;
        while(x <= maxDefault || x <= highestScore) {
            let count = 0;
            let ownGroup = false;
            for(let h of highScores) {
                if(h.score >= minLimit && h.score < x) {
                    count++;
                    if (ownId && ownId === h.userId) {
                        ownGroup = true;
                    }
                }
            }
            const data = {x:x, y:count};
            if (ownGroup) {
                data.color = ownDataColor;
            }
            result.push(data);
            minLimit = x;
            x += increment;
        }
    }
    return result;
}

/**
 * Format a number to the "locale" version.
 * @param number - The number to format
 * @param lang - Optional ISO language code, will default to en-US
 * @returns {string}
 */
function numberWithSpaces(number, lang = 'en-US') {
    return Number(number).toLocaleString(lang);
}

function getStatsWithGroupFilter(stats, userIdList) {
    if (!stats || stats.length === 0 || !userIdList) {
        return stats;
    }
    const result = [];
    for (let s of stats) {
        if (userIdList.indexOf(s.userId) !== -1) {
            result.push(s);
        }
    }
    return result;
}

function getNewHighScoresWithGroupFilter(highScores, userIdList, months) {
    if (!highScores || highScores.length === 0) {
        return [];
    }

    let date;
    if (months > 0) {
        date = moment().subtract(months, "month") ;
    }
    else {
        date = highScores.reduce(function(total, current) {
            return (!total || total.isAfter(moment(current.beginTime)) ? moment(current.beginTime) : total);
        }, null);
    }

    return highScores.filter(result => {
        if (userIdList && userIdList.indexOf(result.user) === -1) {
            return false;
        }
        return moment(result.beginTime).isSameOrAfter(date);
    });
}

function getActiveUsersForMonths(activeUserData, selectedGroupUserIds, months) {
    const result = [];
    if (!activeUserData || activeUserData.length === 0) {
        for (let k = 0; k < months;k++) {
            result.push(0);
        }
        return result;
    }

    let date;
    if (months <= 0) {
        date = moment(activeUserData[0].date);
        months = moment().diff(date, 'months') + 1;
    }
    else {
        date = moment().startOf("month").startOf("day").subtract(months - 1, "month");
    }

    for (let j = 0; j < months; j++) {
        let found = false;

        for (let d of activeUserData) {
            const dataMoment = moment(d.date);
            if (dataMoment.year() === date.year() && dataMoment.month() === date.month()) {
                found = true;
                result.push(getActiveUserCountForMonth(d.users, selectedGroupUserIds));
                break;
            }
        }
        if (!found) {
            result.push(0);
        }
        date.add(1, "month");
    }
    return result;
}
function getActiveUserCountForMonth(userList, selectedGroupUserIds) {
    if (userList) {
        if (selectedGroupUserIds) {
            const filteredUsers = userList.filter(function(userId){
                return selectedGroupUserIds.indexOf(userId) !== -1;
            });
            return filteredUsers.length;
        }
        else {
            return userList.length;
        }
    }
    else {
        return 0;
    }
}

function getUserHighscoreName(user, useEmail, firstNameOnly, rights) {
    if (rights.siteAdmin) {
        return "Admin";
    }
    if (!user) {
        return "-";
    }
    if (firstNameOnly && user.firstName) {
        return user.firstName;
    }
    if (user.nick) {
        return user.nick;
    }
    if (!firstNameOnly && (user.firstName || user.lastName)) {
        return (user.firstName || "") + " " + (user.lastName || "");
    }
    if (useEmail && user.email) {
        return user.email;
    }
    return "-";
}

export {getStatsObjectFromData, getTopScoreList, getScoreDistributionList, getStatsWithGroupFilter,
    getHighscoreListWithOwnScore, getUserIdListFromUsers, getActiveUsersForMonths, getNewHighScoresWithGroupFilter,
    getUserHighscoreName, numberWithSpaces };
