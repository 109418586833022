import React, { Component } from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import { Translate } from 'react-i18nify';
import LicenseItem from './LicenseItem';
import AccountListItem from './AccountListItem';

/**
 * License list component in account page
 */
class LicenseList extends Component {
    /**
     * Returns licenses as a list of LicenseItem components
     * @returns {*}
     */
    getLicenseItems() {
        if (this.props.licenseData.length <= 0) {
            return <AccountListItem textLow={<Translate value="noLicences"/>}/>;
        }
        return this.props.licenseData.map((license, index) => {
            return <LicenseItem key={index} name={license.name} playerLimit={license.playerLimit} users={license.users} seats={license.seats}
                valid={license.status.valid} expirationDate={license.status.expirationDate}/>;
        });
    }

    render() {
        const texts = {
            title: <Translate value="licences"/>,
            addUserTitle: <Translate value="addUser"/>,
            textFieldLabel: <Translate value="userEmail"/>,
            button: <Translate value="send"/>
        };

        return (
            <div className="account-container">
                <h2 className="header2">{texts.title}</h2>
                <List className="account-list">
                    {this.getLicenseItems()}
                </List>
            </div>
        );
    }
}

LicenseList.propTypes = {
    licenseData: PropTypes.array,
};

export default LicenseList;
