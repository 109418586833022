import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { InputLabel, Collapse } from '@material-ui/core';
import { Translate } from 'react-i18nify';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';


/**
 * Component for adding items to account item lists.
 */
class AccountAddPanel extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            //Error text shown in TextField when validating input fails
            errorText: '',
            //Open state is used only for detecting whether to show + or - toggle icon
            open: false,
            textValue: '',
            sendInvEmail: true,
            
        }
    }

    /**
     * Returns toggle icon.
     * @returns {FontIcon} Toggle icon
     */
    getToggleIcon() {
        let icon;
        if (this.state.open === false) {
            icon = 'add';
        } else {
            icon = 'remove';
        }
        return <Icon className="material-icons collapse-panel--toggle">{icon}</Icon>;
    }

    toggleSendEmail() {
        this.setState(() => ({
            sendInvEmail: !this.state.sendInvEmail,
        }));
    }

    getAddItemElement() {
        if (this.props.addUsersList) {
            const items = [];
            for (let i = 0; i < this.props.addUsersList.length; i++) {
                items.push(<MenuItem value={this.props.addUsersList[i]._id} key={i}>{this.props.addUsersList[i].email}</MenuItem>)
            }
            return (<React.Fragment> { this.props.addUsersList.length > 0
                    ? <div className="account-add-panel--text-field">
                        <InputLabel>{this.props.textFieldLabel}</InputLabel>
                        <Select onChange={this.onDropDownChange} className="account-add-panel--select-field"
                                value={this.state.textValue}>
                                {items}
                        </Select>
                    </div>
                    : <div style={{paddingTop:5, paddingBottom: 5, width: '100%'}}><Translate value="noAvailableUsers"/> </div>}</React.Fragment>
            );
        }
        else {
            return <TextField className="account-add-panel--text-field" label={this.props.textFieldLabel}
                                onChange={this.onTextFieldChange.bind(this)} error={this.state.errorText !== ""}
                                helperText={this.state.errorText} placeholder={this.props.hintText}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}/>
        }
    }

    getAddItem() {
        return (
            <ListItem key={1} className="account-add-panel--input">
                {this.getAddItemElement()}

                {this.props.showInvitationEmailCheckbox ?
                    <div>
                        <FormControlLabel control={<Checkbox name="sendInvEmailBool" checked={this.state.sendInvEmail} onChange={this.toggleSendEmail.bind(this)} />} label="Send invitation email" />
                    </div>
                    : ''}

                {(!this.props.addUsersList || this.props.addUsersList.length > 0 ) ? <Button className="account-add-panel--add-button"
                            onClick={this.onButtonPress.bind(this)} disabled={this.props.buttonDisabled}>
                                {this.props.buttonLabel}</Button> : null}
            </ListItem>
        );
    }

    /**
     * Returns error message if value is invalid. Returns empty string otherwise.
     * @param value {string} Tested value
     * @returns {string} Validator error message. Empty if input is valid
     */
    isValidInput(value) {
        if (this.props.inputValidator) {
            return this.props.inputValidator(value);
        } else {
            return '';
        }
    }

    /**
     * Handler for nested list toggled.
     * Updates this.state.open to detect if input is visible or not.
     * @param evt
     */
    onNestedListToggle(evt) {
        this.setState({
            open: !this.state.open
        });
    }

    /**
     * Handler for TextField changed.
     * Validates input and updated state.textValue if input was valid
     * @param event Event data
     */
    onTextFieldChange(event) {
        const errorText = this.isValidInput(event.target.value);
        if (errorText) {
            this.setState({errorText: errorText});
        } else {
            this.setState({
                errorText: '',
                textValue: event.target.value
            });
        }
    }

    /**
     * Handler for select field change
     * @param event
     * @param index
     * @param value
     */
    onDropDownChange = (event) => {
        this.setState({textValue: event.target.value})
    };

    /**
     * Handler button press.
     * Calls addButtonPressHandler prop.
     */
    onButtonPress() {
        this.props.addButtonPressHandler(this.state.textValue, this.state.sendInvEmail);
    }

    // nestedItems={[this.getAddItem()]}
    render() {
        return ( 
            <div>{this.props.blockCollapsed ? 
              <div className="account-add-panel">{[this.getAddItem()]}</div>
              : 
              <div className="account-add-panel">
                  <ListItem key={this.props.title} className="account-add-panel--header"
                      onClick={this.onNestedListToggle.bind(this)}>
                          <div className="collapse-panel--container">
                              <div className="collapse-panel--text-primary">{this.props.title}</div>
                              {this.getToggleIcon()}
                          </div>
                  </ListItem>
                  <Collapse in={this.state.open} style={{paddingLeft: 12, paddingRight: 12}}>
                      {[this.getAddItem()]}
                  </Collapse>
              </div>
            }</div>
        );
    }
}

AccountAddPanel.propTypes = {
    title: PropTypes.node.isRequired,
    textFieldLabel: PropTypes.node.isRequired,
    buttonLabel: PropTypes.node.isRequired,
    addButtonPressHandler: PropTypes.func.isRequired,
    inputValidator: PropTypes.func,
    hintText: PropTypes.string,
    showInvitationEmailCheckbox: PropTypes.bool,
    blockCollapsed: PropTypes.bool,
};

export default AccountAddPanel;
