import React from 'react';
import NavigationButton from './NavigationButton';

const Troubleshooting = () => {
  
  return (
    <div className="content-userguide">
      <h1 className="section-header">Section 7 Troubleshooting</h1>
      <div>Below you will find solutions to the most commonly known problems.</div>
      <h4>Portal cannot be accessed at all:</h4>
      <div>Make sure that the following domains are whitelisted (ports 80, 443):</div>
      <ul>
        <li>napcongames.com</li>
        <li>fonts.gstatic.com</li>
      </ul>
      <h4>Portal can be accessed, but opening a game shows a blank screen:</h4>
      <div>Whitelist the required domains:</div>
      <ul>
        <li>napcongames.azureedge.net</li>
      </ul>
      <div>If you are using Internet Explorer 11 or older, please use a more recent browser such as Google Chrome or Microsoft Edge.</div>
      <NavigationButton prevName="Administrator Instructions" prevLink="/userguide/administratorInstructions" nextName={null} nextLink={null}/>
    </div>
  );
};


export default Troubleshooting