import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {getScoreDistributionList} from './StatHelper';
import { I18n} from 'react-i18nify';
import {OWN_USER_COLOR} from './Helpers';

/**
 * Score distribution chart in statistics page mission list
 */
class DistributionChart extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            isFirstRender: true
        }
        this.distributionChart = React.createRef();
    }
    componentDidMount() {
        if (this.state.isFirstRender) {
            this.state.isFirstRender = false;
            setTimeout(this.resizeChart.bind(this), 100);
        }        
    }
    resizeChart() {
        const chart = this.distributionChart.current.chart;
        chart.reflow();
    }

    render() {
        const ownId = !this.props.adminView && this.props.profile ? this.props.profile._id : null;
        const chartData = getScoreDistributionList(this.props.stats, !this.props.adminView, ownId, OWN_USER_COLOR);
        
        const plotLineColor = '#e6e6e6';
        const config = {
            chart: {
                plotBorderColor: '#e6e6e6',
                plotBorderWidth: 2,
                // margin: [0, 0, 0, 0],
                type: 'column',
                height: 300
            },
            credits: {
                enabled: false
            },
            legend: {
                enabled: false
            },
            plotOptions: {
                line: {
                    softThreshold: false,
                    lineWidth: 2
                }
            },
            series: [{
                data: chartData,
                marker: {
                    enabled: false
                },
                type: 'column',
                groupPadding: 0,
                color: this.props.lineColor,
                pointPlacement: -0.5
            }],
            title: {
                text: null
            },
            xAxis: {
                allowDecimals: false,
                minorTickLength: 0,
                min: 0,
                max: 150000,
                tickLength: 10,
                title: {
                    text: I18n.t('highScoreSmall')
                },
                tickInterval:10000,
                labels: {
                    rotation: 300,
                    enabled: true
                },
            },
            yAxis: {
                allowDecimals: false,
                gridLineColor: 'transparent',
                labels: {
                    enabled: true
                },
                minorTickLength: 0,
                tickLength: 0,
                title: {
                    text: I18n.t('userCount')
                },
                minPadding: 0,
                min:0,
                minRange : 0.1,
            }
        };

        return (
            <div>
                <h2 className="header2">{this.props.title}</h2>
                <HighchartsReact ref={this.distributionChart} highcharts={Highcharts} options={config}/>
            </div>
        );
    }
}

DistributionChart.propTypes = {
    stats: PropTypes.array,
    title: PropTypes.string
};

export default DistributionChart;