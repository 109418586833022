
import {
    GET_GAMESERVERS, GET_GAMESERVERS_ADMIN_INFO, ERROR_RESPONSE, TOGGLE_GAMESERVER_SHUTDOWN_REQUEST,
} from '../actions/types';

const INITIAL_STATE = { message: '', error: '' };

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case ERROR_RESPONSE:
            return { ...state, error: action.payload };
        case GET_GAMESERVERS:
            return { ...state, servers: action.payload.servers, waitTime: action.payload.waitTime, guestId: action.payload.guestId, notice: action.payload.notice };
        case GET_GAMESERVERS_ADMIN_INFO:
            return { ...state, serversAdminInfo: action.payload };
        case TOGGLE_GAMESERVER_SHUTDOWN_REQUEST:
            return state;
        default:
            return state;
    }
}