
import axios from 'axios';
import Cookies from 'universal-cookie';
import { API_URL, errorHandler } from './index';
import { ERROR_RESPONSE, GET_GAMESERVERS, GET_GAMESERVERS_ADMIN_INFO, TOGGLE_GAMESERVER_SHUTDOWN_REQUEST } from './types';

const cookies = new Cookies();

export function getGameServers(gameId, guestId, loggedIn) {
    return function (dispatch) {
        const requestParams = {
            headers: { Authorization: cookies.get('token')}
        };
        if (!loggedIn && guestId) {
            requestParams.params = { guestId };
        }

        const url = loggedIn ? `${API_URL}/gameserverinfo/${gameId}` : `${API_URL}/gameserverinfo/guest/${gameId}`;
        axios.get(url, requestParams)
            .then((response) => {
                dispatch({
                    type: GET_GAMESERVERS,
                    payload: response.data
                });
            })
            .catch((error) => {
                errorHandler(dispatch, error.response, ERROR_RESPONSE);
            });
    };
}

export function getGameServersAdminInfo() {
    return function (dispatch) {
        axios.get(`${API_URL}/gameserverinfo/admininfo`, { headers: { Authorization: cookies.get('token') },})
            .then((response) => {
                dispatch({
                    type: GET_GAMESERVERS_ADMIN_INFO,
                    payload: response.data
                });
            })
            .catch((error) => {
                errorHandler(dispatch, error.response, ERROR_RESPONSE);
            });
    };
}

export function toggleGameServerShutdownRequest({serverId}) {
    return function (dispatch) {
        axios.post(`${API_URL}/gameserverinfo/admincommandshutdown`, {serverId}, { headers: { Authorization: cookies.get('token') }})
            .then((response) => {
                dispatch({
                    type: TOGGLE_GAMESERVER_SHUTDOWN_REQUEST,
                    payload: response.data
                });
            })
            .catch((error) => {
                errorHandler(dispatch, error.response, ERROR_RESPONSE);
            });
    };
}