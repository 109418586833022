import React, { Component } from 'react';
import Cookies from 'universal-cookie';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Dialog, DialogContent, DialogActions, DialogTitle, Paper, InputLabel } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import { I18n, Translate } from 'react-i18nify';
import Localization from './Localization';
import { UNITS, LANGUAGES } from './Helpers';
import { registerExternalUser } from './actions/auth';
import PrivacyPolicyAgreement from './PrivacyPolicyAgreement';

const cookies = new Cookies();

class RegisterExternalUserDialog extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            agreementConfirmed: false,
            email: this.props.email,
            firstName: this.props.firstName,
            lastName: this.props.lastName,
            isDarkTheme: true,
            unit: UNITS[0].value,
            language: LANGUAGES[0].value,
            dialogOpen: true,
            errorMsg: null,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.errorMessage !== this.props.errorMessage) {
            const errorMsg = this.getErrorMsg();
            this.setState({ errorMsg });
        }
        if (prevProps.isRegisterRequired !== this.props.isRegisterRequired && !this.props.isRegisterRequired && !this.props.errorMessage) {
            this.setState({ dialogOpen: false });
        }
    }

    /**
     * Returns error message, if there is any.
     * @returns {string}
     */
    getErrorMsg() {
        if (this.props.errorMessage && this.props.errorMessage.status) {
            let errorMsg = '';
            if (Array.isArray(this.props.errorMessage.data.error.validationData)) {
                errorMsg = this.props.errorMessage.data.error.validationData.map((err) => I18n.t(err.msg) || err.msg).join(', ');
            } else if (this.props.errorMessage.data.error) {
                errorMsg = I18n.t(this.props.errorMessage.data.error) || this.props.errorMessage.data.error;
            } else {
                errorMsg = 'unknown error';
            }
            return <Translate value="errorWithMsgStatus" message={errorMsg} status={this.props.errorMessage.status}/>;
        }
        return null;
    }

    getThemeStyle() {
        const themeStyle = {
            vagueText: { color: 'rgba(0, 0, 0, 0.3)' },
            rightPanelText: { color: 'inherit' },
            rdyBtn: 'btn-filled',
            rdyBtnDsbld: 'btn-filled-disabled',
        };

        if (this.state.isDarkTheme) {
            themeStyle.vagueText = { color: 'rgba(255, 255, 255, 0.3)' };
            themeStyle.rightPanelText = { color: 'rgb(255, 255, 255)' };
            themeStyle.rdyBtn = 'btn-filled btn-filled--dark';
            themeStyle.rdyBtnDsbld = 'btn-filled btn-filled-disabled--dark';
            return themeStyle;
        }
        return themeStyle;
    }

    getSelectComponents() {
        const languageText = <Translate value="language"/>;
        const languageSelect = <FormControl className="register-dialog--select">
            <InputLabel shrink>{languageText}</InputLabel>
            <Select onChange={this.onLanguageChange} value={this.state.language}>
                {LANGUAGES.map((language) => <MenuItem key={language.value} value={language.value}>{I18n.t(language.locKey)}</MenuItem>)}
            </Select>
        </FormControl>;

        const unitsText = <Translate value="unitPlural"/>;
        const unitsSelect = <FormControl className="register-dialog--select">
            <InputLabel shrink>{unitsText}</InputLabel>
            <Select onChange={this.onUnitChange} value={this.state.unit}>
                {UNITS.map((unit) => <MenuItem key={unit.value} value={unit.value}>{I18n.t(unit.locKey)}</MenuItem>)}
            </Select>
        </FormControl>;
        return [languageSelect, unitsSelect];
    }

    onUnitChange = (event) => {
        this.setState({ unit: event.target.value });
    }

    onLanguageChange = (event) => {
        this.setState({ language: event.target.value });
        Localization.setLocale(event.target.value);
    };

    setAgreementConfirmed(event) {
        this.setState({ agreementConfirmed: !this.state.agreementConfirmed });
    }

    validateForm() {
        return this.state.agreementConfirmed;
    }

    handleClick() {
        const regToken = cookies.get('token');
        const { email, firstName, lastName } = this.props;
        const title = '';
        const agreementConfirmed = true;
        const { unit, language } = this.state;
        this.props.registerExternalUser(regToken, { email, firstName, lastName, title, agreementConfirmed, unit, language });
    }

    render() {
        const emailLink = <a href={'mailto:' + this.props.email}>{this.props.email}</a>;
        const napconLink = <a href={'https://napcongames.com'}>{'https://napcongames.com'}</a>;
        const texts = {
            mainTitle: <Translate value="welcome" tag="div" className="register-dialog--title"/>,
            greeting: <Translate value="registerExternalGreeting" tag="p" firstName={this.props.firstName} lastName={this.props.lastName} />,
            registerDetails1: <Translate value="registerExternalDetails1"/>,
            registerDetails2: <Translate value="registerExternalDetails2"/>,
            registerDetails3: <Translate value="registerExternalDetails3" tag="p"/>,
            rdy: <Translate value="readyToPlay"/>,
            email: <Translate value="email"/>,
        };
        const themeStyle = this.getThemeStyle();
        const continueButton = this.validateForm()
            ? <Button className={themeStyle.rdyBtn} onClick={this.handleClick.bind(this)}>{texts.rdy}</Button>
            : <Button className={themeStyle.rdyBtnDsbld} disabled>{texts.rdy}</Button>;

        return <Dialog open={this.state.dialogOpen}
            PaperComponent={Paper}
            PaperProps={{ className: 'register-dialog--body', style: { maxWidth: '660px' } }}>
            <DialogTitle disableTypography={true} style={{ padding: '10px 24px' }}>{texts.mainTitle}</DialogTitle>
            <DialogContent className="register-dialog--content" style={{ fontSize: 14 }}>
                {texts.greeting}
                <p>{texts.registerDetails1} {emailLink}. {texts.registerDetails2} {napconLink}.</p>
                {texts.registerDetails3}
                <div className="register-dialog--field-container">
                    <TextField label={texts.email} value={this.props.email} disabled/>
                    {this.getSelectComponents()}
                </div>
                <PrivacyPolicyAgreement simple={true} onChange={(e) => this.setState({ agreementConfirmed: !this.state.agreementConfirmed })} />
                <div className="register-dialog--error">{this.state.errorMsg}</div>
            </DialogContent>
            <DialogActions className="register-dialog-actions">{continueButton}</DialogActions>
           
        </Dialog>;
    }
}

RegisterExternalUserDialog.propTypes = {
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
};

RegisterExternalUserDialog.defaultProps = {
    email: '',
    firstName: '',
    lastName: '',
};

function mapStateToProps(state) {
    return {
        authenticated: state.auth.authenticated,
        isRegisterRequired: state.auth.isRegisterRequired,
        errorMessage: state.auth.error,
    };
}

export default withRouter(connect(mapStateToProps, { registerExternalUser })(RegisterExternalUserDialog));
