import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { I18n, Translate } from 'react-i18nify';
import moment from 'moment';
import { getActiveUsersForMonths } from './StatHelper';

import ColumnChart from './ColumnChart';

/**
 * Component with user completion, play count and active users charts for statistics page admin view
 */
class AdminUserStats extends Component {
    constructor(props, context) {
        super(props, context);
    }

    getMonthCategories(monthCount) {
        const months = [];
        let month = (moment().month() - monthCount + 1) % 12;
        for (let i = 0; i < monthCount; i++) {
            months.push(moment().month(month).format('MMMM'));
            month = (month + 1) % 12;
        }
        return months;
    }

    getStatDataForGraphs(stats) {
        const uniqueCompletions = {};
        const playCountData = {};
        let playCountMax = 0;

        if (stats || stats.length > 0) {
            for (let stat of stats) {
                if (!uniqueCompletions.hasOwnProperty(stat.missionId)) {
                    uniqueCompletions[stat.missionId] = [];
                    playCountData[stat.missionId] = 0;
                }

                if (stat.status === "Success" && uniqueCompletions[stat.missionId].indexOf(stat.userId) === -1) {
                    uniqueCompletions[stat.missionId].push(stat.userId);
                }
                playCountData[stat.missionId]++;
                playCountMax = Math.max(playCountMax, playCountData[stat.missionId]);
            }
        }

        const userCompletionDataForGraph = [];
        const playCountDataForGraph = [];
        this.props.missionInfo.forEach(mission => {
            const missionId = mission.missionId;
            userCompletionDataForGraph.push(uniqueCompletions.hasOwnProperty(missionId) ? uniqueCompletions[missionId].length : 0);
            playCountDataForGraph.push(playCountData.hasOwnProperty(missionId) ? playCountData[missionId] : 0);
        });

        const activeMonths = (this.props.selectedTime >= 0 && this.props.selectedTime < 6) ? 6 :  this.props.selectedTime;

        return {
            userCompletionData: userCompletionDataForGraph,
            playCountData: playCountDataForGraph,
            playCountMax: playCountMax,
            activeUserData: getActiveUsersForMonths(this.props.activeUsers, this.props.selectedGroupUserIds, activeMonths)
        };
    }

    render() {
        const texts = {
            userCompletion: <Translate value="userCompletion"/>,
            playCount: <Translate value="playCount"/>,
            activeUsers: <Translate value="activeUsers"/>
        };

        const statData = this.getStatDataForGraphs(this.props.stats);
        const missionCategories = this.props.missionInfo.map(info => {
            return info.name;
        });

        return (
            <div className="statistics-container">
                <div className="admin-total-stats admin-total-stats-charts">
                    <div className="admin-total-stats--chart">
                        <ColumnChart title={texts.userCompletion} useMissionColors={true} seriesName={""}
                                     categories={missionCategories}
                                     data={statData.userCompletionData} yMax={Math.max(...statData.userCompletionData)}/>
                    </div>
                    <div className="admin-total-stats--chart">
                        <ColumnChart title={texts.playCount} useMissionColors={true} seriesName={""}
                                     categories={missionCategories}
                                     data={statData.playCountData} yMax={statData.playCountMax}/>
                    </div>
                    <div className="admin-total-stats--chart">
                        <ColumnChart title={texts.activeUsers}
                                     categories={this.getMonthCategories(statData.activeUserData.length)} useMissionColors={false}
                                     data={statData.activeUserData} yMax={Math.max(...statData.activeUserData)}/>
                    </div>
                </div>
            </div>
        );
    }
}

AdminUserStats.propTypes = {
    stats: PropTypes.array,
    missionInfo: PropTypes.array.isRequired,
    selectedTime: PropTypes.number.isRequired
};

export default AdminUserStats;
