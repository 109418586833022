import axios from 'axios';
import Cookies from 'universal-cookie';
import { logoutUser } from './auth';
import { STATIC_ERROR, FETCH_USER, FETCH_USER_SEARCH_RESULT, SEARCH_USER, DELETE_USER, MAKE_ORDER, MAKE_CONTACT, MAKE_ORDER_CONFIRMATION, MAKE_OWC_REGISTERATION, SKIP_TUTORIAL, FETCH_AGREEMENT_VERSION, UPDATE_USER_AGREEMENT } from './types';
export const API_URL = './api';

const cookies = new Cookies();
//= ===============================
// Utility actions
//= ===============================

export function getAgreementVersion() {
  
  return function (dispatch) {
    axios.get(`/${API_URL}/setting/policyversion`, {
      headers: { Authorization: cookies.get('token') },
    })
    .then((response) => {
      dispatch({
        type: FETCH_AGREEMENT_VERSION,
        payload: response.data.data,
      });
    })
    .catch((error) => {
      errorHandler(dispatch, error, STATIC_ERROR);
    });
  };
}

export function agreementUpdate(uid, data) {
  return function (dispatch) {
    axios.post(`/${API_URL}/user/update/${uid}`,
      data,
      { headers: { Authorization: cookies.get('token') } })
      .then(res => {
        dispatch({
          type: UPDATE_USER_AGREEMENT,
          payload: res.data
        });
       
        if (!res.data.agreementAgreed) {
          logoutUser();
        }
      })
      .catch(err => {
        console.error(err);
        errorHandler(dispatch, error.response, STATIC_ERROR);
      });
  }
}

export function fetchUser(uid, searched) {
  const userIdParam = uid || "";

  return function (dispatch) {
    axios.get(`/${API_URL}/user/${userIdParam}`, {
      headers: { Authorization: cookies.get('token') },
    })
    .then((response) => {
      dispatch({
        type: searched ? FETCH_USER_SEARCH_RESULT : FETCH_USER,
        payload: response.data.user,
      });
    })
    .catch((error) => {
        errorHandler(dispatch, error, STATIC_ERROR);
    });
  };
}

export function searchUsers(searchValue) {
    return function (dispatch) {
        const urlEnd = searchValue ? 'search/' + encodeURIComponent(searchValue) : 'list/all';

        axios.get(`/${API_URL}/user/${urlEnd}`, {headers: { Authorization: cookies.get('token') },})
            .then((response) => {
                dispatch({
                    type: SEARCH_USER,
                    payload: response.data.results,
                });
            })
            .catch((error) => {
                errorHandler(dispatch, error, STATIC_ERROR);
            });
    };
}

export function deleteUser(uid) {
    return function (dispatch) {
        axios.delete(`${API_URL}/user/${uid}`, {headers: { Authorization: cookies.get('token') },})
            .then((response) => {
                dispatch({
                    type: DELETE_USER,
                    payload: {data: response.data, uid: uid }
                });
            })
            .catch((error) => {
              errorHandler(dispatch, error.response, STATIC_ERROR);
            });
    };
}

// Post napconmakeorder
export function makeOrder({numOfUsersDistiller, numOfUsersFurnace, mainAdminEmail, companyId, company, VatId, busRegNum, address, postalCode, city, country, companyContact, email, phone, billingAddress, billingPostalCode, billingCity, billingCountry, PoNumber, customerRef}) {
    return function (dispatch) {
        axios.post(`/${API_URL}/napconmakeorder`, { numOfUsersDistiller, numOfUsersFurnace, mainAdminEmail, companyId, company, VatId, busRegNum, address, postalCode, city, country, 
          companyContact, email, phone, billingAddress, billingPostalCode, billingCity, billingCountry, PoNumber, customerRef})
            .then((response) => {
                dispatch({
                    type: MAKE_ORDER,
                    payload: response.data
                });
            }) 
            .catch((error) => {
                errorHandler(dispatch, error.response, STATIC_ERROR);
            });
    };
}

// Post napconmakeowcregisteration
export function makeOWCRegisteration({company, companyContact, email}) {
    return function (dispatch) {
        axios.post(`${API_URL}/napconmakeowcregisteration`, {company, companyContact, email})
            .then((response) => {
                dispatch({
                    type: MAKE_OWC_REGISTERATION,
                    payload: response.data
                });
            })
            .catch((error) => {
                errorHandler(dispatch, error.response, STATIC_ERROR);
            });
    };
}

// Post napconmakecontact
export function makeContactRequest({company, companyContact, email, phone, message}) {
    return function (dispatch) {
        axios.post(`${API_URL}/napconmakecontact`, {company, companyContact, email, phone, message})
            .then((response) => {
                dispatch({
                    type: MAKE_CONTACT,
                    payload: response.data
                });
            })
            .catch((error) => {
                errorHandler(dispatch, error.response, STATIC_ERROR);
            });
    };
}

// Post napconconfirmorder
export function makeCorfirmOrderMail({email}) {
    return function (dispatch) {
        axios.post(`${API_URL}/napconmakeconfirmmail`, {email})
            .then((response) => {
                dispatch({
                    type: MAKE_ORDER_CONFIRMATION,
                    payload: response.data
                });
            })
            .catch((error) => {
                errorHandler(dispatch, error.response, STATIC_ERROR);
            });
    };
}

export function sendSkipTutorial(uid) {
    return function (dispatch) {
        axios.post(`${API_URL}/user/skiptutorial/${uid}`, {}, {headers: { Authorization: cookies.get('token') },})
            .then((response) => {
                dispatch({
                    type: SKIP_TUTORIAL,
                    payload: response.data
                });
            })
            .catch((error) => {
                errorHandler(dispatch, error, STATIC_ERROR);
            });
    };
}

export function errorHandler(dispatch, error, type) {
  window.dispatchEvent(new CustomEvent('custom_error', {detail: error}));

  axios.post(`/${API_URL}/logger/errorlog`,
    {
      error: error,
      type: type,
    },
    { headers: { Authorization: cookies.get('token') } })
    .catch(err => {
      console.error('Could not save error message to log.', error);
    });

  dispatch({
    type,
    payload: error,
  });
}

// Post Request
export function postData(action, errorType, isAuthReq, url, dispatch, data) {
  const requestUrl = API_URL + url;
  let headers = {};

  if (isAuthReq) {
    headers = { headers: { Authorization: cookies.get('token') } };
  }

  axios.post(requestUrl, data, headers)
  .then((response) => {
    dispatch({
      type: action,
      payload: response.data,
    });
  })
  .catch((error) => {
    errorHandler(dispatch, error.response, errorType);
  });
}

// Get Request
export function getData(action, errorType, isAuthReq, url, dispatch) {
  const requestUrl = API_URL + url;
  let headers = {};

  if (isAuthReq) {
    headers = { headers: { Authorization: cookies.get('token') } };
  }

  axios.get(requestUrl, headers)
  .then((response) => {
    dispatch({
      type: action,
      payload: response.data,
    });
  })
  .catch((error) => {
    errorHandler(dispatch, error.response, errorType);
  });
}

// Put Request
export function putData(action, errorType, isAuthReq, url, dispatch, data) {
  const requestUrl = API_URL + url;
  let headers = {};

  if (isAuthReq) {
    headers = { headers: { Authorization: cookies.get('token') } };
  }

  axios.put(requestUrl, data, headers)
  .then((response) => {
    dispatch({
      type: action,
      payload: response.data,
    });
  })
  .catch((error) => {
    errorHandler(dispatch, error.response, errorType);
  });
}

// Delete Request
export function deleteData(action, errorType, isAuthReq, url, dispatch) {
  const requestUrl = API_URL + url;
  let headers = {};

  if (isAuthReq) {
    headers = { headers: { Authorization: cookies.get('token') } };
  }

  axios.delete(requestUrl, headers)
  .then((response) => {
    dispatch({
      type: action,
      payload: response.data,
    });
  })
  .catch((error) => {
    errorHandler(dispatch, error.response, errorType);
  });
}
