import { withRouter } from 'react-router-dom';
import { Component } from 'react';

/**
 * ScrollToTop makes sure the window is scrolled up on every navigation.
 */
class ScrollToTop extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            document.querySelector('.main').scrollTop = 0;
        }
    }

    render() {
        return this.props.children;
    }
}

export default withRouter(ScrollToTop)