import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import { Table, TableBody, TableHead, TableRow, TableCell } from '@material-ui/core';

import { getRealtimeStats } from './actions/stats';
import { getTournament } from './actions/tournament';
import { getGroups } from './actions/group';
import { getStatsForUsers } from './actions/stats';
import { fetchUser } from './actions/index';
import { getPoolAll } from './actions/userpool';

import { getMissionTitleByMissionId } from './helpersgames';
import { I18n, Translate } from 'react-i18nify';


class CompetitionPage extends Component {

    texts = {
        tournamentTitle: 'NAPCON Distiller – Tournament Leaderboard',
        rank: 'Rank',
        group: 'Team',
        mission: 'Mission',
        totalScore: 'Total score',
        distanceToLead: 'Difference'
    };

    constructor(props, context) {
        super(props, context);

        this.state = {
            height: '395px',
            fixedHeader: true,
            showCheckboxes: false,
            tournament: null,
            stats: {},
            realtimeStats: {},
            usergroups: [],
            userToGroupMap: {},
            allGroupsUserIdList: [],
            headerColumns: [],
            headerColumns2: [],
            statsTimer: null,
            rtStatsTimer: null,
            gameId : ""
        };
    }

    getValue(value) {
        return value !== undefined && typeof value === "string" ? value : " ";
    }

    componentWillMount() {
        if (!this.props.pool || !this.props.pool._id) {
            this.props.getPoolAll();
            return;
        }
        if (!this.props.tournament || !this.props.tournament._id) {
            this.props.getTournament(this.props.pool._id);
            return;
        }
        this.buildStateFromProps(this.props);
    }

    componentWillReceiveProps() {
        if (!this.props.pool || !this.props.pool._id) {
            this.props.getPoolAll();
            return;
        }
        if (!this.props.tournament || !this.props.tournament._id) {
            this.props.getTournament(this.props.pool._id);
            return;
        }
        this.buildStateFromProps(this.props);
    }

    componentDidMount() {
        let timer = setInterval(this.getStats.bind(this), 10000);
        let rtTimer = setInterval(this.getRealtimeStats.bind(this), 1000);

        this.getStats();

        this.setState({
            statsTimer: timer,
            rtStatsTimer: rtTimer
        });
    }

    componentWillUnmount() {
        clearInterval(this.state.statsTimer);
        clearInterval(this.state.rtStatsTimer);
        this.setState({
            statsTimer: null,
            rtStatsTimer: null
        });
    }

    buildStateFromProps(checkedProps) {
        let userIdList = [];
        checkedProps.usergroups.forEach(group => userIdList = userIdList.concat(group.users));
        let groupmap = {};
        checkedProps.usergroups.forEach(group => {
            group.users.forEach(user => groupmap[user] = group._id);
        });

        const gameId = checkedProps.pool && checkedProps.pool.games.length > 0 ? checkedProps.pool.games[0] : "";

        checkedProps.tournament.missions.sort((r1,r2) => {
            const a = getMissionTitleByMissionId(gameId, r1, I18n._localeKey);
            const b = getMissionTitleByMissionId(gameId, r2, I18n._localeKey);
            return a.localeCompare(b);
        });

        let headerColumns = this.buildHeaderColumns(checkedProps.tournament.missions, gameId);

        this.setState({
            tournament: checkedProps.tournament,
            usergroups: checkedProps.usergroups,
            allGroupsUserIdList: userIdList,
            userToGroupMap: groupmap,
            headerColumns: headerColumns,
            gameId: gameId
        });

        this.getStats();
        this.getRealtimeStats();

    }


    getStats() {
        let _this = this;
        if (!this.state.allGroupsUserIdList || !this.state.tournament) {
            return;
        }

        let groupIds = [];
        for (let group of this.state.usergroups) {
            groupIds.push(group._id);
        }

        getStatsForUsers(this.state.tournament.pool, groupIds,
            moment(this.state.tournament.startDate),
            moment(this.state.tournament.endDate),
            undefined, "Success", this.state.gameId)
            (result => {

            if (!result.payload) {
                return;
            }

            let userStats = result.payload;
            let statsTemp = {};

            userStats.forEach(stat => {
                let scoreNumber = Number(stat.score);
                let group = _this.state.userToGroupMap[stat.userId];

                if (!statsTemp[group]) {
                    statsTemp[group] = {};
                }
                if (!statsTemp[group][stat.missionId] ||
                    statsTemp[group][stat.missionId] < scoreNumber) {
                    statsTemp[group][stat.missionId] = scoreNumber;
                }
            });
            _this.setState({ stats: statsTemp });
        });
    }

    getRealtimeStats() {
        let _this = this;
        if (!this.state.tournament) {
            return;
        }

        getRealtimeStats()(result => {
            if (!result.payload) {
                return;
            }
            let rt = result.payload;
            let rtStatsTemp = {};
            _this.state.tournament.missions.forEach(missionId => {
                if (rt[missionId]) {
                    for (var userId in rt[missionId]) {
                        let group = _this.state.userToGroupMap[userId];
                        let scoreNumber = Number(rt[missionId][userId]);

                        if (!rtStatsTemp[group]) {
                            rtStatsTemp[group] = {};
                        }

                        rtStatsTemp[group][missionId] = scoreNumber;
                    }
                }
            });
            _this.setState({ realtimeStats: rtStatsTemp });
        });
    }

    // headerColumns.push(<TableHeaderColumn><img src={napconBuildVersion + "images/world_cup_logo_plain.png"}
    // className="tournament-page--image" /></TableHeaderColumn>);
    buildHeaderColumns(missionList, gameId) {
        const headStyle = {position: "sticky", top: 0, backgroundColor: "white"};
        let headerColumns = [];
        headerColumns.push(<TableCell style={headStyle}>
            <div>
                {this.texts.rank}
            </div>
        </TableCell>);
        headerColumns.push(<TableCell style={headStyle}>
            <div>
                    {this.texts.group}
                </div>
        </TableCell>);
        missionList.forEach(mission => {
            const missionName = getMissionTitleByMissionId(gameId, mission, I18n._localeKey);
            headerColumns.push(<TableCell style={headStyle}>
                <div>
                    {missionName}
                </div>
            </TableCell>);
        });
        headerColumns.push(<TableCell style={headStyle}>
            <div>
                    {this.texts.totalScore}
                </div>
        </TableCell>);
        headerColumns.push(<TableCell style={headStyle}>
            <div>
                    {this.texts.distanceToLead}
                </div>
        </TableCell>);

        return headerColumns;
    }


    render() {
        if (!(this.state.tournament && this.state.usergroups && this.state.tournament.missions)) {
            return null;
        }
        let _this = this;

        const tableRowsAndScores = [];

        let leaderTotal = null;

        this.state.usergroups.forEach(group => {
            let columns = [];
            columns.push(
                <TableCell>
                    <div>
                        {group.name}
                    </div>
                </TableCell>);

            let total = 0;
            _this.state.tournament.missions.forEach(missionId => {
                let rtStatAvailable = _this.state.realtimeStats[group._id]
                    && _this.state.realtimeStats[group._id][missionId];
                let statAvailable = _this.state.stats[group._id]
                    && _this.state.stats[group._id][missionId];
                let realTime = rtStatAvailable == null && statAvailable == null ? "-" : rtStatAvailable;
                let allTimeStat = statAvailable == null ? "" : statAvailable;
                if (!statAvailable && !rtStatAvailable) {
                    total += 0;
                } else if (!statAvailable && rtStatAvailable) {
                    total += rtStatAvailable;
                } else if (statAvailable && !rtStatAvailable) {
                    total += statAvailable;
                } else if (statAvailable > rtStatAvailable) {
                    total += statAvailable;
                    } else {
                    total += rtStatAvailable;
                    }

                    columns.push(
                        <TableCell>
                        <div>
                            {allTimeStat}
                            </div>
                        <div className="realtime--stat">
                            {realTime}
							</div>
                        </TableCell>);
            });

            columns.push(
                <TableCell className="color-light">
                    <div>
                        {total}
                    </div>
                </TableCell>);

            tableRowsAndScores.push({ cols: columns, score: total });
            if (!leaderTotal || leaderTotal < total) {
                leaderTotal = total;
            }
        });

        // Sort by total scores, add distance to leader
        const tableRows = tableRowsAndScores.sort((r1, r2) => r2.score - r1.score).map((r, index) => {

            r.cols.unshift(
                <TableCell>
                    <div>
                        {index + 1}
                    </div>
                </TableCell>);

            let distToLead = r.score - leaderTotal;
            if (distToLead == 0) {
                r.cols.push(<TableCell></TableCell>);
            } else {
                r.cols.push(
                    <TableCell>
                        <div>
                            {distToLead}
                        </div>
                    </TableCell>);
            }
            return <TableRow>{r.cols}</TableRow>;
        });

        return (
            <div>
                <div className="tournament--header">
                    <div>
                        <img src={napconBuildVersion + "images/world_cup_logo_plain.png"} alt="logo" />
                        <h2>{this.getValue(this.state.tournament.name)}</h2>
                    </div>
                </div>
                <div id="tables">
                    <Table
                        fixedHeader={this.state.fixedHeader}
                        height={this.state.height}
                        headerStyle={{ 'width': "99%" }}
                    >
                        <TableHead
                            className="white-border"
                        >
                            <TableRow>
                                {this.state.headerColumns}
                            </TableRow>
                        </TableHead>
                        <TableBody
                            className="white-border striped"
                        >
                            {tableRows}
                        </TableBody>
                    </Table>
            </div>
            </div>

        );
    }


};

function mapStateToProps(state) {
    return {
        pool: state.userPool.currentPool == null ? null : state.userPool.currentPool.pool,
        tournament: state.tournamentReducer.tournament,
        usergroups: state.group.groups
    };
}

CompetitionPage.propTypes = {
    pool: PropTypes.object
};

export default connect(mapStateToProps, { fetchUser, getPoolAll, getGroups, getTournament })(CompetitionPage);
