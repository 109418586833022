import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Translate, I18n } from 'react-i18nify';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { List, ListItem } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import gamesData from 'games.json';
import Dialog from '@material-ui/core/Dialog';
import LoginDialogElement from './LoginDialogElement';
import {
    makeOrder, makeContactRequest, makeCorfirmOrderMail, agreementUpdate,
} from './actions/index';
import { ContactDialog } from './NewOrderPage';
import ConfirmDialog from './ConfirmDialog';
import LicensePopup from './LicensePopup';
import { getAgreementVersion } from './actions/index';

import { logoutUser } from './actions/auth';

class InfoPage extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            confirmOpen: false,
            gameList: [
                {
                    id: 1, title: 'Furnace', license: 'furnace', gameLink: 'furnace', imageLink: 'furnace-image',
                },
                {
                    id: 2, title: 'Condensing', license: 'condensing', gameLink: 'condensing', imageLink: 'condensing-image',
                },
                {
                    id: 3, title: 'Distiller', license: 'distiller', gameLink: 'distillation', imageLink: 'distiller-image',
                },
                {
                    id: 4, title: 'Heat Transfer', license: 'heat-transfer', gameLink: 'heat-transfer', imageLink: 'heat-transfer-image',
                },
                {
                    id: 5, title: 'Boiling', license: 'boiling', gameLink: 'boiling', imageLink: 'boiling-image',
                },
				{
					id: 6, title: 'Compressor', license: 'compressor', gameLink: 'compressor', imageLink: 'compressor-image',
				},
            ],

        };
    }

    checkObjProp(obj, prop) {
        return Object.prototype.hasOwnProperty.call(obj, prop);
    }

    /**
     * Get games that company has licenses to
     * @returns {Array}
     */
    getGames = () => {
        if (this.props.userPool && !this.props.company) {
            return [];
        }

        const pools = this.props.allPools || (this.props.userPool ? [this.props.userPool] : null);

        if (!pools || !this.props.company) {
            return [];
        }
        const result = [];
        const today = new Date();
        pools
            .forEach((userPool) => {
                if (userPool.pool === null) {
                    return;
                }
                else {
                    userPool.pool.games.forEach((gameName) => {
                        if (!result.includes(gameName)) { result.push(gameName); }
                    });
                }
            });

        return result;
    };

    handleConfirmOpen = () => {
        this.setState({ confirmOpen: true });
    };


    handleConfirmClose = () => {
        this.setState({ confirmOpen: false });
    };

    onGameClick = (event) => {
        // This prevents ghost click.
        event.preventDefault();

        this.setState({
            open: true,
            anchorEl: event.currentTarget,
        });
    };

    componentDidMount() {
        this.onNotificationCallback = this.onNotification.bind(this);
        window.addEventListener('notification', this.onNotificationCallback);
    }

    componentWillUnmount() {
        window.removeEventListener('notification', this.onNotificationCallback);
        this.onNotificationCallback = null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.checkObjProp(this.props.profile, '_id') && this.props.profile._id !== undefined && prevProps.profile._id !== this.props.profile._id) {
            this.props.getAgreementVersion();
        }
    }

    showLicensePopup() {
        const returnValue = String(this.props.agreementAcceptedVersion) !== String(this.props.currentPrivacyPolicyVersion.settingValue);
        if (this.checkObjProp(this.props, 'currentPrivacyPolicyVersion')) {
            if (this.checkObjProp(this.props.currentPrivacyPolicyVersion, 'settingValue')) {
                return returnValue;
            }
        }
        return false;
    }

    onNotification(event) {
        if (event.detail.message === 'contact_request_created') {
            this.handleConfirmOpen();
        }
    }

    handleRequestClose = () => {
        this.setState({
            open: false,
        });
    };

    onContactRequest(company, name, email, phone, message) {
        const phoneOrEmpty = phone || 'empty';
        this.props.makeContactRequest({
            company,
            companyContact: name,
            email,
            phone: phoneOrEmpty,
            message,
        });
    }

    handleDeclineLicenseVersion = () => {
        this.props.logoutUser();
    };

    handleAcceptLicenseVersion = () => {
        this.props.agreementUpdate(this.props.profile._id, { agreementAgreed: true, agreementAcceptedVersion: this.props.currentPrivacyPolicyVersion.settingValue });
    };

    render() {
        const texts = {
            napconGames: <Translate value="napconGames" />,
            supportEmail: I18n.t('supportEmail'),
            fuelBlender: <Translate value="fuelBlender" />,
            more: <Translate value="more" />,
            distiller: <Translate value="distiller" />,
            orderInfo: <Translate value="orderInfo" />,
            registerationInfo2: <Translate value="registerDescription3" />,
            signInInfo: <Translate value="signInInfo" />,
            napconAccountInfo: <Translate value="napconAccountInfo" />,
            distillerInfoText1a: <Translate value="distillerInfoText1a" />,
            furnaceInfoText: <Translate value="furnaceInfoText" />,
            infoPageBlenderText: <Translate value="infoPageBlenderText" />,
            order: <Translate value="order" />,
            play: <Translate value="play" />,
            confirmMessage: <Translate value="confirmMessage"/>,
            confirmTitle: <Translate value="confirmTitle" tag="h3"/>,
            contactButtonText: <Translate value="contactButtonText"/>,
            licensesButtonText: <Translate value="buyLicenseButtonText"/>,
            contactFormMessage: I18n.t('contactFormMessage'),
            whatIsDistiller: <Translate value="whatIsDistiller"/>,
            whatIsFurnace: <Translate value="whatIsFurnace"/>,
            aboutNAPCONGames: <Translate value="aboutNAPCONGames"/>,
        };

        const buttonStyle = {
            fontSize: '12 !important',
        };

        const styleLeftSide = {
            width: '60%',
        };

        const styleRightSide = {
            width: '40%',
        };

        const secondaryHeading = {
            paddingLeft: '10%',
        };

        const gameInfoListStyle = {
            display: 'flex',
            flexWrap: 'wrap',
            marginLeft: 50,
        };

        const licensesAndPlayButtonStyle = {
            textAlign: 'center',
        };

        const imageLinkStyle = {
            cursor: 'pointer',
        };

        const licenses = this.getGames();

        const contactButton = <div className="order--contact-button contact-button">
            <ContactDialog closeHandler={this.onContactRequest.bind(this)} labelText={texts.contactButtonText} defaultText={texts.contactFormMessage} />
        </div>;

        const licensesButton = <a href="https://www.napconsuite.com/contact-us">
            <Button className="btn info-page--button btn-filled color-white" style={buttonStyle}>{texts.licensesButtonText}</Button>
        </a>;

        const contactConfirmDialog = <ConfirmDialog open={this.state.confirmOpen === true} hide={true} title={texts.confirmTitle}
                                                    message={texts.confirmMessage}
                                                    confirm={'Ok'}
                                                    closeHandler={this.handleConfirmClose.bind(this)}
                                                    isDeleteAction={false}/>;

        const loginSection = this.props.authenticated
            ? <div>
            </div>
            : <div className="info-page--section">
                <div className="info-page--text-wrapper">
                    <div>
                        <h1 className="color-black info-page--header stretch add-line">{texts.signInInfo}</h1>
                        <h1 className="color-blue info-page--header stretch">{texts.napconAccountInfo}</h1>
                        <LoginDialogElement />
                    </div>
                </div>
                <div className="info-page--background image1 float-right">
                </div>
            </div>;

        const blenderInfo = <div className="info-page--section">
            <div className="info-page--text-wrapper add-more-margin">
                <div>
                    <h2 className="title-bar--subheading add-line-small">Fuel Blender</h2>
                    <div className="info-page--text-small">
                        {texts.infoPageBlenderText}
                    </div>
                    <div className="info-page--button-container">
                        <Button className="btn info-page--button NAPCON-color" style={buttonStyle} href={'http://www.napconsuite.com/product/napcon-games/#fuel-blender'} target={'_blank'}>
                            {texts.more}
                        </Button>
                    </div>
                    <StoreButtons />
                </div>
            </div>
            <div className="info-page--background image4 float-right align-right">
            </div>
        </div>;


        const furnaceInfo = <div id="outer-container" className="item even info-page--section-furnace-info">
            <div id="inner-container" className="relative">
                <h4 className="game-title-plain">Furnace</h4>
            </div>
            <div id="in-container" className="picture-box furnace-image clear">
                <div className="content">
                    {/*
                        <div className="hidden-container">
                            <div className="title-container">
                            </div>
                            <div className="text-container">
                                {texts.furnaceInfoText}
                                <a className="arrow-link" href="http://www.napconsuite.com/product/napcon-games/#campaign" target="_blank">{texts.more}</a>
                            </div>
                        </div>
                        */}
                </div>
            </div>
            {/* licenses.includes('furnace') ? null : <div className={"item-description"}>{texts.whatIsFurnace}</div> */}
            <div className={'item-description'}>Try the free Furnace trial</div>
            <div className="button-container align-center">
                <Link to="/furnace-trial">
                    <Button className="btn info-page--button btn-filled color-white" style={buttonStyle}>{texts.play}</Button>
                </Link>
            </div>
        </div>;

        const GameListLicensedGames = ({ game }) => (
            <div className="game-item">
                <div id="inner-container" className="relative">
                    <h4 className="game-title-plain">{game.title}</h4>
                </div>

                <div style={imageLinkStyle} id="in-container" className={`picture-box ${game.imageLink} clear`}></div>
                <div style={licensesAndPlayButtonStyle}>
                    <Link to={game.gameLink}>
                        <Button className="btn info-page--button btn-filled color-white" style={buttonStyle}>{texts.play}</Button>
                    </Link>
                </div>
            </div>
        );

        const GameListUnlicensedGames = ({ game }) => (
            <div className="game-item">
                <div id="inner-container" className="relative">
                    <h4 className="game-title-plain">{game.title}</h4>
                </div>
                <div id="in-container" className={`picture-box ${game.imageLink} game-item--disabled`}>
                </div>
                <div style={licensesAndPlayButtonStyle}>
                    {licensesButton}
                </div>
            </div>
        );

        const gameInfo = this.props.authenticated
            ? <div style={gameInfoListStyle}>
                {this.showLicensePopup() ? <LicensePopup content="privacyPolicy" modalOpen={true} handleAccept={this.handleAcceptLicenseVersion} handleDecline={this.handleDeclineLicenseVersion}/> : null}
                {this.state.gameList.map((game) => (
                    licenses.includes(game.license) ? <GameListLicensedGames key={game.id} game = {game} /> : null
                ))}
                {this.state.gameList.map((game) => (
                    licenses.includes(game.license) ? null : <GameListUnlicensedGames key={game.id} game = {game}/>
                ))}
            </div>
            : <div className="info-page--section">
                <div className="info-page--background float-left align-left furnace-info-container" style={styleLeftSide}>{furnaceInfo}</div>
                <div className="info-page--text-wrapper" style={styleRightSide}>
                    <h1 className="color-black info-page--header" style={secondaryHeading}>Step into the game</h1>
                    <p className="info-page--text-paragraph ">{texts.aboutNAPCONGames}<br/><br/>Visit <a href="https://www.napconsuite.com/product/napcon-games/" target="_blank">www.napconsuite.com/product/napcon-games/</a> to learn more.</p>
                </div>
            </div>;


        return (
            <div className="info-page page-container">
                {loginSection}
                {contactConfirmDialog}
                {gameInfo}
                {/* blenderInfo */}
            </div>
        );
    }
}

class StoreButtons extends Component {
    render() {
        return (
            <div className="blender-page--buttons">
                <a href={blenderAppStoreUrl} target="_blank">
                    <img src={`${napconBuildVersion}images/AppStore.png`}
                         className="blender-page--button" />
                </a>
                <a href={blenderPlayStoreUrl} target="_blank">
                    <img src={`${napconBuildVersion}images/GooglePlay.png`}
                         className="blender-page--button" />
                </a>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        currentPrivacyPolicyVersion: state.setting.currentAgreementVersion,
        authenticated: state.auth.authenticated,
        userPool: state.userPool.currentPool,
        allPools: state.userPool.allPools,
        currentCompany: state.userPool.currentCompany,
        company: state.userPool.currentCompany,
        agreementAcceptedVersion: state.user.profile.agreementAcceptedVersion,
        agreementConfirmed: state.user.profile.agreementConfirmed,
        profile: state.user.profile,
    };
}

export default withRouter(connect(mapStateToProps, {
    makeContactRequest, makeCorfirmOrderMail, logoutUser, agreementUpdate, getAgreementVersion,
})(InfoPage));
