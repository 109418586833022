import React, { Component } from 'react';
import { Translate } from 'react-i18nify';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { DialogTitle, DialogContent, DialogActions, Paper } from '@material-ui/core';



/* The LegalDialog component requires a prop named "content" and its value
can be either "privacyPolicy", "termsOfService", "serviceDescription", or
"subscriptionTermsAndConditions" */

class LegalDialog extends Component {
	state = {
		open: this.props.open || false,
		title: "",
		linkName: "",
		content: this.props.content,
		pdfUrl: {},
	};

	getDoc(content) {
		if (content == "privacyPolicy") {
			return (<div>
				<h2>PRIVACY POLICY - NAPCON SERVICES</h2>

				<h3>1. INTRODUCTION</h3>
				<p>This Privacy Policy applies whenever you play NAPCON™ GAMES or otherwise access any of NAPCON Services. Any terms not defined in this Privacy Policy shall be given the meaning allocated to them in the <a href={termsOfServiceUrl} target="_blank">Terms of Service</a>.</p>
				<p>Please read this Privacy Policy before using NAPCON Services. Neste Engineering Solutions respects your privacy and endeavors to safeguard your privacy when you use NAPCON Services.</p>
				<p>By using NAPCON Services, you express your agreement to this Privacy Policy. If you do not agree to the terms of this Privacy Policy, please do not use NAPCON Services.</p>
				<p>This Privacy Policy describes:</p>
				<ul>
					<li>What personal data we collect, how we collect it, and why</li>
					<li>How we use such personal data and with whom we share it</li>
					<li>How you can access and update such personal data</li>
					<li>The choices you can make about how we collect, use, and share your personal data</li>
					<li>How we protect your personal data</li>
				</ul>
				<p>If you do not want us to collect, store, use or share your personal data in the ways described in this Privacy Policy, you may not play NAPCON GAMES or use NAPCON Services.</p>

				<h3>2. DATA WE COLLECT AND HOW WE COLLECT IT</h3>
				<h4>2.1. General</h4>
				<p>We will only collect personal data to the extent necessary for providing our NAPCON Services. Personal data may be collected, combined and used for the purposes of creating user accounts, user profiles, using, maintaining and developing NAPCON Services, behavioral marketing, contacting users, providing services and products, sending customer information and marketing communications (as outlined below) to users, enabling use of NAPCON Services and communication in NAPCON Services and between users, conducting research and creating reports for internal use.</p>

				<h4>2.2. User Data</h4>
				<p>When you create your Account and use NAPCON Services, you may give us data directly and we will store such User Data on our systems and use it for the purposes described in this Privacy Policy. Your Account-specific information may include:</p>
				<ul>
					<li>your first and last names;</li>
					<li>your Organization;</li>
					<li>your e-mail address;</li>
					<li>a password;</li>
					<li>an Account number that is created in NAPCON Services and used to identify your profile;</li>
					<li>your Game Data; and</li>
					<li>other information that helps us identify you when you access your Account or helps us improve NAPCON Services.</li>
				</ul>
				<p>We are not responsible for any personal data you or the Organization choose to make public.</p>

				<h4>2.3. Game Data</h4>
				<p>We may collect and process following data when you use NAPCON Services and NAPCON GAMES:</p>
				<ul>
					<li>time of play by sessions and in total;</li>
					<li>scores and levels;</li>
					<li>correct and wrong answers;</li>
					<li>interactions within the game and with other users;</li>
					<li>game state and the date and time of activity on our websites or games; and</li>
					<li>other session specific information.</li>
				</ul>

				<h4>2.4. Cookies and Automated Information Collection</h4>
				<p>We and service providers acting on our behalf, such as Google Analytics, may store log files and use tracking technologies such as:</p>
				<ul>
					<li>cookies, which are small pieces of data transferred to your mobile device or computer for record-keeping purposes; and</li>
					<li>local shared objects, also known as flash cookies, which help us to reduce fraud, remember your in-game preferences and speed up loading times.</li>
				</ul>
				<p>We and our service providers may use these log files, tags, and tracking technologies to collect and analyze certain kinds of technical data, including:</p>
				<ul>
					<li>IP addresses;</li>
					<li>the type of computer or mobile device you are using;</li>
					<li>your operating system version;</li>
					<li>your mobile device's identifiers, like your MAC Address, Identifier For Advertising (IDFA), and/or International Mobile Equipment Identity (IMEI);</li>
					<li>your browser types;</li>
					<li>your browser language;</li>
					<li>referring and exit pages, and URLs;</li>
					<li>platform type; and</li>
					<li>the amount of time spent on particular pages.</li>
				</ul>
				<p>We will connect this data from your Account.</p>

				<h4>2.5.	Other Information from Your Mobile Device</h4>
				<p>If you use NAPCON Services on your mobile device, in addition to your device identifiers, we may also collect:</p>
				<ul>
					<li>the name you have associated with your device;</li>
					<li>your telephone number;</li>
					<li>your country;</li>
					<li>your specific location data; and</li>
					<li>information about other apps you have on your device.</li>
				</ul>
				<p>We will always ask you to give your explicit consent to process your location data. </p>

				<h4>2.6.	Customer Support Correspondence</h4>
				<p>When you ask for help from our customer support team, we will collect and store the contact information you give them (generally, your name and e-mail address), information about your game play or activity on NAPCON Services, and your Account details. We may also store the communications you have with the customer support team and any information in those communications in order to provide support and improve NAPCON Services.</p>

				<h3>3. HOW WE USE THE DATA WE COLLECT</h3>
				<p>The main use of the information we collect and store is to provide a better gaming experience, but there are other uses as well. The purposes for which we may collect and store your personal data include:</p>
				<ul>
					<li>to monitor and analyze game progress and performance, results and scores;</li>
					<li>to notify users and Organizations of their results and targets for development;</li>
					<li>to provide technical support and respond to user inquiries;</li>
					<li>to monitor and analyze the use of NAPCON Services;</li>
					<li>to protect the safety and well-being of our users;</li>
					<li>to protect Neste Engineering Solutions’ rights and property in connection with the Services;</li>
					<li>to prevent fraud or potentially illegal activities, and to enforce the Terms of Service;</li>
					<li>to manage and deliver contextual and behavioral advertising;</li>
					<li>to notify users of in-game updates, new products or promotional offers;</li>
					<li>to administer rewards, surveys, sweepstakes, contests, or other promotional activities or events sponsored or managed by Us or our business partners;</li>
					<li>to comply with our legal obligations, resolve any disputes We may have with you or other users, and to enforce our agreements with third parties;</li>
					<li>to conduct research; and</li>
					<li>to provide Organizations with game progress and performance, improvement areas, results and scores.</li>
				</ul>
				<p>One important use of your information is communication. If you have provided your email address to us, We will use it to respond to customer support inquiries, and keep you informed of your in-game activity.</p>

				<h3>4. INFORMATION SHARED WITH YOUR ORGANIZATION</h3>
				<p>If your use of NAPCON Services is based on a License by an Organization, your Organization has access to your personal data and may use it subject to the applicable laws and agreements from time to time within the scope of their License.</p>
				<p>When processing your personal data collected and processed in NAPCON Services to which the Organization has access, the Organization acts as a Data Controller and you should direct any inquiries in respect of the use of your personal data by your Organization to the respective contacts of the Organization.</p>
				<p>We shall not be held accountable for any misuse of your personal data or the failure to protect it.</p>

				<h3>5. HOW WE SHARE YOUR PERSONAL DATA TO THIRD PARTIES</h3>
				<h4>5.1. General</h4>
				<p>We may disclose aggregated information (information about you and other users collectively that is not intended to specifically identify you) and other non-personal data about our users for industry analysis, demographic profiling, marketing, analytics, advertising, and other business purposes.</p>
				<p>With your consent, we may share your personal data with third parties or allow them to collect your information from the Services. We may disclose your personal data to our affiliates for the purposes of providing NAPCON Services.</p>
				<p>Notwithstanding the foregoing, personal data may be disclosed to third parties if required under any applicable law or regulation or order by competent authorities, or in order to enforce the Terms of Service and to investigate possible infringing use of the Services.</p>
				<p>Your personal data, and the contents of all of your online communications in the Services and between you and us may be accessed and monitored by us or disclosed to a third party:</p>
				<ul>
					<li>if we have a good faith belief that we have to disclose the information in response to lawful requests by public authorities, including to meet national security or law enforcement requirements, or legal process (for example, a court order, search warrant or subpoena);</li>
					<li>to satisfy any laws or regulations that apply;</li>
					<li>where we believe that NAPCON Services are being used in committing a crime, including to report such criminal activity or to share information with other companies and organizations for the purposes of fraud protection, credit risk reduction, and other security precautions;</li>
					<li>when we have a good faith belief that there is an emergency that poses a threat to the health and/or safety of you, another person or the public generally; and</li>
					<li>to protect the rights or property of Neste Engineering Solutions and applicable third parties, including to enforce the Terms of Service.</li>
				</ul>

				<h4>5.2. Third-Party Service Providers</h4>
				<p>We may include advertising within NAPCON Services. We do not actively share personal information with third-party advertisers for their direct marketing purposes unless you give us your consent.</p>
				<p>When advertisers or ad networks place ads in our Services, they may collect or we may share the following types of data from within our Services:</p>
				<ul>
					<li>performance data (like the number of clicks on an advertisement);</li>
					<li>aggregated or de-identified information about you and other users collectively that is not intended to specifically identify you (like users between the ages of 21 and 25 who live in Helsinki);</li>
					<li>certain technical information (for example, IP addresses, non-persistent device identifiers such as IDFAs, and de-identified persistent device identifiers such as a hashed Android ID); and</li>
					<li>your social network ID.</li>
				</ul>
				<p>The data collected may be used to:</p>
				<ul>
					<li>measure how effective ads are, to offer you targeted advertising in order to personalize your experience by showing you advertisements for products and services that are more likely to appeal to you (a practice known as behavioral advertising); and/or</li>
					<li>undertake web analytics (like Google Analytics which is used to analyze traffic and other user activity to improve your experience).</li>
				</ul>
				<p>Advertisers or ad networks may collect this information through the use of tracking technologies like browser cookies and web beacons, and they may use a single tracking technology or multiple tracking technologies at the same time.</p>
				<p>Please note, after clicking on a third party advertisement, you may no longer be on a site hosted by Neste Engineering Solutions. Neste Engineering Solutions is not responsible for any content or settings on third party sites. </p>
				<p>If you do not want to receive tailored in-application advertisements from third parties, please see Section 6 ”How to Access, Update and Manage Your Information”.</p>

				<h3>6. HOW TO ACCESS, UPDATE AND MANAGE YOUR INFORMATION</h3>
				<h4>6.1	Accessing and Updating Your Personal data</h4>
				<p>If you want to review, delete, or change the personal data or have additional questions, email us at <a href="mailto:napcongames@neste.com">napcongames@neste.com</a>. We will respond to your request within thirty days. If you want to review, delete, or change the personal data processed by the Organization, contact the Organization directly.</p>

				<h4>6.2	Stopping Use of Your Personal data</h4>
				<p>If you no longer want Neste Engineering Solutions to make active use of your personal data, you may send an e-mail to <a href="mailto:napcongames@neste.com">napcongames@neste.com</a>. Place "Delete My Account" in the subject line and include your first name, last name and e-mail address in the body of the e-mail. We will respond to your request within thirty days. Please note that certain records, for example those relating to payments or customer service matters, will be held for legal and accounting purposes.</p>
				<p>To stop the use of your personal data by your Organization, please contact your Organization directly.</p>

				<h4>6.3. Opting Out of Third-Party Tailored Advertising</h4>
				<p>If you do not want to receive tailored in-application advertisements from third parties that relate to your interests in apps on your mobile device, you may opt out by adjusting the ad tracking settings on your device. You can also reset the "Advertising Identifier" (like an IDFA) from your mobile device's settings page, which will prevent continued use of existing behavioral data tied to the previous "Advertising Identifier." </p>
				<p>Further, depending on the platform provider (such as Apple or Google), you may be able to download apps, such as the Digital Advertising Alliance's "AppChoices" app, that offer to provide you with control regarding the collection and use of cross-app data for tailored advertising. Like the opt-out tools mentioned above for web, these mobile opt-out tools are provided by third parties, not Neste Engineering Solutions. Neste Engineering Solutions does not control or operate these tools or the choices that advertisers and others provide through these tools.</p>
				<p><b><i>Please note that you may still receive advertisements from third parties within NAPCON Services even if you opt out of tailored advertising but they will not be based on your activity across unrelated web sites or apps.</i></b></p>

				<h4>6.4. Opting Out of Cookie Tracking</h4>
				<p>You can set your web browser to warn you about attempts to place cookies on your computer or limit the type of cookies you allow. Flash cookies operate differently than browser cookies and cookie management tools available in a web browser may not remove flash cookies.</p>
				<p>If you disable cookies, you may lose some of the features and functionality of NAPCON Services because some cookies are necessary to track and enhance your game activities.</p>

				<h3>7. OUR POLICIES CONCERNING CHILDREN</h3>
				<p>If we learn that we have inadvertently gathered personal data from a child under 15, we will take reasonable measures to promptly remove that data from our records.</p>

				<h3>8. PRIVACY POLICIES OF LINKED THIRD-PARTY SERVICES AND ADVERTISERS</h3>
				<p>Our websites, NAPCON Services and NAPCON GAMES may contain advertisements from third-party services, which are companies other than Neste Engineering Solutions that may link to their own websites, online services or mobile applications. We are not responsible for the privacy practices or the content of these third-party services. If you have any questions about how these third-party services use your information, you should review their policies and contact them directly.</p>

				<h3>9. TRANSFERS OUTSIDE OF THE EU AND THE EEA</h3>
				<p>We may transfer your personal data to affiliated entities or to other third parties outside the EU and the European Economic Area. Please note that these countries and jurisdictions may not have the same data protection laws as your own jurisdiction, and we take steps to ensure adequate safeguards are in place to enable transfer of information to these third countries.</p>

				<h3>10. HOW LONG WE KEEP YOUR INFORMATION</h3>
				<p>We will not retain your personal data for longer than is necessary for the defined purpose of the processing of personal data or for legal requirements. For instance, we may retain some information for a few years after you have closed your Account if this is necessary to meet our legal obligations or to exercise, defend or establish legal rights.</p>
				<p>The information retention by your Organization shall be subject to the respective retention policies of your Organization.</p>

				<h3>11.	SECURITY OF YOUR INFORMATION</h3>
				<p>We implement technical and organizational security measures to help protect the security of your personal data both online and offline. We take steps to ensure that your personal data is treated securely and in accordance with this Privacy Policy. These measures vary based upon the sensitivity of your data.</p>
				<p>Your Account information is protected by the password you use to access your Account. It is important that you protect and maintain your Account's security and that you immediately tell us of any unauthorized use or suspected unauthorized use of your Account. If you forget the password to your Account, NAPCON Services allow you to request that instructions be sent to you that explain how to reset your password. </p>
				<p>While we take precautions against possible security breaches of NAPCON Services and our customer databases and records, no website or Internet transmission is completely secure. We cannot guarantee that unauthorized access, hacking, data loss, or other breaches will never occur, and we cannot guarantee the security of your information while it is being transmitted to NAPCON Services. Any transmission is at your own risk. If you have questions about the security of our websites, please contact us at <a href="mailto:napcongames@neste.com">napcongames@neste.com</a>.</p>

				<h3>12.	CHANGES TO THE PRIVACY POLICY</h3>
				<p>If we decide to make material changes to this Privacy Policy, we will tell you and other users by placing a notice on <a href="https://napcongames.com" target="_blank" >www.napcongames.com</a> or its equivalent in-game, or by sending you a notice to the e-mail address we have on file for you prior to the change becoming effective. We may supplement this process by placing notices on game blogs, social network pages, and/or forums and on other Neste Engineering Solutions websites. You should periodically check <a href="https://napcongames.com" target="_blank" >www.napcongames.com</a> and this Privacy Policy for updates.</p>

				<h3>13.	CONTACT US</h3>
				<p>If you have any questions, comments or concerns regarding the Privacy Policy or practices, please send an e-mail to <a href="mailto:napcongames@neste.com">napcongames@neste.com</a>.</p>

			</div>);
		}
		else if (content == "termsOfService") {
			return (<div>
				<h2>TERMS OF SERVICE - NAPCON SERVICES</h2>
				<p>Please read these Terms of Service of NAPCON™ Services (“Terms of Service”) and our Privacy Policy for NAPCON Services (“Privacy Policy”) carefully before using NAPCON Services.</p>
				<p>Whenever you use NAPCON Services, you agree to be bound by all of the terms and conditions of these Terms of Service. If you don’t agree to all the terms and conditions you must not use NAPCON Services.</p>
				<p>References to “Neste Engineering Solutions,” “Us” or “We” means Neste Engineering Solutions Oy located at Kilpilahti, Porvoo, Finland and any company belonging to the same group of companies with Us.</p>
				<p>In all cases, “Neste Engineering Solutions,” “Us,” or “We” includes our agents, consultants, employees, officers and directors as well as any third party content providers, distributors, licensees or licensors.</p>

				<h3>1. DEFINITIONS</h3>
				<p>“Account” means an account you create when you access NAPCON Services.</p>
				<p>“Game Data” means the data accrued within NAPCON Services of your playing of each NAPCON Game, including, among others, your time of play, scores, level and session specific information. Game Data may include personal data.</p>
				<p>“License” means the license to use the NAPCON Services, obtained through a download of the respective application from an appstore or assigned to you by an Organization.</p>
				<p>“NAPCON Services” or “NAPCON GAME(S)” refers to the products, educational games and related services of Neste Engineering Solutions.</p>
				<p>“Organization” means your employer or any other organization such as a school or other training entity that has acquired from Us a right to assign to you a License from time to time as an employee of the Organization or a participant in the training organized by the Organization.</p>
				<p>“Terms of Service” or “Terms” means these Terms of Service.</p>
				<p>“User Data” means all the data that you upload or we collect about you in the NAPCON Services, including Game Data. User Data may include personal data.</p>

				<h3>2. CHANGES TO THESE TERMS</h3>
				<p>We reserve the right, at our discretion, to change, modify, add or remove parts of these Terms and Privacy Policy at any time by posting the amended Terms or Privacy Policy on Our sites or within NAPCON Services (such as via in-game notices). We may provide additional notice, such as an e-mail message or messaging within NAPCON Services, of any material changes.</p>
				<p>Unless We state otherwise, changes are effective when posted. If you continue to use NAPCON Services after the changes are posted you agree to the changes. New versions of these Terms and the Privacy Policy and any other policies, codes or rules will be accessible at <a href="https://napcongames.com" target="_blank" >www.napcongames.com</a> or within NAPCON Services.</p>
				<p>You cannot make changes to these Terms or Privacy Policy unless both you and Neste Engineering Solutions sign a written amendment.</p>
				<p>If these Terms or the Privacy Policy have provisions that conflict with other Neste Engineering Solutions terms or policies, the provisions in these Terms and the Privacy Policy shall prevail.</p>

				<h3>3. ORGANIZATION</h3>
				<p>An Organization having acquired a right to assign you the License shall ensure that you and any other users authorized by the Organization to use NAPCON Services comply with these Terms. Each Organization shall enter into a separate License Agreement with Us and you understand that the validity of your License may depend on the continuing validity of such a License Agreement between Us and the respective Organization.</p>
				<p>The Organization may have rights to the User Data pursuant to the License Agreement including a right to retain the User Data and use it for their own purposes.</p>
				<p>You should direct any claims regarding your License assigned to you by an Organization or the termination thereof, or regarding the use of your User Data by the Organization to the respective Organization.</p>

				<h3>4. ACCOUNT INFORMATION AND SECURITY</h3>
				<p>To access NAPCON Services or play NAPCON GAMES or create an account with Us, you need to create an Account and select a password and/or provide Us with certain limited personal information, which may include your name and e-mail address. This information will be held and used in accordance with the Privacy Policy.</p>
				<p>You agree to supply Neste Engineering Solutions with accurate, complete, and updated information, particularly your email address.</p>
				<p>The following rules govern the creation and use of your Account:</p>
				<ol>
					<li>You must not have more than one Account at any given time, and must not create an Account using a false identity or information, or on behalf of someone other than yourself;</li>
					<li>You must not sublicense, rent, lease, sell, trade, gift or otherwise transfer your Account to anyone without Neste Engineering Solutions’ written permission; any such transfer or attempted transfer is prohibited and void;</li>
					<li>You will not, under any circumstances try to get login information or access an account belonging to other users; and</li>
					<li>You will not, under any circumstances use NAPCON Services for any unauthorized commercial purpose.</li>
				</ol>

				<h3>5. SECURITY</h3>
				<p>You are responsible for maintaining the security of your Account. Don’t share your Account details with others or allow others to access or use your Account. You are solely responsible for any activity in your Account whether or not authorized by you.</p>
				<p>Tell Us or your Organization immediately of any actual or suspected loss, theft, fraud, or unauthorized use of your Account or Account password.</p>
				<p>You may stop using NAPCON Services at any time and you or your Organization may request that We stop making active use of your data at any time by following the instructions in the Privacy Policy.</p>
				<p>Unless the local laws applicable where you are located require otherwise, We are not required to provide any refunds, benefits or other compensation if you or your Organization request deletion of your Account.</p>

				<h3>6. PRIVACY</h3>
				<p>The Privacy Policy tells you how We collect and use information about you and your computer or mobile device and how We may make this information available to your respective Organization. You understand that through your use of NAPCON Services you acknowledge the collection, use and sharing of this information as described in the Privacy Policy. If you don’t agree with the Privacy Policy, then you must stop using NAPCON Services.</p>
				<p>We encourage you to read the Privacy Policy carefully and use it to make informed decisions.</p>

				<h3>7. USING OUR NAPCON SERVICES</h3>
				<p>You may <b>not</b> use our NAPCON Services if:</p>
				<ul>
					<li>You cannot enter into a binding contract with Neste Engineering Solutions;</li>
					<li><b>You are under 15 years of age</b>, in which case you must not create an Account or use any part of NAPCON Services;</li>
					<li>You have previously been banned from playing any NAPCON GAME or using any NAPCON Services.</li>
				</ul>
				<p>If you are under the age of 18, you represent that your legal guardian has reviewed and agreed to these Terms.</p>

				<h3>8. UNAUTHORIZED USE OF OR CONNECTION TO THE SERVICE</h3>
				<p>You agree that you will not, under any circumstances:</p>
				<ol>
					<li>Use NAPCON Services, intentionally or unintentionally, in connection with any violation of any applicable law or regulation, or do anything that promotes the violation of any applicable law or regulation; or</li>
					<li>Engage in any act that Neste Engineering Solutions deems in its sole discretion to be in conflict with the spirit or intent of NAPCON Services, including but not limited to circumventing or manipulating these Terms, game rules, game mechanics or other policies.</li>
					<li>Use or design cheats, exploits, automation software, bots, hacks, mods or any unauthorized third party or other software designed to modify or interfere with NAPCON Services or any NAPCON GAMES experience;</li>
					<li>Execute, assist, encourage, or become involved in any type of attack, including without limitation distribution of a virus, denial of service attacks upon the service, or other attempts to disrupt NAPCON Services or any other person’s use or enjoyment of NAPCON Services;</li>
					<li>Try to gain unauthorized access to NAPCON Services, accounts registered to others or to any computer or server used to offer or support NAPCON Services or any NAPCON GAME environment (each a "Server") or networks connected to NAPCON Services by any means other than the user interface provided by Neste Engineering</li>
					<li>Solutions, including but not limited to, by circumventing or modifying or causing to be modified, attempting to circumvent or modify, or encouraging or assisting any other person to circumvent or modify, any security, technology, device, files, or software that is part of NAPCON Services;</li>
					<li>Use, facilitate, create, or maintain any unauthorized connection to NAPCON Services, including without limitation (1) any connection to any unauthorized server that emulates, or attempts to emulate any part of NAPCON Services; or (2) any connection using programs, tools, or software not expressly approved by Neste Engineering Solutions;</li>
					<li>Use any unauthorized third party or other software that accesses, intercepts, "mines", or otherwise collects information from or through NAPCON Services or that is in transit from or to NAPCON Services, including, without limitation, any software that reads areas of RAM or streams of network traffic used by NAPCON Services to store information about NAPCON GAME characters, elements, or environment. Neste Engineering Solutions may, at its sole and absolute discretion, allow the use of certain third party user interfaces;</li>
					<li>Intercept, examine or otherwise observe any proprietary communications protocol used by a client, a server, or NAPCON Services, whether through the use of a network analyzer, packet sniffer or any other tools;</li>
					<li>Make any automated use of NAPCON Services, or take any action that imposes or may impose (in our sole discretion) an unreasonable or disproportionately large load on our infrastructure, or</li>
					<li>Bypass any robot exclusion headers or other measures We employ to restrict access to NAPCON Services or use any software, technology, or device to send content or messages, scrape, spider, or crawl NAPCON Services, or harvest or manipulate data;</li>
					<li>Interfere or try to interfere with the proper functioning of NAPCON Services, or connect to or use NAPCON Services in any way not expressly permitted by these Terms, including disrupting, overburdening, or assisting in or encouraging the disruption or overburdening of (1) any Server; or (2) the enjoyment of the NAPCON Services or any NAPCON GAME by any other person;</li>
					<li>Except where permitted by law or relevant open source licenses, modify, reverse engineer, decompile, disassemble, decipher or otherwise try to derive the source code from us, or to obtain any information from NAPCON Services using any method not expressly permitted by Neste Engineering Solutions; or</li>
					<li>Copy, modify or distribute content from any Neste Engineering Solutions site or game, or Neste Engineering Solutions’ copyrights or trademarks or use any method to copy or distribute the content of NAPCON Services except as specifically authorized by Neste </li>
				</ol>

				<h3>9. ADDITIONAL PLATFORM TERMS</h3>
				<p>If you download NAPCON Services from another platform, such as Apple or Google (each a “Platform”) you must also comply with its terms of service/use as well as these Terms.</p>
				<p>You agree that you will abide by all of the commitments you make to any Platform providers. In the event of any conflict between these Terms and the applicable Platform’s terms, the applicable Platform’s terms shall prevail.</p>

				<h3>10. ACCESSING OUR SERVICE</h3>
				<p>We provide NAPCON Services. You are responsible for having the required equipment (computer, phone, tablet, etc.) to use NAPCON Services and pay any fees to connect to the Internet and app stores, or for data or cellular usage to download and use NAPCON Services.</p>

				<h3>11.	SERVICE CHANGES AND LIMITATIONS</h3>
				<p>NAPCON Services are evolving and We may require that you accept updates to NAPCON Services as well as to these Terms and the Privacy Policy. From time to time we may make you update the game or your software to continue to use NAPCON Services.</p>
				<p>Neste Engineering Solutions reserves the right to stop offering or supporting NAPCON Services or a particular NAPCON GAME or part of NAPCON Services at any time either permanently or temporarily, at which point the License and your use of NAPCON Services will be automatically terminated or suspended to the extent the offering or supporting of NAPCON Services is terminated. If that happens, Neste Engineering Solutions is not required to provide benefits or other compensation to users in connection with discontinued elements of NAPCON Services.</p>
				<p>Neste Engineering Solutions may, in its sole discretion limit, suspend, terminate, modify or delete accounts or access to the NAPCON Services or any portion of them and prohibit access to the NAPCON Services. Neste Engineering Solutions may also delay or remove content. Neste Engineering Solutions is under no obligation to compensate you or the Organization for any losses resulting from such actions.</p>

				<h3>12. OWNERSHIP; LIMITED LICENSE</h3>
				<h4>12.1. NAPCON Services</h4>
				<p>NAPCON Services are comprised of works owned by Neste Engineering Solutions, and they are protected by copyright, trademark, patent and other intellectual property rights and applicable laws, rules or regulations. Neste Engineering Solutions owns, has licensed, or otherwise has right to use all of the content that appears in NAPCON Services. These Terms do not grant you or any other party any right, title or interest in NAPCON Services or any content in NAPCON Services.</p>
				<p>As long as you abide by these Terms, you may access and use NAPCON Services using a supported web browser or mobile device.</p>
				<p>If you violate these Terms, or any of our other terms that apply to you, We may take action against you, up to and including permanently suspending your Account. In addition, you may be breaking the law, including violating Neste Engineering Solutions’ intellectual property rights. </p>

				<h4>12.2. Your Account</h4>
				<p>Regardless of what anything else states in these Terms, your Account is not your property. Your Account and any related items are owned by Neste Engineering Solutions. Neste Engineering Solutions gives you a limited right to use your Account and the related items while We offer the NAPCON Services.</p>
				<p>If you want us to delete your Account, email us at <a href="mailto:napcongames@neste.com">napcongames@neste.com</a>  or, if an Organization assigned you a right to use NAPCON Services, contact your Organization.</p>

				<h4>12.3. Game Data</h4>
				<p>We own your Game Data and may use it freely subject to the Privacy Policy.</p>

				<h3>13. WARRANTY DISCLAIMER; NAPCON SERVICES AVAILABLE ON AN “AS IS” BASIS</h3>
				<p>Neste Engineering Solutions makes no promise or guarantee that NAPCON Services will be uninterrupted or error-free. NAPCON Services are provided on an “as is” basis. To the extent permitted by applicable law, Neste Engineering Solutions makes no warranties of any kind, either express or implied, about NAPCON Services.</p>
				<p>NAPCON GAMES are based on our proprietary NAPCON Suite applications, but are only a simulation made for training purposes. NAPCON GAMES are not in any event intended to be used in any actual business application nor can the results derived from any NAPCON GAME be implemented into any real life situation. Neste Engineering Solutions shall not warrant that the functionalities of NAPCON GAMES correspond to the functionalities of any NAPCON Suite application.</p>

				<h3>14. LIMITATIONS; WAIVERS OF LIABILITY</h3>
				<p>NESTE ENGINEERING SOLUTIONS SHALL NOT BE LIABLE</p>
				<ol>
					<li>FOR ANY INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, INCLUDING FOR LOSS OF PROFITS, GOODWILL OR DATA, IN ANY WAY WHATSOEVER ARISING OUT OF THE USE OF, OR INABILITY TO USE, THE SERVICE; OR</li>
					<li>FOR THE CONDUCT OF THIRD PARTIES, INCLUDING OTHER USERS OF THE NAPCON SERVICES AND OPERATORS OF EXTERNAL SITES.</li>
				</ol>
				<p>THE RISK OF USING NAPCON SERVICES AND EXTERNAL SITES RESTS ENTIRELY WITH YOU AS DOES THE RISK OF INJURY FROM NAPCON SERVICES AND EXTERNAL SITES.</p>
				<p>TO THE FULLEST EXTENT ALLOWED BY ANY LAW THAT APPLIES, THE DISCLAIMERS OF LIABILITY IN THESE TERMS APPLY TO ALL DAMAGES OR INJURY CAUSED BY NAPCON SERVICES, OR RELATED TO USE OF, OR INABILITY TO USE, NAPCON SERVICES, UNDER ANY CAUSE OF ACTION IN ANY JURISDICTION, INCLUDING, WITHOUT LIMITATION, ACTIONS FOR BREACH OF WARRANTY, BREACH OF CONTRACT OR TORT (INCLUDING NEGLIGENCE).</p>
				<p>TO THE MAXIMUM EXTENT PERMISSIBLE UNDER APPLICABLE LAWS, THE TOTAL LIABILITY OF NESTE ENGINEERING SOLUTIONS IS LIMITED TO THE TOTAL AMOUNT YOU HAVE PAID NESTE ENGINEERING SOLUTIONS IN THE THIRTY DAYS (30) DAYS IMMEDIATELY PRECEDING THE DATE ON WHICH YOU FIRST ASSERT ANY SUCH CLAIM.</p>
				<p>IF YOU HAVE NOT PAID NESTE ENGINEERING SOLUTIONS ANY AMOUNT IN THE THIRTY (30) DAYS IMMEDIATELY PRECEDING THE DATE ON WHICH YOU FIRST ASSERT ANY SUCH CLAIM, YOUR SOLE AND EXCLUSIVE REMEDY FOR ANY DISPUTE WITH NESTE ENGINEERING SOLUTIONS IS TO STOP USING NAPCON SERVICES AND TO CANCEL YOUR ACCOUNT.</p>
				<p>Some states or countries do not allow the exclusion of certain warranties or the limitations/exclusions of liability described above. So these limitations/exclusions may not apply to you if you reside in one of those states or countries.</p>

				<h3>15. INDEMNITY</h3>
				<p>If you use or misuse NAPCON Services, or if you violate these Terms or any other applicable rules, and that results in loss or damage or in a claim or liability against Neste Engineering Solutions, you agree to indemnify, defend and hold harmless Neste Engineering Solutions for that loss, damage, claim or liability, including compensating Neste Engineering Solutions for its legal fees or expenses. </p>
				<p>If Neste Engineering Solutions wants to, it is allowed to take exclusive charge of the defense of any case on which you are required to compensate or reimburse them, and it will be at your expense. You also have to cooperate in Neste Engineering Solutions’ defense of these cases. Neste Engineering Solutions will use reasonable efforts to let you know if it learns of any claim on which you have to compensate or reimburse them. This will apply even if you stop using NAPCON Services or your Account is deleted.</p>

				<h3>16. APPLICABLE LAW AND SETTLEMENT OF DISPUTES</h3>
				<p>These Terms and any policies referred to in these Terms shall be governed by the laws of Finland without reference to its choice of law rules. </p>
				<p>Any dispute, controversy or claim arising out of or relating to these Terms or the breach, termination or validity thereof shall be finally settled at Neste Engineering Solutions’ discretion</p>
				<ol>
					<li>at your domicile's competent courts;</li>
					<li>in the applicable consumer dispute resolution process; or</li>
					<li>by arbitration in accordance with the Arbitration Rules of the Finnish Central Chamber of Commerce. The arbitration shall be conducted in Helsinki, Finland, in the English language.</li>
				</ol>

				<h3>17. SEVERABILITY</h3>
				<p>If any part of these Terms or the Privacy Policy is not enforceable, the rest of these Terms and the Privacy Policy still applies and is binding and any unenforceable term will be substituted reflecting our intent as closely as possible.</p>

				<h3>18.	ASSIGNMENT</h3>
				<p>We may give our rights, or Our obligations, under these Terms or the Privacy Policy to any person or entity at any time with or without your consent. You may not give your rights or your obligations under these Terms or our Privacy Policy without first getting Neste Engineering Solutions’ written consent, and any attempt to do so without our consent is void.</p>

				<h3>19.	ENTIRE AGREEMENT</h3>
				<p>These Terms, and any other policies or rules We reference in these Terms, make up the entire agreement between you and Us relating to the subject matter of these Terms, and supersede all prior understandings of the parties relating to the subject matter of these Terms, whether those prior understandings were electronic, oral or written, or whether established by custom, practice, policy or precedent, between you and Us.</p>

				<h3>20.	LANGUAGE OF THE TERMS</h3>
				<p>If We provide a translated version of these Terms, the Privacy Policy, or any other terms or policy, it is for informational purposes only. If the translated version means something different than the English version, then the English meaning shall prevail.</p>

				<h3>21.	NO WAIVER</h3>
				<p>If We do not enforce a provision of these Terms, or the Privacy Policy, that does not waive our right to do so later. And, if We do expressly waive a provision of these Terms, or the Privacy Policy that does not mean it is waived for all time in the future. Any waiver must be in writing and signed by both you and Us to be legally binding.</p>

				<h3>22.	NOTICES</h3>
				<p>We may notify you by posting something on <a href="https://napcongames.com" target="_blank" >www.napcongames.com</a>, or in the NAPCON GAME(S) you play, and sending you an e-mail or using other ways of communicating with you based on the contact information you provide to Us.</p>

				<h3>23.	FORCE MAJEURE</h3>
				<p>We are not liable for any changes or problems out of our control, for example changes or problems caused by like natural disasters, war, terrorism, riots, embargoes, acts of civil or military authorities, fire, floods, accidents, network infrastructure failures, strikes, or shortages of transportation facilities, fuel, energy, labor or materials.</p>
			</div>);
		}
		else if (content == "serviceDescription") {
			return (<div>
				<h2>NAPCON GAMES SERVICE DESCRIPTION</h2>

				<h3>1. General</h3>
				<p>This document describes Neste Engineering Solutions NAPCON Games (<a href="https://napcongames.com" target="_blank" >https://napcongames.com</a>) service
					and the procedures and practices followed in their implementation.</p>
				<p>Games are a modern extension to traditional class room training. They simulate chosen industrial
					environments and fully support the same disciplines occurring in real processes. The solution provides an
					interactive and flexible way to professionally train operators. Instant feedback, easy access and adjustable
					level of realism are some of many benefits.</p>

				<h3>2. Features of the Games</h3>

				<h4>2.1. The Technical Platform </h4>
				<p>NAPCON Games (<a href="https://napcongames.com" target="_blank" >https://napcongames.com</a>) is a web based game platform for process industry training
					games.</p>

				<h4>2.2 The Administrator Functions</h4>

				<h5>Account Management</h5>
				<p>Administrators are divided into two groups:</p>
				<ol>
					<li>Head Administrator</li>
					<p>Can invite/create administrator accounts as well as regular user accounts</p>
					<li>Administrator</li>
					<p>Can invite/create regular user accounts </p>
				</ol>
				<p>When the head administrator or administrator has created/invited the new accounts, an automatic
					invitation email will be sent to the registered email account. This email is titled as “NAPCON Games [Game
						name] invitation” and will be sent by <a href="mailto:napcongames@neste.com">napcongames@neste.com</a>. If this email cannot be found, check the
						junk mail folder and possible filters.</p>
					<p>The registration procedure is carried out by the new user by filling in their personal information according to the form and reading through and accepting the terms and conditions and the privacy policy.</p>
					<p>Both administrator groups can manage their license pool according to their needs, accounts can be added and/or removed from the license pool. The available license pool can also be divided into smaller groups according to the specifications determined by the administrators, this can be used to group for example different shifts, production lines etc.</p>

					<h5>Administrator Statistics</h5>
					<p>In the statistics page, the administrators can find different views and statistics regarding their license pool.
						The administrator can toggle between different predetermined groups to see only their statistics or have a view of the whole license pool.
					</p>
					<ul>
						<li>Based on the view (group level or all players) the administrator can see an overview of the following:
							<ul>
								<li>Total playtime</li>
								<li>Average playtime</li>
								<li>Active users</li>
								<li>Total points</li>
								<li>Last month's new high score</li>
							</ul>
						</li>
						<li>Other information includes
							<ul>
								<li>User completion by mission</li>
								<li>Play count per mission</li>
								<li>Progression of number of active users</li>
							</ul>
						</li>
						<li>Administrators can also track how their players are progressing through the missions, leaderboards, which missions they are having difficulties in, what are their strong areas and this can all be monitored at a single mission level.</li>
					</ul>

					<h4>2.3. The User Functions</h4>

					<h5>Playing the Games</h5>
					<p>After successfully registering, the user can login using their email address used in the account creation. After logging in, the game(s) can be accessed through the NAPCON Games portal, by selecting a game from the top navigation bar or from the homepage. This will redirect the player to the game window. In the game window, the player can see different missions available for play and their description. By clicking the mission the player will be taken into briefing and the game can be started. In the first missions, the player is taken through an onboarding phase where the main game mechanics are introduced to the player.</p>
					<h5>User Statistics</h5>
					<p>The user can enter into their personal statistics view by clicking the statistics tab at the top of the page.
						In this statistics view the player can see:</p>
					<ul>
						<li>Their achievements</li>
						<li>Comparison to other players in their group</li>
						<li>Leaderboard</li>
						<li>Score history</li>
					</ul>

					<h3>3. Registration, Order and Delivery Procedure</h3>

					<h4>3.1. Order</h4>
					<p>{/*For online order, the customer fills out the form found at <a href="https://napcongames.com/order" >https://napcongames.com/order</a>.
						In this section, the customer reads and agrees to the organization license, privacy policy, terms and conditions and this service description.
					After successfully filling the order form, an order confirmation email will be sent to registered email address.*/}
					{/* Below is the placeholder text used until new pricing model is implemented*/}
						Orders are handled through our sales representatives. You can reach our sales with the contact form available on our NAPCON Games home page or send an email directly to <a href="mailto:napcongames@neste.com">napcongames@neste.com</a>.
					</p>
					<h4>3.2. Delivery</h4>
					<p>After successfully carried out order and after receiving order confirmation, the licenses will be activated and delivered to the customer soon. In any cases where this does not happen within two days, please contact our team at <a href="mailto:napcongames@neste.com">napcongames@neste.com</a>.</p>
					<h4>3.3. Invoicing</h4>
					<p>Invoice will be sent via email to the customer, according to the information provided in the order form. The customer has 30 days payment time to carry out the payment in full.</p>

					<h3>4. Fault Management</h3>

					<h4>4.1. Support and Fault Management</h4>
					<p>NAPCON Games portal and its content will be available to users at all times as default, however, during updates and other such occurrences there might be short brakes in the access to the game and the games portal. Any major downtimes will be informed.</p>
					<p>If you encounter any issues or need assistance at any matter, you can contact our team at <a href="mailto:napcongames@neste.com">napcongames@neste.com</a> who will assist you as soon as possible.</p>
					<p>*Neste Engineering Solutions will not be providing any on-site assistance.</p>
					<h4>4.2. Planned works</h4>
					<p>The system will undergo updates which may result in short downtimes in the use of the game and the games portal.</p>
					<p><b>All of the newest versions will be automatically updated and available for customers that have active licenses.</b></p>

					<h3>5. Privacy and data management</h3>
					<p>All data will be handled in accordance with NAPCON Games Privacy Policy.</p>

					<p><i>*Neste Engineering Solutions Oy accepts no responsibility whatsoever for errors, omissions and/or inaccuracies in the information contained herein and/or advice given in relation to or as a consequence of any such information. In the event of any errors, omissions or inaccuracies in the information provided, Neste Engineering Solutions Oy reserves at all times the right of final interpretation and decision.</i></p>
				</div>);
		}
		else if (content == "subscriptionTermsAndConditions") {
			return (<div>
				<h2>SUBSCRIPTION TERMS AND CONDITIONS</h2>

				<h3>1. APPLICATION</h3>
				<p>These Subscription Terms and Conditions (“Subscription Terms”) shall apply to the subscription of Licenses to use the NAPCON GAMES (“Subscription”) by Organizations from Neste Engineering Solutions. The Organization may allocate the subscribed Licenses to individual users (“User”) either employed or otherwise connected with the Organization, for the purpose of training of such Users (“Training”).</p>
				<p>The use of NAPCON GAMES shall always be subject to the Terms of Service for NAPCON GAMES and the Privacy Policy (together “Terms”) as valid from time to time and the definitions made in the Terms shall apply in addition to the definitions made in this Agreement. The reference to these Subscription Terms shall be deemed to include the Terms as valid from time to time.</p>
				<p>The Organization may apply such additional terms and conditions, or charge such fees for the Training, as it sees appropriate from time to time. However, the Organization shall ensure that the Users are made aware of the Terms and comply with the Terms at all times.</p>

				<h3>2. LICENSE</h3>
				<p>The Organization may subscribe in writing to any number of Licenses. The Subscription may be subject to credit approval and sanctions checks. Neste Engineering Solutions reserves the right to reject any Subscription at its sole discretion by written notice to the Organization.</p>
				<p>Once the Subscription has been processed and approved by Neste Engineering Solutions, Neste Engineering Solutions grants the Organization a non-transferable and non-exclusive right to assign Licenses to the number of Users specified in the Subscription. The continuity of the Subscription shall be subject to the timely payment of the applicable annual License Fee as available on Neste Engineering Solutions{"'"} website and valid at the time when the License was subscribed in accordance with these Subscription Terms (“License Fee”).</p>
				<p>The Organization shall manage the Users and their Accounts in accordance with the User Management Guidelines issued by Neste Engineering Solutions from time to time and available within the NAPCON GAMES.</p>
				<p>Neste Engineering Solutions may, from time to time, make additional services related to NAPCON GAMES available to the Organization, subject to a separate written agreement.</p>

				<h3>3. PAYMENT OF LICENSE FEE</h3>
				<p>Neste Engineering Solutions shall invoice from the Organization the License Fees plus applicable taxes (such as value added tax) for the subscribed Licenses, whether allocated to Users or not. </p>
				<p>The Organization shall pay the License Fees within thirty (30) days after the date of Neste Engineering Solutions{"'"} invoice.</p>
				<p>Neste Engineering Solutions shall invoice from the Organization a delay interest of twelve percent (12%) per annum on any late payment, calculated from the due date to the actual date of payment.</p>

				<h3>4. USER DATA</h3>
				<p>Neste Engineering Solutions and the Organization shall process personal data in accordance with applicable laws and regulations and in compliance with good processing practices and the Terms. Neste Engineering Solutions and the Organization shall take the necessary technical and organizational measures in order to protect personal data from unauthorized access and accidental or unlawful destruction, loss, alteration, transmission or other unlawful processing.</p>
				<p>Neste Engineering Solutions is the data controller under applicable laws to the extent it determines the purposes and means of the processing of User Data. This includes, amongst others, the processing of User Data for the purposes of provision and development of the NAPCON GAMES as well as communications and marketing directed to Users and Organizations. </p>
				<p>The Organization is the data controller under applicable laws to the extent it processes User Data for the purposes of providing Training, managing the rights and obligations of the employment relationship of the Users or otherwise processing for the purposes determined by the Organizations. The Organization may, pursuant to the Terms or the applicable laws or any agreement between the Organization and Users have a right to collect, retain and use User Data through the validity of the Subscription and thereafter. Such processing of User Data by the Organization shall be subject to the respective privacy policies of the Organization.</p>
				<p>Any such access and retention by the Organization shall be at the sole and exclusive responsibility of the Organization and Neste Engineering Solutions shall bear no responsibility for any claims made by the Users or previous Users against the Organization or Neste Engineering Solutions based on a claim that such a collection, retention or use is in any way violating the rights of such a User.</p>

				<h3>5. COMPLIANCE WITH LAWS</h3>
				<p>The Organization shall comply with all applicable laws and regulations, and shall not do anything that promotes the violation and/or expose Neste Engineering Solutions in violation of any applicable laws or regulations, including but not limited to any applicable US, EU or UK economic or trade sanctions or export controls (“Sanctions”).</p>
				<p>Neste Engineering Solutions reserves at all times the right, at its sole discretion,</p>
				<ol>
					<li>to reject any Subscription;</li>
					<li>terminate the Subscription; or </li>
					<li>limit Organization`s or any User`s access to NAPCON GAMES</li>
				</ol>
				<p>with immediate effect, where the Subscription would otherwise place or threatens to place Neste Engineering Solutions or its affiliates in a position of non-compliance with or in violation of Sanctions, without any obligation of Neste Engineering Solutions to provide any refunds, benefits or other compensation to the Organization or any User.</p>

				<h3>6. LIMITATION OF LIABILITY</h3>
				<p>The maximum liability for damages of Neste Engineering Solutions towards the Organization based on the Subscription and these Subscription Terms shall not exceed in aggregate twenty percent (20%) of the total annual License Fee under the Subscription excluding applicable taxes.</p>
				<p>Neste Engineering Solutions shall not be liable for any indirect or consequential damage such as loss of profits or revenue or damage caused due to any decrease or interruption of service. Also, the limitations of liability contained in the Terms shall apply.</p>

				<h3>7. TERM AND TERMINATION</h3>
				<p>The Subscription shall be valid for one (1) calendar year from the date when the right to allocate Licenses was granted to the Organization by Neste Engineering Solutions. The Subscription shall not be automatically renewed but shall continue uninterrupted if renewed by the Organization in writing before the expiry of the Subscription.</p>
				<p>A material breach by the Organization of these Subscription Terms or any User of the Terms may entitle Neste Engineering Solutions to terminate the Subscription of the Organization immediately without any obligation of Neste Engineering Solutions to provide any refunds or other compensation to the Organization. A delay of payment by more than ten (10) days after a written notice regarding the delayed payment by Neste Engineering Solutions shall be considered a material breach for the purposes of this Section.</p>
				<p>The end or termination of the Subscription for any reason shall not result in the deletion of the User Data or the Accounts but shall stop the access of the Users to the NAPCON GAMES.</p>
			</div>);
		}
		else if (content == "OWCRules") {
			return (<div>
				<h2>NAPCON OPERATOR WORLD CUP FURNACE – OFFICIAL COMPETITION RULES</h2>

			<h3>Gameplay</h3>
			<ul>
				<li>Players compete against each other through an online portal playing a competition mission</li>
				<li>The Operator World Cup Furnace Qualification is divided into monthly rounds</li>
				<li>The best scores recorded to the competition mission are added to a global leaderboard where players are ranked based on their results each round</li>
				<li>A new round begins each month, and the leaderboard of the previous round is locked </li>
				<li>Players can play the missions as many times as they want</li>
				<li>Only the player’s best score is shown on the leaderboard</li>
				<li>One person cannot win more than one (1) prize during the qualification</li>
			</ul>

			<h3>Monthly top 3</h3>
			<ul>
				<li>Each month the best three (3) players will win a one (1) month / five (5) person license to both NAPCON Games Furnace and Distiller games </li>
				<li>A new round will begin after each month</li>
			</ul>

			<h3>Total leaderboard</h3>
			<ul>
				<li>In addition to monthly best players, there is also a chance to win a one (1) month / five (5) person license to both NAPCON Games Furnace and Distiller games by reaching top ranks on the total leaderboard</li>
				<li>The score on the total leaderboard is the sum of the player’s best scores from each individual round</li>
			</ul>

			<h3>Regional Finals</h3>
			<ul>
				<li>The best players selected by NAPCON will compete against each other in live regional finals</li>
				<li>To qualify for the regional finals, the player must have been placed in the top ranks in one of the monthly rounds or on the total leaderboard</li>
				<li>Regional finals locations and times will be informed to the players when the information is available</li>
				<li>More information on the finals will be available at later stage and will be communicated to the finalists</li>
			</ul>

			<p>NAPCON by Neste Engineering Solutions reserves the right to change these rules. In that case, information on the changes will be published as soon as possible.</p>
			<p><i>Neste Engineering Solutions Oy accepts no responsibility whatsoever for errors, omissions and/or inaccuracies in the information contained herein and/or advice given in relation to or as a consequence of any such information. In the event of any errors, omissions or inaccuracies in the information provided, Neste Engineering Solutions Oy reserves at all times the right of final interpretation and decision.</i></p>


			</div>)
		}
		else {
			return (<div>Document not found.</div>);
		}
	};

	componentDidMount() {
		if (this.props.content == "privacyPolicy") {
			this.setState({pdfUrl: privacyPolicyUrl, title: <Translate value="privacyPolicy" tag="h3" />, linkName: <Translate value="privacyPolicy" />});
		}
		else if (this.props.content == "termsOfService") {
			this.setState({pdfUrl: termsOfServiceUrl, title: <Translate value="termsOfService" tag="h3" />, linkName: <Translate value="termsOfService" />});
		}
		else if (this.props.content == "serviceDescription") {
			this.setState({pdfUrl: serviceDescriptionUrl, title: <Translate value="serviceDescription" tag="h3" />, linkName: <Translate value="serviceDescription" />});
		}
		else if (this.props.content == "subscriptionTermsAndConditions") {
			this.setState({pdfUrl: subscriptionTermsAndConditionsUrl, title: <Translate value="subscriptionTermsAndConditions" tag="h3" />, linkName: <Translate value="subscriptionTermsAndConditions" />});
		}
		else if (this.props.content == "OWCRules") {
			this.setState({pdfUrl: OWCRulesUrl, title: <Translate value="owcRules" tag="h3" />, linkName: <Translate value="owcRulesShort" />});
		}
	}

	handleOpen = () => {
		this.setState({open: true});
	};

	handleClose = () => {
		this.setState({open: false});
	};

	handleClick = () => {
		window.open(this.state.pdfUrl);
	};

	render() {

		const actions = [

			<Button
				color="secondary"
				style={{margin: "0 10px"}}
				key={"Download as PDF"}
				onClick={this.handleClick}
				>Download as PDF</Button>,
			<Button
				className="legal-closeButton"
				style={{margin: "0 10px"}}
				color="primary"
				key={"Close"}
				onClick={this.handleClose}>Close</Button>,
		];

		return (<React.Fragment>
			<a className="legal-link" onClick={this.handleOpen}>{this.state.linkName}</a>
				<Dialog
					className="dialog--legal-dialog"
					maxWidth = {"md"}
					open={this.state.open}
					onClose={this.handleClose}
					PaperComponent={Paper}
					PaperProps={{className: "legal-body"}}
					>
					<DialogTitle disableTypography={true} className="legal-title">{this.state.title}</DialogTitle>
					<DialogContent className="legal-body legal-dialog">{this.getDoc(this.state.content)}</DialogContent>
					<DialogActions>
						{actions}
					</DialogActions>
				</Dialog>
			</React.Fragment>
		);
	}
}

export default LegalDialog;
