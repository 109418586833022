import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { createPool, updatePool } from './actions/pool';
import { searchCompanies } from './actions/company';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Translate } from 'react-i18nify';
import { withRouter } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Divider from '@material-ui/core/Divider';

import TournamentPanel from './TournamentPanel';
import find from 'lodash/find';
import gamesData from 'games.json';

/**
 * Pools page main component.
 */
class PoolsPage extends Component {
    constructor(props, context) {
        super(props, context);

        if (this.props.userPool && this.props.searchSelect && this.props.searchSelect.type === "Pool") {
            const pool = this.props.userPool.pool;
            const prevStart = pool.previousStartDate;
            const end = moment(pool.end);
            const start = moment(pool.createdAt);
            const days = Math.max(0,end.diff(moment(), 'days'));
            const prevEnd = moment(pool.end).format('DD.MM.YYYY');

            this.state = {
                name: pool.name,
                seats: pool.seats,
                playerLimit: pool.activePlayerLimit,
                games: pool.games && pool.games.length > 0 ? pool.games[0] : '',
                duration: days,
                admin: this.getAdminEmail(),
                selectedPool: pool,
                company: pool.company || {_id: ''},
                end: end,
                previousEnd: prevEnd,
                previousStart: prevStart,
                currentStartDate: pool.currentStartDate ? moment(pool.currentStartDate) : start
            }
        }
        else {
            this.state = {
                name: '',
                seats: 0,
                playerLimit: 0,
                games: '',
                duration: 0,
                admin: '',
                selectedPool: null,
                company: {_id: ''},
                end: null,
                previousEnd: [],
                previousStart: [],
                addDays: 0,
                currentStartDate: null,
            }
        }
    }

    componentDidMount() {
        this.props.searchCompanies("");
    }

    getAdminEmail() {
        for (let user of this.props.admins) {
            if (user.poolAdmin) {
                return user.email;
            }
        }
        return "";
    }

    updatePoolProps(poolId, {name, seats, games, end, admin, company, previousStartDate, currentStartDate}) {
        this.setState({
            name: name,
            seats: seats,
            games: games ? games : '',
            duration: days,
            admin: admin,
            selectedPool: poolId,
            company: company || {_id: ''},
            end: end,
            previousStart: previousStartDate,
            currentStartDate: currentStartDate
        });
    }

    onBottomButtonClick() {
        const poolParams = {
            name: this.state.name,
            seats: this.state.seats,
            playerLimit: this.state.playerLimit,
            games: this.state.games,
            end: this.getEndDate(),
            admin: this.state.admin,
            company: this.state.company._id,
            previousStartDate: this.addDatePair(),
            currentStartDate: this.getCurrentStart(),
        };

        if (!this.state.selectedPool) {
            this.props.createPool(poolParams);
        }
        else {
            this.props.updatePool(this.state.selectedPool._id, poolParams);
        }
        this.props.history.push('/admin');
    }
    getCurrentStart() {
        if (!this.state.selectedPool) { //for new pool creation
            return moment().format();
        }
        else {
            var keepSameStart = this.state.currentStartDate; //get current start day from state
            //const isValid = moment().isBefore(moment(this.props.userPool.pool.end));
            if(this.state.addDays === 0) { // no duration change, return the same date as before
                return keepSameStart;
            } else { // otherwise, give current date
                return moment().format();
            }
        }
    }
    getEndDate() {
        if (!this.state.selectedPool && this.state.addDays !== 0) { // for new pool creation
            return moment().endOf('day').add(this.state.addDays, 'days');
        }
        const isValid = moment().isBefore(moment(this.props.userPool.pool.end)); //check if the selected pool is still valid
        if(isValid && this.state.addDays !== 0){ // when pool is valid and days are added add the days to current sub
            return moment(this.state.end).add(this.state.addDays, 'days');
        }

        else if(this.state.addDays !== 0){ // expired pool with new subscription
            return moment().endOf('day').add(this.state.addDays, 'days');
        }
        else {  //no duration change, keep the same end date
            return moment(this.props.userPool.pool.end);
        }
    }

    getNewEndDate() { // for "add license days" field update
        if(moment(this.state.end).diff(moment()) > 0){
            return moment(this.state.end).add(this.state.addDays, 'days');
        } else {
        return moment().endOf('day').add(this.state.addDays, 'days');
        }
    }

    addDatePair() {
        var holderArr = this.state.previousStart; // this gets the history of previous subscriptions in format [{started: '', ends: ''}];
        if(!this.state.selectedPool){ //for new pool creation we just pass an empty array
            return holderArr;
        }
        //console.log(this.state.addDays === 0);
        else if(this.state.addDays !== 0){ //check if dates have been added to subscription, if yes, return the new history
        var start = this.props.userPool.pool.currentStartDate ? moment(this.state.selectedPool.currentStartDate) : moment(this.props.userPool.pool.createdAt).endOf('day'); // previous subscription start date
        var end = moment(this.state.end).endOf('day'); // old sub end date
        var datePair = [{started: moment(start).format(), ends: moment(end).format()}]; // create a new history entry of current sub that is to be replaced
        var joined = holderArr.concat(datePair); // add new entry to old history array
            return joined; // return new array
        } else {
            return holderArr; // no days were added nor does history exists, then we create the history from pool creation date and current end date
        }
    }

    onGameChange(event) {
        this.setState({
            games: event.target.value
        });
    }

    onCompanyChange(event) {
        let companyObj = find(this.props.companies, (company) => {
            return company._id === event.target.value;
        });
        if (!companyObj) {
            //Use empty _id value to show empty item with "Select company" text
            companyObj = {_id: ''};
        }

        this.setState({
            company: companyObj
        });
    }

    render() {
        const texts = {
            name: <Translate value="name"/>,
            seats: <Translate value="seats"/>,
            playerLimit: <Translate value="playerLimit"/>,
            admin: <Translate value="adminEmail"/>,
            end: <Translate value="end"/>,
            duration: <Translate value="licenseDuration" duration={this.getNewEndDate().format("DD.MM.YYYY")}/>,
            endDate: <Translate value="endDate"/>,
            created: <Translate value="created"/>,
            createNew: <Translate value="createNewLicensePool"/>,
            update: <Translate value="update"/>,
            selectCompany: <Translate value="selectCompany"/>,
            selectLicense: <Translate value="selectLicense"/>
        };

        const bottomButtonText = this.state.selectedPool ? texts.update : texts.createNew;

        const gameItems = [<MenuItem key={""} value={""}>{texts.selectLicense}</MenuItem>];
        Object.keys(gamesData.games).forEach(function(key) {
            gameItems.push(<MenuItem key={key} value={key}>{key}</MenuItem>);
        });

        const companyItems = [<MenuItem key={""} value={""}>{texts.selectCompany}</MenuItem>];
        if (this.props.companies) {
            this.props.companies.forEach(function(item) {
                companyItems.push(<MenuItem key={item._id} value={item._id}>{item.name}</MenuItem>);
            });
        }
        const previousEnd = this.state.selectedPool ?
        <TextField disabled label="Last subscription" defaultValue={moment(this.state.currentStartDate).format("DD.MM.YYYY") + " " + "-" + " " + moment(this.state.end).format("DD.MM.YYYY") + " " + "(" + this.state.duration + " left)"} variant="outlined"/>
            :
            null;
        return (
            <div className="pool-settings account-container">
                <h2 className="header2">{bottomButtonText}</h2>
                <div className="pool-settings--container">
                    <PoolDropdown titleKey={"license"} dropValue={this.state.games}
                                onDropChange={this.onGameChange.bind(this)}
                                items={gameItems}/>
                    <PoolDropdown titleKey={"companyId"} dropValue={this.state.company._id}
                                onDropChange={this.onCompanyChange.bind(this)}
                                items={companyItems}/>

                    <TextField label={texts.name} value={this.state.name}
                            onChange={e => this.setState({name: e.target.value})}/>
                    <TextField label={texts.seats} type="number" value={this.state.seats}
                            onChange={e => this.setState({seats: e.target.value})}/>
                    <TextField label={texts.playerLimit} type="number" value={this.state.playerLimit}
                        onChange={e => this.setState({ playerLimit: e.target.value})}/>
                    {previousEnd}
                    <TextField label={texts.duration} type="number" placeholder="60"
                            onChange={e => this.setState({addDays: e.target.value})}/>
                    <TextField label={texts.admin} value={this.state.admin}
                            onChange={e => this.setState({admin: e.target.value})}/>
                    <Button variant="contained" onClick={this.onBottomButtonClick.bind(this)}>{bottomButtonText}</Button>
                </div>
                <Divider className="account--divider"/>
                <TournamentPanel pool={this.state.selectedPool}/>
            </div>
        );
    }
}

class PoolDropdown extends Component {
    render() {
        return (<React.Fragment>
                <InputLabel><Translate value={this.props.titleKey}/></InputLabel>
                <Select value={this.props.dropValue} onChange={this.props.onDropChange.bind(this)}
                              autoWidth={true}>
                    {this.props.items}
                </Select>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        userPool: state.userPool.currentPool,
        searchSelect: state.user.searchSelect,
        admins: state.userPool.admins,
        companies: state.company.companySearchResults
    };
}
const PoolsPageWithRouter = withRouter(PoolsPage);
export default connect(mapStateToProps, {createPool, updatePool, searchCompanies})(PoolsPageWithRouter);
