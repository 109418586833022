import React, { Component } from 'react';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-i18nify';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { registerUserUpdate } from './actions/auth';
import { validatePassword, UNITS, LANGUAGES } from './Helpers';
import Localization from './Localization';

const texts = {
    mainTitle: <Translate value="accountInfo"/>,
    firstName: <Translate value="firstNameRegForm"/>,
    lastName: <Translate value="lastNameRegForm"/>,
    nickName: <Translate value="nickName"/>,
    jobTitle: <Translate value="jobTitleRegForm"/>,
    password: <Translate value="password"/>,
    newPassword: <Translate value="newPassword"/>,
    email: <Translate value="email"/>,
    unit: <Translate value="unit"/>,
    language: <Translate value="language"/>,
    save: <Translate value="save"/>,
    passwordsDontMatch: <Translate value="passwordsDontMatch"/>,
    errorPasswordComplexity: <Translate value="passwordComplexity"/>,
    confirmTitle: I18n.t('confirm'),
    confirmCancel: <Translate value="cancel"/>,
    confirmRemove: <Translate value="remove"/>,
    confirmByTypingEmail: I18n.t('confirmByTypingEmail'),
    licensePool: <Translate value="license"/>,
    noLicensePool: I18n.t('noLicense')
};

/**
 * Account information component in account page
 */
class AccountInformation extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            firstName: "",
            lastName: "",
            nick: "",
            title: "",
            password: "",
            newPassword: "",
            newPassword2: "",
            unit: UNITS[0].value,
            language: LANGUAGES[0].value,
        }
    }

	componentDidMount() {
        if (this.props.profile) {
            this._setStateFromProps(this.props.profile);
        }
    }

    onSaveClick() {
        Localization.setLocale(this.state.language);
        this.props.registerUserUpdate(this.props.profile._id, {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.props.profile.email,
            password: this.state.password,
            title: this.state.title,
            newPassword: this.state.newPassword,
            unit: this.state.unit,
            nick: this.state.nick,
            language: this.state.language
        });
    }

    getValue(value) {
        return value !== undefined && typeof value === "string" ? value : "";
    }

    /**
     * Called only once, not when page opened again
     * @param nextProps
     */
  
    componentDidUpdate(prevProps) {
        if (this.props.profile !== prevProps.profile) {
            this._setStateFromProps(this.props.profile);
        }
    }

    _setStateFromProps(profile) {
        this.setState({
            firstName: profile.firstName || "",
            lastName: profile.lastName || "",
            nick: profile.nick || "",
            title: profile.title || "",
            unit: profile.unit || UNITS[0].value,
            language: profile.language || LANGUAGES[0].value
        });
    }
    
    /**
     * Returns error object or null if passwords are good
     * @returns {object}
     */
    verifyPasswords() {
        if (this.state.newPassword2 && this.state.newPassword) {
            if (this.state.newPassword2 !== this.state.newPassword) {
                return texts.passwordsDontMatch;
            }
            if (!validatePassword(this.state.newPassword)) {
                return texts.errorPasswordComplexity;
            }
        }
        return "";
    }

    /**
     * Returns true if all text fields are valid and passwords match.
     * @returns {boolean}
     */
    verifyInputs() {
        if (!this.state.firstName) { // null check
            return false;
        }
        // Old password has data, so new pwd data is needed
        if (this.state.password && this.state.password.length > 0) {
            if (!this.state.newPassword || !this.state.newPassword2 || (this.verifyPasswords() !== "")) {
                return false;
            }
        }
        // New password is set but not giving old one
        if (!this.state.password && (this.state.newPassword || this.state.newPassword2)) {
            return false;
        }

        return this.state.firstName.length > 0 && this.state.lastName.length > 0 && this.state.title.length > 0;
    }

    onUnitChange = (event) => this.setState({unit: event.target.value});

    onLanguageChange = (event) => {
        this.setState({language: event.target.value});
    }
    

    render() {
        const passwordErrorMessage = this.verifyPasswords();
        const buttonStyle = 'register--button' + (this.verifyInputs() === false ? ' register--button-disabled' : '');

        return (
            <div className="account-container">
                <form>
                    <TextField className="account-info--textfield" label={texts.firstName}
                        value={this.getValue(this.state.firstName)}
                        required
                        autoComplete="given-name"
                        onChange={e => this.setState({firstName: e.target.value})}/>
                    <TextField className="account-info--textfield" label={texts.lastName}
                        value={this.getValue(this.state.lastName)}
                        required
                        autoComplete="family-name"
                        onChange={e => this.setState({lastName: e.target.value})}/>
                    <TextField className="account-info--textfield" label={texts.nickName}
                        value={this.getValue(this.state.nick)}
                        autoComplete="nick-name" maxLength={20}
                        onChange={e => this.setState({nick: e.target.value})}/>
                    <TextField className="account-info--textfield" label={texts.jobTitle}
                        value={this.getValue(this.state.title)}
                        required
                        autoComplete="honorific-prefix"
                        onChange={e => this.setState({title: e.target.value})}/>
                    <TextField className="account-info--textfield" label={texts.password} type="password"
                        value={this.state.password} onChange={e => this.setState({password: e.target.value})}
                        autoComplete="current-password"/>
                    <TextField className="account-info--textfield" label={texts.newPassword} type="password"
                        value={this.state.newPassword}
                        autoComplete="new-password"
                        onChange={e => this.setState({newPassword: e.target.value})}/>
                    <TextField className="account-info--textfield" label={texts.newPassword} type="password"
                        value={this.state.newPassword2} error={passwordErrorMessage !== ""}
                        helperText={passwordErrorMessage}
                        autoComplete="new-password"
                        onChange={e => this.setState({newPassword2: e.target.value})}/>
                    <TextField className="account-info--textfield" label={texts.email}
                        value={this.getValue(this.props.profile.email)} disabled={true}
                        autoComplete="email"/>
                    <InputLabel>{texts.unit}</InputLabel>
                    <Select className="account-info--textfield" onChange={this.onUnitChange} value={this.state.unit}>
                        {UNITS.map(unit => <MenuItem key={unit.value} value={unit.value}>{I18n.t(unit.locKey)}</MenuItem>)}
                    </Select>
                    <InputLabel>{texts.language}</InputLabel>
                    <Select className="account-info--textfield" onChange={this.onLanguageChange} value={this.state.language}>
                        {LANGUAGES.map(language => <MenuItem key={language.value} value={language.value}>{I18n.t(language.locKey)}</MenuItem>)}
                    </Select>
                </form>
                <Button className={buttonStyle} onClick={this.onSaveClick.bind(this)}
                    disabled={!this.verifyInputs()}>
                    {texts.save}
                </Button>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

export default connect(mapStateToProps, { registerUserUpdate })(AccountInformation);
