
import axios from 'axios';
import { API_URL, errorHandler } from './index';
import { ERROR_RESPONSE, GET_ACTIVE_USERS } from './types';
import {getGetRequestParams} from '../Helpers';

export function getActiveUsersArgs(args) {
    return getActiveUsers(args.poolId, args.groupId, args.beginDate, args.endDate, args.missionId, args.game);
}

export function getActiveUsers(poolId, groupId, beginDateMoment, endDateMoment, missionId, game) {
    return function (dispatch) {
        axios.get(`${API_URL}/monthlystats/${poolId}`, getGetRequestParams(game, groupId, beginDateMoment, endDateMoment, missionId))
            .then((response) => {
                dispatch({
                    type: GET_ACTIVE_USERS,
                    payload: response.data
                });
            })
            .catch((error) => {
                errorHandler(dispatch, error, ERROR_RESPONSE);
            });
    };
}