import React, { Component } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Translate } from 'react-i18nify';

import { getAchievements } from './actions/achievement';
import { achievements } from './helpersgames';
import keyBy from 'lodash/keyBy';
import {OWN_USER_COLOR} from './Helpers';

/**
 * Achievements panel in statistics page
 */
class AchievementPanel extends Component {
    constructor(props, context) {
        super(props, context);
    }

    componentDidMount() {
        this.checkGetAchievements(this.props);
    }

    componentWillUnmount() {
        this._fetchedAchievements = "";
    }

   componentDidUpdate(prevProps) {
        this.checkGetAchievements(this.props);
    }

    checkGetAchievements(checkedProps) {
        if (checkedProps.profile 
            && checkedProps.profile._id 
            && checkedProps.currentGame 
            && this._fetchedAchievements !== checkedProps.currentGame) {
            this._fetchedAchievements = checkedProps.currentGame;
            checkedProps.getAchievements(checkedProps.profile._id, checkedProps.currentGame);
        }
    }

    /**
     * Returns Translate components for title and description
     * @param achievementName Achievement type name
     * @param achievementData Achievement data object from server. Can be null.
     * @return {{title: XML, desc: XML}}
     */
    getText(achievementName, achievementData) {
        const nextLevelLimit = achievementData ? achievementData.nextLimit : null;
        const currentScore = achievementData ? achievementData.score : null;
        const moreNeeded = Math.max(0, nextLevelLimit - currentScore);
        const localizationKeyBase = achievementName.charAt(0).toLowerCase() + achievementName.slice(1);
        const title = <Translate value={localizationKeyBase + 'Title'}/>;
        let desc = "";
        if (achievementData && achievementData.level === 3) {
            desc = <Translate value={localizationKeyBase + 'CompletedDesc'} more={moreNeeded} progress={currentScore}/>;
        }
        else {
            desc = <Translate value={localizationKeyBase + 'Desc'} more={moreNeeded} progress={currentScore}/>;
        }
        return {title: title, desc: desc};
    }

    /**
     * Returns array achievement components filled with data
     * @return {Array} Achievement components
     */
    getAchievementComponents() {
        //For easy access arrange achievements to object using achievement type as a key
        const achievementsByKeys = keyBy(this.props.achievements, (o) => {
            return o.type
        });

        const achievementComponents = [];
        for (let achievement in achievements[this.props.currentGame]) {
            const achievementData = achievementsByKeys[achievement];
            const texts = this.getText(achievement, achievementData);
            achievementComponents.push(
                <Achievement key={texts.title.props.value} level={achievementData ? achievementData.level : 0}
                             value={achievementData ? achievementData.score : 0}
                             type={achievementData ? achievementData.type : ''}
                             score={achievementData ? achievementData.score : 0}
                             scoreNext={achievementData ? achievementData.nextLimit : 0}
                             title={texts.title}
                             description={texts.desc}/>
            )
        }
        return achievementComponents;
    }

    render() {
        return (
            <div className="statistics-container">
                <div className="achievement--container">
                    {this.getAchievementComponents()}
                </div>
            </div>
        );
    }
}

class Achievement extends Component {
    constructor(props, context) {
        super(props, context);
    }

    /**
     * Returns achievement icon url.
     * @param level {number} Achievement level
     * @returns {string} Icon url
     */
    getImageUrl(level) {
        if (level === 0) {
            return napconBuildVersion + 'images/no_icon.png';
        }

        return napconBuildVersion + 'images/' + this.props.type.toLowerCase() + level + '.png';
    }
    /**
     * Returns achievement progress in scale of 0 to 100.
     * @param score current score
     * @param min achievement starting value (minimum value)
     * @param max achievement next level bound (maximum value)
     */
    getProgressStatus(score, min, max) {
        var calculateProgress = (max - min) > 0;
        return calculateProgress ? (score - min) / (max - min) * 100 : min;
    }

    render() {
        const title = this.props.score + " / " + this.props.scoreNext;
        return (
            <div className="achievement">
                <img src={this.getImageUrl(this.props.level)} className="achievement--icon"/>
                <LinearProgress variant="determinate" value={this.getProgressStatus(this.props.score, 0, this.props.scoreNext)}
                    className={"achievement--progressbar"} title={title} />
                <div className="achievement--title">{this.props.title}</div>
                <div className="achievement--description">{this.props.description}</div>
            </div>
        );
    }
}

Achievement.propTypes = {
    level: PropTypes.number.isRequired,
    title: PropTypes.node.isRequired,
    description: PropTypes.node.isRequired,
    type: PropTypes.string.isRequired
};

Achievement.defaultProps = {
    level: 0,
    value: '-',
    title: '',
    description: ''
};

function mapStateToProps(state) {
    return {
        profile: state.user.profile,
        achievements: state.achievements.data,
        currentGame: state.userPool.currentGame
    };
}

export default connect(mapStateToProps, {getAchievements})(AchievementPanel);

