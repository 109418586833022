import React from 'react';
import {Link} from "react-router-dom";


const NextButtonOnly = ({nextName,nextLink}) => {
    return(
        <div className="nav-userguide-button">
            <div></div> 
            <div className="button-userguide-next">
                <Link to={nextLink}>
                    <div className="article-button-name">Next</div>
                    <div className="button-title">{nextName}</div>
                </Link>
            </div>
        </div>
    );
}

const PrevButtonOnly = ({prevName,prevLink}) => {
    return(
        <div className="nav-userguide-button">
            <div className="button-userguide-prev">
                <Link to={prevLink}>
                    <div className="article-button-name">Previous</div>
                    <div className="button-title">{prevName}</div>
                </Link>
            </div>
            <div></div>
        </div>
    );
}

const PrevNextButton = ({prevName,prevLink, nextName, nextLink}) => {
    return(
        <div className="nav-userguide-button">
            <div className="button-userguide-prev">
                <Link to={prevLink}>
                    <div className="article-button-name">Previous</div>
                    <div className="button-title">{prevName}</div>
                </Link>
            </div>
            <div className="button-userguide-next">
                <Link to={nextLink}>
                    <div className="article-button-name">Next</div>
                    <div className="button-title">{nextName}</div>
                </Link>
            </div>
        </div>
    );
}

const NavigationButton = ({prevName,prevLink, nextName, nextLink}) => {
  return (
      <div>
        {nextName === "Playing Mission" ?  <NextButtonOnly nextName={nextName} nextLink={nextLink}/>
        : prevName === "Administrator Instructions" ? <PrevButtonOnly prevName={prevName} prevLink={prevLink}/>
        : <PrevNextButton prevName={prevName} prevLink={prevLink} nextName={nextName} nextLink={nextLink} />}
        
      </div> 
  );
};


export default NavigationButton