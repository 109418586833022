import React, { Component } from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import { connect } from 'react-redux';
import { removeUserFromPool, inviteUser, promoteUser, demoteUserFromPool } from './actions/userpool';
import { I18n, Translate } from 'react-i18nify';

import AccountAddPanel from './AccountAddPanel';
import AccountListItem from './AccountListItem';
import ConfirmDialog from './ConfirmDialog';

class AdministratorList extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            confirmDemoteHandler: null,
            inputValid: true
        };
    }

    /**
     * Handler for add admin button pressed
     * @param value
     */
    onAddItem(value) {
        this.props.inviteUser(this.props.pool._id, {email: value, admin: true});
    }

    /**
     * Handler for promote admin button pressed
     * @param value
     */
    onPromoteItem(value) {
        this.props.promoteUser(this.props.pool._id, {user: value});
    }

   
    /**
     * Handler for demote admin button (which means demoting from admin to user) press event.
     * Sets confirmDemoteHandler for confirm dialog
     * @param userId User id
     */
    onDemoteItem(userId) {
        this.setState({
            confirmDemoteHandler: this.onConfirmClosed.bind(this, userId)
        });
    }


    /**
     * Handler for confirm dialog closed event.
     * Sends request to demote admin to user for provided user id if confirm is true.
     * Closes confirm dialog.
     * @param userId {string} User id
     * @param confirm {boolean} True if confirmed, false if canceled.
     */
    onConfirmClosed(userId, confirm) {
        if (confirm === true && userId) {
            this.props.demoteUserFromPool(this.props.pool._id, { user: userId });
        }
        this.setState({
            confirmDemoteHandler: null
        });
    }


    /**
     * Parses adminData prop and returns list of AccountListItem components for admins
     * @returns {*} AccountListItem list
     */
    getListItems() {
        if (this.props.adminData.length <= 0) {
            return <AccountListItem key={0} textHigh={<Translate value="noUsersAssigned"/>}/>
        } else {
            return this.props.adminData.map((admin, index) =>
                <AccountListItem key={index} textHigh={admin.title} textLow={admin.name} icon="person"
                                 isRemovable={!admin.poolAdmin}
                                 buttonPressHandler={this.onDemoteItem.bind(this, admin._id)}/>
            );
        }
    }

    /**
     * Validates email list and checks that there's room in user pool.
     * Returns error message or empty string if input is valid.
     * @param input {string} Input
     * @returns {string} Error message. Empty if input is valid.
     */
    adminEmailValidator(input) {
        const emails = input.split(/[\s,;]+/);
        let emailsValid = true;
        let emailCount = 0;
        //Check all emails are valid
        for (let i = 0; i < emails.length; i++) {
            let splitEmail = emails[i].trim();
            if (splitEmail.length > 0) {
                emailCount++;
                if (!splitEmail.includes('@')) {
                    emailsValid = false;
                }
            }
        }

        const availableSeats = this.props.maxCount - this.props.userData.length;

        //No errors for empty input
        if (input.length > 0) {
            if (emailCount > availableSeats) {
                this.setState({inputValid: false});
                return I18n.t('errorTooManyUsers', { seats: availableSeats, userCount: emailCount });
            } else if (emailsValid === false) {
                this.setState({inputValid: false});
                return I18n.t('errorInvalidEmail');
            }
        }

        this.setState({inputValid: true});
        return '';
    }

    render() {
        const texts = {
            title: <Translate value="administrators"/>,
            addAdminTitle: <Translate value="addAdmins"/>,
            promoteUserToAdminTitle: <Translate value="promoteUserToAdmin"/>,
            textFieldLabel: <Translate value="adminEmail"/>,
            button: <Translate value="send"/>,
            buttonPromote: <Translate value="promote"/>,
            // Confirm dialog wants title as string. Using I18n.t doesn't automatically re-render component when
            // language changes but other fields in ConfirmDialog use <Translate/> so title should also update
            // when language changes.
            confirmTitle: <Translate value="confirm" tag="h3"/>,
            confirmMessage: <Translate value="confirmRemoveAdmin"/>,
            confirmCancel: <Translate value="cancel"/>,
            confirmRemove: <Translate value="demote"/>,
            emailHint: I18n.t('emailHint')
        };

        const addPanel = this.props.showAdd ?
            <div>
                <AccountAddPanel title={texts.addAdminTitle} textFieldLabel={texts.textFieldLabel}
                                 buttonLabel={texts.button} addButtonPressHandler={this.onAddItem.bind(this)}
                                 inputValidator={this.adminEmailValidator.bind(this)}
                                 buttonDisabled={!this.state.inputValid} hintText={texts.emailHint}/>
                <AccountAddPanel title={texts.promoteUserToAdminTitle} textFieldLabel={texts.textFieldLabel}
                                 buttonLabel={texts.buttonPromote} addButtonPressHandler={this.onPromoteItem.bind(this)}
                                 addUsersList={this.props.promotableUsers} buttonDisabled={!this.props.promotableUsers || this.props.promotableUsers.length === 0} />
            </div>: null;

        return (
            <div className="account-container">
                <h2 className="header2">{<Translate value="administrators"/>}</h2>
                <List className="account-list">
                    {this.getListItems()}
                </List>
                {addPanel}
                <ConfirmDialog open={this.state.confirmDemoteHandler !== null} title={texts.confirmTitle}
                               message={texts.confirmMessage}
                               cancel={texts.confirmCancel} confirm={texts.confirmRemove}
                               closeHandler={this.state.confirmDemoteHandler}
                               isDeleteAction={true}/>
            </div>
        );
    }
}

AdministratorList.propTypes = {
    adminData: PropTypes.array,
    pool: PropTypes.object,
    showAdd: PropTypes.bool,
    promotableUsers: PropTypes.array,
    userData: PropTypes.array,
    maxCount: PropTypes.number.isRequired
};

function mapStateToProps(state) {
    return {};
}

export default connect(mapStateToProps, { inviteUser, removeUserFromPool, promoteUser, demoteUserFromPool})(AdministratorList);

