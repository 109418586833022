import React from 'react';
import { numberWithSpaces } from './StatHelper';

const StatsHighscore = ({players, gameTitle}) => {
  
  return (
    gameTitle != "none" ? 
        <div className="statistics-container">
            <h2>TOP 10 PLAYERS IN {gameTitle.toUpperCase()}</h2>
            <table className="mission-stats--highscore-list">
                <tbody>
                    {players.sort((a, b) => (a.score > b.score) ? -1 : 1).map((player, index) =>
                        <tr key={index} className="mission-stats--highscore-item">
                             <td>{index+1}.</td>
                            <td className="mission-stats--highscore-name">{player.username}</td>
                            <td className="mission-stats--highscore-score">{numberWithSpaces(player.score)}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>:null
   
  );
};


export default StatsHighscore