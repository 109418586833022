
import axios from 'axios';
import Cookies from 'universal-cookie';
import { API_URL, errorHandler } from './index';
import { ERROR_RESPONSE, CREATE_TOURNAMENT, UPDATE_TOURNAMENT, GET_TOURNAMENTS, STATIC_ERROR } from './types';

const cookies = new Cookies();

export function createTournament({ poolId, name, startDate, endDate, realtimeStatsEnabled, missions, lockedSpeed }) {
    return function (dispatch) {
        axios.post(`${API_URL}/tournament/create`,
        { poolId, name, startDate, endDate, realtimeStatsEnabled, missions, lockedSpeed },
        { headers: { Authorization: cookies.get('token') } })
            .then((response) => {
                dispatch({ type: CREATE_TOURNAMENT, payload: response });
            })
            .catch((error) => {
                errorHandler(dispatch, error.response, ERROR_RESPONSE);
            });
    };
}

export function updateTournament({ tournamentId, name, startDate, endDate, realtimeStatsEnabled, missions, lockedSpeed }) {
    return function (dispatch) {
        axios.post(`${API_URL}/tournament/update`, 
        { tournamentId, name, startDate, endDate, realtimeStatsEnabled, missions, lockedSpeed },
        {headers: { Authorization: cookies.get('token') }})
            .then((response) => {
                dispatch({ type: UPDATE_TOURNAMENT, payload: response });
            })
            .catch((error) => {
                errorHandler(dispatch, error.response, ERROR_RESPONSE);
            });
    };
}

export function getTournament(poolId) {
    return function (dispatch) {
        const urlEnd = poolId ? encodeURIComponent(poolId) : 'all';

        axios.get(`${API_URL}/tournament/${urlEnd}`, {headers: { Authorization: cookies.get('token') },})
            .then((response) => {
                dispatch({
                    type: GET_TOURNAMENTS,
                    payload: response.data.tournament,
                });
            })
            .catch((error) => {
                errorHandler(dispatch, error, STATIC_ERROR);
            });
    };
}