
import axios from 'axios';
import Cookies from 'universal-cookie';
import { API_URL, errorHandler } from './index';
import { ERROR_RESPONSE, CREATE_GROUP, GET_GROUP, ADD_USER_TO_GROUP, REMOVE_USER_FROM_GROUP, DELETE_GROUP} from './types';

const cookies = new Cookies();

export function createGroup(poolId, { name }) {
    return function (dispatch) {
        axios.post(`${API_URL}/group/create/${poolId}`, { name }, {headers: { Authorization: cookies.get('token') }})
            .then((response) => {
                dispatch({
                    type: CREATE_GROUP,
                    payload: response.data
                });
            })
            .catch((error) => {
                errorHandler(dispatch, error.response, ERROR_RESPONSE);
            });
    };
}

export function deleteGroup(poolId, groupId) {
    return function (dispatch) {
        axios.delete(`${API_URL}/group/${poolId}/${groupId}`, {headers: { Authorization: cookies.get('token') }})
            .then((response) => {
                dispatch({
                    type: DELETE_GROUP,
                    payload: response.data
                });
            })
            .catch((error) => {
                errorHandler(dispatch, error.response, ERROR_RESPONSE);
            });
    };
}

export function getGroups(poolId) {
    return function (dispatch) {
        axios.get(`${API_URL}/group/${poolId}`, { headers: { Authorization: cookies.get('token') },})
            .then((response) => {
                dispatch({
                    type: GET_GROUP,
                    payload: response.data
                });
            })
            .catch((error) => {
                errorHandler(dispatch, error, ERROR_RESPONSE);
            });
    };
}

export function addUserToGroup(poolId, {group, user}) {
    return function (dispatch) {
        axios.post(`${API_URL}/group/adduser/${poolId}`, { group, user }, {headers: { Authorization: cookies.get('token') }})
            .then((response) => {
                dispatch({
                    type: ADD_USER_TO_GROUP,
                    payload: response.data
                });
            })
            .catch((error) => {
                errorHandler(dispatch, error.response, ERROR_RESPONSE);
            });
    };
}

export function removeUserFromGroup(poolId, {group, user}) {
    return function (dispatch) {
        axios.post(`${API_URL}/group/removeuser/${poolId}`, { group, user }, {headers: { Authorization: cookies.get('token') }})
            .then((response) => {
                dispatch({
                    type: REMOVE_USER_FROM_GROUP,
                    payload: response.data
                });
            })
            .catch((error) => {
                errorHandler(dispatch, error.response, ERROR_RESPONSE);
            });
    };
}
