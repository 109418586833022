import React from 'react';
import NavigationButton from './NavigationButton';

import missionImage from '../../public/images/userguide_images/playing_mission.png';
const PlayingMission = () => {
  
  return (
    <div className="content-userguide">
      <h1 className="section-header">Section 2 Playing a mission</h1>
      <div>When you have selected a game to play from the menu, you can choose the mission you want to play. You can browse between different missions by using the arrows on the left and right sides. Click Play to start playing a mission.</div>
      <img src={missionImage} style={{ width: "100%", height: '50%', margin: "30px 0" }} alt="logo" />
      <NavigationButton prevName="Getting Started" prevLink="/userguide" nextName="Your Account" nextLink="/userguide/yourAccount"/>
    </div>
  );
};


export default PlayingMission