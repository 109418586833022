
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';

class MainPageContent extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        return (
            <div className="main-page page-container" style={this.props.mainStyle}>
                <Paper className="main-page--content" style={this.props.mainStyle}>
                    {this.props.component}
                </Paper>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return { };
}
// inject routing props to children
export default connect(mapStateToProps)(MainPageContent);
