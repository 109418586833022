import React, { Component } from 'react';
import Divider from '@material-ui/core/Divider';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { removeUserFromPool } from './actions/userpool';
import { getAllUserMissionScores } from './actions/highscore';
import { deleteUser } from './actions/index';
import { I18n, Translate } from 'react-i18nify';
import ConfirmDialog from './ConfirmDialog';
import StatisticPerUser from './StatisticPerUser';

import AccountPageDropDown from './AccountPageDropDown';
import AccountInformation from './AccountInformation';
import PageHeader from './PageHeader';
import LegalDialog from './LegalDialog';

/**
 * Account page main component
 */
class AccountPage extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            deleteUserHandler: null,
            scoresFetched: false,
            gameList:  ["furnace", "distiller", "condensing", "boiling", "heat transfer"],
        }
    }

    componentDidUpdate(prevProps, prevState) { 
       this.renderUserScores();
    }

    renderUserScores(){ 
        if(this.getRenderedProfile() === undefined) { 
            return;
        }

        const userId = this.getRenderedProfile()._id;
        if(!this.state.scoresFetched && this.props.profile.hasOwnProperty('_id')){
           this.props.getAllUserMissionScores(userId, I18n._localeKey);
            this.setState({scoresFetched: true});
        }
    }

    removeAccount() {
        const userId = this.getRenderedProfile()._id;
        this.setState({
            deleteUserHandler: this.onRemoveUserConfirmClosed.bind(this, userId)
        });
    }

    onRemoveUserConfirmClosed(userId, confirm) {
        if (confirm === true) {
            if (this.props.rights.siteAdmin === true) {     // siteadmin = superadmin
                this.props.deleteUser(userId);
                this.props.history.goBack();    // go back to user list again
            }
            else {
                this.props.removeUserFromPool(this.props.userPool.pool._id, {user: userId});
            }
        }
        this.setState({
            deleteUserHandler: null
        });
    }


    renderUserInfo(profile) {
        if (!profile) {
            return null;
        }

        let menu = null;
        if(this.props.rights.siteAdmin) {
            const deleteDisabled = profile.role === "Admin" || !this.props.rights.siteAdmin && (!this.props.userPool || !this.props.userPool.pool ||
                !this.props.userPool.valid);

            const items = [{
                disabled: deleteDisabled,
                callback: this.removeAccount.bind(this),
                textKey: "deleteAccount"
            }];
            menu = <AccountPageDropDown menuItems={items}/>;
        }

        return <div>
            <PageHeader subHeaderLangKey="account" subHeader={menu}/>
            <Divider className="account--divider"/>
            <AccountInformation profile={profile} siteAdmin={this.props.rights.siteAdmin} pool={this.props.userPool ? this.props.userPool.pool : null} />
            <Divider className="account--divider"/>
            {this.props.rights.siteAdmin && this.props.bestMissionScores ?
                <div className="statistics-container">
                    <h2>Game Scores Table</h2>
                        {this.props.bestMissionScores[0].map((gameScores, i)=>(
                            <div key={i} className="align-center">
                                <h2>{this.state.gameList[i].toUpperCase()}</h2>
                                {gameScores.length > 0 ? <StatisticPerUser  games={gameScores}/>: <h3>No Scores</h3>}
                            </div>
                        ))}
                    <Divider className="account--divider"/>
                </div>: null
            }
        </div>;
    }

    renderAgreements() {

		const privacyPolicyLink = <LegalDialog content="privacyPolicy" />;
		const termsOfServiceLink = <LegalDialog content="termsOfService" />;
		const serviceDescriptionLink = <LegalDialog content="serviceDescription" />;
		const subscriptionTermsAndConditionsLink = <LegalDialog content="subscriptionTermsAndConditions" />;

        return (<span>
            <div className="account-container">
            <h2 className="header2 color-black">{<Translate value="legalInformation"/>}</h2>
            {privacyPolicyLink}
			<br />
			{serviceDescriptionLink}
			<br />
			{subscriptionTermsAndConditionsLink}
            <br />
            {termsOfServiceLink}
            </div>
            <Divider className="account--divider"/>
        </span>);
    }

    getRenderedProfile() {
        if (this.props.rights.siteAdmin) {
            if (this.props.searchSelect && this.props.searchSelect.type === 'User') {
                return this.props.searchedProfile;
            }
        }
        else {
            return this.props.profile;
        }
    }

    shouldRenderPoolPanel() {
        if (this.props.rights.poolAdmin || this.props.rights.admin) {
            return true;
        }
        else {
            return this.props.rights.siteAdmin && this.props.searchSelect && this.props.searchSelect.type === 'Pool';
        }
    }

    renderConfirmDialog(confirmVisible, profile) {
        if (!profile) {
            return null;
        }

        const texts = {
            email: <Translate value="email"/>,
            confirmTitle: <Translate value="confirm" tag="h3"/>,
            confirmCancel: <Translate value="cancel"/>,
            confirmRemove: <Translate value="remove"/>,
            confirmByTypingEmail: I18n.t('confirmByTypingEmail'),
            licensePool: <Translate value="license"/>,
            noLicensePool: I18n.t('noLicense')
        };

        const licenseText = this.props.userPool && this.props.userPool.pool ? this.props.userPool.pool.name : texts.noLicensePool;
        const confirmMessage = I18n.t(this.props.rights.siteAdmin ? 'confirmDeleteUserFromDb' : 'confirmDeleteUser',
            {pool:licenseText, email: profile.email});

        return <ConfirmDialog open={confirmVisible} title={texts.confirmTitle} confirmByTypingText={profile.email}
            confirmByTypingTitle={texts.confirmByTypingEmail}
            message={confirmMessage} cancel={texts.confirmCancel}
            confirm={texts.confirmRemove} closeHandler={this.state.deleteUserHandler}
            isDeleteAction={true}/>
    }

    render() {
        const confirmVisible = this.state.deleteUserHandler !== null;
        const confirmDialog = this.renderConfirmDialog(confirmVisible, this.getRenderedProfile());
        const profilePanel = this.renderUserInfo(this.getRenderedProfile());
        const agreementsPanel = this.renderAgreements();

        return (
            <div>
                {profilePanel}
                {agreementsPanel}
                {confirmDialog}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        rights: state.user.rights,
        profile: state.user.profile,
        userPool: state.userPool.currentPool,
        searchSelect: state.user.searchSelect,
        searchedProfile: state.user.searchedProfile,
        bestMissionScores: state.highScores.bestMissionScores,
    };
}

export default withRouter(connect(mapStateToProps, {removeUserFromPool, deleteUser, getAllUserMissionScores})(AccountPage));
