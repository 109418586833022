import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { loginUser, getForgotPasswordToken } from './actions/auth';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import TextField from '@material-ui/core/TextField';
import { Translate } from 'react-i18nify';
import ListItem from '@material-ui/core/ListItem';

const texts = {
    email: <Translate value="email" />,
    password: <Translate value="password" />,
    companyId: <Translate value="companyId" />,
    forgotPwd: <Translate value="forgotPassword" />,
    login: <Translate value="login" />,
    wrongEmailPassword: <Translate value="wrongEmailPassword" />,
    error: <Translate value="error" />,
    missingEmail: <Translate value="missingEmail" />,
};


/**
 * Login dialog component. Dialog uses Popover menu which is opened when title button is pressed.
 */
class LoginDialog extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            open: false,
            anchorElement: this.props.anchor,
            email: '',
            password: '',
            companyId: this.getCompanyFromUrl()
        }
    }

    /**
     * Returns company id from companyId url parameter if defined
     * @returns {string} Company id. Empty if not defined
     */
    getCompanyFromUrl() {
        var url = decodeURI(location.href);
        var index = url.indexOf('?');
        if (index >= 0) {
            var param = url.substring(index + 1);
            var key = 'companyId=';
            var keyEnd = param.indexOf('companyId=') + key.length;
            if (keyEnd >= key.length) {
                return param.substring(keyEnd);
            }
        }
        return '';
    }

	/**
	 * Handler for title button clicked on desktop.
	 * Opens Popover menu.
	 * @param event
	 */
	onTitleButtonClick(event) {
		event.preventDefault();

		this.setState({
			open: !this.state.open,
			anchorElement: event.currentTarget
		})
	}

    /**
     * Handler for title button clicked on mobile.
     * Opens Popover menu.
     * @param event
     */
    onMobileTitleButtonClick(event) {
        event.preventDefault();

        this.setState({
            open: !this.state.open,
            anchorElement: this.props.anchor
        })
    }

    /**
     * Handler for Popover close requested
     */
    onRequestClose() {
        this.setState({
            open: false
        });
    }

    /**
     * Handler for login button click event.
     * Send login action to back-end
     */
    onLoginClick() {
        this.props.loginUser({ email: this.state.email, password: this.state.password, company: this.state.companyId });
    }

    /**
     * Handler for forgot button click event.
     * Send login action to back-end
     */
    onForgotClick() {
        if (this._nextSendTime && moment().isBefore(this._nextSendTime)) {
            window.dispatchEvent(new CustomEvent('notification', { detail: { message: 'passwordResetTooMany' } }));
        }
        else {
            this.props.getForgotPasswordToken({ email: this.state.email });
            this._nextSendTime = moment().add(15, 'seconds');
        }
    }

    /**
     * Returns error message for error status.
     * @param errorMessage
     * @returns {string} Error message. Empty when there's no error
     */
    getErrorMessage(errorMessage) {
        if (errorMessage && errorMessage.status) {
            switch (errorMessage.status) {
                case 400:
                case 401:
                    if (errorMessage.data.message === "no_email_given") {
                        return texts.missingEmail;
                    } else {
                        return texts.wrongEmailPassword;
                    }
            }
            return texts.error + ' ' + errorMessage.status;
        }
        return '';
    }

    /**
     * Handler for password text field key press event.
     * Sends loginUser to server when enter key is pressed
     * @param event {object} Event
     */
    onKeyPress(event) {
        if (event.key === 'Enter') {
            this.props.loginUser({
                email: this.state.email,
                password: this.state.password,
                company: this.state.companyId
            });
        }
    }

    render() {
        const buttonClass = this.state.open === true ? 'titlebar--button-selected titlebar--button' :
            'titlebar--button-deselected titlebar--button';
        const errorMessage = this.getErrorMessage(this.props.errorMessage);
        const style = { textTransform: "uppercase" };
        const style2 = {
            verticalAlign: 'baseline',
        };
		const clickBtn = this.props.mobile ?
			<ListItem key={texts.login} style={style} className={"titlebar--button-deselected"}
				 onClick={this.onMobileTitleButtonClick.bind(this)}>{texts.login}</ListItem>:
			<Button className={buttonClass} onClick={this.onTitleButtonClick.bind(this)}>{texts.login}</Button>;

        return (
            <div className="titlebar-login-button-div">
                {clickBtn}

                <Popover
                    className="login-dialog-popper"
                    open={this.state.open}
                    anchorEl={this.state.anchorElement}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                      }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}

                    onClose={this.onRequestClose.bind(this)}>
                        <div className="login-dialog">
                            <form className="login-dialog--form">
                                <p className="login-dialog--text">{texts.email}</p>
                                <TextField autoFocus className="login-dialog--textfield"
                                    InputProps={{
                                        disableUnderline: true,
                                        color: 'rgba(0, 0, 0, 0.87)',
                                        className: "login-dialog--textfield-input"
                                    }}
                                    value={this.state.email}
                                    autoComplete="email"
                                    onChange={e => this.setState({ email: e.target.value })}
                                    error={errorMessage !== ""}
                                    helperText={errorMessage}
                                />
                                <p className="login-dialog--text">{texts.password}</p>
                                <TextField className="login-dialog--textfield"
                                    type="password"
                                    autoComplete="current-password"
                                    InputProps={{
                                        disableUnderline: true,
                                        color: 'rgba(0, 0, 0, 0.87)',
                                        className: "login-dialog--textfield-input"
                                    }}
                                    value={this.state.password}
                                    onChange={e => this.setState({ password: e.target.value })}
                                    onKeyPress={this.onKeyPress.bind(this)}
                                    />
                            </form>
                            <div className="login-dialog--button-container">
                                <Button className="btn login-dialog--login-button"
                                    style={style2}
                                    onClick={this.onLoginClick.bind(this)}>
                                        {texts.login}
                                </Button>
                                <Button className="btn-no-border--dark"
                                    onClick={this.onForgotClick.bind(this)}>
                                        {texts.forgotPwd}
                                </Button>
                            </div>
                        </div>
                </Popover>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        errorMessage: state.auth.error,
        message: state.auth.message,
        authenticated: state.auth.authenticated,
    };
}

export default withRouter(connect(mapStateToProps, { loginUser, getForgotPasswordToken })(LoginDialog));
