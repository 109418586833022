import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Translate } from 'react-i18nify';
import { I18n } from 'react-i18nify';

import { fetchUser } from './actions/index';
import { NavLink, withRouter } from 'react-router-dom';
import { searchUsers } from './actions/index';
import { searchPools } from './actions/pool';
import { searchCompanies } from './actions/company';
import { getPoolAll } from './actions/userpool';

import { ADMIN_SEARCH_SELECT, GET_POOL, GET_POOL_USERS, GET_GROUP } from './actions/types';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CheckBox from '@material-ui/core/Checkbox';
import {
    Paper,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    FormControlLabel,
    FormGroup,
} from '@material-ui/core';

/**
 * Account information component in account page
 */
class AdminFindData extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            searchValue: "",
            searchEnabled: [true, true, true]
        };

        this.searchTypes = [
            {
                searchFunc: this.props.searchUsers.bind(this),
                searchOption: <Translate value="searchOptionUsers"/>,
                listAll: <Translate value="listAllUsers"/>
            },
            {
                searchFunc: this.props.searchPools.bind(this),
                searchOption: <Translate value="searchOptionPools"/>,
                listAll: <Translate value="listAllPools"/>
            },
            {
                searchFunc: this.props.searchCompanies.bind(this),
                searchOption: <Translate value="searchOptionCompanies"/>,
                listAll: <Translate value="listAllCompanies"/>
            }
        ];
    }

    getValue(value) {
        return value !== undefined ? value : "-";
    }

    onListAllItemsClick(index) {
        this.clearSelectionDataFromStore();

        const newFilter = [index === 0, index === 1, index === 2];
        this.setState({searchEnabled: newFilter});
        this.searchTypes[index].searchFunc.call(this,"")
    }

    onSearchKeyPress(event)
    {
        if (event.charCode === 13) { // enter key pressed
            event.preventDefault();
            this.executeSearch();
        }
    }

    executeSearch() {
        if (this.state.searchValue) {
            this.clearSelectionDataFromStore();

            this.state.searchEnabled.forEach((enabled, index) => {
                if (enabled) {
                    this.searchTypes[index].searchFunc.call(this, this.state.searchValue);
                }
            });
        }
    }

    onFilterChange(index, event, checked){
        const newValue = this.state.searchEnabled.slice();
        newValue[index] = checked;
        this.setState({searchEnabled: newValue});
    }

    searchResultSelectCallback(selected) {
        this.props.dispatch({type: ADMIN_SEARCH_SELECT, payload: selected});
        if (selected) {
            switch (selected.type) {
                case "User":
                    this.props.fetchUser(selected.id, true);
                    this.props.history.push('/account');
                    break;
                case "Pool":
                    this.props.getPoolAll(selected.id);
                    break;
                case "Company":
                    this.props.history.push('/company');
                    break;
                default:
                    console.log("Unknown search result type:", selected.type);
                    break;
            }
        }
        else {
            // list item was unselected
            this.clearSelectionDataFromStore();
        }
    }

    clearSelectionDataFromStore() {
        // Clear current search
        this.props.dispatch({type: ADMIN_SEARCH_SELECT, payload: null});
        // Clear pool
        this.props.dispatch({type: GET_POOL, payload: null});
        this.props.dispatch({type: GET_POOL_USERS, payload: null});
        this.props.dispatch({type: GET_GROUP, payload: {groups: []}});
    }

    getCombinedSearchResults() {
        const result = [];
        //Check if search option is enabled since 'poolSearchResults' are not emptied between searches
        if (this.state.searchEnabled[0] && this.props.userSearchResults) {
            this.getUserSearchResults(result);
        }
        if (this.state.searchEnabled[1] && this.props.poolSearchResults) {
            this.getPoolSearchResults(result);
        }
        if (this.state.searchEnabled[2] && this.props.companySearchResults) {
            this.getCompanySearchResults(result);
        }
        return result;
    }

    getUserSearchResults(result) {
        for (let user of this.props.userSearchResults) {
            let data = "";
            if (!user.firstName && !user.lastName && !user.title && !user.nick) {
                data = this.getValue(user.email) + " " + I18n.t("invitedPending");
            }
            else {
                data = this.getValue(user.email) + " | " + this.getValue(user.firstName) + " | " +
                    this.getValue(user.lastName) + " | " + this.getValue(user.title)+ " | " + this.getValue(user.nick) + " | " +
                    this.getValue(user.pool);
            }
            result.push({
                id: user._id,
                type: "User",
                data: data
            });
        }
    }

    getPoolSearchResults(result) {
        const today = new Date();
        for (let pool of this.props.poolSearchResults) {
            result.push({
                id: pool.pool._id,
                type: "Pool",
                data: {
                    name: pool.pool.name,
                    game: pool.pool.games[0],
                    seats: pool.pool.seats,
                    expiryDate: pool.pool.end,
                    expired: new Date(pool.pool.end) < today,
                    companyName: pool.companyName,
                }
            });
        }
    }

    getCompanySearchResults(result) {
        for (let company of this.props.companySearchResults) {
            result.push({
                id: company._id,
                type: "Company",
                data: this.getValue(company.name)
            });
        }
    }

    render() {
        const texts = {
            searchEmail: <Translate value="searchEmailOrLicense"/>,
            search: <Translate value="search"/>,
            newPool: <Translate value="createNewPool"/>,
            newCompany: <Translate value="createCompany"/>,
            editPool: <Translate value="editPool"/>,
        };
        const styles = {
            searchFilterControl: {
                margin: "0 4px",
                paddingLeft: 16,
                paddingRight: 16,
            }
        }
        const filterBoxes = this.searchTypes.map((type, index) => {
            return <FormControlLabel
                    key={index}
                    control={
                        <CheckBox onChange={this.onFilterChange.bind(this, index)}
                                    checked={this.state.searchEnabled[index]}
                                    color="primary"
                        />}
                    label={type.searchOption}>
                </FormControlLabel>;
        });
        return (
            <div className="account-container table--container">
				<h3 className="header3">{texts.search}</h3>
                <AdminSearchResults searchResults={this.getCombinedSearchResults()}
                                    selectCallback={this.searchResultSelectCallback.bind(this)}/>
                <TextField className="admin--textfield" label={texts.searchEmail}
                           value={this.state.searchValue}
                           onChange={e => this.setState({searchValue: e.target.value})}
                           onKeyPress={this.onSearchKeyPress.bind(this)}/>

                <div>
                    <Button onClick={this.executeSearch.bind(this)} style={styles.searchFilterControl}>
                        {texts.search}
                    </Button>
                    {
                        this.searchTypes.map((type, index) => {
                            return <Button key={index} onClick={this.onListAllItemsClick.bind(this, index)}style={styles.searchFilterControl}>
                                        {type.listAll}</Button>
                        })
                    }
                </div>
                <FormGroup row>{filterBoxes}</FormGroup>

                <br/>
                <NavLink exact={true} to={"/pools"}>
                    <Button onClick={this.searchResultSelectCallback.bind(this,null)}>{texts.newPool}</Button>
                </NavLink>
                <NavLink exact={true} to={"/company"}>
                    <Button onClick={this.searchResultSelectCallback.bind(this,null)}>{texts.newCompany}</Button>
                </NavLink>
            </div>
        );
    }
}

class AdminSearchResults extends Component {
    onRowSelected(event, result) {
        const payload = result.id.length > 0 ? this.findSelectedInSearchResults(result) : null;
        const wasDeselected = !payload || (this.state && this.state.selected === payload.id);
        this.setState({
            selected: (payload && !wasDeselected) ? payload.id : null
        });
        this.props.selectCallback(!wasDeselected ? payload : null);
    }

    findSelectedInSearchResults(result) {
        return this.props.searchResults.find(searchResult => result.id === searchResult.id);
    }
    isSelected(value) {
        return this.state && this.state.selected === value;
    }

    render() {
        const columnStyle = { whiteSpace: "normal" };
        const scrollablePanelStyle = {maxHeight: 500, overflowY: "auto"}
        const headStyle = {position: "sticky", top: 0, backgroundColor: "white"}
        if (!this.props.searchResults || this.props.searchResults.length === 0) {
            return null;
        }

        const texts = {
            type: <Translate value="type"/>,
            info: <Translate value="typeInfo"/>
        };

        const poolInformation = (pool) => {
            let expiryDate = new Date(pool.expiryDate);
            expiryDate =  `${expiryDate.getFullYear()}-${String(expiryDate.getMonth()+1).padStart(2, '0')}-${String(expiryDate.getDate()).padStart(2, '0')}`;
            if(pool.expired) {
                return <div className='expired'><span className='pool-section'>{pool.name}</span><span className='separator'>|</span> <span className='pool-section'>{pool.game}</span> <span className='separator'>|</span> <span className='pool-section'>{pool.seats} seats</span> <span className='separator'>|</span> <span className='pool-section'>{expiryDate}</span> <span className='separator'>|</span> <span className='pool-section'>{pool.companyName}</span> </div>;
            }
            return <div className='unexpired'><span className='pool-section'>{pool.name}</span> <span className='separator'>|</span> <span className='pool-section'>{pool.game}</span> <span className='separator'>|</span> <span className='pool-section'>{pool.seats} seats</span> <span className='separator'>|</span> <span className='pool-section'>{expiryDate}</span> <span className='separator'>|</span> <span className='pool-section'>{pool.companyName}</span></div>;
        };

        return <Paper style={scrollablePanelStyle}>
            <Table className={"low--table"}>
                <TableHead>
                    <TableRow>
                        <TableCell style={headStyle} width={30}>{texts.type}</TableCell>
                        <TableCell style={headStyle} width={300}>{texts.info}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.props.searchResults.map(function(searchResult, index) {
                        return <TableRow onClick={event => this.onRowSelected(event, searchResult)}
                                    key={String(searchResult.id) + String(index)}
                                    className={"low--table-row"}
                                    selected={this.isSelected(searchResult.id)}
                                    style={columnStyle}>
                            <TableCell className={"low--table-row"} width={30} key={'typecell-'+index}>{searchResult.type}</TableCell>
                            {typeof(searchResult.data) === 'string' &&
                                <TableCell className={"low--table-row"} width={300}>{searchResult.data}</TableCell>
                            }
                            {typeof(searchResult.data) === 'object' && searchResult.type.toLowerCase() === 'pool' &&
                                <TableCell className={"low--table-row"} width={300}>{poolInformation(searchResult.data)}</TableCell>
                            }
                        </TableRow>;
                    }.bind(this))}
                </TableBody>
            </Table>
        </Paper>
    };
}

const mapStateToProps = (state) => ({
    userSearchResults: state.user.userSearchResults,
    poolSearchResults: state.pool.poolSearchResults,
    companySearchResults: state.company.companySearchResults,
    searchSelect: state.user.searchSelect,
});
const mapDispatchToProps = (dispatch) => {
    const boundActionCreators = bindActionCreators({searchUsers, fetchUser, searchPools, getPoolAll, searchCompanies}, dispatch);
    return {...boundActionCreators, dispatch};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminFindData));
