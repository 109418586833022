import React, { Component } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { I18n, Translate } from 'react-i18nify';

//TODO: refactor notification system, get rid of switch/if-else hell
/**
 * Notification component for showing errors and other messages.
 * Appears at the top of the screen and hides automatically in 10 seconds or when user clicks anywhere on page.
 */
class Notification extends Component {
    constructor(props, context) {
        super(props, context);

        window.addEventListener('custom_error', this.onError.bind(this));
        window.addEventListener('notification', this.onNotification.bind(this));
        this.state = {
            notificationMessage: ''
        };
    }

    /**
     * Handler for window notification event.
     * Shows message in notification.
     * @param event {CustomEvent} Notification event
     */
    onNotification(event) {
        var notificationText = '';
        switch (event.detail.message) {
            case 'close_notification':
                notificationText = '';
                this.onClosed();
                break;
            case 'pool_created':
                notificationText = <Translate value="licensePoolCreated"/>;
                break;
            case 'pool_updated':
                notificationText = <Translate value="licensePoolUpdated"/>;
                break;
            case 'account_info_updated':
                notificationText = <Translate value="accountInfoUpdater"/>;
                break;
            case 'password_reset':
                notificationText = <Translate value="passwordUpdated"/>;
                break;
            case 'password_reset_sent':
                notificationText = <Translate value="passwordResetSent" email={event.detail.email}/>;
                break;
            case 'reinvite_sent':
                notificationText = <Translate value="reInviteSent" email={event.detail.email}/>;
                break;
            case 'no_license':
                notificationText = <Translate value="noLicense"/>;
                break;
            case 'player_limit_over':
                notificationText = <Translate value="playerLimitOver"/>;
                break;
            case 'waiting_servers':
                notificationText = <Translate value="waitingForServer" time={event.detail.time} />;
                break;
            case 'runbook_expired':
                notificationText = <Translate value="runbookExpired"/>;
                break;
            case 'companyCreated':
            case 'companyUpdated':
                notificationText = <Translate value={event.detail.message}/>;
                break;
            default:
                notificationText = <Translate value={event.detail.message}/>;
                break;
        }

        if (notificationText) {
            this.setState({notificationMessage: notificationText});
        }
    }

    /**
     * Handler for window custom_error event.
     * Shows error message in notification.
     * @param event {CustomEvent} Error event
     */
    onError(event) {
        let errorText = '';
        const data = event.detail.data;

        if (data && Array.isArray(data.error)) {
            //Handle errors by express-validator. Array may contain multiple errors.
            const params = data.error.map(err => err.msg);
            const msgKey = data.msgKey ? data.msgKey : 'invalidData';
            const message = I18n.t(msgKey, {params: params.join(', ')});

            errorText = <Translate value="errorWithMessage" message={message}/>;
        } else if (data && data.pools && typeof data.error === 'string') {
            // notify error of trying to remove a main administrator of one or multiple pools
            const poolsParameter = data.pools.join(", ");
            const message = I18n.t(data.error, {params: poolsParameter});

            errorText = <Translate value="errorWithMessage" message={message}/>;
        }
        else if (data && typeof data.error === 'string') {
            //Handle custom errors
            let message = I18n.t(data.error);
            if (!message || message.length === 0) {
                message = data.error;
            }
            errorText = <Translate value="errorWithMessage" message={message}/>;
        } else if (data && typeof data === 'string') {
            if (data !== 'Unauthorized') {
                //'Unauthorized' error message is only shown in LoginDialog.
                let message = I18n.t(data);
                if (!message || message.length === 0) {
                    message = data.error;
                }
                errorText = <Translate value="errorWithMessage" message={message}/>;
            }
        }
        else if (event.detail.response && typeof event.detail.response.data === 'string') {
            errorText = <Translate value="errorWithMessage" message={event.detail.response.data}/>;
        }
        else {
            //Handle other errors
            errorText = <Translate value="error"/>;
        }

        if (errorText) {
            this.setState({
                notificationMessage: errorText
            });
        }
    }

    /**
     * Handler for Snackbar closed event.
     * Reset notificationMessage state to prevent notification appearing again when component updates.
     */
    onClosed() {
        this.setState({notificationMessage: ''});
    }

    /**
     * Return Snackbar style that positions it at the top of the screen.
     * @returns {object} Snackbar style object
     */
    getNotificationStyle() {
        return {
            base: {
                top: 0,
                bottom: 'auto',
                left: "50%",
                transform: this.state.notificationMessage ? 'translate3d(-50%, 0, 0)' : 'translate3d(-50%, -50px, 0)'
            },
            body: {
                height: 'auto',
            }
        };
    }

    render() {
        //Notification is visible whenever notificationMessage exists
        const styles = this.getNotificationStyle();
        return (
            <Snackbar open={!!this.state.notificationMessage} style={styles.base}
                    ContentProps={{ style: styles.body }}
                    message={this.state.notificationMessage} autoHideDuration={10000}
                    onClose={this.onClosed.bind(this)}
                    action={
                        <IconButton size="small" aria-label="close" color="inherit" onClick={this.onClosed.bind(this)}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    }/>
        );
    }
}

export default Notification;
