import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogActions, DialogTitle, Paper } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

/**
 * Modal confirm dialog component.
 * Has cancel and confirm buttons. Calls this.props.closeHandler when one of the buttons has been pressed.
 */
class ConfirmDialog extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            inputConfirm : ""
        };
    }

    /**
     * Handler for confirm and cancel button pressed event.
     * Calls closeHandler prop.
     * @param confirm {boolean} True if confirm was clicked, false if cancel was clicked.
     */
    onButtonClick(confirm) {
        this.props.closeHandler(confirm);
    }

    handleCloseKeyDown(event){
        if (event.charCode === 13 && !this.verifyInput()) { // enter key pressed
            event.preventDefault();
            this.props.closeHandler(true);
        }
    };

    verifyInput() {
        if (this.props.confirmByTypingText) {
            return this.props.confirmByTypingText !== this.state.inputConfirm;
        }
        return false;
    }

    render() {
        const actions = [ //has a hide option for cancel button if one wished to use as a notification dialog rather than pure confirmation. Just add hide={true}
            this.props.hide ? null : <Button key={"cancel"} color="secondary" onClick={this.onButtonClick.bind(this, false)}>{this.props.cancel}</Button>,
            <Button key={"confirm"} className={this.props.isDeleteAction ? "delete-action--btn" : ""} onClick={this.onButtonClick.bind(this, true)} disabled={this.verifyInput()}>{this.props.confirm}</Button>
        ];
        const confirmInput = this.props.confirmByTypingText ? <TextField className="account-info--textfield"
            label={this.props.confirmByTypingTitle} value={this.state.inputConfirm}
            onChange={e => this.setState({inputConfirm: e.target.value})}/> : null;
        return (
            <div>
                <Dialog open={this.props.open} 
                        onClose={this.props.hide ? this.props.closeHandler : undefined}
                        onKeyPress={this.handleCloseKeyDown.bind(this)}
                        style={{fontSize:"1.15rem"}}
                        PaperComponent={Paper}
                        PaperProps={{style:{padding: "1rem 2rem"}}}
                        >
                    <DialogTitle disableTypography={true} style={{padding:"10px 24px"}}>{this.props.title}</DialogTitle>
                    <DialogContent style={{padding:15, color: "rgba(0,0,0,0.6)", display: "flex", flexDirection: "column"}}>
                        {this.props.message}
                        {confirmInput}
                    </DialogContent>
                    <DialogActions>
                        {actions}
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

ConfirmDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.object,
    message: PropTypes.node,
    cancel: PropTypes.node.isRequired,
    confirm: PropTypes.node.isRequired,
    closeHandler: PropTypes.func,
    isDeleteAction: PropTypes.bool
};

ConfirmDialog.defaultProps = {
    open: false,
    title: '',
    message: '',
    cancel: '',
    confirm: '',
    confirmByTypingTitle: '',
    confirmByTypingText: '',
    isDeleteAction: true
};

export default ConfirmDialog;
