import React, { Component } from 'react';
import { connect } from 'react-redux';
import RegisterOWCDialog from './RegisterOWCDialog';
import { withRouter, Link } from 'react-router-dom';
import { Translate, I18n } from 'react-i18nify';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Dialog, DialogTitle, DialogContent, DialogActions, Paper } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { getOWCStats, getOWCRound, getOWCRanking } from './actions/stats';
import { makeContactRequest } from './actions/index';
import PropTypes from 'prop-types';
import { registerOWCUser, addExistingToOWC } from './actions/auth';
import LegalDialog from './LegalDialog';

class OWCLeaderboard extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            hasOWC: false,
            roundNumber: 0,
            scores: [],
            currentRound: 0,
            ranking: [],
            showOWCRegister: false,
        }
    }

    componentDidMount() {
        this.getOWCRound();
    }

    getOWCRound() {
        let _this = this;
        let currentRound = 0;
        getOWCRound()(result => {
            if (!result.payload) {
                return;
            }
            let rd = result.payload;
            currentRound = rd.id;
            _this.setState({ currentRound: currentRound, roundNumber: currentRound });
            _this.getScores(currentRound);
            if (typeof this.props.licenses !== 'undefined' && this.props.licenses.length > 0 && this.props.licenses.includes('owc')) {
                _this.getOWCRanking(currentRound);
            }
        });
    }

    getOWCRanking = (round) => {
        let _this = this;
        getOWCRanking(round).then(results => {
            if (!results) {
                return;
            }
            let rt = results;

            _this.setState({ ranking: rt });
        });
    }

    getScores = (round) => {
        let _this = this;
        getOWCStats(round, 10)(result => {
            if (!result.payload) {
                return;
            }
            let rt = result.payload;
            _this.setState({ scores: rt });
        });
    }

    renderTableBottom = (table) => {
        var table2 = [];
        if (typeof table !== 'undefined' && table.length > 0 && table[0].rank > 9) {
            table2 = table;
        }

        var rows = [];
        var nick = (this.props.profile && this.props.profile.nick) ? this.props.profile.nick : null;
        table2.forEach((obj) => {
            const style = (nick && obj.nick === nick) ? { backgroundColor: 'rgba(255, 255, 255, 0.1)' } : { backgroundColor: 'initial' };
            rows.push(
                <tr style={style} className="width-0">
                    <td className="width-1">{obj.rank}.</td>
                    <td className="width-2">{obj.nick}</td>
                    <td className="orange width-3">{obj.score}</td>
                </tr>
            )
        });
        var tby = null;
        tby = <tbody>
            {rows.map((obj, key) => {
                return (obj)
            })}
        </tbody>
        return tby;
    }

    renderTable = (table) => {
        var rows = [];
        var count = 1;
        var nick = (this.props.profile && this.props.profile.nick) ? this.props.profile.nick : null;

        table.forEach((obj) => {
            const style = (nick && obj.nick === nick) ? { backgroundColor: 'rgba(255, 255, 255, 0.1)' } : { backgroundColor: 'initial' };
            rows.push(
                <tr style={style} className="width-0">
                    <td className="width-1">{count}.</td>
                    <td className="width-2">{obj.nick}</td>
                    <td className="orange width-3">{obj.score}</td>
                </tr>
            )
            count = count + 1;
        });
        var tby = null;
        tby = <tbody>
            {rows.map((obj, key) => {
                return (obj)
            })}
        </tbody>
        return tby;
    }

    handleChange = (event, index, value) => {
        this.setState({
            ranking: [],
            roundNumber: value
        });
        this.getScores(value);
        if (typeof this.props.licenses !== 'undefined' && this.props.licenses.length > 0 && this.props.licenses.includes('owc')) {
            this.getOWCRanking(value);
        }
    }

    determineIfDisabled = (id) => {
        if (id <= this.state.currentRound) {
            return false;
        }
        return true;
    }

    onClose(result, email, nick, agree) {
        if (result) {
            this.props.registerHandler(email, nick, agree);
        }
        else {
            this.setState({ showOWCRegister: false });
        }
    }

    // Decide whether the OWC dialogue should be visible or not, return true or false
    checkError = () => {
        if (this.state.showOWCRegister) {
            if (!this.props.authErrorCheck)
                return true;
            if (this.props.authErrorCheck)
                return false;
        }
        else
            return false;

    }

    render() {
        const texts = {
            play: <Translate value="play" />,
        };

        if (!this.state.hasOWC && typeof this.props.licenses !== 'undefined' && this.props.licenses.length > 0 && this.props.licenses.includes('owc')) {
            this.setState({
                hasOWC: true
            });
            this.getOWCRanking(this.state.currentRound)
        }

        const placeholderScores = [
        ];
		/*
			{ nick: "Hoff", score: 910528 },
			{ nick: "Hoffendal", score: 910528 },
			{ nick: "Hoffmaister", score: 910528 },
			{ nick: "Hoff666666", score: 910528 },
			{ nick: "Hoffburger", score: 910528 },
			{ nick: "Hoffström", score: 910528 },
			{ nick: "Hoffberg", score: 910528 },
			{ nick: "Hoffelhass", score: 910528 },
			{ nick: "Hoffinger", score: 910528 },
			{ nick: "Hoffa", score: 910528 },
		*/

        const leaderboard = (typeof this.state.scores !== 'undefined' && this.state.scores.length > 0) ?
            this.renderTable(this.state.scores) : this.renderTable(placeholderScores);

        const joinOrPlay = (this.state.hasOWC) ?
            <Link to="/owc">
                <Button className="info-page--button owc-btn-filled-orange color-white">{texts.play}</Button>
            </Link>
            :
            <Button className="info-page--button owc-btn-filled-orange color-white"
                label="Join OWC" primary={false} onClick={() => this.setState({ showOWCRegister: true })} />;

        const rankingOrJoinOWC = (this.state.hasOWC && typeof this.state.ranking !== 'undefined' && this.state.ranking.length > 0) ?
            this.renderTableBottom(this.state.ranking)
            :
            <div> {joinOrPlay} </div>;

        const styles = {
            customWidth: {
                width: 150,
            },
        };
        const isPadding = (this.state.hasOWC && typeof this.state.ranking !== 'undefined' && this.state.ranking.length > 0) ?
            "table"
            :
            "no-padding table";

        return (
            <div className="owc-leaderboard background-purple">
                <RegisterOWCDialog open={this.checkError()} clickHandler={this.onClose.bind(this)} />
                <div className="grid">
                    <p className="heading owc-add-line">leaderboard</p>
                    <Select
                        autoWidth={true}
                        className="monthselect"
                        value={this.state.roundNumber}
                        onChange={this.handleChange}
                        labelStyle={{ color: 'white' }}
                    >
                        <MenuItem key={100} value={100}>Total</MenuItem>
                        <MenuItem key={0} value={0} disabled={this.determineIfDisabled(0)}>Round 1 May</MenuItem>
                        <MenuItem key={1} value={1} disabled={this.determineIfDisabled(1)}>Round 2 June</MenuItem>
                        <MenuItem key={2} value={2} disabled={this.determineIfDisabled(2)}>Round 3 July</MenuItem>
                        <MenuItem key={3} value={3} disabled={this.determineIfDisabled(3)}>Round 4 August</MenuItem>
                        <MenuItem key={4} value={4} disabled={this.determineIfDisabled(4)}>Round 5 September</MenuItem>
                    </Select>
                </div>
                <div>
                    <table className="table">
                        {leaderboard}
                    </table>
                    <hr className="divider" />
                    <table className={isPadding}>
                        {rankingOrJoinOWC}
                    </table>
                </div>
            </div>
        );
    }
}

class GamesTrialHomePage extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            showOWCRegister: false,
        }
    }

    onCloseRegisterOWCDialog(result, email, nick, agree) {
        if (result) {
            if (this.props.profile && this.props.profile._id) {
                this.props.addExistingToOWC(email, nick, agree);
                this.setState({ showOWCRegister: false });
            }
            else {
                this.props.registerOWCUser(email, nick, agree);
                this.setState({ showOWCRegister: false });
            }
        }
        else {
            this.setState({ showOWCRegister: false });
        }
    }

    registerOWC(email, nick, agree) {
        if (this.props.profile && this.props.profile._id) {
            this.props.addExistingToOWC(email, nick, agree);
        }
        else {
            this.props.registerOWCUser(email, nick, agree);
        }
    }

    detectMob = () => {
        if (window.innerWidth <= 767) {
            return true;
        } else {
            return false;
        }

    };

    getGames = () => {
        if (this.props.userPool && !this.props.company) {
            return [];
        }

        const pools = this.props.allPools || (this.props.userPool ? [this.props.userPool] : null);
        if (!pools || !this.props.company) {
            return [];
        }

        const result = [];
        pools
            .filter(userPool => (userPool.pool.company))
            .forEach(function (userPool) {
                userPool.pool.games.forEach(function (gameName) {
                    result.push(gameName);
                });
            });

        return result;
    }

    onContactRequest(company, name, email, phone, message) {
        const phoneOrEmpty = phone || 'empty';
        this.props.makeContactRequest({
            company: company,
            companyContact: name,
            email: email,
            phone: phoneOrEmpty,
            message: message,
        });
    }

    getdialogHeaders(game) {
        let headers = {};
        if (game === 'distiller') {
            headers = {
                title: <Translate value="distDialogTitle" tag="h3" />,
                description: <Translate value="distDialogDescription" />,
                message: I18n.t('distDialogMessage')
            };
            return headers;
        }
        else if (game === 'furnace') {
            headers = {
                title: <Translate value="furnaceDialogTitle" tag="h3"/>,
                description: <Translate value="furnaceDialogDescription" />,
                message: I18n.t('furnaceDialogMessage')
            };
            return headers;
        }
    }

    render() {
        //const licenses = this.getGames();
        const texts = {
            play: <Translate value="play" />,
        };

        const furnaceTrialLink = "/furnace-trial";
        const distiller = "distiller";
        const furnace = "furnace";

        const bottomTitle =
            <p className="heading owc-add-line purple heading-bottom-margin">Want to play the full version of Furnace? <br />
                <span className="orange">Request a Full version trial.</span>
            </p>;

        const orderOrPlayFurnace = this.detectMob() ?
            <div className="font-18 owcTitle"> Play with tablet or desktop </div>
            :
            <Link to={furnaceTrialLink}>
                <Button className="btn info-page--button owc-btn-filled color-white">{texts.play}</Button>
            </Link>;


        const teaserInfo =
            <div id="outer-container" className="item even">
                <div id="in-container" className="picture-box furnace-image-owc clear">
                </div>
                <div className="button-container">
                    {orderOrPlayFurnace}
                </div>
            </div>;

        const distillerTeaserInfo =
            <div id="outer-container" className="item even">
                <div id="in-container" className="picture-box distiller-image-owc clear">
                </div>
                <div className="button-container">
                    <RequestTrialDialog dialogHeaders={this.getdialogHeaders(distiller)} closeHandler={this.onContactRequest.bind(this)} />
                </div>
            </div>;


        /*<div className="grid-item area-a">
                        </div>*/
        /* style override of "grid-container" is to prevent extra horizontal space appearing on the right side of the page*/
        const titleSection =
            <div className="OWC background-block-right">
                <div className="trial-title">
                    <div className=""></div>
                    <div className="item2-new"></div>
                    <div className="item3-new-trial purple"><span className="line-height-50">NAPCON Games </span> <br />  <span className="line-height-50">Trial</span></div>
                    <div className="item4-new"></div>
                </div>
                <div className="trial-container">
                    <div>
                        <p className="game-title middle"> <span className="line-height-50 orange">Distiller</span></p>
                        <p className="purple middle">READY TO TRY YOUR DISTILLATION SKILLS?</p>
                        <p className="purple middle">Learn and test your skills in industrial distilling. <br />To get a free trial, click the request trial button below.</p>
                        <div className="middle">
                            <div style={{ gridTemplateColumns: "auto" }}>
                                <div className="grid-item area-b">
                                    {distillerTeaserInfo}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p className="game-title middle"> <span className="line-height-50 orange">Furnace</span></p>
                        <p className="purple middle">READY TO DISCOVER YOUR HIDDEN POTENTIAL?</p>
                        <p className="purple middle">Learn and test your skills in operating an industrial furnace. <br />Start right away by clicking the play button below.</p>
                        <div>
                            <div style={{ gridTemplateColumns: "auto" }}>
                                <div className="grid-item area-b">
                                    {teaserInfo}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>;
        return (
            <div className="page-container">
                {titleSection}
                <div className="background-block-left">
                    <div className="bottom-grid">
                        <div className="item left-20">
                            {bottomTitle}
                            <div className="limit-text">
                                <span>The third release of NAPCON Games, Furnace, is a simulation-based tutorial game. With NAPCON Games Furnace, operators and other industry professionals are able to enhance and test their skills in industrial furnace principles and operating. Playing is interactive and exciting with carefully designed features.</span>
                            </div>
                            <p className="heading-bottom-margin">Get ahead with training by getting a <span className="orange">30-day free trial.</span></p>
                            <br />
                            <RequestTrialDialog dialogHeaders={this.getdialogHeaders(furnace)} closeHandler={this.onContactRequest.bind(this)} />
                        </div>
                        <div className="item owc-bottom-background hide"></div>
                    </div>
                </div >
            </div>
        );
    }
}

class RequestTrialDialog extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            open: false,
            company: '',
            companyContact: '',
            email: '',
            phone: '',
            message: this.props.dialogHeaders.message,
            clickedOnce: false
        };
    }

    determineErrorText(value) {
        let errorText = 'This field is required.';
        if (value.length > 0 || this.state.clickedOnce === false) {
            errorText = '';
        }
        return errorText;
    }

    /**
     * Returns true if all text fields are valid
     * @returns {boolean}
     */
    verifyInputs() {
        return this.state.company.length > 0 && this.state.companyContact.length > 0 && this.state.email.length > 0 && this.state.message.length > 0;
    }

    handleOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    onSubmitClick = () => {
        if (this.verifyInputs()) {
            this.props.closeHandler(this.state.company, this.state.companyContact, this.state.email, this.state.phone, this.state.message);
            this.setState({ open: false });
        }
        else {
            this.setState({ clickedOnce: true });
            //window.dispatchEvent(new CustomEvent('notification', { detail: { message: 'Please_fill_all_required_fields' } }));
        }
    };

    render() {
        const texts = {
            contact: <Translate value="contact" />,
            cancel: <Translate value="cancel" />,
            send: <Translate value="send" />,
            company: <Translate value="company" />,
            organization: <Translate value="organization" />,
            name: <Translate value="name" />,
            email: <Translate value="email" />,
            phone: <Translate value="phone" />,
            message: <Translate value="message" />,
        };
        const companization = <div>{texts.company} / {texts.organization}</div>;

        const actions = [

            <Button
                key={texts.cancel.props.value}
                className="btn-no-border"
                style={{margin: "0 10px"}}
                onClick={this.handleClose}>
                    {texts.cancel}
            </Button>,
            <Button
                key={texts.send.props.value}
                className="btn-filled"
                style={{margin: "0 10px"}}
                onClick={this.onSubmitClick}>
                    {texts.send}
            </Button>,

        ];
        const fieldStyle = { backgroundColor: '#eaf3fa', margin: '1em 0', borderTopRightRadius: '5px' };
        return (

            <div>
                <Button className="btn owc-btn-filled" onClick={this.handleOpen}>Request trial</Button>
                <Dialog
                    className="order-width"
                    open={this.state.open}
                    onClose={this.handleClose}
                    PaperComponent={Paper}
                    PaperProps={{className: "legal-body"}}
                >
                    <DialogTitle disableTypography={true} className="legal-title">{this.props.dialogHeaders.title}</DialogTitle>
                    <DialogContent className="legal-dialog legal-body" style={{maxWidth: '450px', paddingBottom: '0px'}}>
                        <p className="contact-form-subtitle no-margin">{this.props.dialogHeaders.description}</p>
                        <form>
                            <TextField className="title-blue"
                                label={companization}
                                autoComplete="organization"
                                fullWidth={true}
                                multiline
                                InputLabelProps={{
                                    shrink: true,
                                    style: {paddingLeft: '0.25em'},
                                  }}
                                InputProps={{ disableUnderline: true }}
                                rows={1}
                                rowsMax={1}
                                style={fieldStyle}
                                value={this.state.company}
                                error={this.determineErrorText(this.state.company) !== ""}
                                helperText={this.determineErrorText(this.state.company)}
                                onChange={e => this.setState({ company: e.target.value })} />
                            <TextField className="title-blue"
                                label={texts.name}
                                fullWidth={true}
                                autoComplete="name"
                                multiline
                                InputLabelProps={{
                                    shrink: true,
                                    style: {paddingLeft: '0.25em'},
                                  }}
                                InputProps={{ disableUnderline: true }}
                                rows={1}
                                rowsMax={1}
                                style={fieldStyle}
                                value={this.state.companyContact}
                                error={this.determineErrorText(this.state.companyContact) !== ""}
                                helperText={this.determineErrorText(this.state.companyContact)}
                                onChange={e => this.setState({ companyContact: e.target.value })} />
                            <TextField className="title-blue"
                                label={texts.email}
                                fullWidth={true}
                                autoComplete="email"
                                multiline
                                InputLabelProps={{
                                    shrink: true,
                                    style: {paddingLeft: '0.25em'},
                                  }}
                                InputProps={{ disableUnderline: true }}
                                rows={1}
                                rowsMax={1}
                                style={fieldStyle}
                                value={this.state.email}
                                error={this.determineErrorText(this.state.email) !== ""}
                                helperText={this.determineErrorText(this.state.email)}
                                onChange={e => this.setState({ email: e.target.value })} />
                            <TextField className="title-blue"
                                label={texts.phone}
                                fullWidth={true}
                                autoComplete="tel"
                                multiline
                                InputLabelProps={{
                                    shrink: true,
                                    style: {paddingLeft: '0.25em'},
                                  }}
                                InputProps={{ disableUnderline: true }}
                                rows={1}
                                rowsMax={1}
                                style={fieldStyle}
                                value={this.state.phone}
                                onChange={e => this.setState({ phone: e.target.value })} />
                        </form>
                        <TextField className="title-blue"
                            label={texts.message}
                            InputLabelProps={{
                                shrink: true,
                                style: {paddingLeft: '0.25em'},
                              }}
                            InputProps={{ disableUnderline: true }}
                            fullWidth={true}
                            multiline
                            rows={5}
                            rowsMax={10}
                            style={fieldStyle}
                            value={this.state.message}
                            error={this.determineErrorText(this.state.message) !== ""}
                            helperText={this.determineErrorText(this.state.message)}
                            onChange={e => this.setState({ message: e.target.value })} />
                    </DialogContent>
                    <DialogActions style={{ padding: '14px' }}>{actions}</DialogActions>
                </Dialog>
            </div>
        );
    }
}

RequestTrialDialog.propTypes = {
    closeHandler: PropTypes.func,
    dialogHeaders: PropTypes.object
};

function mapStateToProps(state) {
    return {
        authenticated: state.auth.authenticated,
        userPool: state.userPool.currentPool,
        allPools: state.userPool.allPools,
        currentCompany: state.userPool.currentCompany,
        company: state.userPool.currentCompany,
        profile: state.user.profile,
        owc: state.userPool.owc,
        errorchk: state.auth.authenticated,
    };

}

export default withRouter(connect(mapStateToProps, { makeContactRequest, registerOWCUser, addExistingToOWC })(GamesTrialHomePage));
