
import axios from 'axios';
import { API_URL, errorHandler } from './index';
import { ERROR_RESPONSE, GET_HIGHSCORES, GET_HIGHSCORE_SUM, GET_USERIDLIST_HIGHSCORES, GET_BESTSCORES, GET_HIGHSCORE_GAME_TOP10, GET_HIGHSCORES_USER, GET_HIGHSCORES_USER_GAME } from './types';
import { getGetRequestParams } from '../Helpers';

export function getHighScoresArgs(args) {
    return getHighScores(args.poolId, args.groupId, args.beginDate, args.endDate, args.missionId, args.game);
}

export function getHighScores(poolId, groupId, beginDateMoment, endDateMoment, missionId, game) {
    return function (dispatch) {
        axios.get(`${API_URL}/highscore/${poolId}`, getGetRequestParams(game, groupId, beginDateMoment, endDateMoment, missionId))
            .then((response) => {
                dispatch({
                    type: GET_HIGHSCORES,
                    payload: response.data
                });
            })
            .catch((error) => {
                errorHandler(dispatch, error.response, ERROR_RESPONSE);
            });
    };
}

export function getHighscoresForGame(game, missionId) {
    return function (dispatch) {
        axios.get(`${API_URL}/highscore/top10/${game}`, getGetRequestParams(null, null, null, null, missionId))
          .then((response) => {
              dispatch({
                  type: GET_HIGHSCORE_GAME_TOP10,
                  payload: response.data.scoreData,
              });
          })
          .catch((error) => {
              errorHandler(dispatch, error.response, ERROR_RESPONSE);
          });
    }
}

export function getHighScoresForUserIdList(userIdList, beginDateMoment, endDateMoment, missionId) {
    return function (dispatch) {
        axios.get(`${API_URL}/highscore/users/0`, getGetRequestParams(null, userIdList, beginDateMoment, endDateMoment, missionId))
            .then((response) => {
                dispatch({
                    type: GET_USERIDLIST_HIGHSCORES,
                    payload: response.data
                });
            })
            .catch((error) => {
                errorHandler(dispatch, error.response, ERROR_RESPONSE);
            });
    };
}

export function getBestScoresForUser(userId, game, beginDateMoment, endDateMoment, missionId) {
    return function (dispatch) {
        axios.get(`${API_URL}/highscore/bestscores/${userId}`, getGetRequestParams(game, null, beginDateMoment, endDateMoment, missionId))
            .then((response) => {
                dispatch({
                    type: GET_BESTSCORES,
                    payload: response.data
                });
            })
            .catch((error) => {
                errorHandler(dispatch, error.response, ERROR_RESPONSE);
            });
    };
}

export function getAllUserMissionScores(userId, locale) {
    return function (dispatch) {
        axios.get(`${API_URL}/highscore/missionscore/${userId}`, getGetRequestParams(null, null, null, null, null, locale))
            .then((response) => {
                dispatch({
                    type: GET_HIGHSCORES_USER,
                    payload: response.data.scoreList
                });
            })
            .catch((error) => {
                errorHandler(dispatch, error.response, ERROR_RESPONSE);
            });
    };
}

export function getScoresForUserByGame(poolId, locale) {
    return function (dispatch) {
        axios.get(`${API_URL}/highscore/userscore/${poolId}`, getGetRequestParams(null, null, null, null, null, locale))
            .then((response) => {
                dispatch({
                    type: GET_HIGHSCORES_USER_GAME,
                    payload: response.data.userScoreList
                });
            })
            .catch((error) => {
                errorHandler(dispatch, error.response, ERROR_RESPONSE);
            });
    };
}

export function getHighScoreSum(poolId, groupId, game) {
    return function (dispatch) {
        axios.get(`${API_URL}/highscore/sum/${poolId}`, getGetRequestParams(game, groupId))
            .then((response) => {
                dispatch({
                    type: GET_HIGHSCORE_SUM,
                    payload: response.data
                });
            })
            .catch((error) => {
                errorHandler(dispatch, error.response, ERROR_RESPONSE);
            });
    };
}