
import React, { Component } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Translate } from 'react-i18nify';
import { InputLabel } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

class AccountPageDropDown extends Component {
    onActionChange(event) {
        if (event.target.value) {
            event.target.value();
        }
    }

    render() {
        return <div className="statistics-header--admin">
            <Select className="statistics-header--admin-drop-down" value={"-1"}
                          onChange={this.onActionChange.bind(this)} autoWidth={false}>
                <MenuItem key={0} value={"-1"}>{<Translate value="selectAction"/>}</MenuItem>
                {this.props.menuItems? this.props.menuItems.map((item, index) =>
                    <MenuItem disabled={item.disabled} key={index+1} value={item.callback}
                        component={item.container} to={item.to} style={{textDecoration:'none'}}>
                            {<Translate value={item.textKey} />}
                        </MenuItem>
                ) : null}
            </Select>
        </div>;
    }
}

export default AccountPageDropDown;