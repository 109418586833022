import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import Icon from '@material-ui/core/Icon';

class AccountListItem extends Component {
    constructor(props, context) {
        super(props, context);
    }

    /**
     * Handler for remove button press event
     */
    onRemovePress() {
        if (this.props.buttonPressHandler) {
            this.props.buttonPressHandler();
        }
    }

    render() {
        const removeButton = this.props.isRemovable === true ?
            <Icon className="material-icons account-list-item--remove"
                      onClick={this.onRemovePress.bind(this)}>{"clear"}</Icon> : null;
        const textHigh = this.props.textHigh ? <div>{this.props.textHigh}</div> : null;
        //Add spacing between texts if both exist
        const textLowSpacing = this.props.textHigh ? ' account-list-item--text-spacing' : '';
        const textLow = this.props.textLow ?
            <div className={"account-list-item--text-low" + textLowSpacing}>{this.props.textLow}</div> : null;

        return (
            <ListItem key={this.props.textLow} className="account-list-item">
                <div className="account-list-item--container">
                    <Icon className="material-icons account-list-item--icon">{this.props.icon}</Icon>
                    <div className="account-list-item--text">
                        {textHigh}
                        {textLow}
                    </div>
                    {removeButton}
                </div>
            </ListItem>
        );
    }
}

AccountListItem.propTypes = {
    buttonPressHandler: PropTypes.func,
    isRemovable: PropTypes.bool,
    icon: PropTypes.string,
    textHigh: PropTypes.node,
    textLow: PropTypes.node,
};

AccountListItem.defaultProps = {
    buttonPressHandler: null,
    isRemovable: false,
    icon: '',
    textHigh: '',
    textLow: ''
};

export default AccountListItem;
