
import { FETCH_USER, FETCH_USER_SEARCH_RESULT, SEARCH_USER, ERROR_RESPONSE, ADMIN_SEARCH_SELECT, UPDATE_RIGHTS,
    DELETE_USER, SKIP_TUTORIAL, POOL_CHANGE, UPDATE_USER_AGREEMENT } from '../actions/types';

const clone = require('lodash/clone');

const INITIAL_STATE = { profile: {}, message: '', error: '', rights: { siteAdmin: false, poolAdmin: false, admin: false, init: false } };

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case FETCH_USER:
            const updateRights = getRights(state.rights, action.payload, null);
            return { ...state, profile: action.payload, rights: updateRights, isRegistrationRequired: !action.payload.agreementConfirmed};
        case FETCH_USER_SEARCH_RESULT:
            return { ...state, searchedProfile: action.payload };
        case SEARCH_USER:
            return { ...state, userSearchResults: action.payload };
        case ERROR_RESPONSE:
            return { ...state, error: action.payload };
        case ADMIN_SEARCH_SELECT:
            return { ...state, searchSelect: action.payload };
        case UPDATE_RIGHTS:
            if (action && action.payload && action.payload.currentPool && action.payload.currentPool.length > 0) {
                const currentPool = action.payload.currentPool[0];
                const updateRightsB = getRights(state.rights, null, currentPool);
                return {...state, rights: updateRightsB};
            }
            return { ...state};
        case UPDATE_USER_AGREEMENT:
            return { ...state, profile: action.payload, agreementUpdated: true, agreementAcceptedVersion: "2.0" };
        case DELETE_USER:
            const userSearchResultsAfterDelete = clone(state.userSearchResults);
            for (var i = 0; i < userSearchResultsAfterDelete.length; i++) {
                if (userSearchResultsAfterDelete[i]._id === action.payload.uid) {
                    userSearchResultsAfterDelete.splice(i, 1);
                    break;
                }
            }
            window.dispatchEvent(new CustomEvent('notification', {detail: {message: 'account_deleted'}}));
            return { ...state, userSearchResults: userSearchResultsAfterDelete };
        case SKIP_TUTORIAL:
        // noop
            return state;
        case POOL_CHANGE:
            return { ...state, rights: {siteAdmin: false, poolAdmin: false, admin: false, init: false} };
        default:
            return state;
    }
}

function getRights(currentRights, newProfile, newPool) {
    let result = {};
    result.siteAdmin = currentRights.siteAdmin || (newProfile !== null && newProfile.role === 'Admin');
    result.poolAdmin = currentRights.poolAdmin || (newPool !== null && newPool.type === "PoolAdmin");
    result.admin = currentRights.admin || (newPool !== null && newPool.type === "Admin");
    result.init = newPool !== null;
    return result;
}