
import { Translate, I18n } from 'react-i18nify';
import React, {Component} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BottomButtonBar from './BottomButtonBar';

import RegisterOWCDialog from './RegisterOWCDialog';
import RegisterFurnaceTrialDialog from './RegisterFurnaceTrialDialog';
import ConfirmDialog from './ConfirmDialog';
import LinearProgressBar from './LinearProgressBar';
import CountDownTimer from './CountDownTimer';
import { getFullPoolStatsForMission, approveScore } from './actions/stats';
import { getBestScoresForUser } from './actions/highscore';
import { getAchievements} from './actions/achievement';
import { getGameServers } from './actions/gameserverinfo';
import { sendSkipTutorial} from './actions/index';
import { registerOWCUser, addExistingToOWC } from './actions/auth';
import { getHighscoreListWithOwnScore, getUserHighscoreName } from './StatHelper';
import { sendDataToIframe } from './Helpers';
import { MISSION_PLAYED } from './actions/types';

class DistillationGamePage extends Component {
    constructor(props, context) {
        super(props, context);

        this._reconnectTime = 15000;
        this._receivedStats = null;
        this._registerBarCreated = false;
        this._reconnectTimerId = null;

        this.state = {
            resultData: null,
            showOWCRegister: false,
            showWaitingQueueDialog: false,
            isExternalUser: this.props.location.pathname === '/external',
        };
    }

    componentWillMount() {
        this.checkGetAchievements(this.props);
    }

    onWindowResize() {
        const bottomDivs = document.getElementsByClassName('cookieWrapper');
        let bottomHeight = 0;
        for (let i = 0; i < bottomDivs.length; i++) {
            if (bottomDivs[i].style.display !== 'none') {
                bottomHeight = Math.max(bottomDivs[i].offsetHeight, bottomHeight);
            }
        }
        // do not leave room for toolbar for external users
        const toolbarMinHeightPx = 56;
        const toolbarHeightVh = window.innerHeight * 0.05;
        let toolbarHeight = toolbarHeightVh > toolbarMinHeightPx ? toolbarHeightVh : toolbarMinHeightPx;
        toolbarHeight = this.state.isExternalUser ? 0 : toolbarHeight;
        const gameWindowHeight = window.innerHeight - toolbarHeight - bottomHeight;
        sendDataToIframe('resize', { iFrameWidth: window.innerWidth, iFrameHeight: gameWindowHeight });
    }

    checkGetAchievements(checkedProps) {
        if (!checkedProps.achievements || checkedProps.achievements.length === 0) {
            if (checkedProps.userPool && checkedProps.userPool.user && !this.fetchedAchievements) {
                this.fetchedAchievements = true;
                checkedProps.getAchievements(checkedProps.userPool.user, this.props.gameId);
            }
        }
    }

    componentDidUpdate(prevProps) {
        // Resize the game frame when the register owc bottom bar is not visible anymore
        if (this._registerBarCreated && !this._registerButtonBar) {
            this.onWindowResize();
            this._registerBarCreated = false;
        }
        /*
        * Inform webclient that external user is ready to play (has been signed in/registered).
        * This ensures that even if webclient has initialized already, the mission and server will be added.
        */
        if (prevProps.userPool !== this.props.userPool && this.props.userPool
            && !this.props.isRegisterRequired
            && this.state.isExternalUser) {
            sendDataToIframe('ext_user_ready', {});
        }

        /*
        * Inform webclient when saving to external system is started, and is done or has encountered an error.
        */
        if (prevProps.externalSaveInProgress !== this.props.externalSaveInProgress) {
            if (this.props.externalSaveInProgress) {
                sendDataToIframe('ext_save_progress', {});
            } else if (this.props.externalSaveSuccess) {
                sendDataToIframe('ext_save_complete', {});
            } else {
                const errorMsg = this.getErrorMessageString();
                sendDataToIframe('ext_save_error', { errorMsg });
            }
        }
    }

    getErrorMessageString() {
        if (this.props.errorMessage && this.props.errorMessage.status) {
            let message = '';
            if (this.props.errorMessage.data.error) {
                message = I18n.t(this.props.errorMessage.data.error) || this.props.errorMessage.data.error;
            } else {
                message = 'unknown error';
            }
            const { status } = this.props.errorMessage;
            return I18n.t('errorWithMsgStatus', { message, status });
        }
        return '';
    }

    componentDidMount() {
        this.setState({
            isExternalUser: this.props.location.pathname === '/external',
        });

        this.props.gameShownCallback(true);
        this.onWindowMessageCallback = this.onWindowMessage.bind(this);
        window.addEventListener('message', this.onWindowMessageCallback);

        this.onWindowResizeCallback = this.onWindowResize.bind(this);
        window.addEventListener('resize', this.onWindowResizeCallback);
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.onWindowMessageCallback);
        window.removeEventListener('resize', this.onWindowResizeCallback);
        this.fetchedAchievements = false;
        this.onWindowMessageCallback = null;
        this.onWindowResizeCallback = null;
        this.props.gameShownCallback(false);
        if (this._reconnectTimerId) {
            clearTimeout(this._reconnectTimerId);
        }
    }

    onWindowMessage(e) {
        if (e.data.type === 'request_highscores') {
            this.fetchPoolHighscoresAndUserAchievements(e.data);
        } else if (e.data.type === 'request_gameservers') {
            // do not get gameservers for unconfirmed external users
            if (!this.state.isExternalUser || this.props.userPool) {
                this.props.getGameServers(this.props.gameId, this.props.guestId, this.props.userPool);
            }
        } else if (e.data.type === 'request_approvescore') {
            this._requestScoreApproving();
            this._requestScoreApproving = null;
        } else if (e.data.type === 'complete_external_success') {
            if (this.state.isExternalUser) {
                this.props.onExternalComplete();
            }
        }
        else if (e.data.type === 'request_profile') {
            if (!this.state.isExternalUser || this.props.userPool) {
                this.checkCurrentPool(this.props.allPools);
                this.onWindowResize();
                sendDataToIframe('profile', { profile: this.props.profile, owc: this.props.owc });
            }
        } else if (e.data.type === 'request_setskiptutorial') {
            this.props.sendSkipTutorial(this.props.profile._id);
        } else if (e.data.type === 'request_owc_registration' && this.props.gameId === 'furnace-trial' && !this.props.owc) {
            sendDataToIframe('fullscreen', { value: false });
            this.setState({ showOWCRegister: true });
        } else if (e.data.type === 'request_mission' && this.state.isExternalUser) {
            if (this.state.isExternalUser) {
                sendDataToIframe('mission', { missionId: this.props.missionId });
            }
        }

        if (e.data.type === 'request_bestscores') {
            if (this.props.userPool) {
                this.props.getBestScoresForUser(this.props.userPool.user, this.props.gameId);
            } else {
                this.sendBestScoresToFrame();
            }
        }
    }

    fetchPoolHighscoresAndUserAchievements(data) {
        const { missionId, score } = data;

        this._receivedStats = null;
        this._receivedAchievements = null;
        this._requestScoreApproving = this.props.approveScore.bind(this, this.props.userPool.user, { score, missionId });

        this.setState({ resultData: data });
        this.props.dispatch({ type: MISSION_PLAYED, missionId });

        // setTimeout allow time for gameserver to send stats before requesting them from portal server
        setTimeout(() => {
            // request full statistics specifically for the mission user just played (including other users)
            this.props.getFullPoolStatsForMission(this.props.userPool.pool._id, null, missionId, this.props.gameId);
            this.props.getAchievements(this.props.userPool.user, this.props.gameId);
        }, 2000);
    }

    checkCurrentPool(allPools) {
        if (!allPools || !this.props.currentGame || !this.props.gameId || this.props.currentGame === this.props.gameId) {
            return;
        }

        for (let current of allPools) {
            if (current.pool.games.indexOf(this.props.gameId) !== -1) {
                window.dispatchEvent(new CustomEvent('suggest_pool_change', { detail: { pool: current } }));
                break;
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        this.checkGetAchievements(nextProps);
        if (this.props.missionStatsPool !== nextProps.missionStatsPool && this.state && this.state.resultData !== null) {
            this._receivedStats = nextProps.missionStatsPool[this.state.resultData.missionId];
            this.sendStatsToFrame(this.state.resultData);
        }
        if (this.props.bestScores !== nextProps.bestScores) {
            this._receivedStats = nextProps.bestScores;
            this.sendBestScoresToFrame();
        }
        if (this.props.achievements !== nextProps.achievements && this.state && this.state.resultData !== null) {
            this._receivedAchievements = nextProps.achievements;
            this.sendStatsToFrame(this.state.resultData);
        }
        if (this.props.servers !== nextProps.servers) {
        // check server status for any player limit over or no licence issues else try to reconnect
            this.getServerStatus(nextProps.servers, nextProps.notice);
            sendDataToIframe('gameservers', { servers: nextProps.servers });
        }
        if (this.props.profile !== nextProps.profile || this.props.owc !== nextProps.owc) {
            sendDataToIframe('profile', { profile: nextProps.profile, owc: nextProps.owc });
        }
        if (this.state.showOWCRegister && nextProps.owc) {
            this.setState({ showOWCRegister: false });
        }
        if (this.props.allPools !== nextProps.allPools) {
            this.checkCurrentPool(nextProps.allPools);
        }
    }

    sendStatsToFrame(resultData) {
        if (!this._receivedStats || !this._receivedAchievements) {
            return;
        }

        const topScoresWithOwn = getHighscoreListWithOwnScore(this._receivedStats, resultData.score, resultData.status,
            this.props.userPool.user);

        for (let r of topScoresWithOwn) {
            r.name = getUserHighscoreName(this.props.usersById[r.userId], undefined, undefined, this.props.rights);
        }

        resultData.highScores = topScoresWithOwn;
        resultData.achievements = this._receivedAchievements;

        sendDataToIframe('highscores', resultData);
        this._receivedStats = null;
        this._receivedAchievements = null;
        this.setState({resultData: null});
    }

    sendBestScoresToFrame() {
        sendDataToIframe('bestscores', { bestScores: this._receivedStats });
        this._receivedStats = null;
    }

    getApproximateNextServerUpTime() {
        return this.props.waitTime ? this.props.waitTime : '';
    }

    changeState(notice) {
        switch (notice) {
            case 'simultaneous_player_limit_over':
                this.setState({ showWaitingQueueDialog: true });
                break;
            case 'simultaneous_player_limit_ok':
                this.setState({ showWaitingQueueDialog: false });
                break;
            default:
        }
    }

    getServerStatus(servers, notice) {
        if (!servers) {
            return;
        }
        if (this.props.userPool && !this.props.userPool.valid) {
            window.dispatchEvent(new CustomEvent('notification', { detail: { message: 'no_license' } }));
        }
        if (servers.length === 0) {
            if (this._reconnectTimerId !== null) {
                // Already waiting for server update
                window.dispatchEvent(new CustomEvent('notification', { detail: { message: 'runbook_expired' } }));
                return;
                
            }
            const nextServerTime = this.getApproximateNextServerUpTime();
            if (notice) {
                this.changeState(notice);
                this.clearNotification();
            } else {
                window.dispatchEvent(new CustomEvent('notification', { detail: { message: 'waiting_servers', time: nextServerTime } }));
            }

            // Fetch servers again when list is empty
            this._reconnectTimerId = setTimeout(() => {
                this.props.getGameServers(this.props.gameId, this.props.guestId, this.props.userPool);
                clearTimeout(this._reconnectTimerId);
                this.clearNotification();
            }, this._reconnectTime);
        }
        else if (servers.length > 0 && notice) {
            this.clearNotification();
            this.changeState(notice);
            clearTimeout(this._reconnectTimerId);
        }
    }

    clearNotification() {
        window.dispatchEvent(new CustomEvent('notification', { detail: { message: 'close_notification' } }));
    }

    onCloseRegisterOWCDialog(result, email, nick, agree) {
        if (result) {
            if (this.props.profile && this.props.profile._id) {
                this.props.addExistingToOWC(email, nick, agree);
            }
            else {
                this.props.registerOWCUser(email, nick, agree);
            }
        }
        else {
            this.setState({showOWCRegister: false});
        }
    }

    renderOWCRegistration() {
        if ((this.props.gameId !== 'furnace-trial' && this.props.gameId !== 'owc') || this.props.owc) {
            return null;
        }
        this._registerBarCreated = true;
        const textKey = this.props.gameId === 'furnace-trial' ? 'furnaceTrialBottomRegisterText' : 'owcBottomRegisterText';
        // z-index is changed to 1 level lower than the cookieWrapper class so that the actual cookie banner shows over the owc registration
        return <div className="registerBottomBarWrapper">
            <BottomButtonBar centerText={<Translate value={textKey} />} fontSize={'14px'}
                buttonText={null} onClick={() => this.setState({showOWCRegister: true})} color={'rgb(240,240,240)'}/>
        </div>;
    }

    hideWaitingQueueDialog(confirm) {
        this.setState({ showWaitingQueueDialog: false });
    }

    renderWaitingQueueDialog() {
        if (!this.state.showWaitingQueueDialog) {
            return null;
        }

        const texts = {
            title: <Translate value={'waitingQueueTitle'} tag="h3" />,
            buttonText: <Translate value={'ok'} />,
            timeUnit: 'seconds'
        };

        const timeInSecond = this._reconnectTime * 0.001;
        const waitingQueueMessage = <div className="playerLimitOver">
            <div>
                <Translate value="playerLimitOver"/>
                <CountDownTimer timeInSeconds={timeInSecond} />
                {texts.timeUnit}
            </div>
            <div>
                <LinearProgressBar mode={'determinate'} maxValue={timeInSecond} />
            </div>
        </div>;

        return <ConfirmDialog open={this.state.showWaitingQueueDialog} confirm={texts.buttonText} title={texts.title}
            message={waitingQueueMessage} hide={true} closeHandler={this.hideWaitingQueueDialog.bind(this)} isDeleteAction={false}/>
    }

    render() {
        const isExternal = this.state.isExternalUser ? '&isDirectLink=1' : '&isDirectLink=0';
        const gameFramePath = gameFrameFile + '?lan=' + I18n._locale + '&game=' + this.props.gameId + isExternal;
        this._registerButtonBar = this.renderOWCRegistration();
        let styleClass = 'game-page' + (this._registerButtonBar !== null ? ' game-page--owc' : '');
        styleClass = this.state.isExternalUser ? styleClass + ' game-page--external' : styleClass;
        const waitingQueueDialog = this.renderWaitingQueueDialog();
        return (
            <div className={styleClass}>
                <iframe src={gameFramePath} width="100%" height="100%" allowFullScreen={true} style={{ border: 'none' }}/>
                <RegisterFurnaceTrialDialog open={this.state.showOWCRegister} clickHandler={this.onCloseRegisterOWCDialog.bind(this)} isDarkTheme={true} />
                {this._registerButtonBar}
                {waitingQueueDialog}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        authenticated: state.auth.authenticated,
        errorMessage: state.stats.error,
        isRegisterRequired: state.user.isRegisterRequired,
        missionStatsPool: state.stats.missionStatsPool,
        userPool: state.userPool.currentPool,
        allPools: state.userPool.allPools,
        owc: state.userPool.owc,
        usersById: state.userPool.usersById,
        users: state.userPool.users,
        profile: state.user.profile,
        rights: state.user.rights,
        servers: state.gameServer.servers,
        waitTime: state.gameServer.waitTime,
        guestId: state.gameServer.guestId,
        notice: state.gameServer.notice,
        achievements: state.achievements.data,
        lastServerCreatedTime: state.gameServer.lastServerCreatedTime,
        currentGame: state.userPool.currentGame,
        bestScores: state.highScores.bestScores,
        externalSaveInProgress: state.stats.externalSaveInProgress,
        externalSaveSuccess: state.stats.externalSaveSuccess,
    };
}

const mapDispatchToProps = (dispatch) => {
    const boundActionCreators = bindActionCreators({ getFullPoolStatsForMission, getBestScoresForUser, getGameServers, getAchievements,
        approveScore, sendSkipTutorial, registerOWCUser, addExistingToOWC }, dispatch);
    return {...boundActionCreators, dispatch};
};

export default connect(mapStateToProps, mapDispatchToProps)(DistillationGamePage);
