import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import authReducer from './auth_reducer';
import userReducer from './user_reducer';
import groupReducer from './group_reducer';
import companyReducer from './company_reducer';
import poolReducer from './pool_reducer';
import userPoolReducer from './userpool_reducer';
import statsReducer from './stats_reducer';
import achievementReducer from './achievement_reducer';
import gameServerReducer from './gameserver_reducer';
import highScoreReducer from './highscore_reducer';
import activeUserReducer from './activeusers_reducer';
import orderReducer from './order_reducer';
import tournamentReducer from './tournament_reducer';
import messageReducer from './message_reducer';
import settingReducer from './setting_reducer';

import { UNAUTH_USER } from '../actions/types';

const appReducer = combineReducers({
  form: formReducer,
  auth: authReducer,
  user: userReducer,
  pool: poolReducer,
  userPool: userPoolReducer,
  group: groupReducer,
  stats: statsReducer,
  achievements: achievementReducer,
  gameServer: gameServerReducer,
  highScores: highScoreReducer,
  activeUsers: activeUserReducer,
  orderReducer: orderReducer,
  tournamentReducer: tournamentReducer,
  company: companyReducer,
  message: messageReducer,
  setting: settingReducer,
});

const rootReducer = (state, action) => {
    if (action.type === UNAUTH_USER) {
        state = undefined
    }
    return appReducer(state, action)
};

export default rootReducer;
