import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getTournament, createTournament, updateTournament } from './actions/tournament';
import PropTypes from 'prop-types';
import { I18n } from 'react-i18nify';
import { getMissionListInfoSorted } from './helpersgames';

import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import { FormControlLabel, InputLabel, Paper } from '@material-ui/core';
import {Table, TableBody, TableRow, TableCell} from '@material-ui/core';

/**
 * Component enabling editing of a tournament
 */
class TournamentPanel extends Component {
    selectedRows = [];
    texts = {
        name: I18n.t("tournamentName"),
        startDate: I18n.t("tournamentStartDate"),
        endDate: I18n.t("tournamentEndDate"),
        realtimeStatsEnabled: I18n.t("tournamentRealtimeStatsEnabled"),
        missions: I18n.t("tournamentMissions"),
        lockedSpeed: I18n.t("tournamentLockedSpeed"),
        create: I18n.t("createTournament"),
        update: I18n.t("updateTournament"),
        header: I18n.t("tournamentEditorHeader")
    };

    constructor(props, context) {
        super(props, context);

        let start = new Date();
        let end = new Date();
        // Default length: 10 days
        end.setTime(end.getTime() + 10*24*60*60*1000);
        this.state = {
            tournament: null,
            pool: null,
            name: '',
            minDate: new Date(),
            startDate: start,
            endDate: end,
            realtimeStatsEnabled: false,
            missions: [],
            lockedSpeed: -1,
            missionChoices: [],
            bottomButtonText: this.texts.create,
            gameId : ""
        };
    }

    componentWillMount() {
        if (!this.props.pool) {
            return null;
        }

        const gameId = this.props.pool.games && this.props.pool.games.length > 0 ? this.props.pool.games[0] : "";
        this.setState({
            pool: this.props.pool,
            gameId: gameId,
            bottomButtonText: this.texts.create,
            missionChoices: getMissionListInfoSorted(gameId, I18n._localeKey)
        });

        var tourn = getTournament(this.props.pool._id)(this.handleTournamentFetchResult.bind(this));
    }

    handleTournamentFetchResult(result) {
        if (result.payload && result.payload._id) {
            let tourn = result.payload;

            this.setState({
                tournament: tourn,
                pool: tourn.pool,
                name: tourn.name,
                startDate: new Date(tourn.startDate),
                endDate: new Date(tourn.endDate),
                realtimeStatsEnabled: tourn.realtimeStatsEnabled,
                missions: tourn.missions.map(String),
                lockedSpeed: tourn.lockedSpeed,
                bottomButtonText: this.texts.update,
            });
        }
    }

    onBottomButtonClick() {
        // TODO: Find out if something breaks when user configures
        // this on a different date than the current UTC date
        this.state.startDate.setUTCHours(0, 0, 0, 0);
        this.state.endDate.setUTCHours(47, 59, 59, 0);

        if (!this.state.tournament) {
            this.props.createTournament({
                poolId: this.state.pool._id,
                name: this.state.name,
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                realtimeStatsEnabled: this.state.realtimeStatsEnabled.toString(),
                missions: this.state.missions.join(','),
                lockedSpeed: this.state.lockedSpeed || -1
            });
        } else {
            this.props.updateTournament({
                tournamentId: this.state.tournament._id,
                name: this.state.name,
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                realtimeStatsEnabled: this.state.realtimeStatsEnabled.toString(),
                missions: this.state.missions.join(','),
                lockedSpeed: this.state.lockedSpeed || -1
            });
        }
    }

    setStartDate(newValue) {
        if (this.state.endDate < newValue) {
            var newEndDate = new Date();
            newEndDate.setTime(newValue.valueOf() + 24*60*60*1000);
            this.setState({ startDate: new Date(newValue), endDate: newEndDate });
        } else {
            this.setState({ startDate: new Date(newValue) });
        }
    }

    onMissionTableRowSelection(clickedRow) {
        let selectedRows = this.state.missions;
        if (selectedRows.includes(clickedRow.missionId)) {
            selectedRows.splice(selectedRows.indexOf(clickedRow.missionId),1);
        } else {
            selectedRows.push(clickedRow.missionId);
        }
        this.setState({ missions: selectedRows });
    }

    render() {
        if (!this.state.pool) {
            return null;
        }
        const columnStyle = { paddingTop: 0, paddingBottom: 0, whiteSpace: "normal" };
        var tableRows = [];
        this.state.missionChoices.forEach((mission, index) => {
            tableRows.push(
            <TableRow key={index} className="low--table-row" onClick={e => this.onMissionTableRowSelection(mission)} 
                        selected={this.state.missions.indexOf(mission.missionId) >= 0}
                        style={columnStyle}>
                <TableCell className="low--table-row">
                    {mission.name}
                </TableCell>
            </TableRow>
            );        
        });
        return (
            <div>
                <h2 className="header2">{this.texts.header}</h2>
                <div className="tournament-controls--container">
                    <TextField label={this.texts.name} value={this.state.name}
                        onChange={e => this.setState({name: e.target.value})}/>
                    <KeyboardDatePicker label={this.texts.startDate} format="DD.MM.YYYY" value={this.state.startDate} minDate={this.state.minDate}
                        onChange={e => this.setStartDate(e)}/>
                    <KeyboardDatePicker label={this.texts.endDate} format="DD.MM.YYYY" value={this.state.endDate} minDate={this.state.startDate}
                        onChange={e => this.setState({endDate: new Date(e)})}/>
                    <FormControlLabel
                        label={this.texts.realtimeStatsEnabled}
                        control={
                            <Switch checked={this.state.realtimeStatsEnabled}
                                                onChange={(e, newValue) => {this.setState({realtimeStatsEnabled: newValue})}}/>
                        }
                    ></FormControlLabel>
                    
                    <InputLabel>{this.texts.lockedSpeed}</InputLabel>
                    <Select value={this.state.lockedSpeed}
                        // A bug in SelectField requires the newValue > 0 check
                        onChange={e => this.setState({lockedSpeed: e.target.value > 0 ? e.target.value : -1})}>
                        <MenuItem key={-1} value={-1}>Unlocked</MenuItem>
                        <MenuItem key={1} value={1}>1</MenuItem>
                        <MenuItem key={2} value={2}>2</MenuItem>
                        <MenuItem key={3} value={3}>3</MenuItem>
                        <MenuItem key={4} value={4}>4</MenuItem>
                        <MenuItem key={5} value={5}>5</MenuItem>
                        <MenuItem key={6} value={6}>6</MenuItem>
                    </Select>
                </div>
                <h3 className="header3">{this.texts.missions}</h3>
                <div className="tournament-controls--container table--container">
                    <Paper>
                        <Table className="low--table">
                            <TableBody>
                            { tableRows }
                            </TableBody>
                        </Table>
                    </Paper>
                    <Button variant="contained" onClick={this.onBottomButtonClick.bind(this)}>{this.state.bottomButtonText}</Button>
                </div>
            </div>
        );
    }
}



function mapStateToProps(state) {
    return {};
}

TournamentPanel.propTypes = {
    pool: PropTypes.object
};

export default connect(mapStateToProps, { getTournament, createTournament, updateTournament })(TournamentPanel);