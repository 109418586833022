import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { loginUser, getForgotPasswordToken } from './actions/auth';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Translate } from 'react-i18nify';
import ForgotPasswordDialog from './ForgotPasswordDialog';

const texts = {
    email: <Translate value="email" />,
    password: <Translate value="password" />,
    companyId: <Translate value="companyId" />,
    forgotPwd: <Translate value="forgotPassword" />,
    login: <Translate value="login" />,
    wrongEmailPassword: <Translate value="wrongEmailPassword" />,
    error: <Translate value="error" />,
    missingEmail: <Translate value="missingEmail" />,
};

/**
 * Login dialog component. Dialog uses Popover menu which is opened when title button is pressed.
 */
class LoginDialogElement extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            email: '',
            password: '',
            companyId: this.getCompanyFromUrl(),
            open: false,
        }
    }

    /**
     * Returns company id from companyId url parameter if defined
     * @returns {string} Company id. Empty if not defined
     */
    getCompanyFromUrl() {
        var url = decodeURI(location.href);
        var index = url.indexOf('?');
        if (index >= 0) {
            var param = url.substring(index + 1);
            var key = 'companyId=';
            var keyEnd = param.indexOf('companyId=') + key.length;
            if (keyEnd >= key.length) {
                return param.substring(keyEnd);
            }
        }
        return '';
    }

    /**
     * Handler for login button click event.
     * Send login action to back-end
     */
    onLoginClick() {
        this.props.loginUser({ email: this.state.email, password: this.state.password, company: this.state.companyId });
    }

    /**
     * Handler for forgot button click event.
     * Send login action to back-end
     */
    onForgotClick = () => {
        this.setState({open: false});
    }

    handleClickOpen = () => {
		this.setState({open: true});
    };

    handleCancel = () => {
		this.setState({open: false});
    };

    /**
     * Returns error message for error status.
     * @param errorMessage
     * @returns {string} Error message. Empty when there's no error
     */
    getErrorMessage(errorMessage) {
        if (errorMessage && errorMessage.status) {
            switch (errorMessage.status) {
                case 400:
                case 401:
                    if (errorMessage.data.message === "no_email_given") {
                        return;
                    } else {
                        return texts.wrongEmailPassword;
                    }
            }
            return texts.error + ' ' + errorMessage.status;
        }
        return '';
    }

    /**
     * Handler for password text field key press event.
     * Sends loginUser to server when enter key is pressed
     * @param event {object} Event
     */
    onKeyPress(event) {
        if (event.key === 'Enter') {
            this.props.loginUser({
                email: this.state.email,
                password: this.state.password,
                company: this.state.companyId
            });
        }
    }

    render() {

        const errorMessage = this.getErrorMessage(this.props.errorMessage);
        const style = { textTransform: "uppercase" };
        const style2 = {
            verticalAlign: 'baseline',
        };
        const buttonStyle = {
            fontSize: '12 !important',
        };

        return (
            <div className= "info-page--login-dialog" >
                <div className= "login-dialog-main">
                    <form>
                    <p className="login-dialog--text-main"> {texts.email} </p>
                    <TextField className="login-dialog--textfield-main"
                        InputProps={{ disableUnderline: true }}
                        autoComplete="email"
                        value={this.state.email}
                        onChange={e => this.setState({ email: e.target.value })}
                        error={errorMessage !== ""}
                        helperText={errorMessage} />
                    <p className="login-dialog--text-main"> {texts.password} </p>
                    <TextField className="login-dialog--textfield-main"
                        type="password"
                        autoComplete="current-password"
                        InputProps={{ disableUnderline: true }}
                        value={this.state.password}
                        onChange={e => this.setState({ password: e.target.value })}
                        onKeyPress={this.onKeyPress.bind(this)} />
                    </form>
                    <div className="login-dialog--button-container-main">
                        <Button className="btn login-dialog--login-button-main"
                            style={style2}
                            onClick={this.onLoginClick.bind(this)}>
                                <span style={buttonStyle}>{texts.login}</span>
                        </Button>
						<Button className="btn-no-border"
                            onClick={this.handleClickOpen.bind(this)}>
                                <span style={buttonStyle}>{texts.forgotPwd}</span>
                        </Button>
                        <ForgotPasswordDialog 
                            open={this.state.open} 
                            handleResetPassword={this.onForgotClick} 
                            handleCancel={this.handleCancel} 
                        />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        errorMessage: state.auth.error,
        message: state.auth.message,
        authenticated: state.auth.authenticated,
    };
}

export default withRouter(connect(mapStateToProps, { loginUser })(LoginDialogElement));
