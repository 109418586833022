import { SEARCH_COMPANY, ERROR_RESPONSE, CREATE_COMPANY, UPDATE_COMPANY, DELETE_COMPANY } from '../actions/types';

const INITIAL_STATE = { companySearchResults: null, message: '', error: '' };

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case CREATE_COMPANY:
            window.dispatchEvent(new CustomEvent('notification', {detail: {message: 'companyCreated'}}));
            return { ...state, companySearchResults: [] };
            break;
        case UPDATE_COMPANY:
            window.dispatchEvent(new CustomEvent('notification', {detail: {message: 'companyUpdated'}}));
            return { ...state, companySearchResults: [] };
            break;
        case ERROR_RESPONSE:
            return { ...state, error: action.payload };
        case SEARCH_COMPANY:
            return { ...state, companySearchResults: action.payload };
        case DELETE_COMPANY:
            window.dispatchEvent(new CustomEvent('notification', {detail: {message: 'companyDeleted'}}));
            return { ...state, companySearchResults: null };
    }
    return state;
}
