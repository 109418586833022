//= =====================
// Auth Actions
//= =====================
export const AUTH_USER = 'auth_user',
    AUTH_EXT_USER = 'auth_ext_user',
    REG_EXT_USER = 'reg_ext_user',
    REG_EXT_USER_COMPLETED = 'reg_ext_user_completed',
    UNAUTH_USER = 'unauth_user',
    AUTH_ERROR = 'auth_error',
    AUTH_USER_UPDATE = 'auth_user_update',
    FORGOT_PASSWORD_REQUEST = 'forgot_password_request',
    RESET_PASSWORD_REQUEST = 'reset_password_request',
    PROTECTED_TEST = 'protected_test',
    AUTH_REGISTER_INFO = 'auth_register_info',
    UPDATE_RIGHTS = 'update_rights',
    MAKE_ORDER = 'make_order',
    MAKE_CONTACT = 'make_contact',
    MAKE_ORDER_CONFIRMATION = 'make_order_confirmation',
    MAKE_OWC_REGISTERATION = 'make_owc_registeration',
    SKIP_TUTORIAL = 'skip_tutorial',
    NEW_TOKEN = 'new_token',
    FETCH_AGREEMENT_VERSION = 'fetch_agreement_version',
    UPDATE_USER_AGREEMENT = 'update_user_agreement';

//= =====================
// User Profile Actions
//= =====================
export const FETCH_USER = 'fetch_user',
    FETCH_USER_SEARCH_RESULT = 'fetch_user_search_result',
    SEARCH_USER = 'search_user',
    DELETE_USER = 'delete_user';

export const CREATE_COMPANY = 'create_company',
    UPDATE_COMPANY = 'update_company',
    SEARCH_COMPANY = 'search_company',
    DELETE_COMPANY = 'delete_company';

export const CREATE_POOL = 'create_pool',
    UPDATE_POOL = 'update_pool',
    SEARCH_POOL = 'search_pool',
    DELETE_POOL = 'delete_pool';

export const CREATE_TOURNAMENT = 'create_tournament',
    UPDATE_TOURNAMENT = 'update_tournament',
    GET_TOURNAMENTS = 'get_tournaments';

export const CREATE_ADMIN_MESSAGE = 'create_admin_message',
    GET_ADMIN_MESSAGE = 'get_admin_message',
    DELETE_ADMIN_MESSAGE = 'delete_admin_message';

export const INVITE_USER = 'invite_user',
    REMOVE_USER = 'remove_user',
    GET_POOL = 'get_pool',
    GET_ALL_POOLS = 'get_all_pools',
    GET_POOL_USERS = 'get_pool_users',
    PROMOTE_USER = 'promote_user',
    DEMOTE_USER = 'demote_user',
    POOL_CHANGE = 'pool_change';

export const CREATE_GROUP = 'create_group',
    GET_GROUP  = 'get_group',
    DELETE_GROUP = 'delete_group',
    ADD_USER_TO_GROUP = 'add_user_to_group',
    REMOVE_USER_FROM_GROUP = 'remove_user_from_group';

export const GET_STATS = 'get_stats',
    GET_REALTIME_STATS = 'get_realtime_stats',
    GET_OWC_STATS = 'get_owc_stats',
    GET_OWC_ROUND = 'get_owc_round',
    GET_OWC_INFO = 'get_owc_info',
    GET_STATS_MISSION = 'get_stats_mission',
    GET_STATS_MISSION_POOL = 'get_stats_mission_pool',
    APPROVE_SCORE = 'approve_score',
    EXTERNAL_SAVE_STARTED = 'external_save_started',
    EXTERNAL_SAVE_COMPLETE = 'external_save_complete',
    EXTERNAL_SAVE_ERROR = 'external_save_error',
    RENDER_STATS_PDF = 'render_stats_pdf';

export const GET_ACHIEVEMENTS = 'get_achievements';

export const GET_GAMESERVERS = 'get_gameservers',
    GET_GAMESERVERS_ADMIN_INFO = 'get_gameservers_admininfo',
    TOGGLE_GAMESERVER_SHUTDOWN_REQUEST = 'toggle_gameserver_shutdown_request';

export const GET_HIGHSCORES = 'get_highscores',
    GET_USERIDLIST_HIGHSCORES = 'get_useridlist_highscores',
    GET_BESTSCORES = 'get_bestscores',
    GET_HIGHSCORE_SUM = 'get_highscores_sum',
    GET_HIGHSCORE_GAME_TOP10 = 'get_highscore_game_top10',
    GET_HIGHSCORES_USER = 'get_highscores_user',
    GET_HIGHSCORES_USER_GAME = 'get_highscores_user_game';

export const GET_ACTIVE_USERS = 'get_activeusers';

//= =====================
// Page Actions
//= =====================
export const STATIC_ERROR = 'static_error',
    ADMIN_SEARCH_SELECT = 'admin_search_select',
    MISSION_PLAYED = 'mission_played';

export const ERROR_RESPONSE = 'error_response';
