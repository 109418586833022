import React, {Component, useState} from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button';
import { Translate, I18n } from 'react-i18nify';


/**
 * Modal select pool dialog component.
 * Cannot be closed or cancelled.
 */
class SelectPoolDialog extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            selectedIndex: null,
            lastPoolIndex: null
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.currentCompany && props.allCompanies) {
            const selectedIndex = props.allCompanies.findIndex(item => item.name === props.currentCompany.name);
            if (state.lastPoolIndex !== selectedIndex) {
                return{
                    selectedIndex: selectedIndex, 
                    lastPoolIndex: state.selectedIndex
                };
            } 
        }

        // Return null to indicate no change to state.
        return null;
    }

    /**
     * Handler for confirm and cancel button pressed event.
     * Calls closeHandler prop.
     */
    onButtonClick() {
        this.props.closeHandler(this.props.allCompanies[this.state.selectedIndex]);
    }

    onPoolSelected(index) {
        this.setState({
            selectedIndex: index,
        });
    }

    getCompanyList() {
        return this.props.allCompanies || [];
    }

    render() {
        const validPools = this.props.allCompanies && this.props.allCompanies.length > 0;

        let defaultValue = 0;
        let companies = this.getCompanyList().map((company, index) => {
            if (this.props.currentCompany && this.props.currentCompany._id === company._id) {
                defaultValue = index;
            }
            return <MenuItem key={index} value={index} 
                        className={index === this.state.selectedIndex ? "menu-item--selected" : ""}
                        onClick={this.onPoolSelected.bind(this, index)}>
                        {company.name}
                    </MenuItem>
        });

        if (companies.length === 0) {
            companies = <Translate value="missingCompany"/>;
        }

        return (
            <div>
                <Dialog open={this.props.open}>
                    <DialogTitle disableTypography={true} style={{padding:"10px 24px", color:"rgba(0,0,0,0.6)"}}>
                        {<Translate value="selectCompany" tag="h3"/>}
                    </DialogTitle>
                    <MenuList className="menu-list--selectCompany">
                        {companies}
                    </MenuList>
                    {this.props.message}
                    <Button onClick={this.onButtonClick.bind(this)} disabled={!validPools}>{<Translate value="ok"/>}</Button>
                </Dialog>
            </div>
        );
    }
}

SelectPoolDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    closeHandler: PropTypes.func.isRequired
};

SelectPoolDialog.defaultProps = {
    open: false
};

export default SelectPoolDialog;