
import axios from 'axios';
import Cookies from 'universal-cookie';
import { API_URL, errorHandler } from './index';
import { ERROR_RESPONSE, CREATE_POOL, UPDATE_POOL, SEARCH_POOL, DELETE_POOL, STATIC_ERROR } from './types';

const cookies = new Cookies();

export function createPool({ name, seats, playerLimit, games, end, admin, company }) {
    return function (dispatch) {
        axios.post(`${API_URL}/pool/create`, { name, seats, playerLimit, games, end, admin, company }, {headers: { Authorization: cookies.get('token') }})
            .then((response) => {
                dispatch({ type: CREATE_POOL, payload: response });
            })
            .catch((error) => {
                errorHandler(dispatch, error.response, ERROR_RESPONSE);
            });
    };
}

export function updatePool(poolId, { name, seats, playerLimit, games, end, admin, company, previousStartDate, currentStartDate }) {
    return function (dispatch) {
        axios.post(`${API_URL}/pool/update/${poolId}`, { name, seats, playerLimit, games, end, admin, company, previousStartDate, currentStartDate }, {headers: { Authorization: cookies.get('token') }})
            .then((response) => {
                dispatch({ type: UPDATE_POOL, payload: response });
            })
            .catch((error) => {
                errorHandler(dispatch, error.response, ERROR_RESPONSE);
            });
    };
}

export function searchPools(searchValue) {
    return function (dispatch) {
        const urlEnd = searchValue ? 'search/' + encodeURIComponent(searchValue) : 'list/all';

        axios.get(`${API_URL}/pool/${urlEnd}`, {headers: { Authorization: cookies.get('token') },})
            .then((response) => {
                dispatch({
                    type: SEARCH_POOL,
                    payload: response.data.results,
                });
            })
            .catch((error) => {
                errorHandler(dispatch, error, STATIC_ERROR);
            });
    };
}

export function deletePool(poolId) {
    return function (dispatch) {
        axios.delete(`${API_URL}/pool/${poolId}`, {headers: { Authorization: cookies.get('token') },})
            .then((response) => {
                dispatch({
                    type: DELETE_POOL,
                    payload: response.data
                });
            })
            .catch((error) => {
                errorHandler(dispatch, error, STATIC_ERROR);
            });
    };
}
