import { GET_TOURNAMENTS, ERROR_RESPONSE, CREATE_TOURNAMENT, UPDATE_TOURNAMENT } from '../actions/types';

const INITIAL_STATE = { tournament: {}, message: '', error: '' };

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case CREATE_TOURNAMENT:
            window.dispatchEvent(new CustomEvent('notification', {detail: {message: 'tournament_created'}}));
            break;
        case UPDATE_TOURNAMENT:
            window.dispatchEvent(new CustomEvent('notification', {detail: {message: 'tournament_updated'}}));
            break;
        case ERROR_RESPONSE:
            return { ...state, error: action.payload };
        case GET_TOURNAMENTS:
            return { ...state, tournament: action.payload };
        default:
            return state;
    }
}
