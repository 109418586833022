import React, { Component } from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import { connect } from 'react-redux';
import { removeUserFromPool, inviteUser } from './actions/userpool';
import { Translate, I18n } from 'react-i18nify';

import AccountAddPanel from './AccountAddPanel';
import AccountListItem from './AccountListItem';
import ConfirmDialog from './ConfirmDialog';

/**
 * User list component in account page
 */
class UserList extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            confirmRemoveHandler: null,
            inputValid: true
        }
    }

    /**
     * Handler for add user button pressed
     * @param value
     */
    onAddItem(email, sendInvEmail) {
        this.props.inviteUser(this.props.pool._id, {email: email, admin: false, sendInvEmail: sendInvEmail});
    }

    /**
     * Handler for remove user button pressed.
     * Sets confirmRemoveHandler state which will open confirm dialog
     * @param userId
     */
    onRemoveItem(userId) {
        this.setState({
            confirmRemoveHandler: this.onConfirmClosed.bind(this, userId)
        });
    }

    /**
     * Handler for confirm dialog closed event.
     * Sends remove user from pool request for user id if confirm is true.
     * Sets confirmRemoveHanler to null which will close the confirm dialog.
     * @param userId {string} Id of removed user
     * @param confirm {boolean} True if confirmed, false if canceled.
     */
    onConfirmClosed(userId, confirm) {
        if (confirm === true && userId) {
            this.props.removeUserFromPool(this.props.pool._id, {user: userId});
        }
        this.setState({
            confirmRemoveHandler: null
        });
    }

    /**
     * Parses userData prop and returns list of AccountListItem components for users
     * @returns {*} AccountListItem list
     */
    getListItems() {
        if (this.props.userData.length <= 0) {
            return <AccountListItem key={0} textHigh={<Translate value="noUsersAssigned"/>}/>
        } else {
            return this.props.userData.map((user, index) =>
                <AccountListItem key={index} textLow={user.name} textHigh= {(user.nick == undefined ? I18n.t('notSetText')  : user.nick) + " - " + user.email} icon="person" isRemovable={!user.poolAdmin}
                                 buttonPressHandler={this.onRemoveItem.bind(this, user._id)}/>
            );
        }
    }

    /**
     * Validates email list and checks that there's room in user pool.
     * Returns error message or empty string if input is valid.
     * @param input {string} Input
     * @returns {string} Error message. Empty if input is valid.
     */
    addUserValidator(input) {
        const emails = input.split(/[\s,;]+/);
        let emailsValid = true;
        let emailCount = 0;
        //Check all emails are valid
        for (let i = 0; i < emails.length; i++) {
            let splitEmail = emails[i].trim();
            if (splitEmail.length > 0) {
                emailCount++;
                if (!splitEmail.includes('@')) {
                    emailsValid = false;
                }
            }
        }

        const availableSeats = this.props.maxCount - this.props.userData.length;

        //No errors for empty input
        if (input.length > 0) {
            if (emailCount > availableSeats) {
                this.setState({inputValid: false});
                return I18n.t('errorTooManyUsers', {seats: availableSeats, userCount: emailCount});
            } else if (emailsValid === false) {
                this.setState({inputValid: false});
                return I18n.t('errorInvalidEmail');
            }
        }

        this.setState({inputValid: true});
        return '';
    }

    render() {
        const texts = {
            title: <Translate value="users"/>,
            addUserTitle: <Translate value="addUser"/>,
            textFieldLabel: <Translate value="userEmail"/>,
            button: <Translate value="send"/>,
            confirmTitle: <Translate value="confirm" tag="h3"/>,
            confirmMessage: <Translate value="confirmRemoveUser"/>,
            confirmCancel: <Translate value="cancel"/>,
            confirmRemove: <Translate value="remove"/>,
            emailHint: I18n.t('emailHint')
        };

        return (
            <div className="account-container">
                <div className="user-list--title-container">
                    <h2 className="header2 user-list--title">{texts.title}</h2>
                    <h2 className="header2 user-list--count">
                        {this.props.userData.length + "/" + this.props.maxCount}
                    </h2>
                </div> 
                <AccountAddPanel title={texts.addUserTitle} textFieldLabel={texts.textFieldLabel}
                                 buttonLabel={texts.button} addButtonPressHandler={this.onAddItem.bind(this)}
                                 inputValidator={this.addUserValidator.bind(this)}
                                 showInvitationEmailCheckbox={true} blockCollapsed={true}
                                 buttonDisabled={!this.state.inputValid} hintText={texts.emailHint}/>       
                <List className="account-list">
                    {this.getListItems()}
                </List>
                <ConfirmDialog open={this.state.confirmRemoveHandler !== null} title={texts.confirmTitle}
                               message={texts.confirmMessage}
                               cancel={texts.confirmCancel} confirm={texts.confirmRemove}
                               closeHandler={this.state.confirmRemoveHandler}
                               isDeleteAction={true}/>
            </div>
        );
    }
}

UserList.propTypes = {
    maxCount: PropTypes.number.isRequired,
    userData: PropTypes.array,
    pool: PropTypes.object
};

function mapStateToProps(state) {
    return {};
}

export default connect(mapStateToProps, {inviteUser, removeUserFromPool})(UserList);
