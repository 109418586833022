import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, Link, NavLink } from "react-router-dom"
import GettingStarted from './userguides/GettingStarted';
import PlayingMission from './userguides/PlayingMission';
import YourAccount from './userguides/YourAccount';
import GamePortals from './userguides/GamePortals';
import LearningObjectives from './userguides/LearningObjectives';
import AdministratorInstructions from './userguides/AdministratorInstructions';
import Troubleshooting from './userguides/Troubleshooting';

const gettingStarted = <div>Section 1 - Getting Started</div>;
const playingMission = <div>Section 2 - Playing a mission</div>;
const yourAccount = <div>Section 3 - Your Account</div>;
const gamePortal = <div>Section 4 - Game Portal</div>;
const learningObjectives = <div>Section 5 - Learning Objectives</div>;
const administratorInstructions = <div>Section 6 - Administrator Instructions</div>;
const troubleshooting = <div>Section 7 - Troubleshooting</div>;
const highlighted = { fontWeight: "bold", color: "black"};

class UserGuidePage extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
          visible: false,
        };

        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
    }

    handleMouseDown(e) {
      this.toggleMenu();
      e.stopPropagation();
    }

    toggleMenu() {
      this.setState({
          visible: !this.state.visible
      });
    }

    render() {
        return (
          <div >
            <MobileMenu handleMouseDown={this.handleMouseDown} menuVisibility={this.state.visible}/>
            <MenuButton handleMouseDown={this.handleMouseDown}/>
            <DesktopMenu/>
          </div>
        );
    }
}

class MenuButton extends Component {
  render() {
    return (
      <button className="button-menu-round" onMouseDown={this.props.handleMouseDown}></button>
    );
  }
}

class DesktopMenu extends Component{
  render() {
    return (
      <div className="wrapper-userguide">
        <div className="sidebar-userguide">
          <h2>USER GUIDE</h2>
          <hr className="sidebar-userguide-line"/>
          <NavLink activeStyle={highlighted} exact to={`/userguide`}>{gettingStarted}</NavLink>
          <NavLink activeStyle={highlighted} to={`/userguide/playingMission`}>{playingMission}</NavLink>
          <NavLink activeStyle={highlighted} to={`/userguide/yourAccount`}>{yourAccount}</NavLink>
          <NavLink activeStyle={highlighted} to={`/userguide/gamePortal`}>{gamePortal}</NavLink>
          <NavLink activeStyle={highlighted} to={`/userguide/learningObjectives`}>{learningObjectives}</NavLink>
          <NavLink activeStyle={highlighted} to={`/userguide/administratorInstructions`}>{administratorInstructions}</NavLink>
          <NavLink activeStyle={highlighted} to={`/userguide/troubleshooting`}>{troubleshooting}</NavLink>   
        </div>
        <div className="main-userguide">
          <Route exact path={`/userguide`} component={GettingStarted}/>
          <Route path={`/userguide/playingMission`} component={PlayingMission}/>
          <Route path={`/userguide/yourAccount`}component={YourAccount}/>
          <Route path={`/userguide/gamePortal`} component={GamePortals}/>
          <Route path={`/userguide/learningObjectives`} component={LearningObjectives}/>
          <Route path={`/userguide/administratorInstructions`} component={AdministratorInstructions}/>
          <Route path={`/userguide/troubleshooting`} component={Troubleshooting}/>
        </div>
      </div>
    );
  }
}

class MobileMenu extends Component {
  render() {
    var visibility = "flyout-menu hide";
 
    if (this.props.menuVisibility) {
      visibility = "flyout-menu show";
    }
    return (      
        <div className={visibility}>
             <h2>USER GUIDE</h2>
              <hr className="sidebar-userguide-line"/>
              <NavLink activeStyle={highlighted} exact to={`/userguide`}>{gettingStarted}</NavLink>
              <NavLink activeStyle={highlighted} to={`/userguide/playingMission`}>{playingMission}</NavLink>
              <NavLink activeStyle={highlighted} to={`/userguide/yourAccount`}>{yourAccount}</NavLink>
              <NavLink activeStyle={highlighted} to={`/userguide/gamePortal`}>{gamePortal}</NavLink>
              <NavLink activeStyle={highlighted} to={`/userguide/learningObjectives`}>{learningObjectives}</NavLink>
              <NavLink activeStyle={highlighted} to={`/userguide/administratorInstructions`}>{administratorInstructions}</NavLink>
              <NavLink activeStyle={highlighted} to={`/userguide/troubleshooting`}>{troubleshooting}</NavLink>   
        </div>  
    );
  }
}

function mapStateToProps(state) {
    return {
        authenticated: state.auth.authenticated
    };
}

export default connect(mapStateToProps, {})(UserGuidePage);