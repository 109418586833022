
import { GET_HIGHSCORES, ERROR_RESPONSE, GET_HIGHSCORE_SUM, POOL_CHANGE, GET_BESTSCORES,  GET_HIGHSCORE_GAME_TOP10, GET_HIGHSCORES_USER, GET_HIGHSCORES_USER_GAME } from '../actions/types';

const INITIAL_STATE = { data: [], message: '', error: '', highscore: '', gameTop10: []};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case ERROR_RESPONSE:
            return { ...state, error: action.payload };
        case GET_HIGHSCORES:
            return { ...state, data: action.payload.highscores };
        case  GET_HIGHSCORE_GAME_TOP10:
            return { ...state, gameTop10: action.payload };
        case GET_HIGHSCORE_SUM:
            return { ...state, totalPoints: action.payload.sum };
        case GET_BESTSCORES:
            return { ...state, bestScores: action.payload };
        case GET_HIGHSCORES_USER:
            return { ...state, bestMissionScores: action.payload };
        case GET_HIGHSCORES_USER_GAME:
            return { ...state, gameUserScore: action.payload };
        case POOL_CHANGE:
            return { ...state, data: [], totalPoints: null };
    }
    return state;
}