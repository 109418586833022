import { AUTH_USER, AUTH_EXT_USER, REG_EXT_USER, REG_EXT_USER_COMPLETED, UNAUTH_USER, AUTH_ERROR, FORGOT_PASSWORD_REQUEST, RESET_PASSWORD_REQUEST,
    AUTH_USER_UPDATE, ERROR_RESPONSE, AUTH_REGISTER_INFO, NEW_TOKEN } from '../actions/types';
import { TOKEN_EXPIRATION_TIME } from '../Main.js'
import { sendDataToIframe } from '../Helpers';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const INITIAL_STATE = { error: '', message: '', content: '', authenticated: false };

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case AUTH_USER:
            saveToken(action.payload.token);
            sendDataToIframe('token', { token: action.payload.token }, true);
            return { ...state, error: '', message: '', authenticated: true, isRegisterRequired: false };
        case AUTH_EXT_USER:
            saveToken(action.payload.token);
            sendDataToIframe('token', { token: action.payload.token }, true);
            return { ...state, error: '', message: '', authenticated: true, isRegisterRequired: action.payload.registerRequired,
                addedPool: action.payload.pool, mission: action.payload.mission };
        case REG_EXT_USER:
            saveToken(action.payload.token);
            sendDataToIframe('token', { token: action.payload.token }, true);
            return { ...state, error: '', message: '', authenticated: true, registrationCompleted: false, tempProfile: action.payload.user, isRegisterRequired: true,
                addedPool: action.payload.pool, mission: action.payload.mission };
        case REG_EXT_USER_COMPLETED:
            saveToken(action.payload.token);
            sendDataToIframe('token', { token: action.payload.token }, true);
            return { ...state, error: '', message: '', authenticated: true, registrationCompleted: true, addedPool: action.payload.pool, mission: action.payload.mission };
        case UNAUTH_USER:
            const msg = 'session_expired';
            if (action.payload === msg) {
                window.dispatchEvent(new CustomEvent('notification', { detail: { message: 'session_expired' } }));
            }
            return { ...state, authenticated: false, error: action.payload };
        case AUTH_USER_UPDATE:
            window.dispatchEvent(new CustomEvent('notification', { detail: { message: 'account_info_updated' } }));
            return state;
        case AUTH_ERROR:
            return { ...state, error: action.payload };
        case AUTH_REGISTER_INFO:
            return { ...state, registerEmail: action.payload };
        case FORGOT_PASSWORD_REQUEST:
            const message = action.payload.reinvite === true ? 'reinvite_sent' : 'password_reset_sent';
            window.dispatchEvent(
                new CustomEvent('notification', { detail: { message: message, email: action.payload.email } })
            );
            return state;
        case RESET_PASSWORD_REQUEST:
            window.dispatchEvent(new CustomEvent('notification', { detail: { message: 'password_reset' } }));
            return { ...state, message: action.payload };
        case ERROR_RESPONSE:
            return { ...state, error: action.payload };
        case NEW_TOKEN:
            saveToken(action.payload.token);
            sendDataToIframe('token', { token: action.payload.token }, true);
            return state;  // compared to auth_user, does not set authenticated value
        default:
            return state;
    }
}

function saveToken(token) {
    cookies.set('token', token, {
        path: '/',
        secure: location.protocol.indexOf('https') === 0,
        maxAge: TOKEN_EXPIRATION_TIME // seconds
    });
    cookies.get('token'); // firefox fix, without becomes undefined when renewing
}
