import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import Icon from '@material-ui/core/Icon';
import { List, Collapse } from '@material-ui/core';

/**
 * Collapsible component which shows its children components only when in open state
 */
class CollapsePanel extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            //Open state is used only for detecting whether to show + or - toggle icon
            open: props.initiallyOpen
        };
    }
    
    componentDidUpdate(prevProps) {
        if (this.state.open && prevProps.closeWhenChangedData && this.props.closeWhenChangedData) {
            for (let i = 0; i < prevProps.closeWhenChangedData.length; i++) {
                if (prevProps.closeWhenChangedData[i] !== this.props.closeWhenChangedData[i]) {
                    this.props.onPanelOpen();
                    break;
                }
            }
        }
    }
    
    componentDidMount() { 
        const isRenderPdf = window.location.href.indexOf("openMissions") !== -1;
        if (isRenderPdf) {
            // has to be called to ensure the data is drawn to stats pdf
            this.props.onPanelOpen(this);
        }
    }

    /**
     * Returns toggle icon. Returns empty icon if panel is disabled
     * @returns {FontIcon} Toggle icon
     */
    getToggleIcon() {
        let icon;
        if (this.props.disabled === true) {
            icon = '';
        } else if (this.state.open === false) {
            icon = 'add';
        } else {
            icon = 'remove';
        }
        return <Icon className="material-icons collapse-panel--toggle">{icon}</Icon>;
    }

    /**
     * Handler for nested list toggled.
     * Updates this.state.open to detect if input is visible or not.
     * @param event
     */
    onNestedListToggle(event) {
        this.setState({
            open: !this.state.open
        });
        this.props.onPanelOpen(event);
    }

    /**
     * Returns children components inside ListItem
     * @returns {ListItem} Children components in ListItem
     */
    getNestedItems() {
        return [
            <ListItem key={0} className="collapse-panel--content" disabled={true}>
                {this.props.children}
            </ListItem>
        ];
    }

    render() {
        const listItemClass = 'collapse-panel--header' + (this.props.disabled === true ?
                ' collapse-panel--header-disabled' : '');
        const circleClass = 'item--circle ' + this.props.colorClass;

        const mainClassName = "collapse-panel statistics-container" + (this.props.pageBreak ? " page-break" : "");

        return (
            <div className={mainClassName}>
                <ListItem className={listItemClass}
                          key={this.props.primaryText}
                          onClick={this.onNestedListToggle.bind(this)}
                          disabled={this.props.disabled}>
                    <div className={circleClass} />
                    <div className="collapse-panel--container">
                        <div className="collapse-panel--text">
                            <div className="collapse-panel--text-primary"> {this.props.primaryText}</div>
                            {this.props.secondaryText}
                        </div>
                        {this.getToggleIcon()}
                    </div>
                </ListItem>
                <Collapse in={this.state.open}>
                    <List className="nested">
                        {this.getNestedItems()}
                    </List>
                </Collapse>
            </div>
        );
    }
}

CollapsePanel.propTypes = {
    disabled: PropTypes.bool,
    initiallyOpen: PropTypes.bool,
    primaryText: PropTypes.node,
    secondaryText: PropTypes.node,
    colorClass: PropTypes.string,
    pageBreak: PropTypes.bool,
};

CollapsePanel.defaultProps = {
    disabled: false,
    initiallyOpen: false,
    primaryText: '',
    secondaryText: '',
    colorClass: '',
    pageBreak: false
};

export default CollapsePanel;
