
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { makeOrder, makeContactRequest, makeCorfirmOrderMail } from './actions/index';
import { Translate } from 'react-i18nify';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import PageHeader from './PageHeader';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import gamesData from 'games.json';
import LegalDialog from './LegalDialog';
import { DialogActions, DialogTitle, DialogContent, Paper } from '@material-ui/core';

const orderPrices = {
    0: 0,
    10: 480,
    25: 440,
    50: 300,
    100: 240,
    500: 180,
    1000: 120
  };

const orderUsers = ["0", 10, 25, 50, 100, 500, 1000];

const orderUsersFurnace = ["0", 10, 25, 50, 100, 500, 1000];

const countries = ["",
'Albania',
'Algeria',
'Andorra',
'Angola',
'Antigua and Barbuda',
'Argentina',
'Armenia',
'Australia',
'Austria',
'Azerbaijan',
'Bahamas',
'Bahrain',
'Bangladesh',
'Barbados',
'Belgium',
'Belize',
'Benin',
'Bhutan',
'Bolivia',
'Bosnia and Herzegovina',
'Botswana',
'Brazil',
'Brunei',
'Bulgaria',
'Burkina Faso',
'Cabo Verde',
'Cambodia',
'Cameroon',
'Canada',
'Chad',
'Chile',
'China',
'Colombia',
'Comoros',
'Republic of the Congo',
'Costa Rica',
'Cote d Ivoire',
'Croatia',
'Cyprus',
'Czech Republic',
'Denmark',
'Djibouti',
'Dominica',
'Dominican Republic',
'Ecuador',
'Egypt',
'El Salvador',
'Equatorial Guinea',
'Estonia',
'Ethiopia',
'Fiji',
'Finland',
'France',
'Gabon',
'Gambia',
'Georgia',
'Germany',
'Ghana',
'Greece',
'Grenada',
'Guatemala',
'Guyana',
'Haiti',
'Honduras',
'Hungary',
'Iceland',
'India',
'Indonesia',
'Ireland',
'Italy',
'Jamaica',
'Japan',
'Jordan',
'Kazakhstan',
'Kenya',
'Kiribati',
'Kosovo',
'Kuwait',
'Kyrgyzstan',
'Laos',
'Latvia',
'Lesotho',
'Liberia',
'Liechtenstein',
'Lithuania',
'Luxembourg',
'Macedonia',
'Madagascar',
'Malawi',
'Malaysia',
'Maldives',
'Mali',
'Malta',
'Marshall Islands',
'Mauritania',
'Mauritius',
'Mexico',
'Micronesia',
'Moldova',
'Monaco',
'Mongolia',
'Montenegro',
'Morocco',
'Mozambique',
'Namibia',
'Nauru',
'Nepal',
'Netherlands',
'New Zealand',
'Nicaragua',
'Niger',
'Nigeria',
'Norway',
'Oman',
'Pakistan',
'Palau',
'Palestine',
'Panama',
'Papua New Guinea',
'Paraguay',
'Peru',
'Philippines',
'Poland',
'Portugal',
'Qatar',
'Romania',
'Rwanda',
'Saint Kitts and Nevis',
'Saint Lucia',
'Saint Vincent and the Grenadines',
'Samoa',
'San Marino',
'Sao Tome and Principe',
'Saudi Arabia',
'Senegal',
'Serbia',
'Seychelles',
'Sierra Leone',
'Slovakia',
'Slovenia',
'Solomon Islands',
'South Africa',
'South Korea',
'Spain',
'Sri Lanka',
'Suriname',
'Swaziland',
'Sweden',
'Switzerland',
'Taiwan',
'Tajikistan',
'Tanzania',
'Thailand',
'Timor-Leste',
'Togo',
'Tonga',
'Trinidad and Tobago',
'Turkey',
'Turkmenistan',
'Tuvalu',
'Uganda',
'United Arab Emirates',
'United Kingdom',
'United States of America',
'Uruguay',
'Uzbekistan',
'Vanuatu',
'Vatican City',
'Vietnam',
'Zambia'
];

export class ContactDialog extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            open: this.props.open || false, //prop for open to be true as default
            company: '',
            companyContact: '',
            email: '',
            phone: '',
            message: this.props.defaultText || '', //prop for a default text in dialog message box
            clickedOnce: false,
            confirmOpen: false, //additional confirmation dialog of successfully sent contact request
        };
    }


    onContactRequest(company, name, email, phone, message) {
        var phoneOrEmpty = phone || 'empty';
        this.props.makeContactRequest({
            company: company,
            companyContact: name,
            email: email,
            phone: phoneOrEmpty,
            message: message,
        });
    }
    determineErrorText(value) {
        var errorText = 'This field is required.';
        if (value.length > 0 || this.state.clickedOnce === false) {
            errorText = '';
        }
        return errorText;
    }

    /**
     * Returns true if all text fields are valid
     * @returns {boolean}
     */
    verifyInputs() {
        return this.state.company.length > 0 && this.state.companyContact.length > 0 && this.state.email.length > 0 && this.state.message.length > 0;
    }

    handleOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    onSubmitClick = () => {
        if (this.verifyInputs()) {
            this.props.closeHandler(this.state.company, this.state.companyContact, this.state.email, this.state.phone, this.state.message);
            this.setState({ open: false,
                            company: '',
                            companyContact: '',
                            email: '',
                            phone: '',
                            message: this.props.defaultText || '',
                            });
            
        }
        else {
            this.setState({ clickedOnce: true });
            //window.dispatchEvent(new CustomEvent('notification', { detail: { message: 'Please_fill_all_required_fields' } }));
        }
    };

    render() {
        const texts = {
            contact: <Translate value="contact" />,
            cancel: <Translate value="cancel" />,
            send: <Translate value="send" />,
            company: <Translate value="company" />,
			organization: <Translate value="organization" />,
            name: <Translate value="name" />,
            email: <Translate value="email" />,
            phone: <Translate value="phone" />,
            message: <Translate value="message" />,
			contactForm: <Translate value="contactForm" tag="h3" />,
            confirmMessage: <Translate value="confirmMessage"/>,
            confirmTitle: <Translate value="confirmTitle"/>,
            contactFormDescription: <Translate value="contactFormDescription"/>
	};
		const companization = <div>{texts.company} / {texts.organization}</div>;

        const actions = [

            <Button key={texts.cancel.props.value}
                className="btn-no-border"
                style={{margin: "0 10px"}}
                onClick={this.handleClose}
            >{texts.cancel}</Button>,
            <Button key={texts.send.props.value}
                className="btn-filled"
                style={{margin: "0 10px"}}
                onClick={this.onSubmitClick}
            >{texts.send}</Button>,

        ];
        const fieldStyle = { backgroundColor: '#eaf3fa', margin: '1em 0', borderTopRightRadius: '5px' };
        return (

            <div>
                <Button className="btn login-dialog--login-button-main" onClick={this.handleOpen}>
                    {this.props.labelText ? this.props.labelText : "Contact us"}
                </Button>
                <Dialog
                    fullScreen={this.props.fullScreen ? this.props.fullScreen : false}
                    className="order-width"
                    open={this.state.open}
                    onClose={this.handleClose}
                    PaperComponent={Paper}
                    PaperProps={{className: "legal-body"}}
                >
                    <DialogTitle disableTypography={true} className="legal-title">{texts.contactForm}</DialogTitle>
                    <DialogContent className="legal-dialog" style={ this.props.fullScreen ? null : { maxWidth: '450px' }}>
                        <p className="contact-form-subtitle no-margin">{texts.contactFormDescription}</p>
                        <form>
                            <TextField className="title-blue"
                                label={companization}
                                autoComplete="organization"
                                fullWidth={true}
                                multiline
                                InputLabelProps={{
                                    shrink: true,
                                    style: {paddingLeft: '0.25em' },
                                }}
                                InputProps={{ disableUnderline: true }}
                                rows={1}
                                rowsMax={1}
                                style={fieldStyle}
                                value={this.state.company}
                                error={this.determineErrorText(this.state.company) !== ""}
                                helperText={this.determineErrorText(this.state.company)}
                                onChange={e => this.setState({ company: e.target.value })} />
                            <TextField className="title-blue"
                                label={texts.name}
                                fullWidth={true}
                                autoComplete="name"
                                InputLabelProps= {{
                                    shrink: true,
                                    style: {paddingLeft: '0.25em' },
                                }}
                                InputProps={{ disableUnderline: true }}
                                multiline
                                rows={1}
                                rowsMax={1}
                                style={fieldStyle}
                                value={this.state.companyContact}
                                error={this.determineErrorText(this.state.companyContact) !== ""}
                                helperText={this.determineErrorText(this.state.companyContact)}
                                onChange={e => this.setState({ companyContact: e.target.value })} />
                            <TextField className="title-blue"
                                label={texts.email}
                                fullWidth={true}
                                autoComplete="email"
                                InputLabelProps= {{
                                    shrink: true,
                                    style: {paddingLeft: '0.25em' },
                                }}
                                InputProps={{ disableUnderline: true }}
                                multiline
                                rows={1}
                                rowsMax={1}
                                style={fieldStyle}
                                value={this.state.email}
                                error={this.determineErrorText(this.state.email) !== ""}
                                helperText={this.determineErrorText(this.state.email)}
                                onChange={e => this.setState({ email: e.target.value })} />
                            <TextField className="title-blue"
                                label={texts.phone}
                                fullWidth={true}
                                autoComplete="tel"
                                InputLabelProps= {{
                                    shrink: true,
                                    style: {paddingLeft: '0.25em' },
                                }}
                                InputProps={{ disableUnderline: true }}
                                multiline
                                rows={1}
                                rowsMax={1}
                                style={fieldStyle}
                                value={this.state.phone}
                                onChange={e => this.setState({ phone: e.target.value })} />
                        </form>
                        <TextField className="title-blue"
                            label={texts.message}
                            fullWidth={true}
                            multiline
                            InputLabelProps= {{
                                shrink: true,
                                style: {paddingLeft: '0.25em' },
                            }}
                            InputProps={{ disableUnderline: true }}
                            defaultValue={this.props.defaultText ? this.props.defaultText : null}
                            rows={5}
                            rowsMax={10}
                            style={fieldStyle}
                            /*value={this.state.message}*/
                            error={this.props.defaultText === null || this.determineErrorText(this.state.message) !== ""}
                            helperText={this.props.defaultText ? null : this.determineErrorText(this.state.message)}
                            onChange={e => this.setState({ message: e.target.value })} />
                        </DialogContent>
                        <DialogActions style={{padding: '14px'}}>{actions}</DialogActions>
                </Dialog>

            </div>
        );
    }
}

export class OrderPage extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            numOfUsersDistiller: orderUsers[0],
            numOfUsersFurnace: orderUsersFurnace[0],
            mainAdminEmail: '',
            companyId: '',
            company: '',
            companyContact: '',
            address: '',
            postalCode: '',
            city: '',
            country: countries[0],
            billingCountry: countries[0],
            billingCity: '',
            billingPostalCode: '',
            email: '',
            phone: '',
            billingAddress: '',
            PoNumber: '',
            customerRef: '',
            VatId: '',
            busRegNum: '',
            agreementConfirmed: false,
            clickedOnce: false,
            orderInfoDialogOpen: false,
            sameDetails: false,
            checked: false,
            distillerDisabled: false,
            furnaceDisabled: false,
            checked2: false,
            manyUsers1: false,
            manyUsers2: false,
            total: 0,
            totalDistiller: 0,
            totalFurnace: 0,

        };
    }

    componentDidMount() {
        this.onNotificationCallback = this.onNotification.bind(this);
        window.addEventListener('notification', this.onNotificationCallback);
    }
    /*
    shouldComponentUpdate(prevProps) {
        if (this.props.authenticated !== prevProps.authenticated){
            this.updateDistillerValues();
        }
    }
    */
    componentWillUnmount() {
        window.removeEventListener('notification', this.onNotificationCallback);
        this.onNotificationCallback = null;
    }

    /**
     * Handler for notification event.
     * Clears text field when order_created message is received
     * @param event
     */
    onNotification(event) {
        if (event.detail.message === 'order_created') {
            this.setState({
                mainAdminEmail: '',
                companyId: '',
                company: '',
                companyContact: '',
                address: '',
                postalCode: '',
                city: '',
                billingAddress: '',
                PoNumber: '',
                customerRef: '',
                email: '',
                phone: '',
                VatId: '',
                busRegNum: '',

            });
        }
        else if (event.detail.message === 'contact_request_created') {
            
        }
    }


    handleDialogOpen = () => {
        this.setState({ orderInfoDialogOpen: true });
    };

    handleDialogClose = () => {
        this.setState({ orderInfoDialogOpen: false });
    };
    theSameDetails() {
        if (!this.state.checked) {
            this.setState({ billingCity: this.state.city, billingCountry: this.state.country, billingAddress: this.state.address, billingPostalCode: this.state.postalCode })
        } else {
            this.setState({ billingCity: "", billingCountry: countries[0], billingAddress: "", billingPostalCode: "" })
        }
        this.setState({ checked: !this.state.checked });
    };

    onContinueClick() {
        if (this.verifyInputs()) {
            this.setState({ clickedOnce: false });
            var phoneOrEmpty = this.state.phone || 'empty';
            var PoNumberOrEmpty = this.state.PoNumber || 'empty';
            var customerRefOrEmpty = this.state.customerRef || 'empty';
            var VatIdOrEmpty = this.state.VatId || 'empty';
            var billingCountry = this.state.country || this.state.billingCountry;
            this.props.makeOrder({
                numOfUsersDistiller: this.state.numOfUsersDistiller,
                numOfUsersFurnace: this.state.numOfUsersFurnace,
                mainAdminEmail: this.state.mainAdminEmail,
                companyId: this.state.companyId,
                company: this.state.company,
                VatId: VatIdOrEmpty,
                busRegNum: this.state.busRegNum,
                address: this.state.address,
                postalCode: this.state.postalCode,
                city: this.state.city,
                country: this.state.country,
                companyContact: this.state.companyContact,
                email: this.state.email,
                phone: phoneOrEmpty,
                billingAddress: this.state.billingAddress,
                billingPostalCode: this.state.billingPostalCode,
                billingCity: this.state.billingCity,
                billingCountry: this.state.billingCountry,
                PoNumber: PoNumberOrEmpty,
                customerRef: customerRefOrEmpty,
            });
            this.props.makeCorfirmOrderMail({
                email: this.state.email,
            });
            this.handleDialogOpen();
        }
        else {
            this.setState({ clickedOnce: true });
            //window.dispatchEvent(new CustomEvent('notification', { detail: { message: 'Please_fill_all_required_fields' } }));
        }
    }
    getGames = () => {
        if (this.props.userPool && !this.props.company) {
            return [];
        }

        const pools = this.props.allPools || (this.props.userPool ? [this.props.userPool] : null);
        if (!pools || !this.props.company) {
            return [];
        }
        const result = [];
        pools
            .filter(userPool => (userPool.pool.company && userPool.pool.company._id === this.props.company._id))
            .forEach(function (userPool) {
                userPool.pool.games.forEach(function (gameName) {
                    result.push(gameName);
                });
            });
        return result;
    }
    getUsersItems() {
        let items = [];
        //orderUsers determined in index.html
        for (var i = 0; i < orderUsers.length; i++) {
            items.push(<MenuItem key={items.length} value={orderUsers[i]}>{orderUsers[i]}</MenuItem>);
        }
        return items;
    }
    getUsersItemsFurnace() {
        let items = [];
        //orderUsers determined in index.html
        for (var i = 0; i < orderUsersFurnace.length; i++) {
            items.push(<MenuItem key={items.length} value={orderUsersFurnace[i]}>{orderUsers[i]}</MenuItem>);
        }
        return items;
    }

    getCountries() {
        let items = [];
        for (var i = 0; i < countries.length; i++) {
            items.push(<MenuItem key={items.length} value={countries[i]}>{countries[i]}</MenuItem>);
        }
        return items;
    }

    /**
    * Handler for submit contact request
    * @param company
    * @param name
    * @param email
    * @param phone
    * @param message
    */
    onContactRequest(company, name, email, phone, message) {
        var phoneOrEmpty = phone || 'empty';
        this.props.makeContactRequest({
            company: company,
            companyContact: name,
            email: email,
            phone: phoneOrEmpty,
            message: message,
        });
    }
    detectMob = () => {
        if (window.innerWidth <= 857) {
            return true;
        } else {
            return false;
        }

    };


    // Updates the license prices when distiller licenses are selected from dropdown menu
    updateDistillerValues(value) {
        this.setState({numOfUsersDistiller: value });
        var licensesOwned = this.getGames();
        var hasDistiller = licensesOwned.includes('distiller');
        var hasFurnace = licensesOwned.includes('furnace');
        var totalCentsDistiller = value * orderPrices[value] * 100;
        var totalCentsFurnace = (this.state.numOfUsersFurnace) * orderPrices[this.state.numOfUsersFurnace] * 100;

        this.setState({ total: Math.round((totalCentsDistiller + totalCentsFurnace) / 100) });
        this.setState({ totalDistiller: Math.round((totalCentsDistiller) / 100) });
        this.setState({ totalFurnace: Math.round((totalCentsFurnace) / 100) });

    }
    // Does the same thing for furnace license menu
    updateFurnaceValues(value) {
        this.setState({ numOfUsersFurnace: value });
        var licensesOwned = this.getGames();
        var hasDistiller = licensesOwned.includes('distiller');
        var hasFurnace = licensesOwned.includes('furnace');
        var totalCentsFurnace = value * orderPrices[value] * 100;
        var totalCentsDistiller = (this.state.numOfUsersDistiller) * orderPrices[this.state.numOfUsersDistiller] * 100;


        this.setState({ total: Math.round((totalCentsDistiller + totalCentsFurnace) / 100) });
        this.setState({ totalFurnace: Math.round((totalCentsFurnace) / 100) });
        this.setState({ totalDistiller: Math.round((totalCentsDistiller) / 100) });

    }

    determineErrorText(value) {
        const texts = {
            requiredField: <Translate value="requiredField" />,
        };
        var errorText = texts.requiredField;
        if (value.length > 0 || this.state.clickedOnce === false) {
            errorText = '';
        }
        return errorText;
    }

    determineErrorTextForEmail(value) {
        const texts = {
            requiredField: <Translate value="requiredField" />,
            notValidEmail: <Translate value="notValidEmail" />,
        };

        var errorText = texts.requiredField;
        if (value.length > 0 || this.state.clickedOnce === false) {
            errorText = '';
        }
        if (value.length > 0 && !value.trim().includes('@')) {
            errorText = texts.notValidEmail;
        }
        return errorText;
    }

    scrollToOrderForm = () => {
        const element = document.getElementById("toOrder");
        if (element) element.scrollIntoView();
    }
    /**
     * Returns true if all text fields are valid
     * @returns {boolean}
     */
    verifyInputs() {
        return (this.state.numOfUsersDistiller > 0 || this.state.numOfUsersFurnace > 0) && this.state.mainAdminEmail.length > 0 && this.state.country !== "" && this.state.billingCountry !== "" && this.state.companyId.length > 0 && this.state.company.length > 0 && this.state.companyContact.length > 0 &&
            this.state.address.length > 0 && this.state.postalCode.length > 0 && this.state.billingPostalCode.length > 0 && this.state.billingCity.length > 0 && this.state.city.length > 0 && this.state.email.length > 0 && this.state.billingAddress.length > 0
            && this.state.busRegNum.length > 0 && this.state.email.trim().includes('@') && this.state.mainAdminEmail.trim().includes('@');
    }
    render() {


        const theLabelStyle = {
            color: '#8fb9ce', overflow: 'keep-all', textOverflow: 'hidden', whiteSpace: 'nowrap'
        }
        const mobileDec = this.detectMob() ?
            "License name (E.G. Company name)"
            :
            "License name (E.G. Company name, plant, business unit)";
        const texts = {
            contactDescription: <Translate value="contactDescription" />,
            distiller: <Translate value="distiller" />,
            distillerOneYear: <Translate value="distillerOneYear" />,
            numOfUsers: <Translate value="numOfUsers" />,
            tax: <Translate value="addedTax" />,
            taxDescription: <Translate value="taxDescription" />,
            adminEmailTitle: <Translate value="adminEmail" />,
            adminEmailDescription: <Translate value="adminEmailDescription" />,
            confirmDescription: <Translate value="orderConfirmDescription" />,
            mainAdminEmail: <Translate value="mainAdminEmail" />,
            licenseName: <Translate value="licenseName" />,
            billingInfo: <Translate value="billingInfo" />,
            company: <Translate value="company" />,
            VatId: <Translate value="VatId" />,
            name: <Translate value="contactPerson" />,
            address: <Translate value="address" />,
            postalCode: <Translate value="postalCode" />,
            city: <Translate value="city" />,
            country: <Translate value="country" />,
            billingAddress: <Translate value="billingAddress" />,
            billingPostalCode: <Translate value="billingPostalCode" />,
            billingCity: <Translate value="billingCity" />,
            billingCountry: <Translate value="billingCountry" />,
            PoNumber: <Translate value="PoNumber" />,
            customerRef: <Translate value="customerRef" />,
            email: <Translate value="contactPersonEmail" />,
            phone: <Translate value="contactPersonPhone" />,
            continue: <Translate value="continue" />,
            agree: <Translate value="agreePolicy" />,
            and: <Translate value="agreePolicyAnd" />,
            privacyPolicy: <Translate value="privacyPolicy" />,
            termsOfService: <Translate value="termsOfService" />,
            agreementDescription: <Translate value="agreementDescription" />,
            supportMail: <Translate value="supportEmail" />,
            serviceDescription: <Translate value="serviceDescription" />,
            subscriptionTermsAndConditions: <Translate value="subscriptionTermsAndConditions" />,
            countryInfo: <Translate value="countryInfo" />,
            confirmText: <Translate value="confirmText" />,
            usersDescription: <Translate value="usersDescription" />,
            busRegNum: <Translate value="busRegNum" />,
            newOrderSuccessMessage: <Translate value="newOrderSuccessMessage" />,
            contactMessage: <Translate value="contactMessage" />,
            ok: <Translate value="ok" />,
        };

        const privacyPolicyLink = <LegalDialog content="privacyPolicy" />;
        const termsOfServiceLink = <LegalDialog content="termsOfService" />;
        const serviceDescriptionLink = <LegalDialog content="serviceDescription" />;
        const subscriptionTermsAndConditionsLink = <LegalDialog content="subscriptionTermsAndConditions" />;
        const buttonDisabled = this.state.agreementConfirmed === false;

        const continueButton = this.state.agreementConfirmed ?
            <Button className="order-btn-ok info-page--button btn-filled color-white"
                onClick={this.onContinueClick.bind(this)}>
                    {texts.continue}
                </Button>
            :
            <Button className="order-btn info-page--button color-disabled"
                disabled={true}>
                {texts.continue}
            </Button>;

        const actions = [
            <Button
                label={texts.ok}
                primary={true}
                onClick={this.handleDialogClose}
            />,
        ];
        const licensesOwned = this.getGames();
        const hasDistiller = licensesOwned.includes('distiller');
        const hasFurnace = licensesOwned.includes('furnace');
        const discount = hasDistiller || hasFurnace || (this.state.numOfUsersFurnace > 1 && this.state.numOfUsersDistiller > 1) ?
        0.8
        :
        1;
        const priceDistiller =
        Math.round(this.state.totalDistiller);

        const priceFurnace =
        Math.round(this.state.totalFurnace);

        const priceTotal =
        Math.round(this.state.total * discount);

        const discountAmount =
        Math.round(this.state.total - priceTotal);

        const discountTotal = discount === 1 || this.state.numOfUsersDistiller > 499 || this.state.numOfUsersFurnace > 499 ?
        ''
        :
        <Translate className="orange" value="euroSum" sum={"Discount:" + " " + "-"+discountAmount} />;


        const orderSum = this.state.numOfUsersDistiller > 499 || this.state.numOfUsersFurnace > 499 ?
            "Ask for price"
            :
            <Translate value="euroSum" sum={priceTotal} />;

        const distillerSum = this.state.numOfUsersDistiller > 499 || this.state.numOfUsersDistiller < 1 || this.state.numOfUsersFurnace > 499 ?
            ''
            :
            <Translate value="euroSum" sum={"Distiller:" + " " + priceDistiller} />;

        const furnaceSum = this.state.numOfUsersFurnace > 499 || this.state.numOfUsersFurnace < 1 || this.state.numOfUsersDistiller > 499 ?
            ''
            :
            <Translate value="euroSum" sum={"Furnace:" + " " + priceFurnace} />;

        const toOrderButton =
            <div className="margin-left-20 order-margin-40">
                <Button className="btn login-dialog--login-button-main" onClick={this.scrollToOrderForm}>Order</Button>
            </div>;

        const orderInfoDialog =
            <Dialog
                modal={false}
                open={this.state.orderInfoDialogOpen}
                onClose={this.handleDialogClose}>
                <h1 className="color-black order-page--header line-height-3 font-18 margin-b-20 padding-left nothing order-add-line"> Thank You! </h1>
                <p className="title-blue line-height-3-5 nothing font-14 order-padding-right padding-left">{texts.newOrderSuccessMessage}</p>
                <DialogActions>
                    {actions}
                </DialogActions>
            </Dialog>;
        const addressCheckBox =
            <Checkbox
                label="Same as company details"
                labelStyle={{ color: '#8fb9ce', overflow: 'keep-all', textOverflow: 'hidden', whiteSpace: 'nowrap' }}
                className="nothing title-blue font-10 margin-b-5 textfields"
                iconStyle={{ border: 'none', color: '8fb9ce', width: '20px', height: '20px', marginRight: '0px', fill: '#eaf3fa' }}
                onCheck={(e, newValue) => this.theSameDetails()}
            />;

        const contactButton =
                <div className="order--contact-button contact-button"><ContactDialog closeHandler={this.onContactRequest.bind(this)} /></div>;

        const landingSection =
            <div className="order-page--section less-height">
                <div className="order-page--text-wrapper-big">
                    <div>
                        <h1 className="color-black info-page--header stretch add-line">NAPCON GAMES</h1>
                        <h1 className="color-blue info-page--header stretch margin-0">Start your interactive training now!</h1>
                        <div className="order-page--text-small">
                            <p className="no-margin"> Thank You for being interested in purchasing NAPCON Games products. If you are interested for a free trial of our products, please contact us.</p>
                            <p className="no-margin"> If you have any specific enquiries or questions about the process or our products, please contact us.</p>
                            <div className="top-flex">
                                {contactButton}
                                {toOrderButton}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="order-page--background image5 float-right hide">
                </div>
            </div>;

        const furnaceDropDown =
            <div className="">
                <h4 className="nothing title-blue">Furnace licenses</h4>
                <Select className="drop-menu left-float"
                    value={this.state.numOfUsersFurnace}
                    onChange={e => this.updateFurnaceValues(e.target.value)}
                    labelStyle={{ height: '36px', lineHeight: '36px' }}
                    underlineStyle={{ borderTop: '0px', bottom: '0px' }}
                    iconStyle={{ right: '0px', padding: '1px', height: '36px', top: '1px' }}
                    disabled={this.state.furnaceDisabled}
                >
                    {this.getUsersItemsFurnace()}
                </Select>
            </div>;

        const gameOrderSection =
            <div className="order-page--section">
                <div className="order-page--text-wrapper">
                    <div>
                        <h1 className="color-black order-page--header stretch add-line">License Amount</h1>
                        <div classname="content">
                            <div classname="text-container">
                            </div>
                        </div>
                    </div>
                </div>
            </div>;
        const discountText = "20% discount will be applied to orders if Your company has previously purchased licenses to our products. <br /> To enable the discount automatically, please log in with the licensee account. <br /> Discount is also applied to new customers who order both licenses."
        const prices = this.state.totalDistiller > 0 || this.state.totalFurnace > 0 ?
            <div className="inline-grid">
            <div className="left-float margin-top-10">
                {distillerSum}
                <Divider className="no-bg" />
                {furnaceSum}
                <Divider className="no-bg" />
                {discountTotal}
                <Divider className="width-120 black-line" />
                Total: {orderSum}
            </div>
            <div className="order-text-container margin-top-10 font-10">
                Value added tax (VAT) applicable in your country may be added to the price.
            </div>
            </div>
        :
        '';
        const licenseSection = <div className="item odd">
            <div className="order-page--text-wrapper">
                <div>
                    <h1 className="color-black order-page--header stretch add-line title-black">License Amount</h1>
                    <div className="adjust inline-grid">
						<div className="order-text-container">
							If your company has previously purchased licenses to our products, you will receive <span className="orange">20% discount</span> of your next order. <br /> To enable the discount automatically, please log in with the licensee account and select the amount of licenses. <br /> Discount is also applied to new customers who order game licenses to multiple games.
                            <h4 className="nothing title-blue order-margin-top">Distiller licenses</h4>
                            <Select className="drop-menu"
                                value={this.state.numOfUsersDistiller}
                                onChange={e => this.updateDistillerValues(e.target.value)}
                                labelStyle={{ height: '36px', lineHeight: '36px' }}
                                underlineStyle={{ borderTop: '0px', bottom: '0px' }}
                                iconStyle={{ right: '0px', padding: '1px', height: '36px', top: '1px' }}
                                disabled={this.state.distillerDisabled}
                            >
                                {this.getUsersItems()}
                            </Select>
                            {furnaceDropDown}
                        </div>
                        {prices}
                    </div>
                </div>
            </div>
        </div>;

        const adminSection =
            <div className="item even">
                <div >
                    <div className="order-page--text-wrapper">
                        <div>
                            <h1 className="color-black order-page--header stretch add-line title-blue"> Administrator email </h1>
                            <div className="adjust">
                                <div className="order-text-container">
                                    Administrator email is used for creating and managing player accounts
                        </div>
                                <form>
                                    <TextField className="title-blue textfields"
                                        floatingLabelText="Main Administrator email"
                                        fullWidth={true}
                                        autoComplete="email"
                                        floatingLabelStyle={theLabelStyle}
                                        underlineShow={false}
                                        multiline
                                        floatingLabelFixed={true}
                                        rows={1}
                                        rowsMax={1}
                                        textareastyle={fieldStyle}
                                        value={this.state.mainAdminEmail}
                                        error={this.determineErrorText(this.state.mainAdminEmail) !== ""}
                                        helperText={this.determineErrorText(this.state.mainAdminEmail)}
                                        onChange={e => this.setState({ mainAdminEmail: e.target.value })} />
                                    <TextField className="title-blue textfields"
                                        label={mobileDec}
                                        fullWidth={true}
                                        autoComplete="organization"
                                        floatingLabelClassName="no-wrap"
                                        floatingLabelStyle={theLabelStyle}
                                        underlineShow={false}
                                        multiline
                                        floatingLabelFixed={true}
                                        rows={1}
                                        rowsMax={1}
                                        textareastyle={fieldStyle}
                                        value={this.state.companyId}
                                        error={this.determineErrorText(this.state.companyId) !== ""}
                                        helperText={this.determineErrorText(this.state.companyId)}
                                        onChange={e => this.setState({ companyId: e.target.value })} />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>;

        const between =
            <div id="clear" />;

        const companyDetails =
            <div className="item odd">
                <div className="order-page--text-wrapper more-z-index">
                    <div>
                        <h1 className="color-black order-page--header stretch add-line title-blue"> Company Details </h1>
                        <div className="adjust">
                            <form>
                                <TextField className="title-blue textfields"
                                    floatingLabelText="Company name"
                                    fullWidth={true}
                                    autoComplete="organization"
                                    floatingLabelStyle={theLabelStyle}
                                    underlineShow={false}
                                    multiline
                                    floatingLabelFixed={true}
                                    rows={1}
                                    rowsMax={1}
                                    textareastyle={fieldStyle}
                                    value={this.state.company}
                                    error={this.determineErrorText(this.state.company) !== ""}
                                    helperText={this.determineErrorText(this.state.company)}
                                    onChange={e => this.setState({ company: e.target.value })} />
                                <TextField className="title-blue textfields"
                                    floatingLabelText="Business registration number"
                                    fullWidth={true}
                                    floatingLabelStyle={theLabelStyle}
                                    underlineShow={false}
                                    multiline
                                    floatingLabelFixed={true}
                                    rows={1}
                                    rowsMax={1}
                                    textareastyle={fieldStyle}
                                    value={this.state.busRegNum}
                                    error={this.determineErrorText(this.state.busRegNum) !== ""}
                                    helperText={this.determineErrorText(this.state.busRegNum)}
                                    onChange={e => this.setState({ busRegNum: e.target.value })} />
                                <TextField className="title-blue textfields"
                                    floatingLabelText="VAT ID (optional)"
                                    fullWidth={true}
                                    floatingLabelStyle={{ color: '#8fb9ce' }}
                                    underlineShow={false}
                                    multiline
                                    floatingLabelFixed={true}
                                    rows={1}
                                    rowsMax={1}
                                    textareastyle={fieldStyle}
                                    value={this.state.VatIdOrEmpty}
                                    onChange={e => this.setState({ VatId: e.target.value })} />
                                <TextField className="title-blue textfields"
                                    floatingLabelText="Address"
                                    fullWidth={true}
                                    floatingLabelStyle={{ color: '#8fb9ce' }}
                                    underlineShow={false}
                                    multiline
                                    floatingLabelFixed={true}
                                    rows={1}
                                    rowsMax={1}
                                    textareastyle={fieldStyle}
                                    value={this.state.address}
                                    error={this.determineErrorText(this.state.address) !== ""}
                                    helperText={this.determineErrorText(this.state.address)}
                                    onChange={e => this.setState({ address: e.target.value })} />
                                <div className="flex">
                                    <div className="left-float small-width margin-20">
                                        <TextField className="title-blue textfields"
                                            floatingLabelText="Postal Code"
                                            fullWidth={false}
                                            floatingLabelStyle={{ color: '#8fb9ce' }}
                                            underlineShow={false}
                                            multiline
                                            floatingLabelFixed={true}
                                            rows={1}
                                            rowsMax={1}
                                            textareaStyle={{ backgroundColor: '#eaf3fa', width: '90px' }}
                                            value={this.state.postalCode}
                                            error={this.determineErrorText(this.state.postalCode) !== ""}
                                            helperText={this.determineErrorText(this.state.postalCode)}
                                            onChange={e => this.setState({ postalCode: e.target.value })} />
                                    </div>
                                    <div className="small-width margin-20">
                                        <TextField className="title-blue textfields"
                                            floatingLabelText="City"
                                            fullWidth={false}
                                            floatingLabelStyle={{ color: '#8fb9ce' }}
                                            underlineShow={false}
                                            multiline
                                            floatingLabelFixed={true}
                                            rows={1}
                                            rowsMax={1}
                                            textareaStyle={{ backgroundColor: '#eaf3fa', width: '90px' }}
                                            value={this.state.city}
                                            error={this.determineErrorText(this.state.city) !== ""}
                                            helperText={this.determineErrorText(this.state.city)}
                                            onChange={e => this.setState({ city: e.target.value })} />
                                    </div>
                                    <div>
                                        <h4 className="just-after title-blue font-12">Country</h4>
                                        <Select className="drop-menu-country title-blue"
                                            value={this.state.country}
                                            onChange={e => this.setState({ country: e.target.value })}
                                            labelStyle={{ height: '24px', lineHeight: '24px', paddingLeft: '5px' }}
                                            underlineStyle={{ borderTop: '0px', bottom: '0px' }}
                                            iconStyle={{ right: '0px', padding: '1px', width: '30px', height: '20px', top: '1px' }}
                                            error={this.determineErrorText(this.state.country) !== ""}
                                            helperText={this.determineErrorText(this.state.country)}
                                        >
                                            {this.getCountries()}
                                        </Select>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>;
        const contactPerson =
            <div className="item even">
                <div className="order-page--text-wrapper">
                    <div>
                        <h1 className="color-black order-page--header stretch add-line title-blue no-break"> Contact person details</h1>
                        <div className="adjust">
                            <form>
                                <TextField className="title-blue textfields"
                                    floatingLabelText="Contact person name"
                                    fullWidth={true}
                                    autoComplete="name"
                                    floatingLabelStyle={theLabelStyle}
                                    underlineShow={false}
                                    multiline
                                    floatingLabelFixed={true}
                                    rows={1}
                                    rowsMax={1}
                                    textareastyle={fieldStyle}
                                    value={this.state.companyContact}
                                    error={this.determineErrorText(this.state.companyContact) !== ""}
                                    helperText={this.determineErrorText(this.state.companyContact)}
                                    onChange={e => this.setState({ companyContact: e.target.value })} />
                                <TextField className="title-blue textfields"
                                    floatingLabelText="Contact person email"
                                    fullWidth={true}
                                    autoComplete="email"
                                    floatingLabelStyle={theLabelStyle}
                                    underlineShow={false}
                                    multiline
                                    floatingLabelFixed={true}
                                    rows={1}
                                    rowsMax={1}
                                    textareastyle={fieldStyle}
                                    value={this.state.email}
                                    error={this.determineErrorText(this.state.email)}
                                    helperText={this.determineErrorText(this.state.email)}
                                    onChange={e => this.setState({ email: e.target.value })} />
                                <TextField className="title-blue textfields"
                                    floatingLabelText="Contact person phone (optional)"
                                    fullWidth={true}
                                    autoComplete="tel"
                                    floatingLabelStyle={theLabelStyle}
                                    underlineShow={false}
                                    multiline
                                    floatingLabelFixed={true}
                                    rows={1}
                                    rowsMax={1}
                                    textareastyle={fieldStyle}
                                    value={this.state.phoneOrEmpty}
                                    onChange={e => this.setState({ phone: e.target.value })} />
                                <div className="flex height-10 order-margin-top textfields">
                                    <div>
                                        <h4 className="nothing title-blue float-left margin-60 font-12">Billing address</h4>
                                    </div>
                                    <div className="small-width">
                                        {addressCheckBox}
                                    </div>
                                </div>
                                <TextField className="title-blue nothing textfields"
                                    floatingLabelText=""
                                    fullWidth={true}
                                    autoComplete="address"
                                    floatingLabelStyle={{ color: '#8fb9ce' }}
                                    underlineShow={false}
                                    multiline
                                    floatingLabelFixed={true}
                                    rows={1}
                                    rowsMax={1}
                                    textareastyle={fieldStyle}
                                    value={this.state.billingAddress}
                                    error={this.determineErrorText(this.state.billingAddress) !== ""}
                                    helperText={this.determineErrorText(this.state.billingAddress)}
                                    onChange={e => this.setState({ billingAddress: e.target.value })} />
                                <div className="flex">
                                    <div className="left-float small-width margin-20">
                                        <TextField className="title-blue textfields"
                                            floatingLabelText="Postal Code"
                                            fullWidth={false}
                                            floatingLabelStyle={{ color: '#8fb9ce' }}
                                            underlineShow={false}
                                            multiline
                                            floatingLabelFixed={true}
                                            rows={1}
                                            rowsMax={1}
                                            textareaStyle={{ backgroundColor: '#eaf3fa', width: '90px' }}
                                            value={this.state.billingPostalCode}
                                            error={this.determineErrorText(this.state.postalCode) !== ""}
                                            helperText={this.determineErrorText(this.state.postalCode)}
                                            onChange={e => this.setState({ billingPostalCode: e.target.value })} />
                                    </div>
                                    <div className="small-width margin-20">
                                        <TextField className="title-blue textfields"
                                            floatingLabelText="City"
                                            fullWidth={false}
                                            floatingLabelStyle={{ color: '#8fb9ce' }}
                                            underlineShow={false}
                                            multiline
                                            floatingLabelFixed={true}
                                            rows={1}
                                            rowsMax={1}
                                            textareaStyle={{ backgroundColor: '#eaf3fa', width: '90px' }}
                                            value={this.state.billingCity}
                                            error={this.determineErrorText(this.state.city) !== ""}
                                            helperText={this.determineErrorText(this.state.city)}
                                            onChange={e => this.setState({ billingCity: e.target.value })} />
                                    </div>
                                    <div>
                                        <h4 className="just-after title-blue font-12">Country</h4>
                                        <Select className="drop-menu-country title-blue"
                                            value={this.state.billingCountry}
                                            onChange={e => this.setState({ billingCountry: e.target.value })}
                                            labelStyle={{ height: '24px', lineHeight: '24px', paddingLeft: '5px' }}
                                            underlineStyle={{ borderTop: '0px', bottom: '0px' }}
                                            iconStyle={{ right: '0px', padding: '1px', width: '30px', height: '20px', top: '1px' }}
                                        >
                                            {this.getCountries()}
                                        </Select>
                                    </div>
                                </div>
                                <TextField className="title-blue textfields"
                                    floatingLabelText="Purchase order number (optional)"
                                    fullWidth={true}
                                    floatingLabelStyle={theLabelStyle}
                                    underlineShow={false}
                                    multiline
                                    floatingLabelFixed={true}
                                    rows={1}
                                    rowsMax={1}
                                    textareastyle={fieldStyle}
                                    value={this.state.PoNumberOrEmpty}
                                    onChange={e => this.setState({ PoNumber: e.target.value })} />
                                <TextField className="title-blue textfields"
                                    floatingLabelText="Customer reference number (optional)"
                                    fullWidth={true}
                                    floatingLabelStyle={theLabelStyle}
                                    underlineShow={false}
                                    multiline
                                    floatingLabelFixed={true}
                                    rows={1}
                                    rowsMax={1}
                                    textareastyle={fieldStyle}
                                    value={this.state.customerRefOrEmpty}
                                    onChange={e => this.setState({ customerRef: e.target.value })} />
                            </form>
                            <Checkbox
                                iconStyle={{ border: 'none', color: '8fb9ce', width: '20px', height: '20px', marginRight: '0px', fill: '#eaf3fa' }}
                                label={
                                    <div className="order-page--legal-checkbox-text">
                                        <span>{texts.agree} </span>
                                        {serviceDescriptionLink}, {subscriptionTermsAndConditionsLink}, {privacyPolicyLink},
                                        <span> {texts.and} </span>
                                        {termsOfServiceLink}.
                           <span> {texts.confirmText}</span>

                                    </div>}
                                // Necessary hack to enable clicking links in the label
                                labelStyle={{ zIndex: 3 }}
                                onCheck={(e, newValue) => this.setState({ agreementConfirmed: newValue })} />
                            <div className="order--button-container button-adjust order-button-adjust">
                                {continueButton}
                                {orderInfoDialog}
                            </div>
                        </div>
                    </div>
                </div>
            </div>;
        const orderSection =
            <div className="order-page--section more-height">
                <div className="order-grid-section">
                    <div id="toOrder" className="order-grid height-600">
                        {licenseSection}
                        {adminSection}
                    </div>
                    <div id="divider"/>
                    <div className="order-grid">
                        {companyDetails}
                        {contactPerson}
                    </div>
                </div>
            </div>;
        return (
            <div className="order-page page-container">
                {landingSection}
                {orderSection}
            </div>

        );
    }
}


ContactDialog.propTypes = {
    closeHandler: PropTypes.func
};

function mapStateToProps(state) {
    return {
        authenticated: state.auth.authenticated,
        userPool: state.userPool.currentPool,
        allPools: state.userPool.allPools,
        currentCompany: state.userPool.currentCompany,
        company: state.userPool.currentCompany
    };
}

export default withRouter(connect(mapStateToProps, { makeOrder, makeContactRequest, makeCorfirmOrderMail })(OrderPage));
