import { I18n } from 'react-i18nify';
import moment from 'moment';

const localizationUrl = require('./localization.json');

/**
 * Localization object creates translation tables and sets current language.
 *
 * Use setLocale to change language.
 * <Translate/> and <Localize/> components are automatically re-rendered when language changes.
 * I18n.t(key) and I18n.l(key) can be used to get translated and localized values but re-rendering components must be
 * done manually when using these functions.
 */
export default {
    initialize(callback, lan) {

        // Load translations JSON
        const that = this;
        const xmlHttp = new XMLHttpRequest();
        lan = lan || "en";

        xmlHttp.onreadystatechange = function() {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    try {
                        const localization = JSON.parse(this.responseText);
                        I18n.setTranslations(localization.translations);
                        moment.updateLocale('en', {
                            months: localization.monthTranslations.en
                        });
                        moment.updateLocale('fi', {
                            months: localization.monthTranslations.fi
                        });
                        moment.updateLocale('tr', {
                            months: localization.monthTranslations.tr
                        });

                        that.setLocale(lan);
                    }
                    catch (e) {
                        console.log(e);
                        console.log('Parsing translations failed');
                    }
                }

                // Ready to render using the translations now
                if (callback) {
                    callback();
                }
            }
        }

        xmlHttp.open("GET", localizationUrl, true);
        xmlHttp.send();
    },

    setLocale(langCode) {
        moment.locale(langCode);
        I18n.setLocale(langCode);
    }
}
