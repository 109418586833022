
const gameListData = require('./../../server/gamedata/games.json');
const gameNames = Object.keys(gameListData.games);
const defaultGame = gameListData.defaultGame;

const gameData = {};
for (let i = 0; i < gameNames.length; i++) {
    let gameName = gameNames[i];
    gameData[gameName] = require('./../../server/gamedata/' + gameName + '.json');
}

const allAchievements = {};
const achievements = {};
for (let game in gameData) {
    achievements[game] = gameData[game].achievements;

    for (let achName in gameData[game].achievements) {
        if (!allAchievements.hasOwnProperty(achName)) {
            allAchievements[achName] = gameData[game].achievements[achName];
        }
        else {
            console.log("Cannot use same achievement name in multiple games:", achName);
        }
    }
}

function getGameDataTranslation(gameId, lang, key) {
    if (!gameData.hasOwnProperty(gameId) || !gameData[gameId].localization.hasOwnProperty(lang) || !gameData[gameId].localization[lang].hasOwnProperty(key)) {
        console.log("Missing translation:",gameId, lang, key);
        return "";
    }
    return gameData[gameId].localization[lang][key];
}

/**
 * Return missionlist, order determined by json activeMissions
 * @param gameId
 * @param lang
 * @returns {Array}
 */
function getMissionListInfoSorted(gameId, lang) {
    if (!gameData.hasOwnProperty(gameId) || !gameData[gameId].missions) {
        return [];
    }

    const result = [];
    let missionsIndex = 0;
    gameData[gameId].missions.forEach(mission => {
        let index = missionsIndex++;
        if(gameData[gameId].activeMissions)
            index = gameData[gameId].activeMissions.indexOf(Number(mission.id));
        if (index !== -1) {
            result[index] = {
                name: getMissionTitleByMissionId(gameId, mission.id, lang ),
                missionId: mission.id,
                skipResults: mission.skipResults
            };
        }
    });
    return result;
}

function getMissionTitleByMissionId(gameId, missionId, lang) {
    const translationKey = `mission${missionId}Title`;
    return getGameDataTranslation(gameId, lang, translationKey) || missionId;
}


module.exports = {
    gameNames,
    gameData,
    allAchievements,
    achievements,
    defaultGame,
    gameListData,
    getGameDataTranslation,
    getMissionTitleByMissionId,
    getMissionListInfoSorted
};
