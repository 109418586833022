import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import { Translate } from 'react-i18nify';
import moment from 'moment';

class LicenseItem extends Component {
    /**
     * Handler for renew button click event
     */
    onRenewClick() {
        console.log('renew ' + this.props.name);
    }

    /**
     * Returns status text
     * @returns {string} Status text
     */
    getStatus() {
        if (this.props.valid === true) {
            return <Translate value="validUntil" date={this.props.expirationDate.format('D.M.YYYY')}/>;
        }
        return <Translate value="expiredOn" date={this.props.expirationDate.format('D.M.YYYY')}/>;
    }

    render() {
        const texts = {
            users: <Translate value="usersPerSeat"/>,
            status: <Translate value="status"/>,
            playerLimit: <Translate value="playerLimitPerPool"/>,
        };

        const iconClass = 'item--circle ' + (this.props.valid ? 'license--icon-valid' : 'license--icon-expired');

        return (
            <ListItem key={this} className="account-list-item">
                <div className={iconClass}/>
                <div className="account-list-item--container">
                    <div className="license--info">
                        <div className="license--name">{this.props.name}</div>
                        <div className="license--details">
                            <header>{texts.users}</header>
                            <div className="license--details-value">{this.props.users + '/' + this.props.seats}</div>
                        </div>
                        <div className="license--details">
                            <header>{texts.status}</header>
                            <div className="license--details-value">{this.getStatus()}</div>
                        </div>
                        <div className="license--details">
                            <header>{texts.playerLimit}</header>
                            <div className="license--details-value">{this.props.playerLimit}</div>
                        </div>
                    </div>
                </div>
            </ListItem>
        );
    }
}

LicenseItem.propTypes = {
    name: PropTypes.string,
    users: PropTypes.number,
    playerLimit: PropTypes.number,
    seats: PropTypes.number,
    valid: PropTypes.bool,
    expirationDate: PropTypes.instanceOf(moment)
};

export default LicenseItem;
