
import React, { Component } from 'react';
import { I18n, Translate } from 'react-i18nify';
import { numberWithSpaces } from './StatHelper';
import { getMissionTitleByMissionId } from 'helpersgames';

class UserPoolStats extends Component {
   
    render() {
      let scores = [];
        if (this.props.games.length > 0) {
              scores = this.props.games.map((gameScore, i) =>
                <tr key={i} className="game-content-row">
                  <td>{getMissionTitleByMissionId(gameScore.game, gameScore.missionId, I18n._localeKey)}</td>
                  <td>{numberWithSpaces(gameScore.score)}</td> 
                </tr>
        );
      }
    return (
          <div>
              <table className="stat-user-table">
              <thead>
                   <tr>
                      <th>Mission</th>
                      <th>Points</th>                   
                   </tr>
                 </thead>
                 <tbody>
                    {scores}
                 </tbody>
              </table>
          </div>
     
    );}
  };
               
export default UserPoolStats