import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getPoolAll } from './actions/userpool';
import Button from '@material-ui/core/Button';
import Cookies from 'universal-cookie';
import { POOL_CHANGE } from './actions/types';

import { Translate }from 'react-i18nify';

const cookies = new Cookies();

class PageHeader extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            classSelector: "header2 statistics-header statistics-container"
        }
    }

    componentDidMount() {
        /* 
         * Different CSS classsname will be provided based 
         * on the page (whether account or statistics) rendered
        */
        if (this.props.subHeaderLangKey != "account") {
            this.setState({ classSelector: "header2 statistics-container" });
        }
    }

    onPoolClick(selectedPool) {
        if (!this.props.userPool || selectedPool._id !== this.props.userPool._id) {
            cookies.set('company' + this.props.profile._id, selectedPool.pool.name, {path: '/', maxAge: 86400 * 365});

            this.props.dispatch({type: POOL_CHANGE, companyChange: false});
            this.props.getPoolAll(selectedPool._id, selectedPool.pool.name);
        }
    }

    getPoolsRow() {
        if(!this.props.userPool || !this.props.company || !this.props.allPools) {
            return null;
        }
        const containedButtonStyle = { 
            margin: "1px 1px",
            backgroundColor: "rgb(255, 255, 255)",
            borderRadius: "2px"
        }
        const result = this.props.allPools
            .filter(userPool =>{ 
                if (userPool.pool !== null) {
                    return userPool.pool.company && userPool.pool.company._id === this.props.company._id;
                }
               })
            .sort((p1, p2) => p1.pool.name.localeCompare(p2.pool.name))
            .map((userPool) => {
                const labelText = userPool.pool.name + " (" + userPool.pool.games.toString()  + ")";
                return <Button key={userPool._id} variant="contained" onClick={this.onPoolClick.bind(this, userPool)}
                    disabled={userPool._id === this.props.userPool._id} style={containedButtonStyle}>{labelText}</Button>;
            });

        return result && result.length > 1 ? result : null;
    }

    render() {
        const icon = this.props.icon || null;
        return (
            <div>
                <h1 className="header1 color-black statistics-container"><div className="title--left-line"><Translate value="napconGames"/></div></h1>
                <h2 className="header2 statistics-header statistics-container">
                    <div className="statistics-game-name">
                        {this.getPoolsRow()}
                    </div>
                </h2>
                <div>
                    <h2 className={this.state.classSelector}>
                        <div className="title--left-line"><Translate value={this.props.subHeaderLangKey} /></div>
                        {icon}
                    </h2>
                    {this.props.subHeader}
                </div>
                <br/>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    allPools: state.userPool.allPools,
    company: state.userPool.currentCompany
});
const mapDispatchToProps = (dispatch) => {
    const boundActionCreators = bindActionCreators({getPoolAll}, dispatch);
    return {...boundActionCreators, dispatch};
};

export default connect(mapStateToProps, mapDispatchToProps)(PageHeader);
