import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-i18nify';
import HelpSection from './HelpSection';

class HelpPage extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        const texts = {
            helpIndex: <Translate value="helpIndex" />,
            helpInfo: <Translate value="helpInfo"/>
        };


        return (
            <div>
                <h1 className="header1 color-blue help-page--header-main">{texts.helpIndex}</h1>
                <div className="help-page--info-container">
                    <div className="help-page--left">{texts.helpInfo}</div>
                </div>
                <HelpSection />
            </div>

        );
    }
}

function mapStateToProps(state) {
    return {
        authenticated: state.auth.authenticated
    };
}

export default connect(mapStateToProps, {})(HelpPage);
