import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-i18nify';
import { I18n } from 'react-i18nify';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CheckBox from '@material-ui/core/Checkbox';

import { createAdminMessage, getAdminMessage, deleteAdminMessage } from './actions/message';

/**
 * Componenet for siteAdmins to write AdminMessage
 */
class AdminWriteAdminMessage extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
			message: this.props.adminMessage,
        };
    }


	publishAdminMessage() {
		this.props.createAdminMessage({ text: this.state.message });
	}

	removeAdminMessage() {
		this.props.deleteAdminMessage();
		this.setState({message: ''});
	}

    render() {
		const texts = {
			header: <Translate value="adminMessage" />,
			label: <Translate value="adminMessageLabel"/>,
			publish: <Translate value="publish"/>,
			remove: <Translate value="remove"/>,
		};

		const styles = {
			label: {
				color: 'rgb(158, 158, 158)',
				marginBottom: '0px',
			},
			container: {
				paddingBottom: '20px',
			},
			buttonContainer: {
				display: 'flex',
				flexDirection: 'row',
				width: '100%'
			}
		};

        return (
            <div className="account-container" style={styles.container}>
				<h3 className="header3">{texts.header}</h3>
				<p style={styles.label} >{texts.label}</p>
                <TextField className="admin--textfield"
                           value={this.state.message}
                           onChange={e => this.setState({message: e.target.value})}/>
				<div style={styles.buttonContainer}>
				   <Button onClick={this.publishAdminMessage.bind(this)}>{texts.publish}</Button>
				   <Button onClick={this.removeAdminMessage.bind(this)}>{texts.remove}</Button>
				</div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
		adminMessage: state.message.adminMessage
    };
}

export default connect(mapStateToProps, {createAdminMessage, getAdminMessage, deleteAdminMessage})(AdminWriteAdminMessage);
