import { I18n } from 'react-i18nify';
import moment from 'moment';
import { GET_POOL, GET_POOL_USERS, ERROR_RESPONSE, INVITE_USER, REMOVE_USER,
    PROMOTE_USER, DEMOTE_USER, GET_ALL_POOLS, POOL_CHANGE, DELETE_POOL } from '../actions/types';

const INITIAL_STATE = { currentPool: null, admins: null, users: null, error: '', allPools: null, currentGame: '',
    currentCompany: null, allCompanies: null, owc: false, usersById: null };

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case INVITE_USER:
        case REMOVE_USER:
        case PROMOTE_USER:
            const currentPoolB = getCurrentPool(action);
            const currentCompanyB = currentPoolB ? currentPoolB.pool.company : null;
            const userListsB = getUserLists(action);
            window.dispatchEvent(new CustomEvent('notification', { detail: { message: getUserPoolMessage(action.type) } }));
            return { ...state, currentPool: currentPoolB, currentCompany: currentCompanyB, currentGame: getCurrentGameId(currentPoolB),
                admins: userListsB.admins, users: userListsB.users, usersById: userListsB.usersById
            };
        case DEMOTE_USER:
            const currentPoolC = getCurrentPool(action);
            const currentCompanyC = currentPoolC ? currentPoolC.pool.company : null;
            const userListsC = getUserLists(action);
            window.dispatchEvent(new CustomEvent('notification', { detail: { message: getUserPoolMessage(action.type) } }));
            return {
                ...state, currentPool: currentPoolC, currentCompany: currentCompanyC, currentGame: getCurrentGameId(currentPoolC),
                admins: userListsC.admins, users: userListsC.users, usersById: userListsC.usersById
            };
        case GET_POOL:
            const currentPool = getCurrentPool(action);
            const currentCompany = currentPool ? currentPool.pool.company : null;
            return { ...state, currentPool, currentCompany, currentGame: getCurrentGameId(currentPool) };
        case GET_ALL_POOLS:
            const allCompanies = getUserCompanies(action.payload.userPools);
            const currentDate = new Date();
            if (action.payload.userPools == undefined) {  return { ...state, allPools:[], allCompanies, owc: getOWCStatus(action.payload) }; }
            const activePools = action.payload.userPools.filter((item, index) => item.pool !== null ? new Date(item.pool.end) >= currentDate : false);
            return { ...state, allPools: activePools, allCompanies, owc: getOWCStatus(action.payload) };
        case GET_POOL_USERS:
            const userLists = getUserLists(action);
            return { ...state, admins: userLists.admins, users: userLists.users, usersById: userLists.usersById };
        case ERROR_RESPONSE:
            return { ...state, error: action.payload };
        case POOL_CHANGE:
        case DELETE_POOL:
            const newState = { ...state, currentPool: null, admins: null, users: null, allPools: null, usersById: null };
            if (action.companyChange) {
                newState.currentCompany = null;
            }
            return newState;
        default:
    }
    return state;
}

function getCurrentGameId(userPool) {
    return userPool && userPool.pool && userPool.pool.games && userPool.pool.games.length > 0 ? userPool.pool.games[0] : '';
}

function getUserPoolMessage(actionType) {
    switch (actionType) {
        case INVITE_USER: return 'invited_to_pool';
        case REMOVE_USER: return 'removed_from_pool';
        case PROMOTE_USER: return 'promoted_to_admin';
        case DEMOTE_USER: return 'demoted_from_admin';
        default: return '';
    }
}

function getCurrentPool(action) {
    let currentPool = null;
    if (action && action.payload && action.payload.currentPool && action.payload.currentPool.length > 0) {
        currentPool = action.payload.currentPool[0];
        const expireDate = moment(currentPool.pool.end);
        currentPool.valid = moment().isBefore(expireDate);
    }
    else if (action && action.payload && action.payload.emptyPool) {
        currentPool = { pool: action.payload.emptyPool };
        const expireDate = moment(currentPool.pool.end);
        currentPool.valid = moment().isBefore(expireDate);
    }
    return currentPool;
}

function getUserCompanies(pools) {
    if (!pools) {
        return [];
    }

    const uniqueCompanies = {};
    return pools
        .filter(userPool => userPool.pool && userPool.pool.company)
        .map(function(userPool) {
            if (!uniqueCompanies.hasOwnProperty(userPool.pool.company._id)) {
                uniqueCompanies[userPool.pool.company._id] = 1;
                return userPool.pool.company;
            }
        })
        .filter(company => (company !== undefined))
        .sort(function(a, b) {
            return a.name.localeCompare(b.name);
        });
}

function getOWCStatus(payload) {
    const pools = payload.userPools || payload.currentPool;
    if (!pools) {
        return false;
    }
    for (let userPool of pools) {
        if (userPool.pool && userPool.pool.owc) {
            return true;
        }
    }
    return false;
}

function getUserLists(action) {
    const adminData = [];
    const userData = [];
    const usersById = {};

    if (action && action.payload && action.payload.userpool && action.payload.userpool.length > 0) {
        for (let userpool of action.payload.userpool) {
            const user = userpool.user;
            user.name = user.firstName + " " + user.lastName;
            user.poolAdmin = userpool.type === "PoolAdmin";
            user.admin = userpool.type === "Admin";
            user.adminRights = user.poolAdmin || user.admin;
            user.status = userpool.status;
            if (user.status === "Pending") {
                user.name = user.email + " " + I18n.t("invitedPending");
            }
            if (user.poolAdmin) {
                adminData.unshift(user);
            }
            else if (user.admin) {
                adminData.push(user);
            }

            usersById[user._id] = user;
            userData.push(user);
        }

        adminData.sort((a, b) => a.name.localeCompare(b.name));
        userData.sort((a, b) => a.name.localeCompare(b.name));
    }
    return { admins: adminData, users: userData, usersById };
}
