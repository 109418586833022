import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createCompany, updateCompany } from './actions/company';
import { Translate } from 'react-i18nify';
import { withRouter } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';

/**
 * Company page
 */
class CompanyPage extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            name: ''
        }
    }
    
    componentDidMount() {
        if (this.props.searchSelect && this.props.searchSelect.type === "Company") {
            const company = this.props.companySearchResults.filter(company => company._id === this.props.searchSelect.id);
            if (company && company.length > 0) {
                this.setState({
                    selectedCompany: company[0],
                    name: company[0].name
                });
            }
        }
    }

    onBottomButtonClick() {
        const companyParams = {
            name: this.state.name
        };

        if (!this.state.selectedCompany) {
            this.props.createCompany(companyParams);
        }
        else {
            this.props.updateCompany(this.state.selectedCompany._id, companyParams);
        }
        this.props.history.push('/admin');
    }

    render() {
        const texts = {
            name: <Translate value="name"/>,
            createNew: <Translate value="createCompany"/>,
            update: <Translate value="update"/>,
        };
        const bottomButtonText = this.state.selectedCompany ? texts.update : texts.createNew;

        return (
            <div className="account-container" >
                <h2 className="header2">{bottomButtonText}</h2>
                <form style={{marginBottom:'1em'}}>
                    <TextField label={texts.name} value={this.state.name}
                           onChange={e => this.setState({name: e.target.value})}/>
                </form>
                <Button variant="contained" onClick={this.onBottomButtonClick.bind(this)}>
                    {bottomButtonText}
                </Button>
                <Divider className="account--divider"/>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        searchSelect: state.user.searchSelect,
        companySearchResults: state.company.companySearchResults
    };
}
const CompanyPageWithRouter = withRouter(CompanyPage);
export default connect(mapStateToProps, {createCompany, updateCompany})(CompanyPageWithRouter);
