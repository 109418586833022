import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, Divider } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { createGroup, addUserToGroup, removeUserFromGroup, deleteGroup } from './actions/group';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-i18nify';

import AccountAddPanel from './AccountAddPanel';
import AccountListItem from './AccountListItem';
import ConfirmDialog from './ConfirmDialog';

/**
 * GroupList component for managing groups
 */
class GroupList extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            removeUserHandler: null,
            removeGroupHandler: null
        };
    }

    /**
     * Handler for add group button pressed
     * @param value
     */
    onAddGroup(value) {
        this.props.createGroup(this.props.pool._id, {name: value});
    }

    /**
     * Handler for adding user to group
     * @param group
     * @param user
     */
    onAddUserToGroup(group, user) {
        this.props.addUserToGroup(this.props.pool._id, {group, user});
    }

    /**
     * Handler for removing user from group
     * @param group
     * @param user
     */
    onRemoveUserFromGroup(group, user) {
        this.setState({
            removeUserHandler: this.onRemoveUserConfirmClosed.bind(this, group, user)
        });
    }

    /**
     * Handler for user remove confirm dialog closed event.
     * Sends removeUserFromGroup action to server if remove confirmed.
     * Closes confirm dialog by setting removeUserHandler state to null.
     * @param group Group id
     * @param user User id
     * @param confirm True if confirmed, false if canceled.
     */
    onRemoveUserConfirmClosed(group, user, confirm) {
        if (confirm === true) {
            this.props.removeUserFromGroup(this.props.pool._id, {group, user});
        }
        this.setState({
            removeUserHandler: null
        });
    }

    /**
     * Handler for remove group button press
     * @param group Group id
     */
    onRemoveGroup(group) {
        this.setState({
            removeGroupHandler: this.onRemoveGroupConfirmClosed.bind(this, group)
        });
    }

    /**
     * Handler for group remove confirm dialog closed event.
     * Sends remove group action to server if remove confirmed.
     * Closes confirm dialog by setting removeGroupHandler to null.
     * @param group Group id
     * @param confirm True if confirmed, false id canceled
     */
    onRemoveGroupConfirmClosed(group, confirm) {
        if (confirm === true) {
            this.props.deleteGroup(this.props.pool._id, group);
        }
        this.setState({
            removeGroupHandler: null
        });
    }

    /**
     * Get current & possible members lists from given group member id list and all users list
     * @param memberIdList
     * @param allUsers
     * @returns {{current: Array, possible: Array}}
     */
    getLists(memberIdList, allUsers) {
        const currentMembers = [], possibleMembers = [];
        for (let user of allUsers) {
            if (memberIdList.indexOf(user._id) === -1) {
                possibleMembers.push(user);
            }
            else {
                currentMembers.push(user)
            }
        }
        return {current: currentMembers, possible: possibleMembers};
    }

    /**
     * Returns Group components for groupData
     * @returns {*}
     */
    getGroupItems() {
        if (this.props.groupData.length <= 0) {
            return <AccountListItem key={0} textHigh={<Translate value="noGroups"/>}/>;
        } else {
            return this.props.groupData.map((group, index) => {
                let groupListData = this.getLists(group.users, this.props.userData);
                <Group key={index} users={groupListData.current} name={group.name}
                              addUsersList={groupListData.possible}
                              addUserCallback={this.onAddUserToGroup.bind(this, group._id)}
                              removeUserCallback={this.onRemoveUserFromGroup.bind(this, group._id)}
                              removeGroupCallback={this.onRemoveGroup.bind(this, group._id)}/>
            });
        }
    }

    render() {
        const texts = {
            title: <Translate value="groups"/>,
            addGroupTitle: <Translate value="addGroup"/>,
            textFieldLabel: <Translate value="groupName"/>,
            button: <Translate value="add"/>,
            confirmTitle: <Translate value="confirm" tag="h3"/>,
            confirmUserMessage: <Translate value="confirmRemoveUserFromGroup"/>,
            confirmGroupMessage: <Translate value="confirmRemoveGroup"/>,
            confirmCancel: <Translate value="cancel"/>,
            confirmRemove: <Translate value="remove"/>
        };
        const confirmVisible = this.state.removeUserHandler !== null || this.state.removeGroupHandler !== null;
        const confirmCloseHandler = this.state.removeUserHandler || this.state.removeGroupHandler;
        const confirmMessage = this.state.removeUserHandler !== null ? texts.confirmUserMessage :
            texts.confirmGroupMessage;

        return (
            <div className="account-container">
                <h2 className="header2">{texts.title}</h2>
                <List>
                    {this.getGroupItems()}
                    <AccountAddPanel title={texts.addGroupTitle} textFieldLabel={texts.textFieldLabel}
                                     buttonLabel={texts.button} addButtonPressHandler={this.onAddGroup.bind(this)}/>
                </List>
                <ConfirmDialog open={confirmVisible} title={texts.confirmTitle}
                               message={confirmMessage} cancel={texts.confirmCancel}
                               confirm={texts.confirmRemove} closeHandler={confirmCloseHandler}
                               isDeleteAction={true}/>
            </div>
        );
    }
}

GroupList.propTypes = {
    groupData: PropTypes.array
};

/**
 * Group component has list of users and add panel for new users
 */
class Group extends Component {
    constructor(props, context) {
        super(props, context);
    }

    /**
     * Handler for add user button pressed
     */
    onAddUser(value) {
        this.props.addUserCallback(value);
    }

    onRemoveItem(value) {
        this.props.removeUserCallback(value);
    }

    /**
     * Handler for group remove button pressed
     * @param value
     */
    onRemoveGroup(value) {
        this.props.removeGroupCallback(value);
    }

    /**
     * Returns list of AccountListItems for users and header item for group.
     * @returns {Array}
     */
    getUserItems() {
        const texts = {
            noUsers: <Translate value="noUsersAssigned"/>,
            user: <Translate value="user"/>
        };

        let items = [];
        items.push(<GroupHeader key={items.length} name={this.props.name}
                                removeButtonHandler={this.onRemoveGroup.bind(this)}/>);
        items.push(<Divider style={{backgroundColor:'#ababab', marginLeft: '100px'}}/>);
        if (this.props.users.length <= 0) {
            items.push(<AccountListItem key={items.length} textHigh={texts.noUsers}/>);
        } else {
            this.props.users.map((user) => {
                items.push(<AccountListItem key={items.length} icon={"person"} textHigh={texts.user} textLow={user.name}
                                            isRemovable={true}
                                            buttonPressHandler={this.onRemoveItem.bind(this, user._id)}/>)
            });
        }
        return items;
    }

    render() {
        const texts = {
            addTitle: <Translate value="addUser"/>,
            textFieldLabel: <Translate value="userEmail"/>,
            button: <Translate value="add"/>
        };
        return (
            <div>
                <List className="account-list">
                    {this.getUserItems()}
                </List>
                <AccountAddPanel title={texts.addTitle} textFieldLabel={texts.textFieldLabel}
                                 buttonLabel={texts.button} addButtonPressHandler={this.onAddUser.bind(this)}
                                 addUsersList={this.props.addUsersList}/>
            </div>
        );
    }
}

Group.propTypes = {
    name: PropTypes.string,
    users: PropTypes.array,
    addUserCallback: PropTypes.func,
    removeGroupCallback: PropTypes.func,
    removeUserCallback: PropTypes.func
};

class GroupHeader extends Component {
    constructor(props, context) {
        super(props, context);
    }

    /**
     * Handler for remove button pressed event.
     * Calls removeButtonHandler prop.
     */
    onRemovePress() {
        this.props.removeButtonHandler();
    }

    render() {
        return (
            <ListItem key={this.props.name} className="account-list-item account-list-item--no-border" style={{ padding: "5px 16px" }}>
                <div className="account-list-item--container">
                    <Icon className="material-icons account-list-item--icon"></Icon>
                    <div className="account-list-item--text">
                        {this.props.name}
                    </div>
                    <Icon className="material-icons account-list-item--remove"
                              onClick={this.onRemovePress.bind(this)}>{"clear"}</Icon>
                </div>
            </ListItem>
        );
    }
}

GroupHeader.propTypes = {
    name: PropTypes.string,
    removeButtonHandler: PropTypes.func
};

function mapStateToProps(state) {
    return {};
}

export default connect(mapStateToProps, {createGroup, addUserToGroup, removeUserFromGroup, deleteGroup})(GroupList);

