import { ERROR_RESPONSE, CREATE_ADMIN_MESSAGE, GET_ADMIN_MESSAGE, DELETE_ADMIN_MESSAGE } from '../actions/types';

const INITIAL_STATE = { adminMessage: '', error: '' };

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case CREATE_ADMIN_MESSAGE:
			let message = 'Admin message published: "'+action.payload.result.text+'"';
            window.dispatchEvent(new CustomEvent('notification', {detail: {message: message}}));
			return { ...state, adminMessage: action.payload.result.text };
		case GET_ADMIN_MESSAGE:
			return { ...state, adminMessage: action.payload.result.text };
		case DELETE_ADMIN_MESSAGE:
			window.dispatchEvent(new CustomEvent('notification', {detail: {message: 'Admin message removed'}}));
			return { ...state, adminMessage: '' };
        case ERROR_RESPONSE:
            return { ...state, error: action.payload };
    }
    return state;
}
