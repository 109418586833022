import React from 'react';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: { 
        height: "auto", 
        lineHeight: "auto", 
        padding: "5px 80px 5px 5px",
        lineHeight: "1.5rem"
    }
}));

/* props in the CookieBottomButtonBar are the cookie consent infotext and the privacy policy link for the legal dialog */

const BottomButtonBar = ({fontSize, centerText, privacyPolicy, buttonText, onClick}) => {
        const fontStyle = {fontSize: fontSize || "12px", textAlign: "center"};
        const classes = useStyles();
        return (
            <Paper className={classes.root}>
                <div style={fontStyle}>{centerText} {privacyPolicy}</div>
                <div style={{ position: "absolute", right: 0, top: 0, height: "100%", width: "100px" }}>
                    { buttonText ?
                        <Button onClick={e => onClick(e)} className={"titlebar--consent-ok-button"}>
                            {buttonText}
                        </Button> : null
                    }
                </div>
            </Paper>
        );
};

export default BottomButtonBar;
