
const clone = require('lodash/clone');

import { CREATE_GROUP, GET_GROUP, ERROR_RESPONSE, ADD_USER_TO_GROUP, REMOVE_USER_FROM_GROUP, DELETE_GROUP, POOL_CHANGE } from '../actions/types';

const INITIAL_STATE = { groups: [], message: '', error: ''};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case ERROR_RESPONSE:
            return { ...state, error: action.payload };
        case CREATE_GROUP:
            const groupsAfterAdd = clone(state.groups);
            groupsAfterAdd.push(action.payload.group);
            return { ...state, groups: sortGroupsAlphabetically(groupsAfterAdd)};
        case DELETE_GROUP:
            const groupsAfterDelete = clone(state.groups);
            if (action.payload.group) {
                for (let i = 0; i < groupsAfterDelete.length; i++) {
                    if (groupsAfterDelete[i]._id === action.payload.group._id) {
                        groupsAfterDelete.splice(i, 1);
                        break;
                    }
                }
            }
            return { ...state, groups: sortGroupsAlphabetically(groupsAfterDelete) };
        case ADD_USER_TO_GROUP:
        case REMOVE_USER_FROM_GROUP:
            const newGroup = action.payload.group;
            let newGroups = clone(state.groups);
            for (let i = 0; i < newGroups.length; i++) {
                if (newGroups[i]._id === newGroup._id) {
                    newGroups[i] = newGroup;
                    break;
                }
            }
            return { ...state, groups: sortGroupsAlphabetically(newGroups)};
        case GET_GROUP:
            return { ...state, groups: sortGroupsAlphabetically(action.payload.groups) };
        case POOL_CHANGE:
            return { ...state, groups: []};
    }
    return state;
}

function sortGroupsAlphabetically(groups) {
    if (groups) {
        groups.sort(function (a, b) {
            return a.name.localeCompare(b.name);
        });
    }
    return groups;
}
