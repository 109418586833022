import {FETCH_AGREEMENT_VERSION } from '../actions/types';
const INITIAL_STATE = { currentAgreementVersion: {} };

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case FETCH_AGREEMENT_VERSION:
            return { ...state, currentAgreementVersion: action.payload};
        default:
            return state;
    }
}
