
import React, { Component } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { Translate } from 'react-i18nify';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import LegalDialog from './LegalDialog';

const texts = {
    agree: <Translate value="agreePolicy"/>,
    and: <Translate value="agreePolicyAnd"/>,
};

class PrivacyPolicyAgreement extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            agreementConfirmed: false,
            simple: this.props.simple,
        };
    }

    render() {
        const privacyPolicyLink = <LegalDialog content="privacyPolicy" />;
        const termsOfServiceLink = <LegalDialog content="termsOfService" />;
        const serviceDescriptionLink = <LegalDialog content="serviceDescription" />;
        const subscriptionTermsAndConditionsLink = <LegalDialog content="subscriptionTermsAndConditions" />;
        const owcRulesLink = <LegalDialog content="OWCRules" />;

        let textContent;

        /* Call <PrivacyPolicyAgreement simple={true} /> to have the only Privacy Policy and Terms of Service */
        if (this.state.simple === true) {
            textContent = <div className="privacy-policy-text">
                <span>{texts.agree} </span>
                {privacyPolicyLink}<span> {texts.and} </span>{termsOfServiceLink}.
            </div>;
        }
        else if (this.state.simple === 'owc') {
            textContent = <div className="privacy-policy-text">
                <span>{texts.agree} </span>
                {privacyPolicyLink}, {termsOfServiceLink}<span> {texts.and} </span> {owcRulesLink}.
            </div>;
        }
        else {
            textContent = <div className="privacy-policy-text">
                <span>{texts.agree} </span>
                {serviceDescriptionLink}, {subscriptionTermsAndConditionsLink}, {privacyPolicyLink},
                <span> {texts.and} </span>
                {termsOfServiceLink}.
            </div>;
        }

        return (
            <FormControlLabel style={{ zIndex: 3 }} label={textContent}
                control={
                    <Checkbox color="primary" onChange={(e) => this.props.onChange(e)}
                    />
                }>
        	</FormControlLabel>
        );
    }
}

export default PrivacyPolicyAgreement;
