import React from 'react';
import NavigationButton from './NavigationButton';

const LearningObjectives = () => {
  
  return (
    <div className="content-userguide">
      <h1 className="section-header">Section 5 Learning Objectives</h1>
      <h2>Distiller Missions</h2>
      <h3>Tutorials – Trainee learns the basics of the game</h3>
      <h4>Tutorial 1</h4>
      <div>Objective in tutorial 1 is to teach the controls of the game interface. All the buttons and controls are explained. In the end the player is given a simple task of adjusting the column bottom section temperature by increasing the reboiler steam flow.</div>
      <h4>Tutorial 2</h4>
      <div>Tutorial 2 introduces the means to control the column bottom section i.e. the reboiler.</div>
      <div>Objective is to learn how adjustments in the reboiler affect the temperatures, column bottom liquid level and bottom product composition.</div>
      <h4>Tutorial 3</h4>
      <div>Tutorial 3 covers the controls for the column top section. (Reflux, Condenser and Distillate).</div>
      <div>First objective is to teach how adjusting the reflux affects the distillate purity.</div>
      <div>Second objective is to manipulate the column pressure by controlling the distillate flow.</div>
      <div>Third task teaches controlling the condenser temperature. Opening the cooling water valve increases the heat transfer and top product subcools further. This also affects the condenser liquid level.</div>
      <h4>Tutorial 4</h4>
      <div>In the 4th tutorial the objective is to learn the dynamics of the whole column. Trainee starts by manipulating the reboiler and then has to adjust the reflux and distillate flows as well. Key lesson is to learn that it is possible to change either product composition and keep the other one constant along with the column pressure.</div>
      <h3 className ="level-mission-header">Intermediate</h3>
      <div>Objectives are to learn how to respond to disturbances happening in the feed. Controllable valves are usually either the reflux, reboiler or both. Moreover, usually the condenser is also present for distillate temperature control.</div>
      <h4>Disturbance: Feed Temperature Increases</h4>
      <div>Objective of this mission is to provide knowledge on how the reboiler and reflux values are affected when the feed temperature increases. Furthermore, how the temperature profile within the column changes. However, the pressure, top vapor and bottom liquid temperatures can be kept constant. This allows the top and bottom products to be kept at the desired composition, even though the composition is not shown in the mission.</div>
      <h4>Disturbance: Feed Temperature Decreases</h4>
      <div>The objective is similar as in mission 5.1.2.1. However, this time the feed temperature drops. Controls are similar as in mission 5.1.2.1. Nevertheless, the actions should be inverse from mission 5.1.2.1.</div>
      <h4>Disturbance: Feed Mass Flow Decreases</h4>
      <div>Learning objective in this mission is to teach the difference of feed volume change compared to feed temperature change. When the feed volume or mass flow changes, the column temperature profile can still be kept similar as before the disturbance. Furthermore, the changes needed for reflux and reboiler are related to the magnitude of the feed volume change.</div>
      <h4>Disturbance: Feed Temperature Decreases</h4>
      <div>Similar objective as in mission 5.1.2.2. However, this time it is the player who controls the column pressure along with the top vapour temperature and condenser temperature. Student learns how the feed temperature drop affects the top section controls of the column.</div>
      <h4>Disturbance: Feed Temperature Increases</h4>
      <div>Similar to mission 5.1.2.1. Furthermore, this time the player is also responsible for controlling the column pressure with the distillate flow. Idea is to maintain constant pressure, top and bottom temperature.</div>
      <h3 className ="level-mission-header">Advanced</h3>
      <div>These missions target to test the student/trainee if he/she has learned the dynamics in the distillation column. Furthermore, mission 5.1.3.6 provides a new disturbance of feed composition change which can be used to teach what to do when feed composition changes.</div>
      <h4>Disturbance: Feed Mass Flow Decreases</h4>
      <div>Similar to 5.1.2.3. However, this time the player has to operate all the valves themselves. Severity of disturbance has a random factor. Also the target values are now the Distillate C7 component mass fraction and Bottom Product C6 component mass fraction. Objective is to test if the player has learned what to do in this situation and to learn how to control the whole distillation column.</div>
      <h4>Disturbance: Feed Mass Flow increases</h4>
      <div>Similar to 5.1.3.1 but with inverse disturbance. Severity of disturbance has a random factor. The objectives are the same, actions are inverse. Objective is to test if the player has learned what to do in this situation and to learn how to control the whole distillation column.</div>
      <h4>Disturbance: Feed Temperature decreases</h4>
      <div>Similar to 5.1.2.2. However, the player has to operate all the valves themselves. Severity of disturbance has a random factor. Also the target values are now the Distillate C7 component mass fraction and Bottom Product C6 component mass fraction. Objective is to test if the player has learned what to do in this situation and to learn how to control the whole distillation column.</div>
      <h4>Disturbance: Feed Temperature increases</h4>
      <div>Similar to 5.1.3.3 but with inverse disturbance. Severity of disturbance has a random factor. The objectives are the same, actions are inverse. Objective is to test if the player has learned what to do in this situation and to learn how to control the whole distillation column.</div>
      <h4>Disturbance: New product composition target given</h4>
      <div>The task in this mission is similar as in Tutorial 5.1.1.4. The trainee will be given a new product composition target. Either bottom or top product target can change and is chosen randomly. Moreover, the severity has a random factor. The product composition target will be reversed at 30minutes of mission time. The objective is to learn how to keep one product composition constant and change the other one.</div> 
      <h4>Disturbance: Feed composition changes</h4>
      <div>In this mission the feed composition changes. Amount of heavier components in the feed will increase during the disturbance. Learning objective is to understand how the change in feed composition affects to the reboiler, reflux and product flows.</div>
      <h3 className ="level-mission-header">Specials</h3>
      <h4>Single mystery disturbance takes place</h4>
      <div>One of the disturbances from Advanced missions, except the feed composition change, happens randomly. Player has to act accordingly to the disturbance. Objective is to test the skills of the player how they can handle any disturbance happening.</div>
      <h4>Multiple disturbances happen in succession</h4>
      <div>Multiple disturbances from Advanced missions happen in succession. Player has to respond accordingly to all of the disturbances. The difficulty has increased significantly compared to other missions as the disturbances increase the severity of each other. This is the ultimate test of player skills in operating a distillation column.</div>
      <h4>The Sandbox</h4>
      <div>In this mission the player can roam freely and test the dynamics of the distillation column. He or She cannot affect the feed mass flow, temperature or composition. Only the controls of the column.</div>
      <hr className="sidebar-userguide-separator"/>
      <h2>Furnace Missions</h2>
      <h3>Tutorials</h3>
      <h4>Mission 1.1 Basic Controls</h4>
      <div>Objective in the first tutorial is to learn the controls of the game. Furthermore, there are notations of operating the furnace in a safe way e.g. increasing air flow before the fuel gas. The player goes through all the valves with the story and learns the basic controls.</div>
      <h4>Mission 1.2 General Control Strategy</h4>
      <div>Trainee learns that generally the target values in a furnace are the Oxygen content in flue gas and the outlet temperature of the process fluid. Furthermore, the necessity to have excess air is stressed for safe operation of the furnace.</div>
      <div>Trainee is taught to first increase the air flow and then the fuel gas flow.</div>
      <h3 className ="level-mission-header">Advanced Missions</h3>
      <h4>New Outlet temperature target</h4>
      <div>Trainee is taught how to adjust the outlet temperature in the furnace, while keeping the excess air in original target value. Moreover, the controls are limited to Air, Damper and Fuel valves to focus the temperature adjustment by fuel and air rather than with crude oil flow.</div>
      <div>The target value is changed twice during the mission. First change happens at 2 minutes and the second at 33 minutes.</div>
      <h4>Increased Crude Oil Throughput</h4>
      <div>In this mission the trainee learns the dynamics between the mass flow of process fluid and the heat duty (fuel and air flow) of the furnace. As the outlet temperature target is kept constant and the throughput is changed, trainee learns the effects of changes in the process fluid flow.</div>
      <div>The throughput target changes twice during the mission, first at 2 minutes and again at 32 minutes. Moreover, the specification window narrows down throughout the mission.</div>
      <h4>Fuel gas composition changes</h4>
      <div>The furnace utilizes refinery gas (rich in hydrogen and hydrocarbons from methane to pentane) as its fuel in every mission. However, in this mission the fuel is switched to natural gas, which is mostly (94% by volume) methane. Trainee is able to see the difference in fuel gas density, flame color and oxygen demand.</div>
      <div>Learning objective is to learn the differences between different types of fuels.</div>
      <h4>Air leakage in the furnace</h4>
      <div>This mission presents a scenario where air is leaking from the seams in to the furnace (not visible in the air flow register). The leakage can take place in the firebox (lower, middle or upper section), shield tube section or convection section. There are no actions to fix the air leakage from controller point of view. However, the trainee learns how an air leakage affects the furnace and is able to spot an air leakage.</div>
      <h4>Fouling in the furnace tubes</h4>
      <div>Objective of this mission is to teach the trainee how tube fouling affects the furnace. Nevertheless, tube fouling might not necessarily happen as rapidly as demonstrated in the mission, the effects are the same. Trainee learns how the flow and heat transfer changes in a fouled tube. Furthermore, the trainee is taught to equalize the outlet temperatures of between the tube passes and maintain good flow in all of the tube passes.</div>
      <div>The fouling is introduced in two phases. First fouling occurs at 2 minutes and the fouling becomes worse at 28 minutes and 20 seconds mark.</div>
      <h4>Reduction in Crude oil flow rate</h4>
      <div>In this mission trainee is introduced to a disturbance in crude oil flow. Disturbance happens three times during the mission. The inlet pressure of the crude oil will change and this will affect the flow amount of crude oil. The objective is to maintain target outlet temperature and excess air amount. The trainee can adjust the crude oil valves and the fuel gas and air valves to achieve the objective.</div>
      <div>Learning objective is to learn the dynamics between the flow and controlling valves, as well as with flow amount and heat duty of the furnace.</div>
      <h3 className ="level-mission-header">Specials</h3>
      <h4>Sandbox</h4>
      <div>Trainee is given the possibility to explore the dynamics of the furnace freely.</div>
      <hr className="sidebar-userguide-separator"/>
      <h2>Boiling Missions</h2>
      <h3>Introduction</h3>
      <h4>Mission 1 - Tutorial</h4>
      <div>Objective of this mission is familiarize trainee with the reboiler structure and its controls. Trainee is taught how to control the circulation valve, steam flow, and the drain valve and trainee learns how they affect the reboiler.</div>
      <h3>Intermediate Missions</h3>
      <h4>Mission 2 - Reboiler Start-Up</h4>
      <div>Objective in this mission is to learn how to start reboiler in a safe way. Trainee is taught that steam flow has to be increased slowly so there will not be film boiling.</div>
      <h3>Advanced Missions</h3>
      <h4>Mission 3 - Film Boiling</h4>
      <h4>Disturbance: Feed mass flow decreases</h4>
      <div>Objective in this mission is to learn how film boiling affects to the process efficiency. Trainee learns how to handle with film boiling by controlling the temperature in the reboiler. Furthermore, the trainee is taught to get process back to its steady state in a safe way, when film boiling stops. Film boiling starts in 2 min and in 5.5 min there is not film boiling anymore.</div>
      <h4>Mission 4 - Restarting Reboiler</h4>
      <div>Objective in this mission is to learn how to restart the reboiler in a safe way, when heavier components have accumulated into the reboiler. Trainee is taught how to remove lighter components by adding a steam flow and how to remove heavier components by emptying the reboiler</div>
      <h3>Specials</h3>
      <h4>The Sandbox - Free Play</h4>
      <div>In this mission the trainee is given the possibility to explore the controls of the reboiler freely.</div>
      <hr className="sidebar-userguide-separator"/>
      <h2>Condensing Missions</h2>
      <h3>Introduction</h3>
      <h4>Mission 1 - Condenser Controls</h4>
      <div>Objective in this mission is to learn the controls of the condensing game by using partial condenser. Trainee learns how to adjust the outlet temperature in condenser by controlling cold water circulation. Trainee is taught how to adjust the column pressure to the right level and how to control the amount of liquid pumped out of the reflux drum. The trainee goes through all the valves with the story and learns the basic controls.</div>
      <h3>Intermediate Missions</h3>
      <h4>Mission 2 - Disturbance: Cooling Water Temperature Rises</h4>
      <div>Objective in this mission is to provide knowledge on how to keep the distillate temperature at the target value by adjusting cold water circulation, when condenser cooling water temperature is increasing. Trainee learns how to control the temperature of the condensate stream and the pressure at the top of the distillation column at the same time in the total condenser system.</div>
      <h3>Advanced Missions</h3>
      <h4>Mission 3 - Disturbance: Feed Composition Changes</h4>
      <div>Objective in this mission is to learn how to get overhead flow to condensate completely when the feed composition changes, while ensuring that column top pressure stays in the target level by using the flare valve. Feed composition changes twice during the mission, first it goes lighter and then it will go heavier again at 13 minutes</div>
      <h4>Mission 4 - Disturbance: Non-Condensibles in Condenser</h4>
      <div>This mission presents a scenario where nitrogen is leaking and will accumulate into the condenser. Trainee learns how to remove non-condensable subject from the condenser in a safe way by lowering condenser level until gas starts to accumulate into the overflow tank and then removing non-condensable subject through the flare.</div>
      <h4>Mission 5 - Disturbance: Overhead Flow Decreases</h4>
      <div>Objective in this mission is to learn how to keep the column pressure and distillate temperature stable while overhead flow is decreasing. Trainee learns how to adjust those values to the right level by controlling cold water circulation and distillate flow out of the system.</div>
      <h3>Specials</h3>
      <h4>The Sandbox - Free Play</h4>
      <div>In this mission the trainee is given the possibility to explore the dynamics of the condenser freely.</div>
      <hr className="sidebar-userguide-separator"/>
      <h2>Heat Transfer Missions</h2>
      <h3>Introduction</h3>
      <h4>Mission 1 - Tutorial Mission</h4>
      <div>Objective in this mission is to learn how to operate the heat exchanger. The trainee goes through all the valves and basic controls and learns how those affect to the temperature of the final product.</div>
      <h3>Intermediate Missions</h3>
      <h4>Mission 2 - Basic Phenomena of Heat Exchanger</h4>
      <div>Objective in this mission is to learn about the basic heat transfer phenomena. Trainee learns how the mass flow rates and temperature are related in the heat exchanger. Trainee is taught how to increase hot side mass flow while keeping cold side final temperature stable.</div>
      <h4>Mission 3 - Disturbance: Fouling</h4>
      <div>Objective in this mission is to learn about fouling and how to compensate with it by increasing the furnace duty. First fouling occurs at 2 minutes and the fouling becomes worse at 8 minutes</div>
      <h3>Advanced Missions</h3>
      <h4>Mission 4 - Steam Superheating</h4>
      <div>Objective in this mission is to learn how to produce superheated steam with a heat exhanger and a steam superheater. Trainee is taught how to produce saturated steam from water and how to superheat the saturated stem in the furnace while keeping the vapor fraction in the right level. Furthermore, the trainee learns the how the boiling point is related to those phases.</div>
      <h4>Mission 5 - Heat Exchangers in Series</h4>
      <h4>Disturbance: Hot stream temperature drops</h4>
      <div>Objective in this mission is to learn about heat exhanger in series. Trainee learns how to keep the final product temperature and the cold side final temperature stable when hot stream temperature will drop in 2 min.</div>
      <h4>Mission 6 - Heat Exchangers in Parallel</h4>
      <h4>Disturbance: Fooling in one heat exchanger</h4>
      <div>Objective in this mission is to learn to operate heat exchangers in parallel. Furthermore, the trainee is taught how to compensate for fooling in one heat exchanger.</div>
      <h3>Specials</h3>
      <h4>The Sandbox 1 - Free Play with Single Heat Exchanger</h4>
      <div>In this mission the trainee is given the possibility to explore the dynamics of the heat exchanger freely.</div>
      <h4>The Sandbox 2 . Free Play with Heat Exchangers in Series</h4>
      <div>In this mission the trainee is given the possibility to explore the dynamics of the heat exchangers in series freely.</div>
      <h4>The Sandbox 3 - Free Play with Heat Exchangers in Parallel</h4>
      <div>In this mission the trainee is given the possibility to explore the dynamics of the heat exchangers in parallel freely.</div>
      <NavigationButton prevName="Game Portal" prevLink="/userguide/gamePortal" nextName="Administrator Instructions" nextLink="/userguide/administratorInstructions"/>  
    </div>
  );
};


export default LearningObjectives