import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import Divider from '@material-ui/core/Divider';
import { getStatsObjectFromData, getStatsWithGroupFilter, getNewHighScoresWithGroupFilter, getUserHighscoreName } from './StatHelper';
import { I18n, Translate } from 'react-i18nify';
import { getMissionListInfoSorted } from 'helpersgames';
import { bindActionCreators } from 'redux';
import sanitize from 'sanitize-filename';

import StatisticsHeader from './StatisticsHeader';
import AchievementPanel from './AchievementPanel';
import AdminTotalStats from './AdminTotalStats';
import AdminUserStats from './AdminUserStats';
import MissionStatsList from './MissionStatsList';
import StatsHighscore from './StatsHighscore';

import { getStats, getStatsForUsersArgs } from './actions/stats';
import { getHighScoresArgs, getHighScoreSum, getHighscoresForGame} from './actions/highscore';
import { getActiveUsersArgs} from './actions/activeusers';
import { renderPdf } from './actions/stats';
import { getUrlParameterByName } from "./Helpers";
import { RENDER_STATS_PDF } from "./actions/types";

const PDF_BASE_NAME = 'NapconGames_Stats';

/**
 * Statistics page main component.
 */
class StatisticsPage extends Component {
    constructor(props, context) {
        super(props, context);

        this.resetFetches();

        this.state = {
            adminView: true,
            //All groups enabled as default
            selectedGroup: getUrlParameterByName("group") || "-1",
            selectedUser: getUrlParameterByName("user") || "-1",
            selectedTime: parseInt(getUrlParameterByName("time")) || 1,
            selectedGame: getUrlParameterByName("game") || "none",
            selectedMissionId: parseInt(getUrlParameterByName("mission")) || -1,
        };
    }

    componentDidMount() {
        this.checkGetStats(this.props, this.state.selectedTime);
    }

    componentWillUnmount() {
        this.resetFetches();
    }

    componentDidUpdate(prevProps) {
        this.checkGetStats(this.props, this.state.selectedTime);
    }

    resetFetches() {
        this.fetchedStats = false;
        this.fetchedHighscores = false;
        this.fetchedActiveUsers = false;
        this.fetchedTotalPoints = false;
        this.fetchedTime = {};
        this.prevSelectedUser = "-1";
        this.prevSelectedGroup = "-1";
    }

    checkGetStats(checkedProps, time) {
        if (!checkedProps.rights || checkedProps.rights.init === false) {
            this.resetFetches();
            return;
        }

        if (this.userIsAdmin(checkedProps)) {
            const activeMonths = time >= 0 && time < 6 ? 6 : time;
            this.checkGetMonthlyStatsFromServer(checkedProps, time, "fetchedStats", checkedProps.getStatsForUsersArgs.bind(this));
            this.checkGetMonthlyStatsFromServer(checkedProps, time, "fetchedHighscores", checkedProps.getHighScoresArgs.bind(this));
            this.checkGetMonthlyStatsFromServer(checkedProps, activeMonths, "fetchedActiveUsers", checkedProps.getActiveUsersArgs.bind(this));
            this.checkGetHighScoreTotalSumFromServer(checkedProps, "fetchedTotalPoints");
        }
        else {
            if (!this.fetchedStats) {
                this.fetchedStats = true;
                const endTime = moment().endOf('day');
                const startTime = moment(endTime).subtract(1, 'month');
                this.props.getStats(checkedProps.profile._id, startTime, endTime, null, checkedProps.currentGame);
            }
        }
    }

    checkGetMonthlyStatsFromServer(checkedProps, months, fetchVariableName, apiCall) {
        if (checkedProps.currentGame && checkedProps.users && checkedProps.users.length > 0 &&
            this.fetchedTime[fetchVariableName] !== months) {
            this.fetchedTime[fetchVariableName] = months;

            const endTime = moment().endOf('day');
            const params = {
                poolId: checkedProps.userPool.pool._id,
                groupIds: null, // Group filtering done on client
                groupId: null,
                beginDate: months > 0 ? moment(endTime).subtract(months, 'month') : null,
                endDate: moment().endOf('day'),
                missionId: null,
                status: null,
                game: checkedProps.currentGame
            };
            apiCall(params);
        }
    }

    checkGetHighScoreTotalSumFromServer(checkedProps, fetchVariableName) {
        if (checkedProps.currentGame && checkedProps.userPool && checkedProps.users && checkedProps.users.length > 0 && !this[fetchVariableName]) {
            this[fetchVariableName] = true;

            const group = this.getSelectedGroup();
            const groupId = group ? group._id : null;
            checkedProps.getHighScoreSum(checkedProps.userPool.pool._id, groupId, checkedProps.currentGame);
        }
    }

    /**
     * Handler for admin view changed event.
     * Sets adminView state.
     * @param isAdminView {boolean} True when admin view is selected
     */
    onAdminViewChange(isAdminView) {
        if (!isAdminView) {
            this.prevSelectedUser = this.state.selectedUser;
            this.prevSelectedGroup = this.state.selectedGroup;
        }
        this.setState({
            adminView: isAdminView,
            selectedUser : isAdminView ? this.prevSelectedUser : "-1",
            selectedGroup: isAdminView ? this.prevSelectedGroup : "-1"
        });
    }

    /**
     * Handler for group selection changed event.
     * Sets selectedGroup state.
     * @param group {number} Index of selected group. Value is -1 when no specific group is selected.
     */
    onGroupViewChange(group) {
        this.setState({
            selectedGroup: group,
            selectedUser : "-1"
        });
    }

    onUserViewChange(user) {
        this.setState({
            selectedGroup: "-1",
            selectedUser: user
        });
    }

    onTimeChange(time) {
        this.setState({selectedTime: time});
        this.checkGetStats(this.props, time);
    }

    onGameChange(game) {
        if(game != "none"){
            this.setState({
                selectedGame: game,
                selectedMissionId: -1
            });
            this.props.getHighscoresForGame(game, null);
        }
    }

    onMissionIdChange(mission){
        if(mission != -1){
            this.setState({selectedMissionId: mission});
            this.props.getHighscoresForGame(this.state.selectedGame, mission);
        }
    }

    userIsAdmin(checkedProps) {
        return checkedProps.rights.siteAdmin || checkedProps.rights.admin || checkedProps.rights.poolAdmin;
    }

    gameId() {
        return this.props.userPool && this.props.userPool.pool ? this.props.userPool.pool.game : 0;
    }

    getAdminTotalStats(stats, selectedGroupUserIds) {
        const result = getStatsObjectFromData(stats);
        const highScores = getNewHighScoresWithGroupFilter(this.props.highScores, selectedGroupUserIds, this.state.selectedTime);
        result.lastMonthHighscores = highScores ? highScores.length : "0";
        result.totalPoints = this.state.selectedUser === "-1" && this.state.selectedGroup === "-1" && this.props.totalPoints ? this.props.totalPoints : "-";
        return result;
    }

    getSelectedGroupUserIds() {
        if (this.state.selectedUser !== "-1") {
            return [this.state.selectedUser]
        }
        else if (this.state.selectedGroup === "-1") {
            return null;
        }
        else {
            for (let g of this.props.groups) {
                if (g._id === this.state.selectedGroup) {
                    return g.users;
                }
            }
        }
        return null;
    }
    getSelectedGroup() {
        if (this.state.selectedGroup === "-1") {
            return null;
        }
        else {
            for (let g of this.props.groups) {
                if (g._id === this.state.selectedGroup) {
                    return g;
                }
            }
        }
        return null;
    }

    onRenderPdf() {
        let fileName = PDF_BASE_NAME;
        if (this.state.selectedUser !== "-1") {
            const user = this.props.usersById[this.state.selectedUser];
            if (user) {
                fileName += "_" + getUserHighscoreName(user, undefined, undefined, this.props.rights);
            }
        }
        if (this.state.selectedGroup !== "-1") {
            const group = this.getSelectedGroup();
            if (group) {
                fileName += "_" + group.name;
            }
        }

        fileName += "_" + moment().format('YYYY_MM_DD') + '.pdf';
        this.props.dispatch({type: RENDER_STATS_PDF, payload: true});
        this.props.renderPdf(this.props.userPool.pool, this.state.selectedUser, this.state.selectedGroup,
            this.state.selectedTime, sanitize(fileName));
    }

    render() {
        const selectedGroupUserIds = this.getSelectedGroupUserIds();
        const usedStats = getStatsWithGroupFilter(this.props.stats, selectedGroupUserIds);
        const isAdminView = this.userIsAdmin(this.props) && this.state.adminView === true;
        const missionInfoList = getMissionListInfoSorted(this.props.currentGame, I18n._localeKey);
        //Show total stats and graphs for admin, achievements for user
        const firstComponent = isAdminView === true ?
            <div>
                <AdminTotalStats game={this.getAdminTotalStats(usedStats, selectedGroupUserIds)}/>
                <Divider className="account--divider"/>
                <AdminUserStats stats={usedStats} missionInfo={missionInfoList} activeUsers={this.props.activeUsers}
                                selectedGroupUserIds={selectedGroupUserIds} selectedTime={this.state.selectedTime} />
            </div> : <AchievementPanel/>;

        return (
            <div>
                <StatisticsHeader {...this.props}
                                  adminRights={this.userIsAdmin(this.props)}
                                  adminView={this.state.adminView} selectedGroup={this.state.selectedGroup}
                                  superAdminview = {this.props.rights.siteAdmin}
                                  onAdminViewChange={this.onAdminViewChange.bind(this)}
                                  onGroupViewChange={this.onGroupViewChange.bind(this)}
                                  onUserViewChange={this.onUserViewChange.bind(this)}
                                  onGameChange={this.onGameChange.bind(this)}
                                  onMissionIdChange={this.onMissionIdChange.bind(this)}
                                  selectedMissionId={this.state.selectedMissionId}
                                  misionIdList={getMissionListInfoSorted(this.state.selectedGame, I18n._localeKey)}
                                  selectedGame={this.state.selectedGame}
                                  selectedUser={this.state.selectedUser}
                                  selectedTime={this.state.selectedTime} onTimeChange={this.onTimeChange.bind(this)}
                                  onRenderPdf={this.onRenderPdf.bind(this)} renderingPdf={this.props.renderingPdf} />
                <Divider className="account--divider"/>
                {firstComponent}
                <Divider className="account--divider"/>
                <MissionStatsList adminView={isAdminView} gameName={<Translate value={this.props.currentGame}/>}
                                  missionInfo={missionInfoList} profile={this.props.profile}
                                  usersById={this.props.usersById}
                                  users={this.props.users} selectedGroupUserIds={selectedGroupUserIds}
                                  group={this.getSelectedGroup()} userPool={this.props.userPool}
                                  currentGame={this.props.currentGame}
                                  rightsInit={this.props.rights && this.props.rights.init} />
                <StatsHighscore players={this.props.gameTop10} gameTitle={this.state.selectedGame}/>
            </div>
        );
    }
}

StatisticsPage.propTypes = {
    profile: PropTypes.object
};

function mapStateToProps(state) {
    return {
        rights: state.user.rights,
        profile: state.user.profile,
        groups: state.group.groups,
        userPool: state.userPool.currentPool,
        users: state.userPool.users,
        usersById: state.userPool.usersById,
        stats: state.stats.stats,
        renderingPdf: state.stats.renderingPdf,
        highScores: state.highScores.data,
        totalPoints: state.highScores.totalPoints,
        activeUsers: state.activeUsers.data,
        currentGame: state.userPool.currentGame,
        gameTop10: state.highScores.gameTop10
    };
}

const mapDispatchToProps = (dispatch) => {
    const boundActionCreators = bindActionCreators({getStats, getStatsForUsersArgs, getHighScoresArgs, getActiveUsersArgs,
        getHighScoreSum, renderPdf, getHighscoresForGame}, dispatch);
    return {...boundActionCreators, dispatch};
};

export default connect(mapStateToProps, mapDispatchToProps)(StatisticsPage);
