import React, { Component } from 'react';
import moment from 'moment';
import Divider from '@material-ui/core/Divider';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { getUsersInPool, removeUserFromPool } from './actions/userpool';
import { getGroups } from './actions/group';
import { deleteUser } from './actions/index';
import { getGameServersAdminInfo, toggleGameServerShutdownRequest} from './actions/gameserverinfo';
import { I18n, Translate } from 'react-i18nify';
import CheckBox from '@material-ui/core/Checkbox';

import PageHeader from './PageHeader';

const TIMEFORMAT = "YYYY-MM-DD HH:mm:ss";

import {
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
} from '@material-ui/core';

/**
 * Account page main component
 */
class AdminServerStats extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            deleteUserHandler: null,
            fetchedServers: false,
        }
    }

    componentDidMount() {
        this.checkGetGameServerAdminInfo(this.props);
    }

    componentDidUpdate(prevProps) {
        this.checkGetGameServerAdminInfo(this.props);
    }

    checkGetGameServerAdminInfo(checkedProps) {
        if (checkedProps.rights && checkedProps.rights.siteAdmin === true && !this.fetchedServers) {
            this.fetchedServers = true;
            checkedProps.getGameServersAdminInfo();
        }
    }

    onActionChange(event, index, value) {
        if (value === "removeAccount") {
            const userId = this.getRenderedProfile()._id;
            this.setState({
                deleteUserHandler: this.onRemoveUserConfirmClosed.bind(this, userId)
            });
        }
    }
    onRemoveUserConfirmClosed(userId, confirm) {
        if (confirm === true) {
            if (this.props.rights.siteAdmin === true) {
                this.props.deleteUser(userId);
            }
            else {
                this.props.removeUserFromPool(this.props.userPool.pool._id, {user: userId});
            }
        }
        this.setState({
            deleteUserHandler: null
        });
    }

    onToggleServerShutdownRequest(serverId)
    {
        this.props.toggleGameServerShutdownRequest({serverId});
    }

    getPoolNameFromId(id) {
        switch (id) {
            //Changing the language doesn't automatically re-render I18n.t values.
            case 1:
                return I18n.t('distillationGame');
            default:
                return '';
        }
    }

    getLicenseData() {
        if (this.props.userPool && this.props.userPool.pool) {
            let result = {};
            const expireDate = moment(this.props.userPool.pool.end);

            result.name = this.props.userPool.pool.name + ' (' +
                this.getPoolNameFromId(this.props.userPool.pool.game) + ')';
            result.users = this.props.users ? this.props.users.length : 0;
            result.seats = this.props.userPool.pool.seats;
            result.status = {
                valid: this.props.userPool.valid === true,
                expirationDate: expireDate
            };
            return [result];
        }
        else {
            return [];
        }
    }

    getRenderedProfile() {
        if (this.props.rights.siteAdmin) {
            if (this.props.searchSelect && this.props.searchSelect.type === 'User') {
                return this.props.searchedProfile;
            }
        }
        else {
            return this.props.profile;
        }
    }

    renderServersAdminInfo() {
        if (!this.props.rights || !this.props.rights.siteAdmin || !this.props.serversAdminInfo) {
            return null;
        }

        // TODO SHOW ADMINSERVERINFO NICER
        const lastCreatedServerTime = this.props.serversAdminInfo.lastServerCreatedTime ?
            moment(this.props.serversAdminInfo.lastServerCreatedTime).toString() : "-";

        const serverTexts = {
            serverTitle: <Translate value="serverTitle"/>,
            serverId: <Translate value="serverId"/>,
            playerCount: <Translate value="playerCount"/>,
            createdTime: <Translate value="createdTime"/>,
            lastUpdateTime: <Translate value="lastUpdateTime"/>,
            lastUpdatePlayers: <Translate value="lastUpdatePlayers"/>,
            players: <Translate value="players"/>,
            serverUrl: <Translate value="serverUrl"/>,
            usersOnServers: <Translate value="usersOnServers"/>,
            lastServerCreatedTime: <Translate value="lastServerCreatedTime"/>,
            serverListCreated: <Translate value="serverListCreated"/>,
            activePlayersOnServers: <Translate value="activePlayersOnServers"/>,
            availablePlayerSlots: <Translate value="availablePlayerSlots"/>,
            maxServerCount: <Translate value="maxServerCount"/>,
            playersWithoutServer: <Translate value="playersWithoutServer"/>,
            nextServerCanBeCreatedAfter: <Translate value="nextServerCanBeCreatedAfter"/>,
            serverShutdownRequestTitle: <Translate value="serverShutdownRequestTitle"/>,
            createdServers: <Translate value="createdServers"/>
        };

        const userTexts = {
            usersTitle: <Translate value="users"/>,
            email: <Translate value="email"/>,
            firstName: <Translate value="firstName"/>,
            lastName: <Translate value="lastName"/>,
            role: <Translate value="role"/>,
            title: <Translate value="jobTitle"/>,
            userId: <Translate value="userId"/>
        };

        let createdServerList = this.props.serversAdminInfo.stats.createdServerList;
        let activePlayersOnServers = this.props.serversAdminInfo.stats.playerCount;
        let playerSlotsAvailable = this.props.serversAdminInfo.stats.spaceCount;
        let maxServerCount = this.props.serversAdminInfo.stats.confServerMaxCount;
        const serverCount = this.props.serversAdminInfo.servers.length;
        const waitingPlayersCount = this.props.serversAdminInfo.stats.waitingPlayersCount;
        let playersWithoutServer = this.props.serversAdminInfo.stats.playersWithoutServer;
        let nextServerCanBeCreatedAfter = moment(this.props.serversAdminInfo.stats.nextServerCanBeCreatedAfter).format(TIMEFORMAT);

        return( <React.Fragment>
            <h2 className="header3"> {serverTexts.serverTitle}</h2>
                {serverTexts.lastServerCreatedTime} {lastCreatedServerTime}<br/>
                {serverTexts.serverListCreated} {moment(this.props.serversAdminInfo.serverTime).format(TIMEFORMAT)}<br/>
                {serverTexts.activePlayersOnServers} {activePlayersOnServers}<br/>
                {serverTexts.availablePlayerSlots} {playerSlotsAvailable}<br/>
                {serverTexts.playersWithoutServer} {playersWithoutServer} / {waitingPlayersCount}<br/>
                {serverTexts.nextServerCanBeCreatedAfter} {nextServerCanBeCreatedAfter}<br/>
                {serverTexts.maxServerCount} {serverCount} / {maxServerCount}<br/>
                {serverTexts.createdServers} {createdServerList}
            <Table className={"low--table"}>
                <TableHead>
                    <TableRow>
                        <TableCell width={30}>{serverTexts.serverId}</TableCell>
                        <TableCell width={30}>{serverTexts.playerCount}</TableCell>
                        <TableCell width={170}>{serverTexts.createdTime}</TableCell>
                        <TableCell width={170}>{serverTexts.lastUpdateTime}</TableCell>
                        <TableCell width={170}>{serverTexts.lastUpdatePlayers}</TableCell>
						<TableCell width={90}>{serverTexts.serverShutdownRequestTitle}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.props.serversAdminInfo.servers.map(function(searchResult,i) {
                        return <TableRow key={String(searchResult.id)+String(i)} className={"low--tablerow"}>
                            <TableCell width={30}>{searchResult.serverId}</TableCell>
                            <TableCell width={30}>{searchResult.playerCount}</TableCell>
                            <TableCell width={170}>{moment(searchResult.createdTime).format(TIMEFORMAT)}</TableCell>
                            <TableCell width={170}>{moment(searchResult.lastUpdateTime).format(TIMEFORMAT)}</TableCell>
                            <TableCell width={170}>{moment(searchResult.lastUpdateTimeWithPlayers).format(TIMEFORMAT)}</TableCell>
							<TableCell width={90}>
								<CheckBox onChange={this.onToggleServerShutdownRequest.bind(this, searchResult.serverId)} defaultChecked={searchResult.shutdownRequested}/>
							</TableCell>
                        </TableRow>;
                    }.bind(this))}
                </TableBody>
            </Table>
            <h2 className="header3"> {userTexts.usersTitle}</h2>
            <Table className={"low--table"}>
                <TableHead>
                    <TableRow>
                        <TableCell width={200}>{userTexts.email}</TableCell>
                        <TableCell width={90}>{userTexts.firstName}</TableCell>
                        <TableCell width={90}>{userTexts.lastName}</TableCell>
                        <TableCell width={90}>{userTexts.role}</TableCell>
                        <TableCell width={90}>{userTexts.title}</TableCell>
                        <TableCell width={200}>{userTexts.userId}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.props.serversAdminInfo.serverUsers.map(function(searchResult) {
                        return <TableRow key={searchResult.id} className={"low--tablerow"}>
                            <TableCell width={200}>{searchResult.email}</TableCell>
                            <TableCell width={90}>{searchResult.firstName}</TableCell>
                            <TableCell width={90}>{searchResult.lastName}</TableCell>
                            <TableCell width={90}>{searchResult.role}</TableCell>
                            <TableCell width={90}>{searchResult.title}</TableCell>
                            <TableCell width={200}>{searchResult._id}</TableCell>
                        </TableRow>;
                    })}
                </TableBody>
            </Table>
        </React.Fragment>
        );
    }


    render() {
        const adminServersInfoPanel = this.props.rights.siteAdmin ? this.renderServersAdminInfo() : null;

        return (
            <div>
                {adminServersInfoPanel}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        rights: state.user.rights,
        profile: state.user.profile,
        userPool: state.userPool.currentPool,
        admins: state.userPool.admins,
        users: state.userPool.users,
        groups: state.group.groups,
        searchSelect: state.user.searchSelect,
        searchedProfile: state.user.searchedProfile,
        serversAdminInfo: state.gameServer.serversAdminInfo
    };
}

export default connect(mapStateToProps, {getUsersInPool, getGroups, removeUserFromPool, deleteUser, getGameServersAdminInfo, toggleGameServerShutdownRequest})(AdminServerStats);
