import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { logoutUser } from './actions/auth';

class LogoutPage extends Component {
    componentDidMount() {
        this.props.logoutUser();
    }

    render() {
        return (
            <Redirect to={'/'} from={'/logout'} />
        );
    }
}
LogoutPage.propTypes = {
    logoutUser: PropTypes.func.isRequired,
};

export default connect(() => ({}), { logoutUser })(LogoutPage);
