
import React, { Component } from 'react';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import { connect } from 'react-redux';
import { deletePool } from './actions/pool';

import ConfirmDialog from './ConfirmDialog';
import AdministratorList from './AdministratorList';
import GroupList from './GroupList';
import LicenseList from './LicenseList';
import AccountPageDropDown from './AccountPageDropDown';
import UserList from './UserList';
import PoolStatPopup from './PoolStatPopup';
import { Translate, I18n } from 'react-i18nify';

class AccountPagePool extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            deletePoolHandler: null,
            open: false,
        }
    }

    deletePool() {
        this.setState({
            deletePoolHandler: this.onRemovePoolConfirmClosed.bind(this, this.props.userPool.pool._id)
        });
    }

    handleClickOpen = () => {
		this.setState({open: true});
    };

    handleCancel = () => {
		this.setState({open: false});
    };

    onRemovePoolConfirmClosed(poolId, confirm) {
        if (confirm === true) {
            this.props.deletePool(poolId);
        }
        this.setState({
            deletePoolHandler: null
        });
    }

    getLicenseData() {
        if (this.props.userPool && this.props.userPool.pool) {
            let result = {};
            const expireDate = moment(this.props.userPool.pool.end);
            const licenses = this.props.userPool.pool.games ? this.props.userPool.pool.games.toString() : "-";

            result.name = this.props.userPool.pool.name + ' (' + licenses + ')';
            result.users = this.props.users ? this.props.users.length : 0;
            result.seats = this.props.userPool.pool.seats;
            result.status = {
                valid: this.props.userPool.valid === true,
                expirationDate: expireDate
            };
            result.playerLimit = this.props.userPool.pool.activePlayerLimit;

            return [result];
        }
        else {
            return [];
        }
    }

    renderConfirmDialog(confirmVisible) {
        if (!this.props.userPool || !this.props.userPool.pool) {
            return null;
        }

        const texts = {
            confirmTitle: <Translate value="confirm" tag="h3"/>,
            confirmCancel: <Translate value="cancel"/>,
            confirmRemove: <Translate value="remove"/>,
            confirmByTypingName: I18n.t('confirmByTypingName')
        };

        const confirmType = this.props.userPool.pool.name;
        const confirmMessage = I18n.t('confirmDeletePoolFromDb', {pool:this.props.userPool.pool.name});

        return <ConfirmDialog open={confirmVisible} title={texts.confirmTitle} confirmByTypingText={confirmType}
                              confirmByTypingTitle={texts.confirmByTypingName}
                              message={confirmMessage} cancel={texts.confirmCancel}
                              confirm={texts.confirmRemove} closeHandler={this.state.deletePoolHandler}
                              isDeleteAction={true}/>
    }

    renderStatPoolDialog(confirmVisible, seats) {
        return <PoolStatPopup poolstats={this.props.userPool} seats={seats} open={confirmVisible} handleCancel={this.handleCancel}/>
    }

    render() {
        if (!this.props.userPool || !this.props.userPool.pool) {
            return null;
        }

        const licenseData = this.getLicenseData();
        const adminData = this.props.admins || [];
        const userData = this.props.users || [];
        const maxCount = this.props.userPool.pool.seats;
        const groupData = this.props.groups || [];
        const promotableUsers = userData.filter(function(user) {
            return user.admin === false && user.poolAdmin === false;
        });

        let adminPoolMenu = null;
        if (this.props.rights.siteAdmin) {
            const items = [
                {disabled: false, textKey:"editPool", container: NavLink, to:"/pools"},
                {disabled: false, textKey:"deletePool", callback: this.deletePool.bind(this)},
                {disabled: false, textKey:"viewPoolStats", callback: this.handleClickOpen.bind(this)},
            ];
            adminPoolMenu = <AccountPageDropDown menuItems={items} />;
        }

        const confirmVisible = this.state.deletePoolHandler !== null;
        const confirmDialog = this.renderConfirmDialog(confirmVisible);
        const openStatDialog = this.renderStatPoolDialog(this.state.open, this.getLicenseData()[0].seats);
        return <React.Fragment>
            {adminPoolMenu}
            <LicenseList licenseData={licenseData}/>
            <Divider className="account--divider"/>
            <AdministratorList adminData={adminData} pool={this.props.userPool.pool}
                               showAdd={this.props.rights.siteAdmin || this.props.rights.poolAdmin || this.props.rights.admin}
                               promotableUsers={promotableUsers} userData={userData} maxCount={maxCount}/>
            <Divider className="account--divider"/>
            <GroupList groupData={groupData} pool={this.props.userPool.pool} userData={userData}/>
            <Divider className="account--divider"/>
            <UserList userData={userData} maxCount={maxCount} pool={this.props.userPool.pool}/>
            {confirmDialog}
            {openStatDialog}
        </React.Fragment>;
    }
}

function mapStateToProps(state) {
    return { };
}

export default connect(mapStateToProps, {deletePool})(AccountPagePool);
