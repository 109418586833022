import "core-js/stable";
import "regenerator-runtime/runtime";
import "custom-event-polyfill";
require('../public/index.html');
import React from 'react';
import ReactDOM from 'react-dom';
import Main from './Main';
import Localization from './Localization';

import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
import Cookies from 'universal-cookie';
import reducers from './reducers/index';
import { AUTH_USER } from './actions/types';
import {sendDataToIframe} from './Helpers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

const cookies = new Cookies();
const createStoreWithMiddleware = applyMiddleware(reduxThunk)(createStore);
const store = createStoreWithMiddleware(reducers);

// if token found, assume it's valid on start
const token = cookies.get('token');
if (token) {
    store.dispatch({ type: AUTH_USER , payload: {token: token}});
}

window.onmessage = function(e){
    if (e.data.type === "request_token") {
        const readToken = cookies.get('token'); // Might not be logged in on start
        sendDataToIframe("token", {token:readToken}, true);
    }
};

Localization.initialize(function() {
    // Render initially when localizations have been loaded
    ReactDOM.render(
            <Provider store={store}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                <Main />
                </MuiPickersUtilsProvider>
            </Provider>,
        document.getElementById('app'));
});

