import React, {Component} from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { getForgotPasswordToken } from './actions/auth';
import { Dialog, DialogContent, DialogActions, Paper, DialogContentText } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { Translate } from 'react-i18nify';

const texts = {
    email: <Translate value="email" />,
    forgotPwd: <Translate value="forgotPassword" />,
    wrongEmailPassword: <Translate value="wrongEmailPassword" />,
    error: <Translate value="error" />,
    missingEmail: <Translate value="missingEmail" />,
    resetPassword: <Translate value="resetPassword" tag="h3"/>,
    passwordResetDescription: <Translate value="passwordResetDescription" />,
};

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography={true} className={classes.root} {...other}>
        {children}
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
});

class ForgotPasswordDialog extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            email: ''
        };
    }

    /**
     * Returns error message for error status.
     * @param errorMessage
     * @returns {string} Error message. Empty when there's no error
     */
    getErrorMessage(errorMessage) {
        if (errorMessage && errorMessage.status) {
            switch (errorMessage.status) {
                case 400:
                case 401:
                   return texts.missingEmail;
            }
            return texts.error + ' ' + errorMessage.status;
        }
        return '';
    }

    handleResetPassword = (event) => {
            if(this.state.email !== ''){
                this.props.handleResetPassword(event.target.value);
            }

            if (this._nextSendTime && moment().isBefore(this._nextSendTime)) {
                window.dispatchEvent(new CustomEvent('notification', { detail: { message: 'passwordResetTooMany' } }));
            }
            else {
                this.props.getForgotPasswordToken({ email: this.state.email });
                this._nextSendTime = moment().add(15, 'seconds');
            }
            this.setState({email: ''});
    };

    handleCancel = (event) => {
        this.props.handleCancel(event.target.value);
    };

    render() {
        const actions = [
			<Button
				color="primary"
				style={{margin: "0 10px"}}
				key={"Reset Password"}
				onClick={this.handleResetPassword}
                >Send Reset Link</Button>,
            <Button
				color="primary"
				style={{margin: "10px 0px"}}
				key={"Cancel"}
				onClick={this.handleCancel}
				>Cancel</Button>
        ];
        const errorMessage = this.getErrorMessage(this.props.errorMessage);
        return (
            <div>
               <Dialog
					className="dialog--legal-dialog"
					maxWidth = {"md"}
					open={this.props.open}
					onClose={this.handleCancel}
					PaperComponent={Paper}
                    PaperProps={{className: "legal-body"}}
				>
                <DialogTitle onClose={this.handleCancel}>{texts.resetPassword}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{texts.passwordResetDescription}</DialogContentText>
                    <TextField className="login-dialog--textfield-main"
                        autoFocus
                        id="name"
                        label={texts.email}
                        type="email"
                        fullWidth
                        autoComplete="email"
                        value={this.state.email}
                        onChange={e => this.setState({ email: e.target.value })}
                        error={errorMessage !== ""}
                        helperText={errorMessage}
                        />
                </DialogContent>
					<DialogActions>
						{actions}
					</DialogActions>
				</Dialog>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        errorMessage: state.auth.error,
        message: state.auth.message,
        authenticated: state.auth.authenticated,
    };
}

export default connect(mapStateToProps, {  getForgotPasswordToken })(ForgotPasswordDialog);
