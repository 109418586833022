
import axios from 'axios';
import moment from 'moment';
import Cookies from 'universal-cookie';
import { API_URL, errorHandler } from './index';
import { ERROR_RESPONSE, EXTERNAL_SAVE_ERROR, GET_STATS, GET_REALTIME_STATS, GET_STATS_MISSION, GET_STATS_MISSION_POOL,
    APPROVE_SCORE, EXTERNAL_SAVE_STARTED, EXTERNAL_SAVE_COMPLETE, RENDER_STATS_PDF, GET_OWC_STATS, GET_OWC_ROUND, GET_OWC_INFO } from './types';
import { getGetRequestParams } from '../Helpers';
import { saveAs } from 'file-saver';

const cookies = new Cookies();

export function getStatsForUsersArgs(args) {
    return getStatsForUsers(args.poolId, args.groupIds || args.groupId, args.beginDate, args.endDate, args.missionId, args.status, args.game);
}

export function getStatsForUsers(poolId, groupIds, beginDateMoment, endDateMoment, missionId, status, game) {
    return function (dispatch) {
        axios.get(`${API_URL}/stats/pool/${poolId}`, getGetRequestParams(game, groupIds, beginDateMoment, endDateMoment, missionId, status))
            .then((response) => {
                dispatch({
                    type: GET_STATS,
                    payload: response.data
                });

            })
            .catch((error) => {
                errorHandler(dispatch, error.response, ERROR_RESPONSE);
            });
    };
}

export function getStats(userId, beginDateMoment, endDateMoment, missionId, game) {
    return function (dispatch) {
        axios.get(`${API_URL}/stats/user/${userId}`, getGetRequestParams(game, null, beginDateMoment, endDateMoment, missionId))
            .then((response) => {
                dispatch({
                    type: GET_STATS,
                    payload: response.data
                });

            })
            .catch((error) => {
                errorHandler(dispatch, error.response, ERROR_RESPONSE);
            });
    };
}

export function getFullStatsForMission(userId, missionId, game) {
    return function (dispatch) {
        axios.get(`${API_URL}/stats/user/${userId}`, getGetRequestParams(game, null, null, null, missionId))
            .then((response) => {
                dispatch({
                    type: GET_STATS_MISSION,
                    payload: response.data,
                    missionId: missionId
                });
            })
            .catch((error) => {
                errorHandler(dispatch, error.response, ERROR_RESPONSE);
            });
    };
}

export function getFullPoolStatsForMission(poolId, groupId, missionId, game) {
    return function (dispatch) {
        axios.get(`${API_URL}/stats/pool/${poolId}`, getGetRequestParams(game, groupId, null, null, missionId))
            .then((response) => {
                dispatch({
                    type: GET_STATS_MISSION_POOL,
                    payload: response.data,
                });
            })
            .catch((error) => {
                errorHandler(dispatch, error.response, ERROR_RESPONSE);
            });
    };
}

export function getRealtimeStats() {
    return function (dispatch) {
        axios.get(`${API_URL}/stats/realtime`, {headers: { Authorization: cookies.get('token') }})
            .then((response) => {
                dispatch({
                    type: GET_REALTIME_STATS,
                    payload: response.data
                });

            })
            .catch((error) => {
                errorHandler(dispatch, error.response, ERROR_RESPONSE);
            });
    };
}

export function approveScore(userId, { score, missionId }) {
    return function (dispatch) {
        axios.post(`${API_URL}/stats/approvescore/${userId}`, { score, missionId }, { headers: { Authorization: cookies.get('token') } })
            .then((response) => {
                dispatch({
                    type: APPROVE_SCORE,
                    payload: response.data,
                });
            }).catch((error) => {
                errorHandler(dispatch, error.response, ERROR_RESPONSE);
            });
    };
}

export function completeExternalTraining(token, service) {
    return (dispatch) => {
        dispatch({
            type: EXTERNAL_SAVE_STARTED,
        });
        axios.post(`${API_URL}/stats/externalcomplete`, { token, service }, { headers: { Authorization: cookies.get('token') } })
            .then((response) => {
                dispatch({
                    type: EXTERNAL_SAVE_COMPLETE,
                    payload: response,
                });
            }).catch((error) => {
                errorHandler(dispatch, error.response, EXTERNAL_SAVE_ERROR);
            });
    };
}

export function renderPdf(pool, selectedUser, selectedGroup, time, clientFileName) {
    return function (dispatch) {
        const poolName = pool.name;
        const poolId = pool._id;

        axios.post(`${API_URL}/stats/renderpdf/`,
            {
                poolName,
                poolId,
                selectedUser,
                selectedGroup,
                time,
            },
            {
                headers: {
                    Authorization: cookies.get('token')
                },
                responseType: 'blob',
            }
        ).then((response) => {
            const blob = new Blob([response.data], { type: 'application/pdf' });
            saveAs(blob, clientFileName);
        }).catch((error) => {
            errorHandler(dispatch, error.response, ERROR_RESPONSE);
        }).finally(() => {
            dispatch({
                type: RENDER_STATS_PDF,
                payload: false,
            });
        });
    };
}

export function getOWCStats(round, amount) {
    return function (dispatch) {
        axios.get(`${API_URL}/stats/owc/${round}`, {params: {count: amount}})
            .then((response) => {
                dispatch({
                    type: GET_OWC_STATS,
                    payload: response.data
                });
            })
            .catch((error) => {
                errorHandler(dispatch, error.response, ERROR_RESPONSE);
            });
    };
}

export function getOWCRound() {
    return function (dispatch) {
        axios.get(`${API_URL}/stats/owcround`)
            .then((response) => {
                dispatch({
                    type: GET_OWC_ROUND,
                    payload: response.data
                });
            })
            .catch((error) => {
                errorHandler(dispatch, error.response, ERROR_RESPONSE);
            });
    };
}

export function getOWCInfo(round) {
    return function (dispatch) {
        axios.get(`${API_URL}/stats/owcinfo/${round}`)
            .then((response) => {
                dispatch({
                    type: GET_OWC_INFO,
                    payload: response.data,
                });
            })
            .catch((error) => {
                errorHandler(dispatch, error.response, ERROR_RESPONSE);
            });
    };
}

export function getRanking(gameId, poolId, missionId, beginParam, endParam) {
    const begin = beginParam && !moment.isMoment(beginParam) ? moment(beginParam) : beginParam;
    const end = endParam && !moment.isMoment(endParam) ? moment(endParam) : endParam;
    return new Promise((resolve, reject) => {
        axios.get(`${API_URL}/stats/ranking/${poolId}`, getGetRequestParams(gameId, null, begin, end, missionId))
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error.response);
                reject(null);
            });
    });
}

export function getOWCRanking(round) {
    const roundParam = (typeof (round) !== 'undefined') ? round : '';
    return new Promise((resolve, reject) => {
        axios.get(`${API_URL}/stats/owcranking/${roundParam}`, { headers: { Authorization: cookies.get('token') } })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error.response);
                reject(null);
            });
    });
}
