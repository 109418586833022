import axios from 'axios';
import Cookies from 'universal-cookie';
import { API_URL, errorHandler } from './index';
import {
    AUTH_USER, AUTH_EXT_USER, REG_EXT_USER, REG_EXT_USER_COMPLETED, AUTH_ERROR, UNAUTH_USER, FORGOT_PASSWORD_REQUEST, RESET_PASSWORD_REQUEST, GET_ALL_POOLS,
    FETCH_USER, ERROR_RESPONSE, AUTH_USER_UPDATE, AUTH_REGISTER_INFO, GET_POOL, GET_POOL_USERS, NEW_TOKEN,
    MAKE_OWC_REGISTERATION,
} from './types';

const cookies = new Cookies();
//= ===============================
// Authentication actions
//= ===============================

export function loginUser({ email, password, company }) {
    return (dispatch) => {
        axios.post(`${API_URL}/auth/login`, { email, password })
            .then((response) => {
                dispatch({ type: AUTH_USER, payload: response.data });
                dispatch({ type: FETCH_USER, payload: response.data.user });
            })
            .catch((error) => {
                errorHandler(dispatch, error.response, AUTH_ERROR);
            });
    };
}

export function loginExternalUser(token, game, service, key) {
    return (dispatch) => {
        axios.post(`${API_URL}/auth/loginexternal`, { token, game, service, key }, { headers: { Authorization: cookies.get('token') } })
            .then((response) => {
                if (response.data.registerRequired) {
                    // if registration is required, do not fetch user data    
                    dispatch({ type: REG_EXT_USER, payload: response.data });
                } else {
                    dispatch({ type: AUTH_EXT_USER, payload: response.data });
                    dispatch({ type: FETCH_USER, payload: response.data.user });
                }
            })
            .catch((error) => {
                errorHandler(dispatch, error.response, AUTH_ERROR);
            });
    };
}

export function newToken() {
    return (dispatch) => {
        axios.post(`/${API_URL}/auth/newtoken/`, { }, { headers: { Authorization: cookies.get('token') } })
            .then((response) => {
                dispatch({ type: AUTH_USER, payload: response.data });
                dispatch({ type: NEW_TOKEN, payload: response.data });
            })
            .catch((error) => {
                errorHandler(dispatch, error.response, ERROR_RESPONSE);
            });
    };
}

export function getRegisterInfo(token) {
    return function (dispatch) {
        axios.get(`${API_URL}/auth/register/${token}`)
            .then((response) => {
                dispatch({ type: AUTH_REGISTER_INFO, payload: response.data.email });
            })
            .catch((error) => {
                errorHandler(dispatch, error.response, ERROR_RESPONSE);
            });
    };
}

export function registerUser(token, { email, firstName, lastName, password, title, agreementConfirmed, unit, nick, language }) {
    return function (dispatch) {
        axios.post(`${API_URL}/auth/register/${token}`, { email, firstName, lastName, password, title, agreementConfirmed, unit, nick, language })
            .then((response) => {
                dispatch({ type: AUTH_USER, payload: response.data });
                dispatch({ type: FETCH_USER, payload: response.data.user });
            })
            .catch((error) => {
                errorHandler(dispatch, error.response, AUTH_ERROR);
            });
    };
}

export function registerExternalUser(token, { email, firstName, lastName, title, agreementConfirmed, unit, nick, language }) {
    return function (dispatch) {
        axios.post(`${API_URL}/auth/registerexternal/${token}`, { email, firstName, lastName, title, agreementConfirmed, unit, nick, language })
            .then((response) => {
                dispatch({ type: REG_EXT_USER_COMPLETED, payload: response.data });
            })
            .catch((error) => {
                errorHandler(dispatch, error.response, AUTH_ERROR);
            });
    };
}

export function registerUserUpdate(uid, { email, firstName, lastName, password, title, newPassword, unit, nick, language }) {
    return function (dispatch) {
        axios.post(`${API_URL}/auth/registerupdate/${uid}`, { email, firstName, lastName, password, title, newPassword, unit, nick, language },
            { headers: { Authorization: cookies.get('token') } })
            .then((response) => {
                dispatch({ type: AUTH_USER_UPDATE, payload: response.data.user });
                dispatch({ type: FETCH_USER, payload: response.data.user });
            })
            .catch((error) => {
                errorHandler(dispatch, error.response, ERROR_RESPONSE);
            });
    };
}

export function logoutUser(receivedError) {
    return function (dispatch) {
        axios.post(`${API_URL}/auth/logout`, { }, { headers: { Authorization: cookies.get('token') } })
            .then((response) => {
                dispatch({ type: UNAUTH_USER, payload: receivedError || '' });
                cookies.remove('token', { path: '/' });
            })
            .catch((error) => {
                errorHandler(dispatch, error.response, AUTH_ERROR);
                dispatch({ type: UNAUTH_USER, payload: error || '' });
                cookies.remove('token', { path: '/' });
            });
    // window.location.href = `${CLIENT_ROOT_URL}/login`;
    };
}

export function sessionExpiresLogoutUser(error) {
    return function (dispatch) {
        dispatch({ type: UNAUTH_USER, payload: error });
    }
}

export function getForgotPasswordToken({ email }) {
    return function (dispatch) {
        axios.post(`${API_URL}/auth/forgot-password`, { email })
            .then((response) => {
                dispatch({
                    type: FORGOT_PASSWORD_REQUEST,
                    payload: response.data,
                });
            })
            .catch((error) => {
                errorHandler(dispatch, error.response, AUTH_ERROR);
            });
    };
}

export function resetPassword(token, { password }) {
    return function (dispatch) {
        axios.post(`${API_URL}/auth/reset-password/${token}`, { password })
            .then((response) => {
                dispatch({
                    type: RESET_PASSWORD_REQUEST,
                    payload: response.data.message,
                });
            })
            .catch((error) => {
                errorHandler(dispatch, error.response, AUTH_ERROR);
            });
    };
}

export function addExistingToOWC(email, nick, agreementConfirmed) {
    return function (dispatch) {
        axios.post(`${API_URL}/auth/registerowc/old`, { email, nick, agreementConfirmed }, { headers: { Authorization: cookies.get('token') } })
            .then((response) => {
                dispatch({ type: GET_ALL_POOLS, payload: response.data });
                if (response.data.user) {
                    dispatch({ type: FETCH_USER, payload: response.data.user });
                    dispatch({ type: MAKE_OWC_REGISTERATION, payload: response.data.user });
                }
            })
            .catch((error) => {
                errorHandler(dispatch, error.response, ERROR_RESPONSE);
            });
    };
}

export function registerOWCUser(email, nick, agreementConfirmed) {
    return function (dispatch) {
        axios.post(`${API_URL}/auth/registerowc/new`, { email, nick, agreementConfirmed })
            .then((response) => {
                dispatch({ type: AUTH_USER, payload: response.data });
                dispatch({ type: FETCH_USER, payload: response.data.user });
                dispatch({ type: MAKE_OWC_REGISTERATION, payload: response.data.user });
            })
            .catch((error) => {
                errorHandler(dispatch, error.response, AUTH_ERROR);
            });
    };
}
