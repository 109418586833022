import React, { Component } from 'react';
import Icon from '@material-ui/core/Icon';
import {I18n, Translate } from 'react-i18nify';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { List, MenuItem, ListItemIcon } from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter, Link, Redirect } from 'react-router-dom';
import { getMissionTitleByMissionId } from './helpersgames';
import Cookies from 'universal-cookie';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { API_URL, errorHandler } from './actions/index';
import { ERROR_RESPONSE } from './actions/types';

const cookies = new Cookies();

const simulatorExercises = [
	{
		index: 1,
		imgUrl: "images/mission_practice.png",
		type: "Perusteet",
		description: "Operoinnin työpöytä, trendit, säätöpiirien operointi, hälytysrajat ja hälytyslista.",
		infoText: ["Tavoitteena oppia", ["tekemään operointia varten ”työpöytä”", "tekemään trendejä/skaalaus/aika/tyyli", "tallentamaan ”työpöytä”", "operoimaan säätöpiirejä:", ["operointijärjestys (laite/ säätöpiiri/ lukituksen purkaminen)", "moodeja (M → A)", "kaskadipiirin käyttöönottoa (L → R)", "suhdesäätöä"], "määrittämään/operoimaan säätöpiireille hälytysrajat", "oppia käyttämään hälytyslistaa"]],
		evaluationText: "Kuvakaappaukset työpöydältä; prosessi-ikkunat, trendit, säätöpiirit."
	},
	{
		index: 2,
		imgUrl: "images/mission_crude_oil.png",
		type: "Tilan muutos",
		description: "Operoidaan kylmä tislauskolonni tasapainotilaan ja kokonaispalautukselle. Otetaan syöttö kolonniin kun se on mahdollista ja aloitetaan tuotteiden ulosotto.",
		infoText: ["Suunnittele missä järjestyksessä operoit tislauskolonnin ylösajon.", "Mistä näet, että olet saavuttanut tislauskolonniin tasapainotilan?", "Mistä näet, että olet saavuttanut kokonaispalautuksen?", "Miksi tislauskolonni operoidaan ensin tasapainotilaan ja kokonaispalautukselle ennenkö otetaan tuotteita ulos tai syöttöä sisään?", "Koska voit ottaa tuotteita ulos? Miksi?", "Kuinka syötön otto vaikuttaa kolonnin toimintaan?", "Mitkä ovat tärkeimpiä tarkkailtavia mittauksia syötön oton yhteydessä?", "Esitä tärkeimmissä mittauksissa taulukkona instrumentointitunnus/ mittausarvo ennen syötön ottamista ja syötön ottamisen jälkeen. Ilmoita taulukossa myös syötön määrä/lämpötila."],
		evaluationText: "Kuvakaappaukset työpöydältä tasapainotilasta ja kokonaispalautuksesta (prosessi-ikkunat, trendit, säätöpiirit). Selvitykset kirjallisena; vastaukset + taulukko."
	},
	{
		index: 3,
		imgUrl: "images/mission_crude_oil.png",
		type: "Tilan muutos",
		description: "Operoidaan tislauksen alasajo",
		infoText: ["Suunnittele tislauksen hallittu alasajojärjestys.", "Missä järjestyksessä operoit tislauksen alasajon? Miksi?", "Miten syötön pumppauksen lopetus vaikuttaa kolonnin lämpöihin?", "Milloin lopetat tuotteiden ulosotot? Miksi?"] ,
		evaluationText: "Kuvakaappaukset työpöydältä tasapainotilasta ja kokonaispalautuksesta (prosessi-ikkunat, trendit, säätöpiirit). Selvitykset kirjallisena."
	},
	{
		index: 4,
		imgUrl: "images/mission_crude_oil.png",
		type: "Tilan muutos",
		description: "Tislauksen teoreettisten perusteiden oppiminen ennen harjoituksen tekemistä. Harjoitteena syötön vesi-propyleeniglykolisuhteen muuttaminen, muutosten havaitseminen sekä niiden selvittäminen tislauksen teoriaan perustuen.",
		infoText: ["Tavoitteena ennen harjoitteen tekemistä:", ["oppia tislaukseen liittyviä fysikaalisia ilmiöitä ja tislauksen teoriaa ennen harjoitusta", ["höyrynpaine", "kiehuminen/paineen vaikutus kiehumispisteeseen", "höyrystymislämpö vedelle ja propyleeniglykolille", "tislauksen lämpötila-konsentraatiokäyrän ymmärtäminen"]], "Harjoitteen tekeminen:", "a) FFIC-101 propyleeniglykoli pitoisuudesta 50% → 80% → 20 %", ["operoidaan FA-106 pintaa (LICSA -111) alemmaksi nopeammalla simulaattorin nopeudella, S= 10 %", "kun LICSA -111 M= 15 % simulaattori muutetaan 1 nopeudelle", "kun LICSA -111 M= 12 % palauta syöttö FA-106 säiliöön operoimalla kaskadipiiriä siten, että LICSA-111 O=12 kg/h ja FFIC-101 on 80 % propyleeniglykolia", "halutessasi voit nopeuttaa simulaattoria"], "Tarkkaile seuraavia:", ["EA-104 ohjausta", "huipunpalautuksen tarvetta", "paine-eroa", "pohjatuotteen määrää ja GA-105 ohjausta", "tisleen määrää ja GA-104 ohjausta", "ylimenolauhduttimen jäähdytystarvetta (TICSA-123)"], "b) FFIC-101 propyleeniglykoli pitoisuudesta 50% → 20 % → 80 %", "Simulaattorin uudelleen lataus ja sama harjoitus suhteella 20 % propyleeniglykolia", ["operoidaan FA-106 pintaa (LICSA -111) alemmaksi nopeammalla simulaattorin nopeudella, S= 10 %", "kun LICSA -111 M= 15 % simulaattori muutetaan 1 nopeudelle", "kun LICSA -111 M= 12 % palauta syöttö FA-106 säiliöön operoimalla kaskadipiiriä siten, että LICSA-111 O=12 kg/h ja FFIC-101 on 20 % propyleeniglykolia", "halutessasi voit nopeuttaa simulaattoria"], "Tarkkaile seuraavia:", ["EA-104 ohjausta", "huipunpalautuksen tarvetta", "paine-eroa", "pohjatuotteen määrää ja GA-105 ohjausta", "tisleen määrää ja GA-104 ohjausta", "ylimenolauhduttimen jäähdytystarvetta (TICSA-123)", "tarkkaile kaikkia lämpöjä myös keskiosan TISA-125", "erittele taulukoituna jokainen muutos ja selvitä mistä muutos johtuu"]],
		evaluationText: "Selvitys teoriasta, kuvakaappaukset työpöydältä, prosessi-ikkunat, trendit, säätöpiirit."
	},
	{
		index: 5,
		imgUrl: "images/mission_disturbance.png",
		type:"Häiriö",
		description: "Huipunpalautuksen merkityksen selvittäminen. Häiriön vaikutuksen tarkkailu ja häiriön vaikutusten syiden selvittäminen. Suunnitelman tekeminen häiriön operoinnista sekä prosessin ajaminen tasapainoon häiriön jälkeen.",
		infoText: ["Huipunpalautuspumppu GA-108 pysähtyy", "1. Vaihe:", ["seurataan häiriön syntyminen ja tarkkaillaan häiriön vaikutuksia prosessiin", "mille laitteelle tuli lukitus? (tarkasta myös PI-kaaviosta)", "miksi lukitus tuli?", "mitä prosessissa tapahtui lukituksen tulon jälkeen?"], "2. Vaihe:", ["käynnistetään simulaattori uudelleen", "suunnitellaan kuinka häiriön tultua operoidaan prosessi takaisin tasapainoon", "operoidaan prosessi tasapainoon suunnitelman mukaisesti /perustellaan operoinnit", "kun otat huipunpalautuksen ja pohjankiehutuksen takaisin päälle, kannattaa ohjausarvon trendeistä katsoa mitkä ohjaukset olivat ennen GA-108 pysähtymistä niin voit nostaa TICSA-122 ohjaukset lähelle ko. arvoa heti"], "Kysymyksiä:", "Milloin pohjatuotteen ulosotto pitää lopettaa?"],
		evaluationText: "Kuvakaappaukset (prosessi-ikkunat, trendit, säätöpiirit). Selvitykset kirjallisina (huipunpalautuksen merkitys, suunnitelma häiriön operoinnista)."
	},
	{
		index: 6,
		imgUrl: "images/mission_disturbance.png",
		type: "Häiriö",
		description: "Häiriön vaikutuksen tarkkailu ja häiriön vaikutusten syiden selvittäminen. Suunnitelman tekeminen häiriön operoinnista sekä prosessin ajaminen tasapainoon  häiriön jälkeen.",
		infoText: ["1. Vaihe:", ["seurataan häiriön syntyminen ja tarkkaillaan häiriön vaikutuksia prosessiin", "kuinka kauan voit ottaa pohjatuotetta ulos?", "lopeta pohjatuotteen ulosotto sitten kun sitä ei enää saa ottaa ulos", "jatka häiriön vaikutusten tarkkailua", "mille laitteelle tuli lukitus? (tarkasta myös PI-kaaviosta)", "miksi lukitus tuli?", "mitä prosessissa tapahtui lukituksen tulon jälkeen?"], "2. Vaihe:", ["käynnistetään simulaattori uudelleen", "suunnitellaan kuinka häiriön tultua prosessia voidaan ajaa 15 min siten, että EA-104 on poissa käytöstä niin, että ei tule lukitusta", "ajetaan sama häiriö uudelleen niin, että EA-104 on n. 15 minuuttia poissa käytöstä", "operoidaan prosessi tasapainoon perustellen operoinnit"], "Tarkkaile ja raportoi taulukkona", ["kolonnin kaikkia lämpöjä", "paine-eroa"]],
		evaluationText: "Kuvakaappaukset (prosessi-ikkunat, trendit, säätöpiirit). Selvitykset kirjallisina (havainnot prosessin lämmöistä ja paine-erosta eri vaiheissa taulukoituna, suunnitelma häiriön operoinnista, perustelut operoinneille)."
	},
	{
		index: 7,
		imgUrl: "images/mission_disturbance.png",
		type: "Häiriö",
		description: "Tarkkaile häiriön vaikutuksia ja selvitä niiden syitä. Tee suunnitelma häiriön operoinnista sekä aja prosessi tasapainoon  häiriön jälkeen.",
		infoText: ["1. Vaihe:", ["seuraa häiriön syntyminen ja tarkkaile häiriön vaikutuksia prosessiin", "mille laitteelle tuli lukitus? (tarkasta myös PI-kaaviosta)", "miksi lukitus tuli?", "miksi tällainen lukitus on olemassa?", "mitä prosessissa tapahtui lukituksen tulon jälkeen?"], "2. Vaihe:", ["käynnistetään simulaattori uudelleen", "pohjatuotepumppu GA-105 on 30 minuuttia huollossa", "pyri operoimaan prosessia siten että ei tule lukituksia", "kun 30 minuutin jälkeen saat pohjatuotepumpun takaisin niin operoi prosessi normaaliin tilaan tasapainotilan kautta", "mitä toimenpiteitä teit?", "erittele säätöpiireittäin ja laitteittain mitä operoit /miksi operoit"], "Huom! Operointi hallitusta ”stepeissä”, ei suoraan nollaan."],
		evaluationText: "Kuvakaappaukset työpöydältä (prosessi-ikkunat, trendit, säätöpiirit). Selvitykset kirjallisena (suunnitelma häiriön operoinnista ja kaikki mahdollinen taulukoituna)."
	},
	{
		index: 8,
		imgUrl: "images/mission_disturbance.png",
		type: "Häiriö",
		description: "Häiriön vaikutuksen tarkkailu ja häiriön vaikutusten syiden selvittäminen. Suunnitelman tekeminen häiriön operoinnista sekä prosessin ajaminen tasapainoon häiriön jälkeen.",
		infoText: ["1. Vaihe:", ["avaa FICQSA-109 täysin auki manuaalilla (MAN) O = 100%", "tarkkaile mitä tapahtuu", "kirjaa ylös mitä tapahtui/ missä järjestyksessä / miksi ?", "mikä lukitus tuli ensin?", "mitä se aiheutti ?", "miksi tällainen lukitus on olemassa?", "miksi muut lukitukset ovat olemassa?"], "2. Vaihe:", ["käynnistä simulaattori uudelleen", "suunnittele kuinka saman häiriön tultua operoit prosessin takaisin tasapainoon /perustele operoinnit", "avaa FICQSA-109 täysin auki manuaalilla (MAN) O = 100%", "automaatti on 15 min. korjattavana", "yritä hallita kolonni ja ajaa se tasapainotilaan niin, että voit 15 minuutin päästä operoida syötön takaisin kolonniin ja jatkaa tislausta"]],
		evaluationText: "Kuvakaappaukset (prosessi-ikkunat, trendit, säätöpiirit). Selvitykset kirjallisina (suunnitelma  operoinnista)."
	},
	{
		index: 9,
		imgUrl: "images/mission_crude_oil.png",
		type: "Tilan muutos",
		description: "Tutkitaan syötön vesi-propyleeniglykolisuhteen muutosta, jotta tisleen tuotevirtauksen muutos olisi 6 kg/h → 7,2 kg/h.",
		infoText: ["Kolonnin syöttö on 12 kg/h ja alkuperäinen syöttösuhde on 50/50.", "Tutkitaan kuinka paljon syötön vesi-propyleeniglykolisuhdetta pitää muuttaa, jotta tislettä saadaan 1,2 kg/h enemmän ilman kokonaismäärän muutosta.", "Miten lämmönsiirto kolonnissa muuttuu?"],
		evaluationText: "Kuvakaappaukset (prosessi-ikkunat, trendit, säätöpiirit)."
	},
	{
		index: 10,
		imgUrl: "images/mission_pid.png",
		type: "Säätöpiirit",
		description: "Tutkitaan miten parametrien muuttaminen vaikuttaa prosessiin.",
		infoText: ["1.", ["Operoidaan ensin vakiosyöttö 12 kg/h.", "Muutetaan TICSA-115 parametrejä Kp ja Ti vuorotellen yhtä kerrallaan ja nostetaan jokaisessa tehtävässä lämpötila 40 °C → 70 °C.", "Tee TICSA-115 trendit mittauksesta, asetuksesta ja ohjauksesta."], "Tehtävät:", "a)", ["Ti = 200 koko ajan", "muuta Kp 0,5 → 1,0 → 1,5 → 2"], "b)", ["Ti = 150", "Kp = 2"], "c)", ["Ti = 100", "Kp= 2"], "Tutki trendeistä paras parametripari.", "2.", ["Käytä parasta parametri paria ja pidä lämpötila vakiona 70 °C.", "Nosta syöttö 10 kg/h → 12 kg/h", "Tarkkaile trendeistä taphtumat."], "Miten parametrien muuttaminen vaikutti prosessiin?", "Mikä oli Kp:n vaikutus? Mikä Ti:n vaikutus?"],
		evaluationText: "Kuvakaappaukset (prosessi-ikkunat, trendit, säätöpiirit)."
	}
];


/**
 * Main page of Careeria (former Edupoli) Simulator "game"
 */
class EdupoliSimulatorPage extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
        }
    }

	getGames() {
			if (this.props.userPool && !this.props.company) {
				return [];
			}

			const pools = this.props.allPools || (this.props.userPool ? [this.props.userPool] : null);
			if (!pools || !this.props.company) {
				return [];
			}

			const result = [];
			pools
				.filter(userPool => (userPool.pool.company))
				.forEach(function (userPool) {
					userPool.pool.games.forEach(function (gameName) {
						result.push(gameName);
					});
				});

			return result;
	}

    render() {
		const licenses = this.getGames();
		let content;

		/** Check that the user has license to Careeria (Edupoli) simulator.
		* If not, show empty page.
		*/

		if (licenses.includes('edupoli-simulator')) {

			const texts = {
				simulatorExercises: <Translate value="simulatorExercises" />,
			};

			const styles = {
				container: {
					textAlign: 'center',
					fontSize: '16px',
				},
				backgroundTop: {
					paddingBottom: '25px',
				},
				backgroundBottom: {
					paddingTop: '25px',
					paddingBottom: '25px',
				},
				title: {
					padding: '50px',
					margin: '0px',
					textTransform: 'uppercase',
					lineHeight: '32px',
				},
			};

			content =
	            <div style={styles.container} className="page-container NAPCON-color">
	                <div style={styles.backgroundTop} className="background-block">
						<h1 style={styles.title} className="title">{texts.simulatorExercises}</h1>
						<EdupoliSimulatorInstructionsPanel />
						<EdupoliSimulatorDocumentsPanel />
	                </div >
					<div style={styles.backgroundBottom} className="background-block-right">
						<EdupoliSimulatorExercisePanel />
	                </div >
	            </div>;

        }
		else {
			content = <div></div>;
		}

        return (
			content
        );
    }
}

/**
 * Collapsible panel for instructions at the top of Careeria (Edupoli) Simulator page
 */
class EdupoliSimulatorInstructionsPanel extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
			open: false,
        }
    }

	// Return toggle icon to be used when open/closed
	getToggleIcon() {
		if (this.state.open === false) {
			return <Icon className="material-icons" style={{ color: 'white' }} >{"add"}</Icon>;
		} else {
			return <Icon className="material-icons" style={{ color: 'white' }} >{"remove"}</Icon>;
		}
	}

	handleClick() {
		if (this.state.open === false) {
			this.setState({open: true});
		}
		else if (this.state.open === true) {
			this.setState({open: false});
		}
	}

	handleDownloadClick() {
		const clientContent_DV5Launcher = napconCDN + "edupoli/dv5launcher/DV5LauncherSetup.exe";
		window.open(clientContent_DV5Launcher);
	}

	getInfoContainerStyle() {
		let style = {
				transition: 'max-height 1s ease-out',
				overflow: 'hidden',
				display: 'flex',
				flexDirection: 'column',
				backgroundColor: 'white',
				color: '#3C344C',
			};

		if (this.state.open === false) {
			style.maxHeight = '0';
			return style;
		}
		else if (this.state.open === true) {

			/* The height is not auto to make transition work.
			** ADJUST maxHeight based on content height.
			** NARROW SCREEN mobile support should be checked
			*/

			style.maxHeight = '400px';
			return style;
		}
	}

    render() {

		const texts = {
            gettingStarted: <Translate value="gettingStarted" />,
			download: <Translate value="download" />,
			dv5Step1: <Translate value="dv5Step1" />,
			dv5Step2: <Translate value="dv5Step2" />,
			dv5Step3: <Translate value="dv5Step3" />,
        };

		const styles = {
			container: {
				textAlign: 'center',
				margin: '0px 12% 25px 12%',
				boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
				borderRadius: '2px',
				padding: '0',
				backgroundColor: '#3C344C',
				color: 'white',
			},
			titleContainer: {
				cursor: 'pointer',
			},
			title: {
				display: 'inline-block',
				width: '80%',
				padding: '5px 0px',
				margin: '0px',
				textAlign: 'left',
			},
			titleIcon: {
				display: 'inline-block',
				width: '20%',
				position: 'relative',
				top: '5px',
				textAlign: 'right',
			},
			infoLine: {
				display: 'flex',
				padding: '20px 3% 20px 1%',
				width: '96%',
			},
			linkLine: {
				paddingBottom: '10px',
			},
			infoNumber: {
				width: '5%',
			},
			infoText: {
				width: '95%',
				textAlign: 'left',
			},
			listItem: {
				textAlign: 'left',
				padding: '0 5%',
			},
			iconStyle: {
				color: '#3C344C',
				fontSize: '16pt',
				left: '22px',
			},
			rightIcon: {
				textTransform: 'uppercase',
				visibility: 'hidden',
				padding: '6px',
				margin: '9px 10px',
				height: 'auto',
				width: 'auto',
				border: '1px solid #3C344C',
				fontSize: '14px',
				borderRadius: '6px',
			},
		};

		const infoContainerStyle = this.getInfoContainerStyle();
        return (
            <div style={styles.container} className="mobile-margins-700">
				<div style={styles.titleContainer} onClick={this.handleClick.bind(this)} >
          			<div style={styles.title}>
						<h2 style={{paddingLeft: '25px'}}>{texts.gettingStarted}</h2>
					</div>
					<div style={styles.titleIcon}>
						<div style={{paddingRight: '25px'}} >{this.getToggleIcon()}</div>
					</div>
				</div>
				<div style={infoContainerStyle} >
					<div style={styles.infoLine} >
						<div style={styles.infoNumber} >1.</div>
						<div style={styles.infoText}>{texts.dv5Step1}</div>

					</div>
					<div style={styles.linkLine} >
						<MenuItem key={"DV5LauncherSetup.exe"} className="simulator--material-link" style={styles.listItem} onClick={this.handleDownloadClick.bind(this)}>
							<ListItemIcon className="fas fa-download" style={styles.iconStyle}/>
							<span style={{flex: 1}}>DV5LauncherSetup.exe</span>
							<div style={styles.rightIcon} className={"simulator--material-open"} >{texts.download}</div>
						</MenuItem>
					</div>
					<div style={styles.infoLine} >
						<div style={styles.infoNumber} >2.</div>
						<div style={styles.infoText}>{texts.dv5Step2}</div>
					</div>
					<div style={styles.infoLine} >
						<div style={styles.infoNumber} >3.</div>
						<div style={styles.infoText}>{texts.dv5Step3}</div>
					</div>
				</div>
            </div>
        );
    }
}

/**
 * Panel for downloadable teaching material documents
 */
class EdupoliSimulatorDocumentsPanel extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
        }
    }

	handleClick(pdfUrl) {
		window.open(pdfUrl);
	}

    render() {

		const texts = {
            materials: <Translate value="materials" />,
			open: <Translate value="open" />,
			simulatorDocuments: <Translate value="simulatorDocuments" />
        };

		const clientContent_materialsFolder = napconBuildVersion + "documents/clientmaterials/careeria/";
		const links = {
			clientContent_diagramDistilling: clientContent_materialsFolder + "Careeria_diagram_distilling.pdf",
			clientContent_diagramCompound: clientContent_materialsFolder + "Careeria_diagram_compound.pdf",
			clientContent_operatingInstructions: clientContent_materialsFolder + "Careeria_operating_instructions.pdf",
			clientContent_instructions: clientContent_materialsFolder + "Careeria_instructions.pdf",
			clientContent_instrumentationSymbols: clientContent_materialsFolder + "Careeria_instrumentation_symbols.pdf",
		};

		const styles = {
			container: {
				textAlign: 'center',
				margin: '0px 12% 25px 12%',
				boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
				borderRadius: '2px',
				padding: '0',
			},
			title: {
				padding: '5px 0px',
				margin: '0px',
				textAlign: 'left',
			},
			firstInfo: {
				padding: '0.66rem 1.6rem',
				textAlign: 'left',
			},
			iconButton: {
				width: '120',
    			height: '120',
    			padding: '30',
			},
			iconStyle: {
				color: '#3C344C',
				fontSize: '20pt',
			},
			rightIcon: {
				textTransform: 'uppercase',
				visibility: 'hidden',
				padding: '0.4rem',
				margin: '9px 10px',
				height: 'auto',
				width: 'auto',
				border: '1px solid #3C344C',
				fontSize: '0.93rem',
				borderRadius: '6px',
			},
			listItem: {
				textAlign: 'left',
				padding: '0 2.5rem',
			}
		};



        return (
            <div style={styles.container} className="main-page--content mobile-margins-700">
				<div>
          			<div style={styles.title}>
						<h2 style={{paddingLeft: '25px'}}>{texts.materials}</h2>
					</div>
				</div>
				<div >
					<div style={styles.firstInfo} >
					{texts.simulatorDocuments}
					</div>
					<div>
						<List>
							<MenuItem key={"Käyttöohje"} className="simulator--material-link" style={styles.listItem} 
								onClick={this.handleClick.bind(this, links.clientContent_instructions)}>
								<ListItemIcon className="far fa-file-pdf" style={styles.iconStyle} color={'#3C344C'}/>
								<span style={{ flex: 1 }}>Käyttöohje</span>
								<div style={styles.rightIcon} className={"simulator--material-open"} >{texts.open}</div>
							</MenuItem>
							<MenuItem key={"Tislaus-operointiohje"} className="simulator--material-link" style={styles.listItem} 
								onClick={this.handleClick.bind(this, links.clientContent_operatingInstructions)}>
								<ListItemIcon className="far fa-file-pdf" style={styles.iconStyle} color={'#3C344C'}/>
								<span style={{ flex: 1 }}>Tislaus-operointiohje</span>
								<div style={styles.rightIcon} className={"simulator--material-open"} >{texts.open}</div>
							</MenuItem>
							<MenuItem key={"PI-kaavio: Sekoitus ja kiintoaineen erotus"} className="simulator--material-link" style={styles.listItem} 
								onClick={this.handleClick.bind(this, links.clientContent_diagramCompound)}>
								<ListItemIcon className="far fa-file-pdf" style={styles.iconStyle} color={'#3C344C'}/>
								<span style={{ flex: 1 }}>PI-kaavio: Sekoitus ja kiintoaineen erotus</span>
								<div style={styles.rightIcon} className={"simulator--material-open"} >{texts.open}</div>
							</MenuItem>
							<MenuItem key={"PI-kaavio: Tislaus"} className="simulator--material-link" style={styles.listItem} 
								onClick={this.handleClick.bind(this, links.clientContent_diagramDistilling)}>
								<ListItemIcon className="far fa-file-pdf" style={styles.iconStyle} color={'#3C344C'}/>
								<span style={{ flex: 1 }}>PI-kaavio: Tislaus</span>
								<div style={styles.rightIcon} className={"simulator--material-open"} >{texts.open}</div>
							</MenuItem>
							<MenuItem key={"Instrumentoinnin piirrosmerkit ja tunnusnumerointi"} className="simulator--material-link" style={styles.listItem}
								onClick={this.handleClick.bind(this, links.clientContent_instrumentationSymbols)}>
								<ListItemIcon className="far fa-file-pdf" style={styles.iconStyle} color={'#3C344C'}/>
								<span style={{ flex: 1 }}>Instrumentoinnin piirrosmerkit ja tunnusnumerointi</span>
								<div style={styles.rightIcon} className={"simulator--material-open"} >{texts.open}</div>
							</MenuItem>
    					</List>
					</div>
				</div>
            </div>
        );
    }
}


/**
 * Panel for simulator exercises
 * DV5Launcher URL components dv5launcher:// index of the exercise / user token
 */
class EdupoliSimulatorExercisePanel extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
        }
    }

	handleClick(pdfUrl) {
		window.open(pdfUrl);
	}

	getSimulatorExercises() {
		return simulatorExercises.map((arr) => {
			const title = getMissionTitleByMissionId('edupoli-simulator', arr["index"], I18n._localeKey);
			const imgUrl = arr["imgUrl"];
			const type = arr["type"];
			const description = arr["description"];
			const infoText = arr["infoText"];
			const evaluationText = arr["evaluationText"];
			const index = arr["index"];
			return <EdupoliSimulatorExercise
				title={title} imgUrl={imgUrl} type={type} description={description} infoText={infoText} evaluationText={evaluationText} index={index} />
		});
	}

    render() {

		const texts = {
            materials: <Translate value="materials" />,
			open: <Translate value="open" />,
        };

		const styles = {
			container: {
				margin: '0px 12% 50px 12%',
				display: 'flex',
				flexWrap: 'wrap',
				justifyContent: 'flex-start',
			}
		}

		const exercises = this.getSimulatorExercises();

        return (
			<div style={styles.container} className="mobile-margins-700" >
				{exercises}
			</div>
        );
    }
}

/**
 * A simulator exercise element
 * Takes title (title), image url (imgUrl), exercise type (type), exercise description (description), and additional information text (infoText) as props
 */
class EdupoliSimulatorExercise extends Component {
    constructor(props, context) {
		super(props, context);
        this.state = {
			expanded: false,
		}
		this.infocontainer = React.createRef();
    }

	handleClick(pdfUrl) {
		window.open(pdfUrl);
	}

	addSmoothScroll(orig){
		for (let i = 0.1; i <= 1; i = i + 0.05) {
			let time = 100 + i * (500 - 300);
			setTimeout(function() {
				this.handleScrollToElement(i, orig);
			}.bind(this), time)
		  }
	}

	handleScrollToElement(x, orig) {
		const node = ReactDOM.findDOMNode(this);
		let change = x * (orig - node.offsetTop)
		document.querySelector('.main').scrollTop = orig - change - 80;
	}

	getParsedInfoText(infoText) {

		/* infoText should be in the format of strings inside an array.
		Earch string is a new <p> line.
		Bullet points should be strings in an array inside the main array.
		Sub-bullet points in the same way.
		Example: ["Normal line", ["Bullet point", ["Sub-bullet point"]]]*/

		if (Array.isArray(infoText)) {

		const arr1 = infoText;

		const textList = arr1.map(text => {
			// Check if array items are also arrays and map out contents
			if (Array.isArray(text)) {
				const array = text.map(arrayItem => {

					// Check if array items are also arrays and map out contents
					if (Array.isArray(arrayItem)) {
						const subArray = arrayItem.map(subArrayItem => {
							return <li>{subArrayItem}</li>;
						});
						return <ul>{subArray}</ul>;
					}
					// If array items are not arrays, treat them normally
					else {
						return <li>{arrayItem}</li>;
					}
				});
				return <ul>{array}</ul>;
			}
			else {
				// If array items are not arrays, treat them normally
				return <p>{text}</p>;
			}
		});
		return textList;
	}
	else {
		return <p>infoText should be inside an array</p>;
	}
	}

	handleExpand() {
		if (this.state.expanded === false) {
			this.setState({expanded: true});

			// Disable auto-scroll with small screens
			if (window.innerWidth > 1600) {

				/* Scroll to the position of the exercise element when expanding
				the additional information so that that the exercise does not "jump"
				out of sight */

				let orig = ReactDOM.findDOMNode(this).offsetTop; // original location in the screen
				this.addSmoothScroll(orig);
			}
		}
		else {
			this.setState({expanded: false});
		}
	}

	componentDidUpdate() {
		/* To return scroll of the infocontainer back to top after it has
		been minimized. */
        ReactDOM.findDOMNode( this.infocontainer.current ).scrollTop = 0;
    }

	handleDv5Open() {

		/* Request new token to be used as launch parameter for DV5Launcher.
		The portal cookie token will remain the same. */

		axios.post(`${API_URL}/auth/newtoken/`, { }, {headers: { Authorization: cookies.get('token') }})
		.then((response) => {

			const dv5Url = "dv5launcher://" + this.props.index.toString() + "/" + response.data.token.toString().replace(" ", "%20");
			const openedWindow = window.open(dv5Url);

			/* setTimeout will close the new blank tab after 10 s */
			setTimeout(
				function() {
					openedWindow.close();
				}
				.bind(this),
				10000
			);
		})
		.catch((error) => {
			errorHandler(() => {}, error.response, ERROR_RESPONSE);
		});
	}

	getExpandableStyles() {

		let styles = {
			exerciseContainer: {
				transition: 'all 0.4s ease-out',
				margin: '1%',
				width: '28%',
				boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
				borderRadius: '2px',
				padding: '20px',
				position: 'relative',
			},
			description: {
				height: '100px',
				textAlign: 'left',
			},
			additionalInfoContainer: {
				position: 'relative',
				backgroundColor: 'rgba(0,0,0,0.1)',
				height: '150px',
				overflowX: 'hidden',
				overflowY: 'hidden',
				margin: '20px 0',
			},
			additionalInfoText: {
				padding: '10px',
				textAlign: 'left',
				minHeight: '0',
			},
			additionalInfoExpandButton: {
				width: '100%',
				height: '100%',
				color: 'white',
				position: 'absolute',
				top: '0',
				backgroundColor: 'rgba(0,0,0,0.5)',
				visibility: 'hidden',
				cursor: 'pointer',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			},
			expandButtonSpacer: {
				visibility: 'hidden',
				height: '30px',
			},
			evaluationTextContainer: {
				height: '100px',
				paddingBottom: '20px',
			},
			additionalInfoExpandButtonLabel: <Translate value="expand" />,
			additionalInfoExpandButtonPositionClassName: ''
		};

		if (this.state.expanded === true) {
			styles.exerciseContainer.width = '59%';
			styles.description.height = '80px';
			styles.additionalInfoContainer.minHeight = '350px';
			styles.additionalInfoContainer.overflowY = 'scroll';
			styles.additionalInfoText.minHeight = '270px';
			styles.additionalInfoExpandButton.bottom = '0';
			styles.additionalInfoExpandButton.top = 'auto';
			styles.additionalInfoExpandButton.height = 'auto';
			styles.additionalInfoExpandButton.visibility = 'visible';
			styles.additionalInfoExpandButton.position = 'sticky';
			styles.additionalInfoExpandButton.backgroundColor = 'rgba(0,0,0,0.1)';
			styles.expandButtonSpacer.visibility = 'visible';
			styles.evaluationTextContainer.height = '60px';
			styles.additionalInfoExpandButtonLabel = <Translate value="minimize" />;
			styles.additionalInfoExpandButtonPositionClassName = ' position-sticky';
			return styles;
		}
		else {
			return styles;
		}
	}

    render() {

		const texts = {
            materials: <Translate value="materials" />,
			open: <Translate value="open" />,
			start: <Translate value="startExercise" />,
			evaluation : <Translate value="evaluation" />,
        };

		/* The height of the textfields should be adjusted according to
		amount of input text (description, infoText) */

		const styles = {
			title: {
				padding: '0',
				margin: '10px 0 20px 5px',
				lineHeight: '24px',
				height: '60px',
			},
			image: {
				height: '140px',
			},
			infotext: {
				paddingBottom: '10px',
			},
			evaluationText: {
				fontSize: '14px',
			},
			iconButton: {
				width: '120',
    			height: '120',
    			padding: '30',
			},
			iconStyle: {
				fontColor: '#3C344C',
				fontSize: '20pt',
			},
			rightIcon: {
				textTransform: 'uppercase',
				visibility: 'hidden',
				padding: '6px',
				margin: '9px 10px',
				height: 'auto',
				width: 'auto',
				border: '1px solid #3C344C',
				fontSize: '14px',
				borderRadius: '6px',
			},
			listItem: {
				textAlign: 'left',
			},
			expandLabel: {
				margin: '5px',
				textTransform: 'uppercase',
			},
			buttonSpacer: {
				height: '42px',
			},
			buttonContainer: {
				position: 'absolute',
				bottom: '20px',
				left: '0',
				width: '100%',
			}
		};

		const expandableStyles = this.getExpandableStyles();

		const infoText = this.getParsedInfoText(this.props.infoText);



		/* infoExpandButton position needs two values sticky and -webkit-sticky
		for Safari that cannot be easily set from React side. Therefore we change
		the CSS class. */
		const infoExpandButtonClassName = "simulator--exercise-infoexpandbutton" + expandableStyles.additionalInfoExpandButtonPositionClassName;

        return (
				<div style={expandableStyles.exerciseContainer} className="main-page--content simulator--exercise-container">
					<div style={styles.title}>
						<h2 style={{float: 'left', margin: '0'}}>{this.props.index}.</h2>
						<h2 style={{margin: '0 0 0 25px'}}>
							{this.props.title}
						</h2>
					</div>
					<img style={styles.image} src={napconBuildVersion + this.props.imgUrl} />
					<h3>{this.props.type}</h3>
					<div style={expandableStyles.description}>{this.props.description}</div>
					<div ref={this.infocontainer} className="simulator--exercise-infocontainer" style={expandableStyles.additionalInfoContainer} >
						<div style={expandableStyles.additionalInfoText}>{infoText}</div>
						<div style={expandableStyles.expandButtonSpacer}></div>
						<div className={infoExpandButtonClassName} style={expandableStyles.additionalInfoExpandButton} onClick={this.handleExpand.bind(this)} >
							<p style={styles.expandLabel}>{expandableStyles.additionalInfoExpandButtonLabel}</p>
						</div>
					</div>
					<div style={expandableStyles.evaluationTextContainer}>
						<div><b>{texts.evaluation}</b></div>
						<div style={styles.evaluationText}>{this.props.evaluationText}</div>
					</div>
					<div style={styles.buttonSpacer}></div>
					<div style={styles.buttonContainer}>
						<Button className="btn-filled" onClick={this.handleDv5Open.bind(this)}>{texts.start}</Button>
					</div>
				</div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userPool: state.userPool.currentPool,
        allPools: state.userPool.allPools,
        company: state.userPool.currentCompany,
    };

}

export default withRouter(connect(mapStateToProps)(EdupoliSimulatorPage));
