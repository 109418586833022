import { MAKE_ORDER, MAKE_CONTACT, MAKE_OWC_REGISTERATION } from '../actions/types';
import {pushToGTMDataLayer} from '../Helpers';

const INITIAL_STATE = {message: '', error: ''};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case MAKE_ORDER:
            window.dispatchEvent(new CustomEvent('notification', {detail: {message: 'order_created'}}));
            pushToGTMDataLayer({'event': 'orderCreated'});
            return {...state};
        case MAKE_OWC_REGISTERATION:
            //window.dispatchEvent(new CustomEvent('notification', {detail: {message: 'registeration_received'}}));
            pushToGTMDataLayer({'event': 'OWCRegisterationCompleted'});
            return {...state};    
        case MAKE_CONTACT:
            window.dispatchEvent(new CustomEvent('notification', {detail: {message: 'contact_request_created'}}));
            pushToGTMDataLayer({'event': 'contactRequestCreated'});
            return {...state};
    }
    return state;
}