import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {MISSIONCOLORS, OWN_USER_COLOR} from './Helpers';

class ColumnChart extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            isFirstRender: true
        }
        this.columnChart = React.createRef();
    }
    componentDidMount() {
        if (this.state.isFirstRender) {
            this.state.isFirstRender = false;
            setTimeout(this.resizeChart.bind(this), 100);
        }        
    }
    resizeChart() {
        const chart = this.columnChart.current.chart;
        chart.reflow();
    }
    render() {
        const config = {
            chart: {
                plotBorderColor: '#3c334c',
                plotBorderWidth: 2,
                type: 'column',
                height: 250,
            },
            credits: {
                enabled: false
            },
            legend: {
                enabled: false
            },
            plotOptions: {
                column: {
                    color: OWN_USER_COLOR,
                    colors: MISSIONCOLORS,
                },
                series: {
                    colorByPoint: this.props.useMissionColors
                }
            },
            series: [{
                name: this.props.seriesName,
                data: this.props.data
            }],
            title: {
                text: null
            },
            xAxis: {
                categories: this.props.categories,
                title: {
                    text: null
                },
                allowDecimals: false,
                labels: {
                    rotation: 270
                },
                tickInterval:1
            },
            yAxis: {
                title: {
                    text: null
                },
                max: this.props.yMax || 1,
                allowDecimals: false
            }
        };

        if (window.location.href.indexOf("openMissions") !== -1) {
            config.chart.width = 750;
        }

        return (
            <div>
                <h2 className="header2 admin-total-chart--title">{this.props.title}</h2>
                <HighchartsReact highcharts={Highcharts} ref={this.columnChart} oneToOne={true} options={config}/>
            </div>
        );
    }
}

ColumnChart.propTypes = {
    data: PropTypes.array,
    title: PropTypes.node,
    categories: PropTypes.array,
    yMax: PropTypes.number
};

export default ColumnChart;