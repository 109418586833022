import React from 'react';

import inviationImage from '../../public/images/userguide_images/getting_started_1.png';
import formImage from '../../public/images/userguide_images/getting_started_2.png';
import listGameImage from '../../public/images/userguide_images/getting_started_3.png';
import NavigationButton from './NavigationButton';

const GettingStarted = () => {
  
  return (
    <div className="content-userguide">
      <h1 className="section-header">Section 1 Gettings started as player</h1>
      <div>You should receive an invitation to your e-mail account. Click the “Activate account here” link or paste the link into your web browser window.</div>
      <div>PRO TIP: Google Chrome is the preferred browser.</div>
      <img src={inviationImage} style={{ width: "50%", margin: "30px 0" }} alt="logo" />
      <div>You are then directed to your account activation page, where you can fill in the required information.</div>
      <img src={formImage} style={{ width: "50%", margin: "30px 0" }} alt="logo" />
      <div>Fill in the necessary information and agree to the Privacy Policy and Terms of Service, and click the Save button.</div>
      <div>You should automatically be logged in, and able to access the Games from the “Games” menu. If not, go to <a href="https://napcongames.com/">www.napcongames.com</a> and fill in your login information.</div>
      <img src={listGameImage} style={{ width: "50%", margin: "30px 0" }} alt="logo" />
      <NavigationButton prevName={null} prevLink={null} nextName="Playing Mission" nextLink="/userguide/playingMission"/> 
      
    </div>
  );
};


export default GettingStarted