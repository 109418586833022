import React, { Component } from 'react';

export default class CountDownTimer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      completed: 0,
    };
  }

  componentDidMount() {
    this.progress(this.props.timeInSeconds);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  componentWillReceiveProps() {
    this.progress(this.props.timeInSeconds);
  }

  progress(completed) {
    if (completed >= 0) {
      this.setState({ completed });
      this.timer = setTimeout(() => this.progress(completed - 1), 1000);
    }
  }

  render() {
    return (
      <span style={{ width: '22px', paddingLeft: '8px', color: 'white' }}>{this.state.completed} </span>
    );
  }

}