import React from 'react';
import NavigationButton from './NavigationButton';

const GamePortals = () => {
  
  return (
    <div className="content-userguide">
      <h1 className="section-header">Section 4 Games Portal</h1>
        <div>NAPCON Games Portal is a public website at <a href="https://napcongames.com/">https://napcongames.com</a>. It is a platform that offers access to our training games portfolio. At the time of writing the publicly available games are Distiller and Furnace.</div>
        <div>For the regular user the portal provides access to the games the user has a license for as well as his/her personal statistics about the games.</div>
        <div>For the administrator the portal provides license pool management features and enhanced statistics features. A license pool hosts the collection of user licenses a company has bought for a specific game, and the management features provide the company ways to manage these licenses.</div>
        <h2>Statistics</h2>
          <div>In the menu, click “Statistics” and you will be brought to a statistics page. Here you can follow your own progress, see which badges you have earned and how you have played the different missions.</div>
          <div>You can switch statistics for the different Games from the top menu.</div>
          <h3>Statistics view for the player</h3>
            <ul>
              <li>Achievements</li>
              <ul>
                <li>See earned achievements and progress towards yet unearned achievements</li>
              </ul>
              <li>Statistics per mission</li>
              <ul>
                <li>Highs cores</li>
                <ul>
                  <li>Personal top 3</li>
                  <li>License pool top 10</li>
                  <li>High score distribution</li>
                  <li>Time filters: All time, last 6 months, last 3 months</li>
                </ul>
                <li>Total number of times mission has been played</li>
                <li>Total number of times mission has been aborted</li>
                <li>Total time mission has been played</li>
                <li>Average time mission has been played</li>
              </ul>
            </ul> 
          <h3>Statistics for the administrator</h3>
            <div>All statistics are filterable by:</div>
            <ul>
                    <li>Time: last 30 days, last 3 months, last 6 months, all time</li>
                    <li>Specific user or group</li>
            </ul>
            <div>Total statistics for the license pool:</div>
            <ul>
                    <li>Total play time</li>
                    <li>Average play time</li>
                    <li>Active users (number of users who have completed at least one mission)</li>
                    <li>Total points (all time)</li>
                    <li>Number of new high scores</li>
                    <li>Graphs</li>
                    <ul>
                      <li>Number of unique completion per mission</li>
                      <li>Number of play times per mission</li>
                      <li>Number of active users per month</li>
                    </ul>
                    <li>Statistics per mission</li>
                    <ul>
                      <li>High scores</li>
                      <ul>
                        <li>License pool top 10</li>
                        <li>High score distribution</li>
                      </ul>
                      <li>Total number of times mission has been played</li>
                      <li>Total number of times mission has been aborted</li>
                      <li>Total time mission has been played</li>
                      <li>Average time mission has been played</li>
                    </ul>
            </ul>
        <div>Statistics are downloadable as a PDF.</div>
        <hr className="sidebar-userguide-separator"/>
        <h2>License pool features for the administrator</h2>
          <ul>
            <li>Invite a new user or administrator (create a new account) or an existing user/administrator</li>
            <li>Remove users/administrators from the license pool (account will not be deleted, only removed from the selected pool)</li>
            <li>Promote a user to administrator or demote an administrator to user</li>
            <li>Create and remove groups</li>
          </ul>
      <NavigationButton prevName="Your Account" prevLink="/userguide/yourAccount" nextName="Learning Objectives" nextLink="/userguide/learningObjectives"/>
    </div>
  );
};


export default GamePortals