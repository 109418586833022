
import { GET_ACTIVE_USERS, POOL_CHANGE, ERROR_RESPONSE } from '../actions/types';

const INITIAL_STATE = { data: [], message: '', error: ''};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case ERROR_RESPONSE:
            return { ...state, error: action.payload };
        case GET_ACTIVE_USERS:
            return { ...state, data: action.payload };
        case POOL_CHANGE:
            return { ...state, data: []};
    }
    return state;
}