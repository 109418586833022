
import { ERROR_RESPONSE, EXTERNAL_SAVE_ERROR, GET_STATS, GET_STATS_MISSION, GET_STATS_MISSION_POOL, APPROVE_SCORE, EXTERNAL_SAVE_STARTED, EXTERNAL_SAVE_COMPLETE, 
    POOL_CHANGE, MISSION_PLAYED, RENDER_STATS_PDF } from '../actions/types';

const clone = require('lodash/clone');

const INITIAL_STATE = { stats: [], missionStats: {}, missionStatsPool: {}, message: '', error: '' };

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case ERROR_RESPONSE:
            return { ...state, error: action.payload };
        case GET_STATS:
            return { ...state, stats: action.payload };
        case GET_STATS_MISSION:
            let newMissionStats = clone(state.missionStats);
            if (action.payload.length > 0) {
                newMissionStats[action.payload[0].missionId] = action.payload;
            } else {
                newMissionStats[action.missionId] = [];
            }
            return { ...state, missionStats: newMissionStats};
        case GET_STATS_MISSION_POOL:
            let missionStatsPool = clone(state.missionStatsPool);
            if (action.payload.length > 0) {
                missionStatsPool[action.payload[0].missionId] = action.payload;
            }
            return { ...state, missionStatsPool };
        case APPROVE_SCORE:
            return { ...state };
        case EXTERNAL_SAVE_STARTED:
            return { ...state, externalSaveInProgress: true };
        case EXTERNAL_SAVE_COMPLETE:
            return { ...state, externalSaveInProgress: false, externalSaveSuccess: true };
        case EXTERNAL_SAVE_ERROR:
            return { ...state, error: action.payload, externalSaveInProgress: false, externalSaveSuccess: false };
        case POOL_CHANGE:
            return { ...state, stats: [], missionStats: {}, missionStatsPool: {} };
        case MISSION_PLAYED:
            if (state.missionStats.hasOwnProperty(action.missionId)) {
                const missionStatsEmptied = clone(state.missionStats);
                delete missionStatsEmptied[action.missionId];
                return { ...state, missionStats: missionStatsEmptied };
            }
            return { ...state };
        case RENDER_STATS_PDF:
            return { ...state, renderingPdf: action.payload };
        default: return state;
    }
}
