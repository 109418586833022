import React, { Component } from 'react';
import { Translate } from 'react-i18nify';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { DialogTitle, DialogContent, DialogActions, Paper } from '@material-ui/core';

class LicensePopup extends Component {
	state = {
        open: false,
		title: "",
		linkName: "",
		content: this.props.content,
        pdfUrl: {},
        date: "",
		blockBackgroundClick: true,
	};

	getDoc(content) {
		if (content == "privacyPolicy") {
			return (<div>
				<h2>PRIVACY POLICY - NAPCON SERVICES</h2>

				<h3>1. INTRODUCTION</h3>
				<p>This Privacy Policy applies whenever you play NAPCON™ GAMES or otherwise access any of NAPCON Services. Any terms not defined in this Privacy Policy shall be given the meaning allocated to them in the <a href={termsOfServiceUrl} target="_blank">Terms of Service</a>.</p>
				<p>Please read this Privacy Policy before using NAPCON Services. Neste Engineering Solutions respects your privacy and endeavors to safeguard your privacy when you use NAPCON Services.</p>
				<p>By using NAPCON Services, you express your agreement to this Privacy Policy. If you do not agree to the terms of this Privacy Policy, please do not use NAPCON Services.</p>
				<p>This Privacy Policy describes:</p>
				<ul>
					<li>What personal data we collect, how we collect it, and why</li>
					<li>How we use such personal data and with whom we share it</li>
					<li>How you can access and update such personal data</li>
					<li>The choices you can make about how we collect, use, and share your personal data</li>
					<li>How we protect your personal data</li>
				</ul>
				<p>If you do not want us to collect, store, use or share your personal data in the ways described in this Privacy Policy, you may not play NAPCON GAMES or use NAPCON Services.</p>

				<h3>2. DATA WE COLLECT AND HOW WE COLLECT IT</h3>
				<h4>2.1. General</h4>
				<p>We will only collect personal data to the extent necessary for providing our NAPCON Services. Personal data may be collected, combined and used for the purposes of creating user accounts, user profiles, using, maintaining and developing NAPCON Services, behavioral marketing, contacting users, providing services and products, sending customer information and marketing communications (as outlined below) to users, enabling use of NAPCON Services and communication in NAPCON Services and between users, conducting research and creating reports for internal use.</p>

				<h4>2.2. User Data</h4>
				<p>When you create your Account and use NAPCON Services, you may give us data directly and we will store such User Data on our systems and use it for the purposes described in this Privacy Policy. Your Account-specific information may include:</p>
				<ul>
					<li>your first and last names;</li>
					<li>your Organization;</li>
					<li>your e-mail address;</li>
					<li>a password;</li>
					<li>an Account number that is created in NAPCON Services and used to identify your profile;</li>
					<li>your Game Data; and</li>
					<li>other information that helps us identify you when you access your Account or helps us improve NAPCON Services.</li>
				</ul>
				<p>We are not responsible for any personal data you or the Organization choose to make public.</p>

				<h4>2.3. Game Data</h4>
				<p>We may collect and process following data when you use NAPCON Services and NAPCON GAMES:</p>
				<ul>
					<li>time of play by sessions and in total;</li>
					<li>scores and levels;</li>
					<li>correct and wrong answers;</li>
					<li>interactions within the game and with other users;</li>
					<li>game state and the date and time of activity on our websites or games; and</li>
					<li>other session specific information.</li>
				</ul>

				<h4>2.4. Cookies and Automated Information Collection</h4>
				<p>We and service providers acting on our behalf, such as Google Analytics, may store log files and use tracking technologies such as:</p>
				<ul>
					<li>cookies, which are small pieces of data transferred to your mobile device or computer for record-keeping purposes; and</li>
					<li>local shared objects, also known as flash cookies, which help us to reduce fraud, remember your in-game preferences and speed up loading times.</li>
				</ul>
				<p>We and our service providers may use these log files, tags, and tracking technologies to collect and analyze certain kinds of technical data, including:</p>
				<ul>
					<li>IP addresses;</li>
					<li>the type of computer or mobile device you are using;</li>
					<li>your operating system version;</li>
					<li>your mobile device's identifiers, like your MAC Address, Identifier For Advertising (IDFA), and/or International Mobile Equipment Identity (IMEI);</li>
					<li>your browser types;</li>
					<li>your browser language;</li>
					<li>referring and exit pages, and URLs;</li>
					<li>platform type; and</li>
					<li>the amount of time spent on particular pages.</li>
				</ul>
				<p>We will connect this data from your Account.</p>

				<h4>2.5.	Other Information from Your Mobile Device</h4>
				<p>If you use NAPCON Services on your mobile device, in addition to your device identifiers, we may also collect:</p>
				<ul>
					<li>the name you have associated with your device;</li>
					<li>your telephone number;</li>
					<li>your country;</li>
					<li>your specific location data; and</li>
					<li>information about other apps you have on your device.</li>
				</ul>
				<p>We will always ask you to give your explicit consent to process your location data. </p>

				<h4>2.6.	Customer Support Correspondence</h4>
				<p>When you ask for help from our customer support team, we will collect and store the contact information you give them (generally, your name and e-mail address), information about your game play or activity on NAPCON Services, and your Account details. We may also store the communications you have with the customer support team and any information in those communications in order to provide support and improve NAPCON Services.</p>

				<h3>3. HOW WE USE THE DATA WE COLLECT</h3>
				<p>The main use of the information we collect and store is to provide a better gaming experience, but there are other uses as well. The purposes for which we may collect and store your personal data include:</p>
				<ul>
					<li>to monitor and analyze game progress and performance, results and scores;</li>
					<li>to notify users and Organizations of their results and targets for development;</li>
					<li>to provide technical support and respond to user inquiries;</li>
					<li>to monitor and analyze the use of NAPCON Services;</li>
					<li>to protect the safety and well-being of our users;</li>
					<li>to protect Neste Engineering Solutions’ rights and property in connection with the Services;</li>
					<li>to prevent fraud or potentially illegal activities, and to enforce the Terms of Service;</li>
					<li>to manage and deliver contextual and behavioral advertising;</li>
					<li>to notify users of in-game updates, new products or promotional offers;</li>
					<li>to administer rewards, surveys, sweepstakes, contests, or other promotional activities or events sponsored or managed by Us or our business partners;</li>
					<li>to comply with our legal obligations, resolve any disputes We may have with you or other users, and to enforce our agreements with third parties;</li>
					<li>to conduct research; and</li>
					<li>to provide Organizations with game progress and performance, improvement areas, results and scores.</li>
				</ul>
				<p>One important use of your information is communication. If you have provided your email address to us, We will use it to respond to customer support inquiries, and keep you informed of your in-game activity.</p>

				<h3>4. INFORMATION SHARED WITH YOUR ORGANIZATION</h3>
				<p>If your use of NAPCON Services is based on a License by an Organization, your Organization has access to your personal data and may use it subject to the applicable laws and agreements from time to time within the scope of their License.</p>
				<p>When processing your personal data collected and processed in NAPCON Services to which the Organization has access, the Organization acts as a Data Controller and you should direct any inquiries in respect of the use of your personal data by your Organization to the respective contacts of the Organization.</p>
				<p>We shall not be held accountable for any misuse of your personal data or the failure to protect it.</p>

				<h3>5. HOW WE SHARE YOUR PERSONAL DATA TO THIRD PARTIES</h3>
				<h4>5.1. General</h4>
				<p>We may disclose aggregated information (information about you and other users collectively that is not intended to specifically identify you) and other non-personal data about our users for industry analysis, demographic profiling, marketing, analytics, advertising, and other business purposes.</p>
				<p>With your consent, we may share your personal data with third parties or allow them to collect your information from the Services. We may disclose your personal data to our affiliates for the purposes of providing NAPCON Services.</p>
				<p>Notwithstanding the foregoing, personal data may be disclosed to third parties if required under any applicable law or regulation or order by competent authorities, or in order to enforce the Terms of Service and to investigate possible infringing use of the Services.</p>
				<p>Your personal data, and the contents of all of your online communications in the Services and between you and us may be accessed and monitored by us or disclosed to a third party:</p>
				<ul>
					<li>if we have a good faith belief that we have to disclose the information in response to lawful requests by public authorities, including to meet national security or law enforcement requirements, or legal process (for example, a court order, search warrant or subpoena);</li>
					<li>to satisfy any laws or regulations that apply;</li>
					<li>where we believe that NAPCON Services are being used in committing a crime, including to report such criminal activity or to share information with other companies and organizations for the purposes of fraud protection, credit risk reduction, and other security precautions;</li>
					<li>when we have a good faith belief that there is an emergency that poses a threat to the health and/or safety of you, another person or the public generally; and</li>
					<li>to protect the rights or property of Neste Engineering Solutions and applicable third parties, including to enforce the Terms of Service.</li>
				</ul>

				<h4>5.2. Third-Party Service Providers</h4>
				<p>We may include advertising within NAPCON Services. We do not actively share personal information with third-party advertisers for their direct marketing purposes unless you give us your consent.</p>
				<p>When advertisers or ad networks place ads in our Services, they may collect or we may share the following types of data from within our Services:</p>
				<ul>
					<li>performance data (like the number of clicks on an advertisement);</li>
					<li>aggregated or de-identified information about you and other users collectively that is not intended to specifically identify you (like users between the ages of 21 and 25 who live in Helsinki);</li>
					<li>certain technical information (for example, IP addresses, non-persistent device identifiers such as IDFAs, and de-identified persistent device identifiers such as a hashed Android ID); and</li>
					<li>your social network ID.</li>
				</ul>
				<p>The data collected may be used to:</p>
				<ul>
					<li>measure how effective ads are, to offer you targeted advertising in order to personalize your experience by showing you advertisements for products and services that are more likely to appeal to you (a practice known as behavioral advertising); and/or</li>
					<li>undertake web analytics (like Google Analytics which is used to analyze traffic and other user activity to improve your experience).</li>
				</ul>
				<p>Advertisers or ad networks may collect this information through the use of tracking technologies like browser cookies and web beacons, and they may use a single tracking technology or multiple tracking technologies at the same time.</p>
				<p>Please note, after clicking on a third party advertisement, you may no longer be on a site hosted by Neste Engineering Solutions. Neste Engineering Solutions is not responsible for any content or settings on third party sites. </p>
				<p>If you do not want to receive tailored in-application advertisements from third parties, please see Section 6 ”How to Access, Update and Manage Your Information”.</p>

				<h3>6. HOW TO ACCESS, UPDATE AND MANAGE YOUR INFORMATION</h3>
				<h4>6.1	Accessing and Updating Your Personal data</h4>
				<p>If you want to review, delete, or change the personal data or have additional questions, email us at <a href="mailto:napcongames@neste.com">napcongames@neste.com</a>. We will respond to your request within thirty days. If you want to review, delete, or change the personal data processed by the Organization, contact the Organization directly.</p>

				<h4>6.2	Stopping Use of Your Personal data</h4>
				<p>If you no longer want Neste Engineering Solutions to make active use of your personal data, you may send an e-mail to <a href="mailto:napcongames@neste.com">napcongames@neste.com</a>. Place "Delete My Account" in the subject line and include your first name, last name and e-mail address in the body of the e-mail. We will respond to your request within thirty days. Please note that certain records, for example those relating to payments or customer service matters, will be held for legal and accounting purposes.</p>
				<p>To stop the use of your personal data by your Organization, please contact your Organization directly.</p>

				<h4>6.3. Opting Out of Third-Party Tailored Advertising</h4>
				<p>If you do not want to receive tailored in-application advertisements from third parties that relate to your interests in apps on your mobile device, you may opt out by adjusting the ad tracking settings on your device. You can also reset the "Advertising Identifier" (like an IDFA) from your mobile device's settings page, which will prevent continued use of existing behavioral data tied to the previous "Advertising Identifier." </p>
				<p>Further, depending on the platform provider (such as Apple or Google), you may be able to download apps, such as the Digital Advertising Alliance's "AppChoices" app, that offer to provide you with control regarding the collection and use of cross-app data for tailored advertising. Like the opt-out tools mentioned above for web, these mobile opt-out tools are provided by third parties, not Neste Engineering Solutions. Neste Engineering Solutions does not control or operate these tools or the choices that advertisers and others provide through these tools.</p>
				<p><b><i>Please note that you may still receive advertisements from third parties within NAPCON Services even if you opt out of tailored advertising but they will not be based on your activity across unrelated web sites or apps.</i></b></p>

				<h4>6.4. Opting Out of Cookie Tracking</h4>
				<p>You can set your web browser to warn you about attempts to place cookies on your computer or limit the type of cookies you allow. Flash cookies operate differently than browser cookies and cookie management tools available in a web browser may not remove flash cookies.</p>
				<p>If you disable cookies, you may lose some of the features and functionality of NAPCON Services because some cookies are necessary to track and enhance your game activities.</p>

				<h3>7. OUR POLICIES CONCERNING CHILDREN</h3>
				<p>If we learn that we have inadvertently gathered personal data from a child under 15, we will take reasonable measures to promptly remove that data from our records.</p>

				<h3>8. PRIVACY POLICIES OF LINKED THIRD-PARTY SERVICES AND ADVERTISERS</h3>
				<p>Our websites, NAPCON Services and NAPCON GAMES may contain advertisements from third-party services, which are companies other than Neste Engineering Solutions that may link to their own websites, online services or mobile applications. We are not responsible for the privacy practices or the content of these third-party services. If you have any questions about how these third-party services use your information, you should review their policies and contact them directly.</p>

				<h3>9. TRANSFERS OUTSIDE OF THE EU AND THE EEA</h3>
				<p>We may transfer your personal data to affiliated entities or to other third parties outside the EU and the European Economic Area. Please note that these countries and jurisdictions may not have the same data protection laws as your own jurisdiction, and we take steps to ensure adequate safeguards are in place to enable transfer of information to these third countries.</p>

				<h3>10. HOW LONG WE KEEP YOUR INFORMATION</h3>
				<p>We will not retain your personal data for longer than is necessary for the defined purpose of the processing of personal data or for legal requirements. For instance, we may retain some information for a few years after you have closed your Account if this is necessary to meet our legal obligations or to exercise, defend or establish legal rights.</p>
				<p>The information retention by your Organization shall be subject to the respective retention policies of your Organization.</p>

				<h3>11.	SECURITY OF YOUR INFORMATION</h3>
				<p>We implement technical and organizational security measures to help protect the security of your personal data both online and offline. We take steps to ensure that your personal data is treated securely and in accordance with this Privacy Policy. These measures vary based upon the sensitivity of your data.</p>
				<p>Your Account information is protected by the password you use to access your Account. It is important that you protect and maintain your Account's security and that you immediately tell us of any unauthorized use or suspected unauthorized use of your Account. If you forget the password to your Account, NAPCON Services allow you to request that instructions be sent to you that explain how to reset your password. </p>
				<p>While we take precautions against possible security breaches of NAPCON Services and our customer databases and records, no website or Internet transmission is completely secure. We cannot guarantee that unauthorized access, hacking, data loss, or other breaches will never occur, and we cannot guarantee the security of your information while it is being transmitted to NAPCON Services. Any transmission is at your own risk. If you have questions about the security of our websites, please contact us at <a href="mailto:napcongames@neste.com">napcongames@neste.com</a>.</p>

				<h3>12.	CHANGES TO THE PRIVACY POLICY</h3>
				<p>If we decide to make material changes to this Privacy Policy, we will tell you and other users by placing a notice on <a href="https://napcongames.com" target="_blank" >www.napcongames.com</a> or its equivalent in-game, or by sending you a notice to the e-mail address we have on file for you prior to the change becoming effective. We may supplement this process by placing notices on game blogs, social network pages, and/or forums and on other Neste Engineering Solutions websites. You should periodically check <a href="https://napcongames.com" target="_blank" >www.napcongames.com</a> and this Privacy Policy for updates.</p>

				<h3>13.	CONTACT US</h3>
				<p>If you have any questions, comments or concerns regarding the Privacy Policy or practices, please send an e-mail to <a href="mailto:napcongames@neste.com">napcongames@neste.com</a>.</p>

			</div>);
		}
		else {
			return (<div>Document not found.</div>);
		}
	};

	componentDidMount() {
		if (this.props.content == "privacyPolicy") {
			this.setState({date: <Translate value="privacyPolicyUpdatedDate" tag="h4" />,pdfUrl: privacyPolicyUrl, title: <Translate value="privacyPolicy" tag="h2" />, linkName: <Translate value="privacyPolicy" />});
		}
        this.setState({open: this.props.modalOpen})
    }

	handleAccept = (event) => {
		this.props.handleAccept(event.target.value);
		this.setState({open: false});
	};

	handleDecline = (event) => {
		this.props.handleDecline(event.target.value);
		this.setState({open: false});
	};

	handleClick = () => {
		window.open(this.state.pdfUrl);
	};

	render() {

		const actions = [
            
            <Button
                style={{margin: "10px 10% 10px 10%", padding: "8px 25px 8px 25px", color: "#42aae0", backgroundColor: "#dff0ff"}}
                key={"Decline"}
                onClick={this.handleDecline}>Decline</Button>,

            <Button
                style={{margin: "10px 20% 10px 0", padding: "8px 25px 8px 25px", color: "white", backgroundColor: "#42aae0"}}
                key={"Accept"}
                onClick={this.handleAccept}>Accept</Button>,
      
			<Button
				color="secondary"
				style={{margin: "10px 0px"}}
				key={"Download as PDF"}
				onClick={this.handleClick}
				>Download as PDF</Button>
		];

		return (
            <div>
				<Dialog
					className="dialog--legal-dialog"
					maxWidth = {"md"}
					open={this.state.open}
					onClose={this.handleClose}
					PaperComponent={Paper}
                    PaperProps={{className: "legal-body"}}
                    disableBackdropClick={this.state.blockBackgroundClick}
					>
                <DialogTitle disableTypography={true} className="policy-title"> 
                    <img src={napconBuildVersion + "images/napcon_logo.png"} className="title-logo" />
                    <div className="title-text">{this.state.title}<div className="date-text">{this.state.date}</div>
                    </div>
                </DialogTitle>
                   
					<DialogContent className="legal-body legal-dialog">{this.getDoc(this.state.content)}</DialogContent>
					<DialogActions>
						{actions}
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}

export default LicensePopup;
