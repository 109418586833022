import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { I18n, Translate }from 'react-i18nify';
import PageHeader from './PageHeader';
import Icon from '@material-ui/core/Icon';
import CircularProgress from '@material-ui/core/CircularProgress';

import { getUserHighscoreName } from './StatHelper';
import Tooltip from '@material-ui/core/Tooltip';

/**
 * Statistics header component.
 * Has admin and user view selection and group selection for admin user.
 */
class StatisticsHeader extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        const adminDropDown = this.props.adminRights ? <StatisticsHeaderAdmin {...this.props} /> : null;
        let icon = null;
        if (this.props.renderingPdf) {
            icon = <div className="title--left-line--icon"> <Translate value="download" />
                <CircularProgress className={"statistics-header--print-icon"} size={18} thickness={3} />
            </div>
        }
        else if (this.props.adminRights && this.props.adminView) {
            const iconStyle = {
                cursor: "pointer", 
                fontSize: "21px",
            };
            icon = <div className="title--left-line--icon" onClick={this.props.onRenderPdf.bind(this)}>
                    <Translate value="download"/>
                    <Icon className="material-icons statistics-header--print-icon" color="primary" style={iconStyle}
                        title={I18n.t('downloadPdfTitle')} >file_download</Icon>
                </div>
        }

        return (
            <PageHeader {...this.props} subHeaderLangKey="statistics" icon={icon} subHeader={adminDropDown}/>
        );
    }
}

StatisticsHeader.propTypes = {
    adminRights: PropTypes.bool.isRequired,
    groups: PropTypes.array,
    users: PropTypes.array,
    onAdminViewChange: PropTypes.func.isRequired,
    onGroupViewChange: PropTypes.func.isRequired,
    onUserViewChange: PropTypes.func.isRequired,
    selectedGroup: PropTypes.string.isRequired,
    selectedUser: PropTypes.string.isRequired,
    adminView: PropTypes.bool.isRequired,
    gameName: PropTypes.node,
    onRenderPdf: PropTypes.func.isRequired,
    renderingPdf: PropTypes.bool,
};

/**
 * Component with select group and admin view drop down menus for admin statistics header
 */
class StatisticsHeaderAdmin extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            isDesktop: "statistics-header--admin-filter-drop-down"
        }
    }

    componentDidMount() {
        const self = this 
     /*
     After the component mounts, add a touch event listener to check whether user
     browsing on mobile to disable hovering class.
     */
        window.addEventListener('touchstart', function detectTouch() {
            self.setState({ isDesktop: "statistics-header--admin-drop-down-mobile" });
            window.removeEventListener('touchstart', detectTouch);
        });
    }

    /**
     * Handler for group drop down changed
     * @param event Event data
     */
    onGroupChange(event) {
        if (this.props.selectedGroup !== event.target.value) {
            this.props.onGroupViewChange(event.target.value);
        }
    }

    /**
     * Handler for user drop down changed
     * @param event Event data
     */
    onUserChange(event) {
        if (this.props.selectedUser !== event.target.value) {
            this.props.onUserViewChange(event.target.value);
        }
    }

    /**
     * Handler for admin view drop down changed
     * @param event Event data
     */
    onAdminChange(event) {
        if (this.props.adminView !== event.target.value) {
            this.props.onAdminViewChange(event.target.value);
        }
    }

    /**
     * Handler for time drop down changed
     * @param event Event data
     */
    onTimeChange(event) {
        if (this.props.selectedTime !== event.target.value) {
            this.props.onTimeChange(event.target.value);
        }
    }

    /**
     * Handler for game drop down changed
     * @param event Event data
     */
    onGameChange(event) {
        if (this.props.selectedGame !== event.target.value) {
            this.props.onGameChange(event.target.value);
        }
    }

    onMissionIdChange(event) {
        if (this.props.selectedMissionId !== event.target.value) {
            this.props.onMissionIdChange(event.target.value);
        }
    }

    /**
     * Returns items for admin view drop down menu
     * @returns {Array} Array of MenuItem components
     */
    getAdminItems() {
        let items = [];
        items.push(<MenuItem key={items.length} value={true}>{<Translate value="adminView"/>}</MenuItem>);
        items.push(<MenuItem key={items.length} value={false}>{<Translate value="userView"/>}</MenuItem>);
        return items;
    }

    /**
     * Returns items for group select drop down menu
     * @returns {Array} Array of MenuItem components
     */
    getGroupItems() {
        let items = [];
        items.push(<MenuItem key={items.length} value={"-1"} label={<Translate value="groupSelect"/>}
                             >{<Translate value="allGroups"/>}</MenuItem>);
        this.props.groups.map((group, index) =>
            items.push(<MenuItem key={items.length} value={group._id}>{group.name}</MenuItem>)
        );
        return items;
    }

    /**
     * Returns items for user select drop down menu
     * @returns {Array} Array of MenuItem components
     */
    getUserItems() {
        let items = [];
        items.push(<MenuItem key={items.length} value={"-1"} label={<Translate value="userSelect"/>}
                             >{<Translate value="userNone"/>}</MenuItem>);
        if (this.props.users) {
            this.props.users.map((user, index) => {
                if (user.status !== "Pending") {
                    items.push(<MenuItem key={items.length} value={user._id}>{getUserHighscoreName(user, undefined, undefined, this.props.adminView)}</MenuItem>);
                }
            });
        }
        return items;
    }

    /**
     * Returns items for time select drop down menu
     * @returns {Array} Array of MenuItem components
     */
    getTimeItems() {
        const items = [];
        items.push(<MenuItem key={items.length} value={1}>{<Translate value="lastMonth"/>}</MenuItem>);
        items.push(<MenuItem key={items.length} value={3}>{<Translate value="lastMonths3"/>}</MenuItem>);
        items.push(<MenuItem key={items.length} value={6}>{<Translate value="lastMonths6"/>}</MenuItem>);
        items.push(<MenuItem key={items.length} value={-1}>{<Translate value="allTime"/>}</MenuItem>);
        return items;
    }

     /**
     * Returns items for game select drop down menu
     * @returns {Array} Array of MenuItem components
     */
    getGameItems() {
        const items = [];
        items.push(<MenuItem key={items.length} value={"none"}>SELECT GAME</MenuItem>);
        items.push(<MenuItem key={items.length} value={"furnace"}>{<Translate value="furnace"/>}</MenuItem>);
        items.push(<MenuItem key={items.length} value={"distiller"}>{<Translate value="distiller"/>}</MenuItem>);
        items.push(<MenuItem key={items.length} value={"condensing"}>{<Translate value="condensing"/>}</MenuItem>);
        items.push(<MenuItem key={items.length} value={"boiling"}>{<Translate value="boiling"/>}</MenuItem>);
        items.push(<MenuItem key={items.length} value={"heat-transfer"}>{<Translate value="heat-transfer"/>}</MenuItem>);
        return items;
    }

    getMissionIdItems() {
        const items = [];
        items.push(<MenuItem key={items.length} value={-1}>SELECT MISSION</MenuItem>);
        {this.props.misionIdList.map((mission) => {
            items.push(<MenuItem key={items.length} value={mission.missionId}>{mission.name}</MenuItem>);
        })}  
        return items;
    }

    render() {
        const renderAllFilters = this.props.adminView;
        return (
            <div className="statistics-header--admin-filter-container">
                <div className="statistics-header--admin-filter">
                    <Translate value="filters" />  
                </div>
                {this.props.adminView && this.props.superAdminview &&
                    <div>
                        <div className="statistic-title-filter"><Translate value="gameSelect" /></div> 
                        <Tooltip title={I18n.t('selectGameTooltip')} placement="top-end">
                            <Select className={this.state.isDesktop} value={this.props.selectedGame}
                                onChange={this.onGameChange.bind(this)}>
                                {this.getGameItems()}
                            </Select>
                        </Tooltip>
                    </div>
                }
                {this.props.adminView && this.props.superAdminview &&
                    <div>
                        <div className="statistic-title-filter"><Translate value="missionSelect" /></div> 
                        <Select className={this.state.isDesktop} value={this.props.selectedMissionId}
                            onChange={this.onMissionIdChange.bind(this)}>
                            {this.getMissionIdItems()}
                        </Select>
                    </div>
                }
                {this.props.adminView &&
                    <div>
                        <div className="statistic-title-filter"><Translate value="timeSelect" /></div> 
                        <Select className={this.state.isDesktop} value={this.props.selectedTime}
                            onChange={this.onTimeChange.bind(this)}>
                            {this.getTimeItems()}
                        </Select>
                    </div>
                }
                {this.props.adminView &&
                    <div>
                        <div className="statistic-title-filter"><Translate value="singleUserView" /></div> 
                        <Select className={this.state.isDesktop} value={this.props.selectedUser}
                            onChange={this.onUserChange.bind(this)}>
                            {this.getUserItems()}
                        </Select>
                    </div>
                }
                {this.props.adminView &&
                    <div>
                        <div className="statistic-title-filter"><Translate value="groupViewStatistic" /></div> 
                        <Select className={this.state.isDesktop} value={this.props.selectedGroup}
                            onChange={this.onGroupChange.bind(this)}>
                            {this.getGroupItems()}
                        </Select>
                    </div>
                }
                <div>
                    <div className="statistic-title-filter"><Translate value="viewAs" /></div>
                    <Select className={this.state.isDesktop} value={this.props.adminView}
                        onChange={this.onAdminChange.bind(this)}>
                        {this.getAdminItems()}
                    </Select>
                </div>
            </div>

        );
    }
}

StatisticsHeaderAdmin.propTypes = {
    users: PropTypes.array,
    groups: PropTypes.array.isRequired,
    selectedGroup: PropTypes.string.isRequired,
    selectedUser: PropTypes.string.isRequired,
    adminView: PropTypes.bool.isRequired,
    onAdminViewChange: PropTypes.func.isRequired,
    onGroupChange: PropTypes.func,
    onUserChange: PropTypes.func
};

export default StatisticsHeader;
