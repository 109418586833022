import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Translate } from 'react-i18nify';
import Cookies from 'universal-cookie';
import isMobile from 'ismobilejs';
import { NavLink, withRouter, Link } from 'react-router-dom';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { List, ListItem } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import NavigationExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Popover from '@material-ui/core/Popover';
import MenuList from '@material-ui/core/MenuList';

import gamesData from 'games.json';
import SelectPoolDialog from './SelectPoolDialog';
import { getUrlParameterByName } from "./Helpers";
import { getPoolAll, getAllPoolsForUser } from './actions/userpool';
import { getUserHighscoreName } from './StatHelper';
import { fetchUser } from './actions/index';
import { POOL_CHANGE } from './actions/types';

import LoginDialog from './LoginDialog';
import AdminMessage from './AdminMessage';

const cookies = new Cookies();
const titles = {
    home: <Translate value="home" />,
    competition: <Translate value="competition" />,
    distiller: <Translate value="distiller" />,
    statistics: <Translate value="statistics" />,
    servers: <Translate value="serverTitle" />,
    account: <Translate value="account" />,
    aboutUs: <Translate value="aboutUs" />,
    login: <Translate value="login" />,
    selectCompany: <Translate value="selectCompany" />,
	company: <Translate value="company" />,
    help: <Translate value="help" />,
    userguide: <Translate value="userguide" />,
    admin: <Translate value="admin" />,
    games: <Translate value="games" />
};
const textWithSubtitleStyle = {display: "flex", flexDirection: "column", maxWidth: "10vw"};

const MOBILE_WIDTH_LIMIT = 1100;

/**
 * GamesMenu component for selecting game when multiple
 */
class GamesMenu extends Component {
    constructor(props, context) {
        super(props, context);
    }

    state = {
        open: false,
    };

    handleClick = (event) => {
        // This prevents ghost click.
        event.preventDefault();
        this.setState({
            open: true,
            anchorEl: event.currentTarget,
        });
    };

    handleRequestClose = () => {
        this.setState({
            open: false,
        });
    };

    render() {
        const gamesLinks = [];

        if (!this.props.games) {
            return <div></div>;
        }

        const menuLinkStyle = { textDecoration: 'none', display: 'block' }
        const menuStyle = { fontSize: 14, fontWeight: 500 };
        this.props.games.forEach(gameItem => {
            gamesLinks.push(<Link key={gameItem.linkto} to={gameItem.linkto} style={menuLinkStyle} className="game-menu--link">
                <MenuItem
                    style={menuStyle}
                    onClick={this.handleRequestClose}>
                    {gameItem.title}
                </MenuItem></Link> );
        });

        const gameSelectorStyle = { height: 56, color: "inherit" };

        return (
            <div style={gameSelectorStyle}>
                <Button
                    style={gameSelectorStyle}
                    className={"titlebar--button-deselected titlebar--button titlebar--button-auth"}
                    onClick={this.handleClick}
                >{titles.games}
                <NavigationExpandMoreIcon/>
                </Button>
                <Popover
                    open={this.state.open}
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                    transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                    onClose={this.handleRequestClose}
                >
                    <MenuList>
                        {gamesLinks}
                    </MenuList>
                </Popover>
            </div>
        );
    }
}

/**
 * TitleBar component renders Toolbar with tab buttons
 */
class TitleBar extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            showPoolSelect: false,
            isMobile: this.showMobileMenu(),
            companyAutoSelected: false,
        }

        window.addEventListener('suggest_pool_change', event => this.changePool(event.detail.pool));
        this.updateDimensions = this.updateDimensions.bind(this);
    }

    showMobileMenu() {
        return isMobile.any || window.innerWidth < MOBILE_WIDTH_LIMIT
    }

    updateDimensions() {
        const oldValue = this.state.isMobile;
        const newValue = this.showMobileMenu();
        if (oldValue !== newValue) {
            this.setState({ isMobile: newValue });
        }
    }

    componentDidMount() {
        if (this.props.authenticated) {
            this.fetchedPoolDataId = null;
            this.props.fetchUser();
            this.checkFetchPoolData(this.props);
            this.checkAutoSelectPool(this.props);
        }
        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.location.pathname === '/external') {
            return;
        }
        if (!prevProps.authenticated && this.props.authenticated) {
            this.fetchedPoolDataId = null;
            this.setState(() => ({
                companyAutoSelected: false,
            }));
            this.props.fetchUser();
        }

        if (!this.state.companyAutoSelected) {
            this.checkFetchPoolData(this.props);
            this.checkAutoSelectPool(this.props);
        }
    }

    checkAutoSelectPool(checkedProps) {
        if (!checkedProps.authenticated || !checkedProps.profile || (checkedProps.rights && checkedProps.rights.siteAdmin)) {
            return;
        }
        if (checkedProps.company) {
            this._changingCompany = false;
        }
        if (!checkedProps.company && checkedProps.allCompanies) {
            if (checkedProps.allCompanies.length === 1) {           // if user have any license

                const firstPool = checkedProps.allPools && checkedProps.allPools.length > 0 ? checkedProps.allPools[0] : null;
                if (firstPool && cookies.get('token') !== this._autoSelect) {
                    this._autoSelect = cookies.get('token');
                    checkedProps.getPoolAll(firstPool.pool._id , firstPool.pool.name);
                    this.saveSelectedPool(firstPool.pool.name);
                    this.setState(() => ({
                        companyAutoSelected: true,
                    }));
                }
            }
            else if (checkedProps.allCompanies.length === 0) {       // if user don't have any license
                this.setState({
                    showPoolSelect: false,
                    companyAutoSelected: true,
                });
            }
            else if (!this._changingCompany){
                this.setState({
                    showPoolSelect: true,
                    companyAutoSelected: true,
                });
            }
        }
    }

    checkFetchPoolData(checkedProps) {
        if (!checkedProps.authenticated || !checkedProps.profile || checkedProps.profile._id === undefined ||
            checkedProps.allPools || this.fetchedPoolDataId === checkedProps.profile._id) {
            return;
        }

        this.fetchedPoolDataId = checkedProps.profile._id;
        const cookiePoolName = cookies.get('company' + checkedProps.profile._id);
        const urlPoolId = getUrlParameterByName("poolId");

        if (urlPoolId) {
            checkedProps.getPoolAll(urlPoolId);
        }
        else if (cookiePoolName) {
            checkedProps.getPoolAll(null, cookiePoolName);
        }
        else {
            checkedProps.getAllPoolsForUser();
        }
    }

    getTopBarText(mobile) {
        if (mobile) {
            return <Translate value="logout" />;
        }

        return <span style={textWithSubtitleStyle}>
            <Translate value="logout" />
            <span className="titlebar--subtitle">{getUserHighscoreName(this.props.profile, true, true, this.props.rights)}</span>
        </span>;
    }

    selectPoolClick() {
        this.setState({
            showPoolSelect: true
        });
    }

    onCompanySelected(selectedCompany) {
        this.setState({
            showPoolSelect: false
        });

        if (selectedCompany && (!this.props.currentCompany || selectedCompany._id !== this.props.currentCompany._id)) {
            const selectedPool = this.props.allPools.reduce((total, current) => {
                return (!total && current.pool.company && current.pool.company._id === selectedCompany._id) ? current : total;
            }, null);

            if (selectedPool) {
                this.changePool(selectedPool);
            }
        }
    }

    changePool(selectedPool) {
        this._changingCompany = true;
        this.saveSelectedPool(selectedPool.pool.name);
        this.props.dispatch({ type: POOL_CHANGE, companyChange: true });
        this.props.getPoolAll(selectedPool.pool._id, selectedPool.pool.name);
    }

    saveSelectedPool(poolName) {
        cookies.set('company' + this.props.profile._id, poolName, {
            path: '/',
            maxAge: 86400 * 365
        });
    }

    shouldNotRenderPoolSelect() {
        return !this.props.authenticated || !this.props.userPool || this.props.rights.siteAdmin || !this.props.allCompanies ||
            this.props.allCompanies.length <= 1;
    }

    renderPoolSelect() {
        if (this.shouldNotRenderPoolSelect()) {
            return null;
        }

        const companyName = this.props.currentCompany ? this.props.currentCompany.name : "";
        const style = { minWidth: 120 };
        const content = <span style={textWithSubtitleStyle}>
            <span>{titles.company}</span>
			<span className="titlebar--subtitle">{companyName}</span>
        </span>;

        return <a key={"poolselect"}><Button style={style} className={"titlebar--button-deselected titlebar--button titlebar--button-auth"}
            onClick={this.selectPoolClick.bind(this) }>{content}</Button></a>;
    }

    renderGameSelect(games){
        return <GamesMenu key={"gamesmenu"} games={games}/>;
    }

    /**
     * Get games that user has licenses to. Note that some games are shown even when not in any pool (owc)
     * @returns {Array}
     */
    getGamePageItems() {
        if (this.props.userPool && !this.props.company) {
            return [{ pureItem: <Translate key={"missingCompany"} value="missingCompany" /> }];
        }

        const result = [];
        const ownedLicenses = {};
        const pools = this.props.allPools || (this.props.userPool ? [this.props.userPool] : null);
        const today = new Date();
        if (pools) {
            pools.forEach((userPool) => {
                if (userPool.pool === null) {
                    return;
                }
                else if ((Object.prototype.hasOwnProperty.call(userPool, 'valid') && !userPool.valid) || (new Date(userPool.pool.end) < today)) {
                    // Pool has expired, skip
                } else {
                    userPool.pool.games.forEach((gameName) => {
                        ownedLicenses[gameName] = 1;
                    });
                }
            });
        }

        Object.keys(gamesData.games)
            .filter(key => ownedLicenses.hasOwnProperty(key) || gamesData.games[key].hasPreviewMissions)
            .forEach((key) => {
                const game = gamesData.games[key];
                const exactPath = '/' + game.portalRouteLink;

				const owcAuthClassName = this.state.isMobile ? "" : " titlebar--button-auth";
				const owcClassName = "color-orange" + owcAuthClassName;
                let btn;
				if (key === "owc") {
					// btn = { linkto:exactPath, btnType: "TitleBarButton", title: <Translate value={key}/>, additionalClassName: owcClassName };
				}
				else {
					btn = {
                        linkto: exactPath, btnType: "TitleBarButton", title: <Translate value={key}/>, additionalClassName: "titlebar--button-auth"
                    };
				}
                if (btn) {
                    result.push(btn);
                }
            });
        return result;
    }

    getNapconImage() {
        return <img src={napconBuildVersion + "images/napcon_logo.png"} className="titlebar--logo" />
    }

    getTitlebarDataNotAuthenticated(mobile) {
        const leftGrp = [];
        if (!mobile) {
            leftGrp.push({ linkto: "/", btnType: "FlatButton", btnStyle: "logo-button", title: "", noActiveClass: true });
        }
        this.getGamePageItems().forEach(gameItem => {
            leftGrp.push(gameItem);
        });

        const rightGrp = [];
		// rightGrp.push({ linkto: "/games_trial", btnType: "TitleBarButton", title: "Games Trial", additionalClassName: "color-orange" });
        rightGrp.push({ linkto: "/", btnType: "TitleBarButton", title: titles.home });
        // rightGrp.push({ pureUrl: napconSuiteUrl, btnType: "FlatButton", btnStyle: "titlebar--button titlebar--link-external", title: titles.aboutUs });
        rightGrp.push({ pureItem: <LoginDialog key={"loginDialog"} mobile={mobile} />, isLoginDialog: true });
        rightGrp.push({ linkto: "/help", btnType: "TitleBarButton", title: titles.help });
        return { leftGrp, rightGrp }
    }

    getTitlebarDataAuthenticated(mobile) {
        const leftGrp = [];
        if (!mobile) {
            leftGrp.push({ linkto: "/", btnType: "FlatButton", btnStyle: "logo-button logo-button-auth", title: "" });
        }
        const games = this.getGamePageItems();
        if (games.length > 3 && !mobile) {
            leftGrp.push({ pureItem: this.renderGameSelect(games) });
        }
        else {
            games.forEach(gameItem => leftGrp.push(gameItem));
        }

        const rightGrp = [];
		rightGrp.push({ linkto: "/games_trial", btnType: "TitleBarButton", title: "Games Trial", additionalClassName: "color-orange titlebar--button-auth" });
        rightGrp.push({ linkto: "/", btnType: "TitleBarButton", title: titles.home, additionalClassName: "titlebar--button-auth", exact: true });
        /*rightGrp.push({
            pureUrl: napconSuiteUrl, btnType: "FlatButton", btnStyle: "titlebar--button titlebar--link-external titlebar--button-auth", title: titles.aboutUs
        });*/
        rightGrp.push({  linkto: "/help", btnType: "TitleBarButton", title: titles.help, additionalClassName: "titlebar--button-auth" });
        rightGrp.push({  linkto: "/userguide", btnType: "TitleBarButton", title: titles.userguide, additionalClassName: "titlebar--button-auth" });
        if (this.props.rights.siteAdmin) {
            //rightGrp.push({ linkto: "/competition", btnType: "TitleBarButton", title: titles.competition, additionalClassName: "titlebar--button-auth" });
            rightGrp.push({ linkto: "/servers", btnType: "TitleBarButton", title: titles.servers, additionalClassName: "titlebar--button-auth" });
        }
        const someAdmin = this.props.rights.siteAdmin || this.props.rights.poolAdmin || this.props.rights.admin;
        if (someAdmin) {
            rightGrp.push({ linkto: "/admin", btnType: "TitleBarButton", title: titles.admin, additionalClassName: "titlebar--button-auth" });
        }

        rightGrp.push({ linkto: "/statistics", btnType: "TitleBarButton", title: titles.statistics, additionalClassName: "titlebar--button-auth" });
        rightGrp.push({ linkto: "/account", btnType: "TitleBarButton", title: titles.account, additionalClassName: "titlebar--button-auth" });
        if (mobile && !this.shouldNotRenderPoolSelect()) {
            rightGrp.push({ onClick: this.selectPoolClick.bind(this), title: titles.company });
        } else {
            rightGrp.push({ pureItem: this.renderPoolSelect() });
        }
        rightGrp.push({ linkto: "/logout", btnType: "TitleBarButton", title: this.getTopBarText(mobile), additionalClassName: "titlebar--button-auth" });

        return { leftGrp, rightGrp }
    }

    render() {
        if (this.props.location.pathname === '/external') {
            return null;
        }
        const mobile = this.state.isMobile;
        const toolbarItems = this.props.authenticated ? this.getTitlebarDataAuthenticated(mobile) : this.getTitlebarDataNotAuthenticated(mobile);
        return mobile ?
            <MobileMenu {...this.props} showSelectPoolModal={this.state.showPoolSelect} toolbarItems={toolbarItems}
                closePoolHandler={this.onCompanySelected.bind(this)} napconImage={this.getNapconImage()} /> :
            <DesktopMenu {...this.props} showSelectPoolModal={this.state.showPoolSelect} toolbarItems={toolbarItems}
                closePoolHandler={this.onCompanySelected.bind(this)} napconImage={this.getNapconImage()} />;
    }
}

/**
 * Desktop menu
 */
class DesktopMenu extends Component {

    renderToolbarItems(items) {
        return items.map((item, index) => {
            if (item.hasOwnProperty("pureItem")) {
                return item.pureItem;
            }
            const itemKey = item.title.props ? item.title.props.value : item.title;
            switch (item.btnType) {
                case "TitleBarButton":
                    const activeClass = item.noActiveClass === true ? "" : "titlebar--button-selected";
                    return <NavLink exact={!!item.exact} key={`navlink-${index}`} to={item.linkto} activeClassName={activeClass} className={item.additionalClassName}>
                            <button className="MuiButtonBase-root MuiButton-root MuiButton-text titlebar--button titlebar--button-auth" tabIndex="0" type="button">
                                <span className="MuiButton-label">{item.title}</span><span className="MuiTouchRipple-root"></span>
                            </button>
                            </NavLink>;
                case "FlatButton":
                    const btn = <Button className={item.btnStyle} linkto={item.linkto}>{item.title}</Button>;
                    return item.pureUrl ? <a key={itemKey} href={item.pureUrl} target="_blank">{btn}</a> : <a key={itemKey} className="titlebar--logo-link" href={"/"}> {btn}</a>;
                default:
                    console.log("unsupported item:", item);
                    return null;
            }
        });
    }

    render() {
        const titleStyle = this.props.inGame ? "CGAppBar2" : "CGAppBar";
        return (
            <div className="titlebar-container">
                <SelectPoolDialog open={this.props.showSelectPoolModal} allPools={this.props.allPools}
                                  allCompanies={this.props.allCompanies} currentCompany={this.props.currentCompany}
                    closeHandler={this.props.closePoolHandler} currentPool={this.props.userPool} />
                <Toolbar className={titleStyle}>
                    <Toolbar key={this.props.toolbarItems.leftGrp}>
                        {this.renderToolbarItems(this.props.toolbarItems.leftGrp)}
                    </Toolbar>
                    <Toolbar key={this.props.toolbarItems.rightGrp}>
                        {this.renderToolbarItems(this.props.toolbarItems.rightGrp)}
                    </Toolbar>
                </Toolbar>
				<AdminMessage inGame={this.props.inGame} />
            </div>
        );
    }
}


/**
 * Mobile menu using Toolbar and list
 */
class MobileMenu extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            showMobileMenu: false,
			anchorEl: null, // anchor element to be passed for LoginDialog
        }
    }

    renderToolbarItemsMobile(items) {
        return items.map((item) => {
            if (item.hasOwnProperty("pureItem")) {
				if (item.hasOwnProperty("isLoginDialog")) {
					return <div key={"loginDialog"} onClick={this.handleLoginClick.bind(this)}><LoginDialog anchor={this.state.anchorEl} mobile={true} /></div>;
				}
				else {
					return item.pureItem;
				}
            }

            const style = { textTransform: "uppercase" };
            const currentPath = item.linkto && item.linkto === this.props.location.pathname;
            let styleClass = currentPath ? "titlebar--button titlebar--button-selected" : "titlebar--button titlebar--button-deselected";

			let additionalClassName;
			if (item.hasOwnProperty("additionalClassName")) {
				additionalClassName = item.additionalClassName;
				styleClass = styleClass + " " + additionalClassName;
			}
            const itemKey = item.title.props ? item.title.props.value : item.title;
            return <ListItem key={itemKey} style={style} className={styleClass} onClick={this.onMobileMenuItemClick.bind(this, item)}>{item.title}</ListItem>;
        });
    }

	handleLoginClick() {
		this.setState({
            showMobileMenu: false
        });
	}

    onMobileMenuItemClick(item) {
        if (item.linkto) {
            this.props.history.push(item.linkto);
        }
        else if (item.pureUrl) {
            window.open(item.pureUrl, '_blank');
        }
        else if (item.onClick) {
            item.onClick();
        }
        else {
            console.log(item);
        }
        this.onMobileMenuClick();
    }

    onMobileMenuClick() {
        this.setState({
            showMobileMenu: !this.state.showMobileMenu
        });
    }
    onMobileLogoClick() {
        this.props.history.push("/");
    }

	getMobileMenuStyle() {
		return this.state.showMobileMenu ? {display: 'initial'} : {display: 'none'};
	}

	anchor = (event) => {
    // This prevents ghost click.
    event.preventDefault();

    this.setState({
      anchorEl: event.currentTarget
    });
  };


    render() {
        const icon = this.state.showMobileMenu ? <CloseIcon /> : <MenuIcon />;

		let mobileMenuClass;
		let TitleBarClass;
		let iconStyle;
		if (this.props.inGame === true) {
			mobileMenuClass = "mobile-menu positionFixed";
			TitleBarClass = "CGAppBar2";
			iconStyle = {color: 'white'};
		}
		else {
			mobileMenuClass = "mobile-menu";
			TitleBarClass = "CGAppBar";
			iconStyle = {};
		}

        return (
            <div className="titlebar-container-mobile">
                <SelectPoolDialog open={this.props.showSelectPoolModal} allPools={this.props.allPools}
                    allCompanies={this.props.allCompanies} currentCompany={this.props.currentCompany}
                    closeHandler={this.props.closePoolHandler} currentPool={this.props.userPool} />
				<Toolbar onClick={this.anchor} className={TitleBarClass}>
                    <Toolbar>
                        <IconButton style={iconStyle} onClick={this.onMobileMenuClick.bind(this)}>{icon}</IconButton>
                    </Toolbar>
                    <Toolbar>
                        <TitleBarButton navLinkClassName="titlebar--logo-link" additionalClassName="logo-button " linkto={"/"} title={""} />
                    </Toolbar>
                </Toolbar>
                <List style={this.getMobileMenuStyle()} className={mobileMenuClass} >
                    {this.renderToolbarItemsMobile(this.props.toolbarItems.leftGrp)}
                    {this.renderToolbarItemsMobile(this.props.toolbarItems.rightGrp)}
                </List>
				<AdminMessage inGame={this.props.inGame} />
            </div>
        );
    }
}

/**
 * Button acting as a tab in Toolbar
 */
class TitleBarButton extends Component {
    constructor(props, context) {
        super(props, context);
    }

	getClassNames() {
		let flatBtnClassName = "";

		if (this.props.additionalClassName != undefined) {
			flatBtnClassName = "titlebar--button " + this.props.additionalClassName;
		}
		else {
			flatBtnClassName = "titlebar--button";
		}
		return flatBtnClassName;
	}

	getNavLinkClassNames() {
		let navLinkClassName = "";

		if (this.props.navLinkClassName != undefined) {
			navLinkClassName = "titlebar--button-deselected " + this.props.navLinkClassName;
		}
		else {
			navLinkClassName = "titlebar--button-deselected";
		}
		return navLinkClassName;
	}

    render() {
        return (
            <NavLink key={this.props.title} exact={true} activeClassName={this.props.activeClassName} className={this.getNavLinkClassNames()}
                to={this.props.linkto}>
                <Button className={this.getClassNames()}>{this.props.title}</Button>
            </NavLink>
        );
    }
}

TitleBarButton.propTypes = {
    title: PropTypes.node.isRequired,
    linkto: PropTypes.string.isRequired,
    activeClassName: PropTypes.string,
    inGame: PropTypes.bool
};

function mapStateToProps(state) {
    return {
        profile: state.user.profile,
        authenticated: state.auth.authenticated,
        rights: state.user.rights,
        userPool: state.userPool.currentPool,
        allPools: state.userPool.allPools,
        allCompanies: state.userPool.allCompanies,
        currentCompany: state.userPool.currentCompany,
        company: state.userPool.currentCompany
    };
}

const mapDispatchToProps = (dispatch) => {
    const boundActionCreators = bindActionCreators({ fetchUser, getPoolAll, getAllPoolsForUser }, dispatch);
    return { ...boundActionCreators, dispatch };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TitleBar));
