
import React, {Component} from 'react';
import { I18n, Translate } from 'react-i18nify';

import { Dialog, DialogContent, DialogActions, DialogTitle, Paper } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import PrivacyPolicyAgreement from './PrivacyPolicyAgreement';

const texts = {
    title: <Translate value="furnaceTrialRegisterTitle" tag="div" className="register-dialog--title"/>,
	rdy: <Translate value="readyToPlay"/>,
    cancel: <Translate value="cancel"/>,
    email: <Translate value="email"/>,
    nick: <Translate value="nick"/>,
	nickName: <Translate value="nickName" />,
	nickDetails: <Translate value="furnaceTrialNickDetails" style={{color: 'inherit'}} />,
	emailDetails: <Translate value="furnaceTrialEmailDetails" style={{color: 'inherit'}} />,
	missions: <Translate value="furnaceTrialMissions" />,
	fullTrial: <Translate value="fullTrial" className="owc-dialog--right-panel--element"/>,
	practice: <Translate value="practice" className="owc-dialog--right-panel--element"/>,
};

class RegisterFurnaceTrialDialog extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            email: '',
            nick: '',
            confirmed: false,
			isDarkTheme: false,
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.profile) {
            if (nextProps.profile.email && this.state.email !== nextProps.profile.email) {
                this.setState({email: nextProps.profile.email});
            }
            if (nextProps.profile.nick && this.state.nick !== nextProps.profile.nick) {
                this.setState({nick: nextProps.profile.nick});
            }
        }
        if (nextProps.isDarkTheme) {
			this.setState({isDarkTheme: true});
		}
    }

    /**
     * Handler for user actions (button click) from owc registration dialogue
     * @param {any} result
     */
    onClickHandler(result) {
        this.props.clickHandler(result, this.state.email, this.state.nick, this.state.confirmed);
        if (!result) {
            this.setState({ confirmed: false });
            return;
        }
        this.setState({ confirmed: true });
    }

    // If registration fails, this function returns the corresponding error text
    getErrorText(fieldKey, errorMessage) {
      if (typeof(errorMessage) === 'object') {
        if (Object.prototype.hasOwnProperty.call(errorMessage, 'statusText')) {
          return errorMessage.statusText;
        }
        return '';
      }
        if (errorMessage && errorMessage.data && errorMessage.data && (Array.isArray(errorMessage.data.error) || Array.isArray(errorMessage.data.error.validationData))) {
            const params = Array.isArray(errorMessage.data.error)
                ? errorMessage.data.error.map((i) => i.msg)
                : errorMessage.data.error.validationData.map((i) => i.msg);

            return params.indexOf(fieldKey) !== -1 ? I18n.t('invalidData', { params: params.join() }) : "";
        }

        const errorKey = errorMessage && errorMessage.data ? errorMessage.data.message || errorMessage.data.error : "";
      return errorKey && errorKey.indexOf(fieldKey) !== -1 ? I18n.t(errorKey) : "";
    }

    // Validate dialogue's input fields and make sure privacy policy checkbox checked
    verifyInputs() {
        return this.state.email.length > 0 && this.state.nick.length > 0 && this.state.confirmed;
    }

	getThemeStyle() {
		let themeStyle = {
			vagueText: {color: 'rgba(0, 0, 0, 0.3)' },
			rightPanelText: {color: 'inherit'},
			rdyBtn: "btn-filled",
			rdyBtnDsbld: "btn-filled-disabled",
		}

		if (this.state.isDarkTheme) {
			themeStyle.vagueText = {color: 'rgba(255, 255, 255, 0.3)' };
			themeStyle.rightPanelText = {color: 'rgb(255, 255, 255)'};
			themeStyle.rdyBtn = "btn-filled btn-filled--dark";
			themeStyle.rdyBtnDsbld = "btn-filled-disabled--dark";
			return themeStyle;
		}
		return themeStyle;
	}

    render() {
        const errorMessageEmail = this.getErrorText("email", this.props.errorMessage);
        const errorMessageNick = this.getErrorText("nick", this.props.errorMessage);

        const themeStyle = this.getThemeStyle();
        const { classes } = this.props;
		const continueButton = this.verifyInputs() ?
            <Button key={'ready-btn'} className={themeStyle.rdyBtn} onClick={this.onClickHandler.bind(this, true)} disabled={false}>{texts.rdy}</Button>
            :
            <Button key={'ready-btn-disabled'} className={themeStyle.rdyBtnDsbld} disabled={true}>{texts.rdy}</Button>;

        const actions = [
            <Button key={'continue-btn'} className="btn-no-border" onClick={this.onClickHandler.bind(this, false)}>{texts.cancel}</Button>,
            continueButton
        ];

        return (
            <Dialog
				open={this.props.open}
                PaperComponent={Paper}
                PaperProps={{className: "owc-dialog--body"}}
				>
                <DialogTitle disableTypography={true} style={{padding:"10px 24px"}}>{texts.title}</DialogTitle>
                <DialogContent className="owc-dialog--content">
                    <Translate value="furnaceTrialCreateAccount" tag="div" />
                    <div className={"owc-dialog--input-panel"} style={themeStyle.vagueText}>
                        <TextField className="register--textfield" label={texts.nickName}
                        onChange={e => this.setState({nick: e.target.value})} autoComplete="nickname"
                        FormHelperTextProps={{style: themeStyle.vagueText}}
                        disabled={this.props.profile && this.props.profile.nick} error={errorMessageNick !== ""}
                        helperText={errorMessageNick !== "" ? errorMessageNick : texts.nickDetails} maxLength={20} value={this.state.nick}
                        />
                        <TextField className="register--textfield" label={texts.email}
                        onChange={e => this.setState({email: e.target.value})} autoComplete="email"
                        FormHelperTextProps={{style: themeStyle.vagueText}}
                        error={errorMessageEmail !== ""} helperText={errorMessageEmail !== "" ? errorMessageEmail : texts.emailDetails}
                        maxLength={64} value={this.state.email}
                        disabled={this.props.profile && this.props.profile._id} />
                    </div>
                    <PrivacyPolicyAgreement simple={true} onChange={(e) => this.setState({ confirmed: !this.state.confirmed })} />
                </DialogContent>
                <DialogActions className="owc-dialog">{actions}</DialogActions>
                <div className="owc-dialog--right-panel" style={themeStyle.rightPanelText} >
                    <p>{texts.missions}</p>
                    <div>
                        <img className="owc-dialog--right-panel--element" src={napconBuildVersion + "images/mission_practice.png"}  />
                        {texts.practice}
                    </div>
                    <div>
                        <img className="owc-dialog--right-panel--element" src={napconBuildVersion + "images/mission_trial.png"}  />
                        {texts.fullTrial}
                    </div>
                </div>
            </Dialog>
        );
    }
}

function mapStateToProps(state) {
    return {
        errorMessage: state.auth.error,
        message: state.auth.message,
        profile: state.user.profile,
    };
}

export default withRouter(connect(mapStateToProps, { })(RegisterFurnaceTrialDialog));
