import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-i18nify';

/**
 * Component showing total play time and other figures for admin
 */
class AdminTotalStats extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        const texts = {
            playTime: <Translate value="totalPlayTime"/>,
            avgPlayTime: <Translate value="avgPlayTime"/>,
            activeUsers: <Translate value="activeUsersMonth"/>,
            totalPoints: <Translate value="totalPoints"/>,
            lastMonthHighscores: <Translate value="lastMonthHighscores"/>
        };
        return (
            <div className="statistics-container">
                <div className="admin-total-stats">
                    <AdminTotalStatsFigure value={this.props.game.totalPlayTime} description={texts.playTime}/>
                    <AdminTotalStatsFigure value={this.props.game.avgPlayTime} description={texts.avgPlayTime}/>
                    <AdminTotalStatsFigure value={this.props.game.activeUsers} description={texts.activeUsers}/>
                    <AdminTotalStatsFigure value={this.props.game.totalPoints} description={texts.totalPoints}/>
                    <AdminTotalStatsFigure value={this.props.game.lastMonthHighscores}
                                           description={texts.lastMonthHighscores}/>
                </div>
            </div>
        );
    }
}

AdminTotalStats.propTypes = {
    game: PropTypes.object
};

class AdminTotalStatsFigure extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        return (
            <div className="admin-total-stats--figure">
                <div className="admin-total-stats--figure-value">{this.props.value}</div>
                <div className="admin-total-stats--figure-description"> {this.props.description}</div>
            </div>
        );
    }
}

export default AdminTotalStats;