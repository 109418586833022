
import axios from 'axios';
import Cookies from 'universal-cookie';
import { API_URL, errorHandler } from './index';
import { ERROR_RESPONSE, GET_POOL, GET_POOL_USERS, STATIC_ERROR, INVITE_USER, REMOVE_USER, UPDATE_RIGHTS, GET_GROUP,
    PROMOTE_USER, DEMOTE_USER, GET_ALL_POOLS } from './types';

const cookies = new Cookies();

export function inviteUser(poolId, {email, admin, sendInvEmail}) {
    return function (dispatch) {
        axios.post(`${API_URL}/userpool/invite/${poolId}`, { email, admin, sendInvEmail }, {headers: { Authorization: cookies.get('token') }})
            .then((response) => {
                dispatch({type: INVITE_USER, payload: response.data });
                dispatch({type: GET_GROUP, payload: response.data});
            })
            .catch((error) => {
                errorHandler(dispatch, error.response, ERROR_RESPONSE);
            });
    };
}

export function removeUserFromPool(poolId, { user }) {
    return function (dispatch) {
        axios.post(`${API_URL}/userpool/remove/${poolId}`, { user }, {headers: { Authorization: cookies.get('token') }})
            .then((response) => {
                dispatch({type: REMOVE_USER, payload: response.data });
                dispatch({type: GET_GROUP, payload: response.data});
            })
            .catch((error) => {
                errorHandler(dispatch, error.response, ERROR_RESPONSE);
            });
    };
}

export function promoteUser(poolId, { user }) {
    return function (dispatch) {
        axios.post(`${API_URL}/userpool/promote/${poolId}`, { user }, {headers: { Authorization: cookies.get('token') }})
            .then((response) => {
                dispatch({type: PROMOTE_USER, payload: response.data });
                dispatch({type: GET_GROUP, payload: response.data });
            })
            .catch((error) => {
                errorHandler(dispatch, error.response, ERROR_RESPONSE);
            });
    };
}


// Function that sends demote admin to user request and dispatch required events to render the page again
export function demoteUserFromPool(poolId, { user }) {
    return function (dispatch) {
        axios.post(`${API_URL}/userpool/demote/${poolId}`, { user }, { headers: { Authorization: cookies.get('token') } })
            .then((response) => {
                dispatch({ type: DEMOTE_USER, payload: response.data });
                dispatch({ type: GET_GROUP, payload: response.data });
            })
            .catch((error) => {
                errorHandler(dispatch, error.response, ERROR_RESPONSE);
            });
    };
}


/*
export function getPool(poolId, poolName) {
    const param = poolId || "";

    return function (dispatch) {
        axios.get(`${API_URL}/userpool/${param}`, getParams(poolName))
            .then((response) => {
                 dispatch({
                     type: GET_POOL,
                     payload: response.data
                 });
                dispatch({
                    type: UPDATE_RIGHTS,
                    payload: response.data
                });
            })
            .catch((error) => {
                errorHandler(dispatch, error, STATIC_ERROR);
            });
    };
}
*/
export function getAllPoolsForUser() {
    return function (dispatch) {
        axios.get(`/${API_URL}/userpool/`, { headers: { Authorization: cookies.get('token') },})
            .then((response) => {
                dispatch({type: GET_ALL_POOLS, payload: response.data});
            })
            .catch((error) => {
                errorHandler(dispatch, error, STATIC_ERROR);
            });
    };
}

export function getPoolAll(poolId, poolName) {
    const param = poolId || "";
    return function (dispatch) {
        axios.get(`/${API_URL}/userpool/all/${param}`, getParams(poolName))
            .then((response) => {
                dispatch({type: GET_POOL, payload: response.data});
                dispatch({type: GET_POOL_USERS, payload: response.data});
                dispatch({type: GET_GROUP, payload: response.data});
                dispatch({type: UPDATE_RIGHTS, payload: response.data});
                dispatch({type: GET_ALL_POOLS, payload: response.data});
            })
            .catch((error) => {
                errorHandler(dispatch, error, STATIC_ERROR);
            });
    };
}

export function getUsersInPool(poolId) {
    return function (dispatch) {
        axios.get(`/${API_URL}/userpool/users/${poolId}`, { headers: { Authorization: cookies.get('token') },})
            .then((response) => {
                dispatch({
                    type: GET_POOL_USERS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                errorHandler(dispatch, error, STATIC_ERROR);
            });
    };
}

function getParams(poolName) {
    const requestParams = {
        params: { },
        headers: { Authorization: cookies.get('token')}
    };
    if (poolName) {
        requestParams.params.poolName = poolName;
    }
    return requestParams;
}

