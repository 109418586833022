
import axios from 'axios';
import Cookies from 'universal-cookie';
import { API_URL, errorHandler } from './index';
import { ERROR_RESPONSE, GET_ACHIEVEMENTS } from './types';

const cookies = new Cookies();
export function getAchievements(userId, game) {
    return function (dispatch) {
        axios.get(`${API_URL}/achievements/${userId}`, { headers: { Authorization: cookies.get('token') }, params: {game: game} })
            .then((response) => {
                dispatch({
                    type: GET_ACHIEVEMENTS,
                    payload: response.data
                });
            })
            .catch((error) => {
                errorHandler(dispatch, error.response, ERROR_RESPONSE);
            });
    };
}