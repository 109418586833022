
import { GET_ACHIEVEMENTS, ERROR_RESPONSE } from '../actions/types';

const INITIAL_STATE = { data: [], message: '', error: ''};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case ERROR_RESPONSE:
            return { ...state, error: action.payload };
        case GET_ACHIEVEMENTS:
            // Notice level change
            if (action.payload.achievements) {
                if (state.data.length === action.payload.achievements.length) {
                    for (var i = 0; i < action.payload.achievements.length; i++) {
                        let newAch = action.payload.achievements[i];
                        newAch.isNewLevel = (newAch.level !== state.data[i].level);
                    }
                }
            }
            return { ...state, data: action.payload.achievements };
    }
    return state;
}