import React from 'react';
import NavigationButton from './NavigationButton';
import accountImage from '../../public/images/userguide_images/your_account.png';

const YourAccount = () => {
  
  return (
    <div className="content-userguide">
      <h1 className="section-header">Section 3 Your account</h1>
      <img src={accountImage} style={{ width: "100%", margin: "30px 0" }} alt="logo" />
      <div>In the menu, clicking “Account” will take you to your individual account page.</div>
      <div>Here you can change your first and last name, nickname (visible in player statistics and high scores), your occupation, password, which units to use (metric or imperial) and also change the language.</div>
      <div>You will also find the legal information in this section.</div> 
      <h2>Changing language</h2>
      <div>Click “Account” in the menu and scroll down to the language option.</div>
      <div>Here you can select from the available languages. The language will primarily affect the games.</div>
      <div>Click “Save” to confirm your changes.</div>
      <div>Sometimes, you might have to log out and log in again, if the language isn’t updated properly.</div>
      <NavigationButton prevName="Playing Mission" prevLink="/userguide/playingMission" nextName="Game Portal" nextLink="/userguide/gamePortal"/>
    </div>
  );
};


export default YourAccount