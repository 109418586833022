
import Cookies from 'universal-cookie';
import PasswordValidator from 'password-validator';

const cookies = new Cookies();
const OWN_USER_COLOR = '#44b3ea';
const UNITS = [{ value: 'Metric', locKey: 'metric' }, { value: 'Imperial', locKey: 'imperial' }];
const LANGUAGES = [
    { value: 'en', locKey: 'english', isoCode: 'en-US' },
    { value: 'fi', locKey: 'finnish', isoCode: 'fi-FI' },
    { value: 'tr', locKey: 'turkish', isoCode: 'tr-TR' },
];
const MISSIONCOLORS = ['#ACE32B', '#FFBB24', '#0FFFB3', '#E32B5F', '#7149EA', '#E86B18'];
const schema = new PasswordValidator().is().min(8).is().max(64).has().digits(); // .has().uppercase().has().lowercase()


function sendDataToIframe(type, dataObject, noLogWarning) {
    const gameFrame = document.getElementsByTagName('iframe')[0];
    if (gameFrame) {
        dataObject.type = type;
        gameFrame.contentWindow.postMessage(dataObject, '*');
    }
    else if (!noLogWarning) {
        console.log('No iframe to send data to', type, dataObject);
    }
}

function getGetRequestParams(game, groupIds, beginDateMoment, endDateMoment, missionId, status, locale) {
    const requestParams = {
        params: { },
        headers: { Authorization: cookies.get('token') }
    };
    if (groupIds) {
        requestParams.params.groupIds = Array.isArray(groupIds) ? groupIds : [groupIds];
    }
    if (beginDateMoment) {
        requestParams.params.begin = encodeURIComponent(beginDateMoment.toISOString());
    }
    if (endDateMoment) {
        requestParams.params.end = encodeURIComponent(endDateMoment.toISOString());
    }
    if (missionId !== null && missionId !== undefined) {
        requestParams.params.missionId = missionId;
    }
    if (status === 'Abandoned' || status === 'Success') {
        requestParams.params.status = status;
    }
    if (game !== null && game !== undefined) {
        requestParams.params.game = game;
    }
    if (locale !== null && locale !== undefined) {
        requestParams.params.locale = locale;
    }

    return requestParams;
}

function validatePassword(pwd) {

    return schema.validate(pwd)
}


function pushToGTMDataLayer(data)
{
    const dataLayer = window.dataLayer = window.dataLayer || [];
    dataLayer.push(data);
}

function getUrlParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'), results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export { sendDataToIframe, getGetRequestParams, MISSIONCOLORS, validatePassword, UNITS, LANGUAGES, pushToGTMDataLayer,
    OWN_USER_COLOR, getUrlParameterByName };
