import React, { Component } from 'react';
import { connect } from 'react-redux';
import { resetPassword } from './actions/auth';
import { Translate } from 'react-i18nify';
import { validatePassword } from './Helpers';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const texts = {
    forgot: <Translate value="setNewPassword"/>,
    newPassword: <Translate value="newPassword"/>,
    newAgain: <Translate value="newPasswordAgain"/>,
    change: <Translate value="change"/>,
    passwordVerifyError: <Translate value="passwordsDontMatch"/>,
    errorPasswordComplexity: <Translate value="passwordComplexity"/>
};

/**
 *  New password page component.
 */
class NewPasswordPage extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            newPassword: '',
            newPassword2: ''
        }
    }

    componentDidMount() {
        this.onNotificationCallback = this.onNotification.bind(this);
        window.addEventListener('notification', this.onNotificationCallback);
    }

    componentWillUnmount() {
        window.removeEventListener('notification', this.onNotificationCallback);
        this.onNotificationCallback = null;
    }

    onNotification(event) {
    
    }

    onChangeClick() {
        if (this.verifyInputs())  {
            const i = window.location.href.indexOf("?");
            const token = i !== -1 ? window.location.href.substr(i + 1) : "";
            if (token) {
                this.props.resetPassword(token, {password: this.state.newPassword});
                this.props.history.push('/');
            }
        }
    }

    /**
     * Returns text error object or null if pwd ok
     * @returns {object}
     */
    verifyPasswords() {
        if (this.state.newPassword2 && this.state.newPassword) {
            if (this.state.newPassword2 !== this.state.newPassword) {
                return texts.passwordVerifyError;
            }
            if (!validatePassword(this.state.newPassword)) {
                return texts.errorPasswordComplexity;
            }
        }
        return null;
    }

    verifyInputs() {
        return (this.state.newPassword2.length > 0 && this.state.newPassword.length > 0 && this.verifyPasswords() === null);
    }

    render() {
        const passwordErrorMessage = this.verifyPasswords();
        const buttonDisabled = this.verifyInputs() === false;

        return (
            <div>
                <h3>{texts.forgot}</h3>
                <TextField type="password" label={texts.newPassword}
                           onChange={e => this.setState({newPassword: e.target.value})}/>
                <br/>
                <TextField type="password" label={texts.newAgain} error={passwordErrorMessage !== ""} 
                            helperText={passwordErrorMessage} onChange={e => this.setState({newPassword2: e.target.value})}/>
                <br/>
                <Button onClick={this.onChangeClick.bind(this)} disabled={buttonDisabled}>{texts.change}</Button>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) => {
    const boundActionCreators = bindActionCreators({resetPassword}, dispatch);
    return {...boundActionCreators, dispatch};
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewPasswordPage));
