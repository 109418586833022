
import axios from 'axios';
import Cookies from 'universal-cookie';
import { API_URL, errorHandler } from './index';
import { ERROR_RESPONSE, CREATE_ADMIN_MESSAGE, GET_ADMIN_MESSAGE, DELETE_ADMIN_MESSAGE } from './types';

const cookies = new Cookies();

export function createAdminMessage({ text, isAdminMessage}) {

	return function (dispatch) {
		axios.post(`${API_URL}/message/adminMessage`,
			{ text },
			{ headers: { Authorization: cookies.get('token') } })
			.then((response) => {
				dispatch({ type: CREATE_ADMIN_MESSAGE, payload: response.data });
			})
			.catch((error) => {
				errorHandler(dispatch, error.response, ERROR_RESPONSE);
			});
};
}

export function getAdminMessage() {

	return function (dispatch) {
		axios.get(`/${API_URL}/message/adminMessage`)
			.then((response) => {
				dispatch({ type: GET_ADMIN_MESSAGE, payload: response.data });
			})
			.catch((error) => {
				errorHandler(dispatch, error.response, ERROR_RESPONSE);
			});
};
}

export function deleteAdminMessage() {

	return function (dispatch) {
		axios.delete(`${API_URL}/message/adminMessage`,
			{ headers: { Authorization: cookies.get('token') } })
			.then((response) => {
				dispatch({ type: DELETE_ADMIN_MESSAGE, payload: response });
			})
			.catch((error) => {
				errorHandler(dispatch, error.response, ERROR_RESPONSE);
			});
};
}
