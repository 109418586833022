import React, { Component } from 'react';
import { I18n, Translate } from 'react-i18nify';
import Paper from '@material-ui/core/Paper';
import { getAdminMessage } from './actions/message';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';

/**
 * For showing superadmin messages to all the users.
 * Appears on top of the page under the TitleBar in every view except when playing games.
 */
class AdminMessage extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
			visible: false,
            message: this.props.adminMessage,
        };
    }

	componentDidMount() {
		this.props.getAdminMessage();
    }

	componentDidUpdate(prevProps) {
		this.props.getAdminMessage();
		if (this.props.adminMessage !== prevProps.adminMessage || this.props.inGame !== prevProps.inGame) {
			this.updateState();
		}
	}
    updateState() {
		if (this.props.inGame) {
			this.setState({visible: false});
		}
		else if (this.props.adminMessage !== "" && this.props.adminMessage !== undefined) {
			this.setState({visible: true});
			this.setState({message: this.props.adminMessage});
		}
		else {
			this.setState({visible: false});
		}
    }

	onClick() {
		this.setState({visible: false});
	}

	getStyle() {
			return {
				container: {
					visibility: 'visible',
					position: 'sticky',
					width: 'auto',
					display: 'flex',
					backgroundColor: 'transparent',
					justifyContent: 'center',
					height: 'auto',
					borderTop: '1px solid rgba(0, 0, 0, 0.1)',
					boxShadow: 'rgba(0, 0, 0, 0.12) 0px 6px 6px -3px',
					flexWrap: 'wrap',
					backgroundColor: 'white',
					padding: '2px 20px',
					alignItems: 'center',
				},
				text: {
					flexShrink: '1',
					color: '#ff8461',
					borderRadius: '2px',
					backgroundColor: 'white',
					textAlign: 'center',
				},
				button: {
					transform: 'scale(0.7)',
					backgroundColor: '#42aae0',
					color: 'white',
				}
		};
	}

	getContents() {
		if (this.state.visible) {

			const styles = this.getStyle();
			const texts = {
				close: <Translate value="close" />,
			};


	        return (
				<div style={styles.container} >
	                <div style={styles.text} >{this.props.adminMessage}</div>
					<Button style= {styles.button} onClick={e => this.onClick(e)}>
						{texts.close}
					</Button>
	            </div>
			);
		}
		else {
			return null;
		}
	}

    render() {
		const contents = this.getContents();
        return (
			<div>
				{contents}
			</div>
        );
    }
}

function mapStateToProps(state) {
    return {
		adminMessage: state.message.adminMessage
    };
}

export default connect(mapStateToProps, { getAdminMessage })(AdminMessage);
