
import React, {Component} from 'react';
import Button from '@material-ui/core/Button';

class BottomButtonBar extends Component {
    render() {
		const buttonStyle = { height: 'auto', lineHeight: 'auto', backgroundColor: this.props.color || 'inherit', padding: "0 80px 0 0" };
        const fontSize = {fontSize: this.props.fontSize || "10px"};

        return (
            <Button variant="contained" onClick={e => this.props.onClick(e)} style={buttonStyle}
                    fullWidth={true}>
                <div style={fontSize}>{this.props.centerText}</div>
                <div style={{ position: "absolute", right: 0, top: 0, height: "100%", width: "100px" }}>
                    { this.props.buttonText ?
                        <Button className={"titlebar--consent-ok-button"}>{this.props.buttonText}</Button> : null
                    }
                </div>
            </Button>
        );
    }
}

export default BottomButtonBar;
