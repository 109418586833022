import React, { Component } from 'react';
import 'url-search-params-polyfill';
import { I18n, Translate } from 'react-i18nify';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Cookies from 'universal-cookie';
import { loginExternalUser } from './actions/auth';
import { completeExternalTraining } from './actions/stats';
import DistillationGamePage from './DistillationGamePage';
import RegisterExternalUserDialog from './RegisterExternalUserDialog';
import { getPoolAll, getAllPoolsForUser } from './actions/userpool';
import { fetchUser } from './actions/index';
import { errorHandler } from './actions/index';
import { ERROR_RESPONSE } from './actions/types';

const cookies = new Cookies();

class ExternalGamePage extends Component {

  constructor(props, context) {
    super(props, context);
    this.props.gameShownCallback(true);
    const query = new URLSearchParams(this.props.location.search);
    this.extToken = query.get('t');
    this.source = query.get('s');
    this.gameId = query.get('game');
    this.key = query.get('key');
    this.state = {
      statusMessage: 'Please hold while we sign you in...'
    };
  }

  componentDidMount() {
    this.props.loginExternalUser(this.extToken, this.gameId, this.source, this.key);
  }

  componentDidUpdate(prevProps) {
    // update status message if something in auth/login status changes
    if (prevProps.isRegisterRequired !== this.props.isRegisterRequired
      || prevProps.tempProfile !== this.props.tempProfile
      || prevProps.errorMessage !== this.props.errorMessage) {
      const message = this.getLoginStatusMessage();
      this.setState({
        statusMessage: message,
      });
    }
    // after registration of external user is done, log them in "for real" to get the mission information etc.
    if (prevProps.registrationCompleted !== this.props.registrationCompleted && this.props.registrationCompleted) {
      this.props.loginExternalUser(this.extToken, this.gameId, this.source, this.key);
    }
    if (this.props.authenticated && this.props.addedPool) {
      if (!this.props.profile) {
        this.props.fetchUser();
      }
      if (this.props.profile && !this.props.allPools) {
        this.checkFetchPoolData(this.props);
      } else if (this.props.allPools && !this.props.userPool) {
        this.checkAutoSelectPool(this.props);
      }
    }
  }

  checkFetchPoolData(props) {
    if (!props.authenticated || !props.profile || props.profile._id === undefined || props.allPools) {
      return;
    }
    props.getAllPoolsForUser();
  }

  checkAutoSelectPool(props) {
    if (!props.authenticated || !props.profile || (props.rights && props.rights.siteAdmin)) {
      return;
    }
    if (!props.company && props.allCompanies) {
      if (props.allCompanies.length === 1) { // if user has any license
        const firstPool = props.allPools.length > 0 ? props.allPools[0] : null;
        if (firstPool) {
          props.getPoolAll(firstPool.pool._id, firstPool.pool.name);
          this.saveSelectedPool(firstPool.pool.name);
        }
      } else if (props.allCompanies.length === 0) { // if user doesn't have any license --> this should not happen. Error in server side?
        errorHandler(() => { }, "Server error", ERROR_RESPONSE);
      } else {
        const selectedPool = this.props.allPools.reduce((total, current) => {
          return (!total && current.pool._id === props.addedPool) ? current : total; // select the pool that was added for user
        }, null);
        if (selectedPool) {
          this.selectPool(selectedPool);
        } else {
          errorHandler(() => { }, "Server error", ERROR_RESPONSE); // for some reason user was not added to correct pool -> Server error
        }
      }
    }
  }

  selectPool(selectedPool) {
    this.saveSelectedPool(selectedPool.pool.name);
    this.props.getPoolAll(selectedPool.pool._id, selectedPool.pool.name);
  }

  saveSelectedPool(poolName) {
    cookies.set(`company${this.props.profile._id}`, poolName, {
      path: '/',
      maxAge: 86400 * 365,
    });
  }

  sendCompleteToExternalService = () => {
    this.props.completeExternalTraining(this.extToken, this.source);
  }

  /**
   * Returns status message. If there is an error message present, return that, else ask customer to hold.
   * @returns {string}
   */
  getLoginStatusMessage() {
    if (this.props.errorMessage && this.props.errorMessage.status) {
      let errorMsg = 'Unknown error';
      if (Object.prototype.hasOwnProperty.call(this.props.errorMessage.data, 'error')) {
        if (Array.isArray(this.props.errorMessage.data.error.validationData)) {
          errorMsg = this.props.errorMessage.data.error.validationData.map((err) => I18n.t(err.msg) || err.msg).join(', ');
        } else if (typeof(this.props.errorMessage.data.error) === 'string' && this.props.errorMessage.data.error) {
          errorMsg = I18n.t(this.props.errorMessage.data.error) || this.props.errorMessage.data.error;
        }
      }
      return <Translate value="errorWithMsgStatus" message={errorMsg} status={this.props.errorMessage.status} />;
    }
    // no localization required as customer language is not known before login
    return 'Please hold while we sign you in...';
  }

  getSigninWaitingDialog() {
    return <Dialog open={true} className="waiting-dialog--bg"
    PaperComponent={Paper}
    PaperProps={{ style: { backgroundColor: 'transparent' } }}>
  <DialogContent className="waiting-dialog--content">
      {this.state.statusMessage}
    {this.props.errorMessage ? null : <CircularProgress className="waiting-dialog--progress" />}
  </DialogContent>
    </Dialog>;
  }

  getRegisterUserDialog() {
    return <RegisterExternalUserDialog email={this.props.tempProfile.email} firstName={this.props.tempProfile.firstName} lastName={this.props.tempProfile.lastName} />;
  }

  render() {
    const gamePage = <DistillationGamePage {...this.props}
    gameId={this.gameId}
    missionId={this.props.mission}
    gameShownCallback={this.props.gameShownCallback}
    onExternalComplete={this.sendCompleteToExternalService} />;
    const overlay = this.props.isRegisterRequired && this.props.tempProfile ? this.getRegisterUserDialog() : this.getSigninWaitingDialog();
    return (<div>
      {!this.props.authenticated || this.props.isRegisterRequired ? overlay : null}
    {gamePage}
  </div>);
  }
}

ExternalGamePage.propTypes = {
  gameShownCallback: PropTypes.func,
  loginExternalUser: PropTypes.func,
  completeExternalTraining: PropTypes.func,
  authenticated: PropTypes.bool,
  isRegisterRequired: PropTypes.bool,
  registrationCompleted: PropTypes.bool,
  tempProfile: PropTypes.object,
  mission: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
    isRegisterRequired: state.auth.isRegisterRequired,
    registrationCompleted: state.auth.registrationCompleted,
    addedPool: state.auth.addedPool, // pool that was automatically assigned to the user
    mission: state.auth.mission, // mission according to the key
    tempProfile: state.auth.tempProfile,
    errorMessage: state.auth.error,
    userPool: state.userPool.currentPool,
    allPools: state.userPool.allPools,
    allCompanies: state.userPool.allCompanies,
    currentCompany: state.userPool.currentCompany,
    company: state.userPool.currentCompany,
    profile: state.user.profile,
  };
}

export default withRouter(connect(mapStateToProps, { loginExternalUser, completeExternalTraining, getPoolAll, getAllPoolsForUser, fetchUser })(ExternalGamePage));
