
import axios from 'axios';
import Cookies from 'universal-cookie';
import { API_URL, errorHandler } from './index';
import { ERROR_RESPONSE, CREATE_COMPANY, UPDATE_COMPANY, SEARCH_COMPANY, DELETE_COMPANY, STATIC_ERROR } from './types';

const cookies = new Cookies();

export function createCompany({ name }) {
    return (dispatch) => {
        axios.post(`${API_URL}/company/create`, { name }, { headers: { Authorization: cookies.get('token') }})
            .then((response) => {
                dispatch({ type: CREATE_COMPANY, payload: response });
            })
            .catch((error) => {
                errorHandler(dispatch, error.response, ERROR_RESPONSE);
            });
    };
}

export function updateCompany(companyId, { name }) {
    return (dispatch) => {
        axios.post(`${API_URL}/company/update/${companyId}`, { name }, {headers: { Authorization: cookies.get('token') }})
            .then((response) => {
                dispatch({ type: UPDATE_COMPANY, payload: response });
            })
            .catch((error) => {
                errorHandler(dispatch, error.response, ERROR_RESPONSE);
            });
    };
}

export function searchCompanies(searchValue) {
    return (dispatch) => {
        const urlEnd = searchValue ? 'search/' + encodeURIComponent(searchValue) : 'list/all';

        axios.get(`${API_URL}/company/${urlEnd}`, {headers: { Authorization: cookies.get('token') },})
            .then((response) => {
                dispatch({
                    type: SEARCH_COMPANY,
                    payload: response.data.results,
                });
            })
            .catch((error) => {
                errorHandler(dispatch, error, STATIC_ERROR);
            });
    };
}

export function deleteCompany(companyId) {
    return (dispatch) => {
        axios.delete(`${API_URL}/company/${companyId}`, {headers: { Authorization: cookies.get('token') },})
            .then((response) => {
                dispatch({
                    type: DELETE_COMPANY,
                    payload: response.data
                });
            })
            .catch((error) => {
                errorHandler(dispatch, error, STATIC_ERROR);
            });
    };
}
